import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import {
  Invoices as EmbedInvoices,
  type EmbedContextProps,
  type InvoicesProps,
} from "@schematichq/schematic-react";
import { titlecase } from "@utils/strings";
import { useMemo } from "react";
import * as Settings from "../../controls/Sidebar/Settings";
import { Select, TextInput } from "../../ui";

type FontStyle = keyof EmbedContextProps["settings"]["theme"]["typography"];
type ThemeSettings = EmbedContextProps["settings"]["theme"];

export const Invoices: UserComponent = () => {
  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as InvoicesProps,
  }));

  return (
    <EmbedInvoices
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      {...props}
    />
  );
};

const InvoicesSettings = () => {
  const { theme } = useEditor((state) => {
    return {
      theme: state.nodes.ROOT.data.props.settings.theme as ThemeSettings,
    };
  });

  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as InvoicesProps,
  }));

  const typographyOptions: { value: FontStyle; label: string }[] = useMemo(
    () => [
      ...Object.keys(theme.typography).map((key) => ({
        value: key as FontStyle,
        label: titlecase(key),
      })),
    ],
    [theme.typography],
  );

  return (
    <Settings.Root title="📄 Invoices" category="Billing">
      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.header.isVisible}
          onVibibilityChange={() => {
            setProp((props: InvoicesProps) => {
              const updated = !props.header.isVisible;
              props.header.isVisible = updated;
            });
          }}
        >
          Header
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.fontStyle as string}
            defaultValue={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.fontStyle,
            )}
            onChange={(option) => {
              setProp((props: InvoicesProps) => {
                props.header.fontStyle = option!.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.date.isVisible}
          onVibibilityChange={() => {
            setProp((props: InvoicesProps) => {
              const updated = !props.date.isVisible;
              props.date.isVisible = updated;
            });
          }}
        >
          Date
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.date.fontStyle as string}
            defaultValue={typographyOptions.find(
              (opt) => opt.value === nodeProps.date.fontStyle,
            )}
            onChange={(option) => {
              setProp((props: InvoicesProps) => {
                props.date.fontStyle = option!.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.amount.isVisible}
          onVibibilityChange={() => {
            setProp((props: InvoicesProps) => {
              const updated = !props.amount.isVisible;
              props.amount.isVisible = updated;
            });
          }}
        >
          Amount
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.date.fontStyle as string}
            defaultValue={typographyOptions.find(
              (opt) => opt.value === nodeProps.date.fontStyle,
            )}
            onChange={(option) => {
              setProp((props: InvoicesProps) => {
                props.date.fontStyle = option!.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.limit.isVisible}
          onVibibilityChange={() => {
            setProp((props: InvoicesProps) => {
              const updated = !props.limit.isVisible;
              props.limit.isVisible = updated;
            });
          }}
        >
          Limit
        </Settings.Header>

        <Settings.Input label="Limit">
          <TextInput
            value={nodeProps.limit.number}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProp((props: InvoicesProps) => {
                props.limit.number = parseInt(event.target.value);
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.collapse.isVisible}
          onVibibilityChange={() => {
            setProp((props: InvoicesProps) => {
              const updated = !props.collapse.isVisible;
              props.collapse.isVisible = updated;
            });
          }}
        >
          See all link
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.collapse.fontStyle as string}
            defaultValue={typographyOptions.find(
              (opt) => opt.value === nodeProps.collapse.fontStyle,
            )}
            onChange={(option) => {
              setProp((props: InvoicesProps) => {
                props.collapse.fontStyle = option!.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

Invoices.craft = {
  displayName: "Invoices",
  props: {
    header: {
      isVisible: true,
      fontStyle: "heading4",
    },
    date: {
      isVisible: true,
      fontStyle: "link",
    },
    amount: {
      isVisible: true,
      fontStyle: "text",
    },
    limit: {
      isVisible: true,
      number: 2,
    },
    collapse: {
      isVisible: true,
      fontStyle: "link",
    },
  },
  related: {
    settings: InvoicesSettings,
  },
};
