import { ReactNode } from "react";

export interface ErrorProps {
  children?: ReactNode;
}

export const Error = ({ children }: ErrorProps) => {
  return (
    <div className="bg-red-50 text-red-400 text-xs py-2 px-3 rounded-md mt-2 ">
      {children}
    </div>
  );
};
