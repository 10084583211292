import { EventsBlankState } from "@components/blanks/pages/EventsBlankState";
import { Pill } from "@components/ui/Pill";
import { listEvents } from "@data/events";
import { EventDetailResponseData, ListEventsParams } from "@models/api";
import { EventOverlay } from "@modules/events";
import { ColumnDef } from "@tanstack/react-table";
import { CompanyCell } from "@ui/CompanyCell";
import { InfiniteTable } from "@ui/InfiniteTable";
import { TableHeader } from "@ui/TableHeader";
import { UserInitials } from "@ui/UserInitials";
import { formatDate } from "@utils/date";

import { useMemo, useState } from "react";

interface CompanyUserEventsTableProps {
  userId: string;
}

export const CompanyUserEventsTable = ({
  userId,
}: CompanyUserEventsTableProps) => {
  const [eventOverlay, setEventOverlay] = useState<string | null>(null);
  const [filter] = useState<ListEventsParams>({ userId });

  const columns = useMemo<ColumnDef<EventDetailResponseData>[]>(
    () => [
      {
        id: "timestamp",
        accessorKey: "timestamp",
        cell: (cellInfo) => {
          return (
            <div className="inline-block space-y-1">
              <div className="leading-none text-gray-400">
                {formatDate(cellInfo.row.original.capturedAt)}
              </div>
            </div>
          );
        },
      },
      {
        id: "company",
        header: "Company",
        accessorKey: "company",
        cell: (cellInfo) => {
          const company = cellInfo.row.original.company;
          return <CompanyCell company={company} />;
        },
      },
      {
        id: "user",
        header: "User",
        accessorKey: "user",
        cell: (cellInfo) => {
          const user = cellInfo.row.original.user;
          if (!user) return null;
          return <UserInitials name={user.name} showName={true} size="xs" />;
        },
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "type",
        cell: (cellInfo) => {
          const { subtype, type } = cellInfo.row.original;
          return <Pill>{subtype || type}</Pill>;
        },
      },
      {
        id: "properties",
        header: "Properties",
        accessorKey: "properties",
        cell: (cellInfo) => {
          return (
            <div className="inline-block max-w-full overflow-hidden">
              <div className="leading-none text-gray-400 overflow-hidden max-w-[300px] text-ellipsis">
                {cellInfo.row.original.bodyPreview}
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div>
        <TableHeader headerText="Event Stream" />

        <InfiniteTable
          queryKey={["events"]}
          queryFn={listEvents}
          filter={filter}
          columns={columns}
          onRowClick={(row: EventDetailResponseData) => setEventOverlay(row.id)}
          blankStateComponent={<EventsBlankState type="static" />}
        />
      </div>

      {eventOverlay && (
        <EventOverlay id={eventOverlay} onClose={() => setEventOverlay(null)} />
      )}
    </>
  );
};
