import { FeatureLifecyclePhase } from "@models/feature";
import { PillStyleTypes } from "@ui/Pill";

export const pillColorFeatureLifecyclePhase = newPillColorLookup({
  [FeatureLifecyclePhase.Inactive]: "gray",
  [FeatureLifecyclePhase.InternalTesting]: "red",
  [FeatureLifecyclePhase.Alpha]: "yellow",
  [FeatureLifecyclePhase.Beta]: "yellow",
  [FeatureLifecyclePhase.GA]: "green",
  [FeatureLifecyclePhase.InPlan]: "blue",
  [FeatureLifecyclePhase.AddOn]: "blue",
  [FeatureLifecyclePhase.Legacy]: "red",
  [FeatureLifecyclePhase.Deprecated]: "gray",
});

const defaultPillColor = "blue" as PillStyleTypes;

export function newPillColorLookup<T extends string>(
  map: Record<T, PillStyleTypes>,
): (val: T) => PillStyleTypes {
  return (val: T) => {
    return map[val] || defaultPillColor;
  };
}
