import { CreateOrUpdateConditionRequestBodyMetricPeriodEnum } from "@models/api";
import { PlanType } from "@models/plan";
import { IconNameTypes } from "@ui/Icon/consts";

export const MetricPeriodDisplay = {
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Day]: "per day",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Month]: "per month",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Week]: "per week",
};

export const MetricPeriodDisplayShortHand = {
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Day]: "/day",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Month]: "/mo",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Week]: "/week",
};

export const PlanTypeDisplayName: Record<PlanType, string> = {
  [PlanType.Plan]: "Plan",
  [PlanType.AddOn]: "Add-on",
};

export const PlanTypeIcon: Record<PlanType, IconNameTypes> = {
  [PlanType.Plan]: "connection",
  [PlanType.AddOn]: "connection",
};

export enum PlanRoutePaths {
  Plans = "plans",
  AddOns = "plan-add-ons",
  Configuration = "plan-configuration",
}

export const PriceIntervalDisplayName: Record<string, string> = {
  day: "/day",
  week: "/week",
  month: "/mo",
  year: "/year",
};
