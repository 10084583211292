import { TableLoader } from "@components/loaders/TableLoader";
import { usePermission } from "@hooks/usePermission";
import useTablePagination from "@hooks/useTablePagination";
import { ComponentResponseData, ListComponentsParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { ComponentType, ComponentTypeDisplay } from "@models/component";
import { FeaturesTableEmptyFilterState } from "@modules/features";
import { ColumnDef } from "@tanstack/react-table";
import { ButtonProps } from "@ui/Button";
import { DropdownDots } from "@ui/DropdownDots";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import pluralize from "pluralize";
import React, { SyntheticEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ComponentsBlankState } from "./ComponentsBlankState";
import { ComponentDeleteOverlay } from "./overlays";
import { countComponents, listComponents } from "../queries";

export interface ComponentsTableProps {
  onCreate?: () => void;
}

export const ComponentsTable = ({ onCreate }: ComponentsTableProps) => {
  const navigate = useNavigate();
  const [filter] = useState<ListComponentsParams>({});

  const [deleteOverlay, setDeleteOverlay] =
    useState<ComponentResponseData | null>();

  const componentEditAllowed = usePermission(
    ClerkUserPermission.components_edit,
  );

  const columns = useMemo<ColumnDef<ComponentResponseData>[]>(() => {
    return [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "type",
        size: 140,
        cell: (cellInfo) => {
          const { type } = cellInfo.row.original;

          return <div>{ComponentTypeDisplay[type as ComponentType]}</div>;
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const component = cellInfo.row.original;

          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Delete",
                    disabled: !componentEditAllowed,
                    onClick: () => {
                      setDeleteOverlay(component);
                    },
                  },
                  {
                    label: "Edit",
                    disabled: !componentEditAllowed,
                    onClick: () => {
                      navigate(component.id);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [componentEditAllowed]);

  const getHeaderText = (count: number) => {
    return pluralize("Components", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<ComponentResponseData, ListComponentsParams>(
    ["components"],
    listComponents,
    countComponents,
    filter,
    getHeaderText,
  );

  const onRowClick = (row: ComponentResponseData, e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    navigate(row.id);
  };

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const headerButtons: ButtonProps[] = [
    {
      children: <>New component</>,
      color: "blue",
      disabled: !componentEditAllowed,
      onClick: onCreate,
    },
  ];

  const noComponentsCreated = countQuery?.data?.count === 0;
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderComponentsTable = () => {
    switch (true) {
      case noComponentsCreated:
        return (
          <ComponentsBlankState
            onCreate={onCreate}
            disabled={!componentEditAllowed}
          />
        );
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeaturesTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              onRowClick={onRowClick}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  return (
    <div className="pb-16">
      {!noComponentsCreated && (
        <TableHeader headerText={headerText} buttons={headerButtons} />
      )}
      {renderComponentsTable()}

      {deleteOverlay && (
        <ComponentDeleteOverlay
          component={deleteOverlay}
          onClose={() => {
            setDeleteOverlay(null);
          }}
        />
      )}
    </div>
  );
};
