import {
  LoadEntityOptions,
  useLoadEntityOptions,
} from "@hooks/useLoadEntityOptions";
import { Error } from "@ui/Error";
import { SelectAsync } from "@ui/Select";
import cx from "classnames";
import { useField } from "formik";
import { ReactNode, useState } from "react";

export interface FormikSelectProps<Entity = any, EntityFilter = any> {
  label?: string;
  name: string;
  options?: Option[];
  placeholder?: string;
  description?: string | null;
  selectedOption?: any;
  disabled?: boolean;
  creatableLabel?: string;
  defaultInputValue?: any;
  defaultOptions?: boolean;
  isMulti?: boolean;
  maxNumberOfSelected?: number;
  loadOptions?: (ftl?: EntityFilter) => Promise<Entity[]>;
  loadOptionsMappers?: LoadEntityOptions<Entity, EntityFilter>;
  onChange?: (option: any) => void;
  onCreate?: (option: any) => Promise<Option> | Option;
  nullable?: boolean;
  creatable?: boolean;
  className?: string;
  [key: string]: unknown;
}

export interface Option {
  readonly label: string | ReactNode;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  readonly value: any;
}

export const FormikAsyncSelect = ({
  label,
  name,
  placeholder,
  description,
  selectedOption,
  disabled = false,
  loadOptions,
  loadOptionsMappers,
  onChange,
  onCreate,
  isMulti = false,
  maxNumberOfSelected = 0,
  nullable = false,
  creatable = false,
  creatableLabel,
  className,
  ...rest
}: FormikSelectProps) => {
  const [, meta, helpers] = useField(name);
  const { error, touched } = meta;

  const [isLoading] = useState(false);
  const [value, setValue] = useState<any>(selectedOption);

  const optionValue = (opt: Option) => {
    const val = opt?.value;
    if (nullable && !val) {
      return null;
    }

    return val;
  };

  const handleChange = (selected: Option | Option[]) => {
    onChange && onChange(selected);
    setValue(selected);
    const formValue = isMulti
      ? (selected as Option[]).map((item) => optionValue(item))
      : optionValue(selected as Option);
    helpers.setValue(formValue);
  };

  const handleCreate = async (selected: Option | Option[]) => {
    if (!onCreate) {
      return;
    }

    const newOption = await onCreate(selected);

    setValue(newOption);
    const formValue = optionValue(newOption);
    helpers.setValue(formValue);
  };

  const { loadEntityOptions } = useLoadEntityOptions(
    loadOptions,
    loadOptionsMappers,
  );

  return (
    <div className={cx("w-full", className)}>
      <SelectAsync
        name={name}
        label={label}
        value={value}
        placeholder={placeholder}
        isLoading={isLoading}
        onChange={handleChange}
        onBlur={() => helpers.setTouched(true)}
        disabled={disabled}
        loadOptions={loadEntityOptions}
        onCreate={handleCreate}
        isClearable
        isMulti={isMulti}
        maxNumberOfSelected={maxNumberOfSelected}
        creatable={creatable}
        creatableLabel={creatableLabel}
        {...rest}
      />
      {description && (
        <div className="text-xs text-gray-400 mt-2">{description}</div>
      )}
      {error && touched && <Error>{error}</Error>}
    </div>
  );
};
