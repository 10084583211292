import { useCallback, useEffect, useState } from "react";

export const useLocalStorage = <T = string>(key: string, defaultValue?: T) => {
  const setItem = useCallback(
    (value: T) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );

  const getItem = () => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.log(error);
    }
  };

  const removeItem = () => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
  };

  const [value, setValue] = useState<T>(() => {
    return getItem();
  });

  useEffect(() => {
    setItem(value);
  }, [value, setItem]);

  return [
    value,
    setValue,
    {
      setItem,
      getItem,
      removeItem,
    },
  ] as const;
};
