import { ReactNode } from "react";

export interface CompanyDetailsProps {
  image?: ReactNode;
  title?: string | ReactNode;
  label?: string | ReactNode;
}

export const ProfileDetails = ({
  image,
  title,
  label,
}: CompanyDetailsProps) => {
  return (
    <div className="flex items-center">
      {image && <div className="mr-6">{image}</div>}
      <div className="flex flex-col space-y-1.5">
        {title && (
          <div className="flex items-center text-2xl leading-none">{title}</div>
        )}
        <div className="flex flex-row items-center space-x-4">
          {label && <div className="text-sm text-gray-400">{label}</div>}
        </div>
      </div>
    </div>
  );
};
