import { Error } from "@ui/Error";
import { Select } from "@ui/Select";

import cx from "classnames";
import { useField } from "formik";

export interface FormiSelectProps {
  label?: string;
  name: string;
  options?: any;
  placeholder?: string;
  className?: string;
  selectedOption?: any;
  disabled?: boolean;
  onChange: (option: any) => void;
  [key: string]: unknown;
}

export const FormikSelectSearch = ({
  label,
  name,
  placeholder,
  options,
  className,
  selectedOption,
  disabled = false,
  onChange,
  ...rest
}: FormiSelectProps) => {
  const [, meta, helpers] = useField(name);
  const { value, error, touched } = meta;

  return (
    <div className={cx("w-full", className)}>
      <Select
        name={name}
        label={label}
        value={value}
        onChange={(option) => {
          onChange(option);
          helpers.setValue(null);
        }}
        options={options}
        placeholder={placeholder}
        onBlur={() => helpers.setTouched(true)}
        selectedOption={selectedOption}
        disabled={disabled}
        isClearable
        {...rest}
      />
      {error && touched && <Error>{error}</Error>}
    </div>
  );
};
