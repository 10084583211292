import cx from "classnames";
import { ButtonHTMLAttributes } from "react";

export type ButtonStyleTypes = "blue" | "white" | "red" | "black";
export type ButtonSizeTypes = "2xs" | "xs" | "sm" | "md" | "lg";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonStyleTypes;
  className?: string;
  size?: ButtonSizeTypes;
}

export const Button = ({
  color = "white",
  size = "md",
  onClick,
  disabled = false,
  children,
  className,
  ...props
}: ButtonProps) => {
  const sizeMap = {
    "2xs": "button-2xs",
    xs: "button-xs",
    sm: "button-sm",
    md: "button-md",
    lg: "button-lg",
  };

  const disabledStyles = "button-disabled";
  const styleMap = {
    blue: "button-blue",
    white: "button-white",
    red: "button-red",
    black: "button-black",
  };

  const styles = cx(
    "button",
    disabled ? disabledStyles : styleMap[color],
    sizeMap[size],
    className,
  );

  return (
    <button className={styles} onClick={onClick} disabled={disabled} {...props}>
      {children}
    </button>
  );
};
