import { Pill } from "@components/ui/Pill";
import { listEvents } from "@data/events";
import { EventDetailResponseData, ListEventsParams } from "@models/api";
import { EventType } from "@models/events";
import { EventOverlay } from "@modules/events";
import { ColumnDef } from "@tanstack/react-table";
import { CompanyCell } from "@ui/CompanyCell";
import { InfiniteTable } from "@ui/InfiniteTable";
import { TableHeader } from "@ui/TableHeader";
import { UserInitials } from "@ui/UserInitials";
import { formatDate } from "@utils/date";
import { useMemo, useState } from "react";

import { CompanyEventsBlankState } from "../blank-states/CompanyEventsBlankState";

interface CompanyEventsTableProps {
  companyId: string;
  eventType?: EventType;
  headerText: string;
  emptyStateTitle: string;
  emptyStateMessage: string;
}

export const CompanyEventsTable = ({
  companyId,
  eventType,
  headerText,
  emptyStateTitle,
  emptyStateMessage,
}: CompanyEventsTableProps) => {
  const [eventOverlay, setEventOverlay] = useState<string | null>(null);
  const [filter] = useState<ListEventsParams>({
    eventTypes: eventType ? [eventType] : undefined,
    companyId,
  });

  const columns = useMemo<ColumnDef<EventDetailResponseData>[]>(
    () => [
      {
        id: "name",
        accessorKey: "name",
        cell: (cellInfo) => {
          const event = cellInfo.row.original;
          return (
            <div className="inline-block space-y-1">
              <Pill text="code">{event.subtype || event.type}</Pill>
            </div>
          );
        },
      },
      {
        id: "timestamp",
        accessorKey: "timestamp",
        cell: (cellInfo) => {
          return (
            <div className="inline-block space-y-1">
              <div className="leading-none text-gray-400">
                {formatDate(cellInfo.row.original.capturedAt)}
              </div>
            </div>
          );
        },
      },
      {
        id: "company",
        header: "Company",
        accessorKey: "company",
        cell: (cellInfo) => {
          const company = cellInfo.row.original.company;
          return <CompanyCell company={company} />;
        },
      },
      {
        id: "user",
        header: "User",
        accessorKey: "user",
        cell: (cellInfo) => {
          const user = cellInfo.row.original.user;
          if (!user) return null;
          return (
            <UserInitials
              name={user.name}
              showName={true}
              size="xs"
              className=" inline-flex items-center"
              truncate
            />
          );
        },
      },
      {
        id: "properties",
        header: "Properties",
        accessorKey: "properties",
        cell: (cellInfo) => {
          return (
            <div className="inline-block max-w-full overflow-hidden">
              <div className="leading-none text-gray-400 overflow-hidden max-w-[300px] text-ellipsis">
                {cellInfo.row.original.bodyPreview}
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <div>
        <TableHeader headerText={headerText} />

        <InfiniteTable
          queryKey={["events"]}
          queryFn={listEvents}
          filter={filter}
          columns={columns}
          onRowClick={(row: EventDetailResponseData) => setEventOverlay(row.id)}
          blankStateComponent={
            <CompanyEventsBlankState
              title={emptyStateTitle}
              message={emptyStateMessage}
            />
          }
        />
      </div>

      {eventOverlay && (
        <EventOverlay id={eventOverlay} onClose={() => setEventOverlay(null)} />
      )}
    </>
  );
};
