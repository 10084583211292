import { Icon } from "@ui/Icon";
import cx from "classnames";
import React from "react";

interface SidebarControlProps {
  onClick: () => void;
  className?: string;
}

export const SidebarControl = ({ onClick, className }: SidebarControlProps) => {
  return (
    <div className="absolute top-12 right-full z-[51]">
      <div
        className={cx(
          "transition-all cursor-pointer rounded-tl-full border-r-0 rounded-bl-full border border-gray-300 bg-white px-1.5 shadow-md",
          className,
        )}
        onClick={onClick}
      >
        <Icon name="sidebar" className="text-2xl leading-none" />
      </div>
    </div>
  );
};
