import { EntityType } from "@models/entityKey";
import { SettingsBox } from "@ui/SettingsBox";
import { EntityKeyDefinitionsTable } from "../tables/EntityKeyDefinitionsTable";
import { EntityTraitDefinitionsTable } from "../tables/EntityTraitDefinitionsTable";

export const KeysAndTraitsPage = () => {
  return (
    <div className="h-full flex flex-col w-full space-y-12 max-w-full overflow-hidden">
      <SettingsBox
        title="Keys"
        description="These keys are unique identifiers that can be used via the API or by other systems to reference companies and users in Schematic."
      >
        <div className="pt-2">
          <h3 className="text-lg font-semibold mb-4">Companies</h3>
          <EntityKeyDefinitionsTable entityType={EntityType.Company} />
        </div>
        <div className="pt-6">
          <h3 className="text-lg font-semibold mb-4">Users</h3>
          <EntityKeyDefinitionsTable entityType={EntityType.User} />
        </div>
      </SettingsBox>

      <SettingsBox
        title="Traits"
        description="Define traits in Schematic to enable targeting based on Company, User, and Plan attributes."
      >
        <div className="pt-2">
          <h3 className="text-lg font-semibold mb-4">Companies</h3>
          <EntityTraitDefinitionsTable entityType={EntityType.Company} />
        </div>
        <div className="pt-6">
          <h3 className="text-lg font-semibold mb-4">Users</h3>
          <EntityTraitDefinitionsTable entityType={EntityType.User} />
        </div>
      </SettingsBox>
    </div>
  );
};
