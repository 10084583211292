import { useEditor } from "@craftjs/core";
import { type EmbedContextProps } from "@schematichq/schematic-react";
import cx from "classnames";

export const Columns = () => {
  const { actions, settings } = useEditor((state) => {
    return {
      settings: state.nodes.ROOT.data.props
        .settings as EmbedContextProps["settings"],
    };
  });

  return (
    <div className="flex gap-[1.125rem] p-0.5 border border-[#E6E6E6] rounded-md">
      {[1, 2, 3].map((n) => {
        const sharedProps = {
          className: "group flex gap-0.5 hover:cursor-pointer",
          tabIndex: 0,
          "aria-label": `${n} columns`,
          onClick: () => {
            actions.setProp("ROOT", (props: EmbedContextProps) => {
              props.settings.theme.numberOfColumns = n as 1 | 2 | 3;
            });
          },
        };

        const sharedChildProps =
          settings.theme.numberOfColumns === n
            ? "bg-[#194BFB]"
            : "bg-[#E6E6E6] group-hover:bg-[#D5D5D5]";

        switch (n) {
          case 1:
            return (
              <div key={n} {...sharedProps}>
                <div
                  className={cx("w-9 h-6 rounded-[5px]", sharedChildProps)}
                />
              </div>
            );
          case 2:
          default:
            return (
              <div key={n} {...sharedProps}>
                <div
                  className={cx("w-4 h-6 rounded-[5px]", sharedChildProps)}
                />
                <div
                  className={cx("w-4 h-6 rounded-[5px]", sharedChildProps)}
                />
              </div>
            );
          case 3:
            return (
              <div key={n} {...sharedProps}>
                <div
                  className={cx("w-3 h-6 rounded-[5px]", sharedChildProps)}
                />
                <div
                  className={cx("w-3 h-6 rounded-[5px]", sharedChildProps)}
                />
                <div
                  className={cx("w-3 h-6 rounded-[5px]", sharedChildProps)}
                />
              </div>
            );
        }
      })}
    </div>
  );
};
