import { ListFeaturesParams } from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";
import { CreateFeatureReq, Feature, UpdateFeatureReq } from "@models/feature";
import { deleteReq, get, post, put } from "./index";

export const countFeatures = async (
  flt?: ListFeaturesParams,
): Promise<Count> => {
  return get<Count, ListFeaturesParams>("features/count", flt);
};

export const createFeature = async (
  req: CreateFeatureReq,
): Promise<Feature> => {
  return post<Feature, CreateFeatureReq>("features", req);
};

export const deleteFeature = async (id: string): Promise<Delete> => {
  return deleteReq(`features/${id}`);
};

export const getFeature = async (featureId: string): Promise<Feature> => {
  return get<Feature>(`features/${featureId}`);
};

export const listFeatures = async (
  flt?: ListFeaturesParams,
): Promise<Feature[]> => {
  return get<Feature[], ListFeaturesParams>("features", flt);
};

export const updateFeature = async (
  featureId: string,
  req: UpdateFeatureReq,
): Promise<Feature> => {
  return put<Feature, UpdateFeatureReq>(`features/${featureId}`, req);
};
