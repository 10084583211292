import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { Icon } from "@ui/Icon";
import React from "react";

type CallToActionProps = {
  title: string;
  description: string;
  actionText: string;
  onAction: () => void;
  onClose: () => void;
};

export const CallToAction = ({
  title,
  description,
  actionText,
  onAction,
  onClose,
}: CallToActionProps) => {
  return (
    <Alert
      background="schematic"
      size="sm"
      style="gray"
      className="text-center mb-8"
    >
      <div
        onClick={() => onClose && onClose()}
        className="inline-flex absolute z-10 text-black/50 top-1 right-1 hover:cursor-pointer hover:text-blue-400"
      >
        <Icon name="close" className="text-3xl" />
      </div>
      <div className="flex flex-row justify-between items-center w-full flex-1 relative px-8 z-10 text-gray">
        <div className="text-lg font-medium font-body">{title}</div>
        <div className="text-base leading-6 text-gray-600">{description}</div>

        <Button onClick={onAction}>{actionText}</Button>
      </div>
    </Alert>
  );
};
