import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { ButtonStyleTypes } from "@ui/Button";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Icon } from "@ui/Icon";
import { MiniObject } from "@ui/MiniObject";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { User } from "@ui/User";
import React, { useMemo, useState } from "react";
import { deleteTeammate } from "../../queries/team";
import { OrganizationMembership } from "../tables/TeamTable";

type TeammateDeleteOverlayProps = {
  onClose: () => void;
  organizationMembership: OrganizationMembership;
};

export const TeammateDeleteOverlay = ({
  onClose,
  organizationMembership,
}: TeammateDeleteOverlayProps) => {
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const miniObjectColumns = useMemo<ColumnDef<OrganizationMembership>[]>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        cell: () => {
          const { publicUserData, publicMetadata } = organizationMembership;
          const firstName =
            (publicMetadata.firstName as string) || publicUserData.firstName;
          const lastName =
            (publicMetadata.lastName as string) || publicUserData.lastName;

          let displayName = publicUserData.userId;
          if (firstName && lastName) {
            displayName = `${firstName} ${lastName}`;
          } else if (firstName) {
            displayName = firstName;
          } else if (lastName) {
            displayName = lastName;
          }

          return (
            <div className="flex">
              <User image={publicUserData.imageUrl} name={displayName} />
            </div>
          );
        },
      },
      {
        id: "email",
        header: "Email",
        accessorKey: "publicMetadata.email",
        cell: () => {
          return (
            <div className="text-sm text-gray-400">
              {organizationMembership.publicMetadata.email ||
                organizationMembership.publicUserData.identifier}
            </div>
          );
        },
      },
    ],
    [organizationMembership],
  );

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteTeammate(organizationMembership.publicUserData.userId!);
      setApiError(undefined);

      onClose();
    } catch (error) {
      setApiError(errorMessage(error));
    }
    setLoading(false);
  };

  const actionButtons = [
    {
      children: "Cancel",
      onClick: onClose,
    },
    {
      children: "Remove teammate",
      color: "blue" as ButtonStyleTypes,
      onClick: handleDelete,
    },
  ];

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="md" className="pb-12">
        <div
          onClick={onClose}
          className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-3xl" />
        </div>

        <div className="p-12 space-y-4">
          <div className="mb-8 space-y-2">
            <div className="text-2xl">Remove teammate?</div>

            <div>
              Are you sure you want to remove this teammate from Schematic?
            </div>
          </div>

          <Alert style="yellow" size="xs" className="flex">
            <div className="mr-3">
              <Icon
                name="exclamation-rounded-filled"
                className="text-2xl leading-none text-yellow-300"
              />
            </div>
            <div>
              If this user was a flag and/or feature maintainer, ownership will
              be transferred to you.
            </div>
          </Alert>

          <MiniObject
            columns={miniObjectColumns}
            size="sm"
            showHeader={false}
          />
        </div>

        {apiError && (
          <div className="px-2">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {apiError}
                </div>
              </div>
            </Alert>
          </div>
        )}

        <div className="flex justify-end px-12">
          <ButtonGroup buttons={actionButtons} />
        </div>
      </OverlayModal>
    </Overlay>
  );
};
