import * as Form from "@radix-ui/react-form";
import cx from "classnames";
import { ReactNode } from "react";

import { Icon } from "../Icon";

type InputSizes = "sm" | "md" | "lg";
interface SearchBarProps {
  children?: ReactNode;
  size?: InputSizes;
  placeholder?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar = ({
  size = "md",
  className,
  onChange,
  ...props
}: SearchBarProps) => {
  const sizeMap = {
    sm: "outline-none border-[1px] border-blue-200 shadow-sm bg-white p-2 pl-11 rounded-lg h-[40px] text-sm",
    md: "outline-none border-[1px] border-blue-200 shadow-md bg-white p-2 pl-11 rounded-lg h-[50px]",
    lg: "",
  };
  const inputStyles = cx("search-bar", sizeMap[size]);

  let debounceTimeout: NodeJS.Timeout | undefined;
  const debouncedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      onChange(e);
    }, 300);
  };

  // TODO: Use formik, remove radix ui form package

  return (
    <div className={cx("mx-auto", className)} {...props}>
      <div className="">
        <Form.Root className="w-full flex">
          <Form.Field className="w-full " name="email">
            <div className="relative">
              <Form.Submit asChild>
                <button className="Button" title={props.placeholder}>
                  <Icon
                    name="search"
                    className="text-2xl absolute top-[50%] translate-y-[-50%] left-3"
                  />
                </button>
              </Form.Submit>

              <Form.Control asChild className="w-full pr-16">
                <input
                  className={inputStyles}
                  type="email"
                  placeholder={props.placeholder}
                  onChange={debouncedOnChange}
                  required
                />
              </Form.Control>
            </div>
          </Form.Field>
        </Form.Root>
      </div>
    </div>
  );
};
