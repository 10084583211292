import { deleteReq, get, post, put } from "@data/index";
import {
  ListUsersParams,
  UpsertUserRequestBody,
  UserDetailResponseData,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";

export const countCompanyUsers = async (
  flt?: ListUsersParams,
): Promise<Count> => {
  return get<Count, ListUsersParams>("company-users/count", flt);
};

export const getCompanyUser = async (
  companyUserId: string,
): Promise<UserDetailResponseData> => {
  return get<UserDetailResponseData>(`company-users/${companyUserId}`);
};

export const listCompanyUsers = async (
  flt?: ListUsersParams,
): Promise<UserDetailResponseData[]> => {
  return get<UserDetailResponseData[], ListUsersParams>("company-users", flt);
};

export const createCompanyUser = async (
  req: UpsertUserRequestBody,
): Promise<UserDetailResponseData> => {
  return post<UserDetailResponseData, UpsertUserRequestBody>(
    "company-users",
    req,
  );
};

export const deleteCompanyUser = async (id: string): Promise<Delete> => {
  return deleteReq(`company-users/${id}`);
};

export const updateCompanyUser = async (
  id: string,
  req: UpsertUserRequestBody,
): Promise<UserDetailResponseData> => {
  return put<UserDetailResponseData, UpsertUserRequestBody>(
    `company-users/${id}`,
    req,
  );
};
