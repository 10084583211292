import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { BlankStateOnCreate } from "@ui/PageBlankState";

export const PlanEntitlementsTableBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <Alert
      background="schematic"
      size="lg"
      style="gray"
      className="text-center mt-6"
    >
      <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
        <div className="flex flex-col text-left items-start space-y-2">
          <div className="text-[25px] font-medium font-body">
            📦 Add a plan entitlement
          </div>
          <div className="text-lg leading-6 text-gray-600">
            Include this feature into one of your plans when you’re ready to go
            to market.
            <br />
            Once in plan, it will be accessible to any customers that have that
            plan.
          </div>
        </div>
        <div className="flex items-center">
          {onCreate && (
            <Button color="blue" onClick={onCreate} disabled={disabled}>
              Add plan entitlement
            </Button>
          )}
        </div>
      </div>
    </Alert>
  );
};

export const CompanyPlanEntitlementsTableBlankState = ({
  onCreate,
}: BlankStateOnCreate) => {
  return (
    <Alert size="lg" style="gray" className="text-center mt-6">
      <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
        <div className="flex flex-col text-left items-start space-y-2">
          <div className="text-[25px] font-medium font-body">
            👥 Assign a plan
          </div>
          <div className="text-lg leading-6 text-gray-600">
            Add this company to a plan audience to assign a plan and all of its
            feature <br />
            entitlements to this company.
          </div>
        </div>
        <div className="flex items-center">
          {onCreate && (
            <Button color="white" onClick={onCreate}>
              Go to plans
            </Button>
          )}
        </div>
      </div>
    </Alert>
  );
};
