import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import {
  useEmbed,
  PaymentMethod as EmbedPaymentMethod,
  type EmbedContextProps,
  type PaymentMethodProps,
} from "@schematichq/schematic-react";
import { Button } from "@ui/Button";
import { titlecase } from "@utils/strings";
import { useMemo } from "react";
import * as Settings from "../../controls/Sidebar/Settings";
import { Checkbox, Select } from "../../ui";

type FontStyle = keyof EmbedContextProps["settings"]["theme"]["typography"];
type ThemeSettings = EmbedContextProps["settings"]["theme"];

export const PaymentMethod: UserComponent = () => {
  const { rootElement } = useEditor((state) => {
    return {
      rootElement: state.nodes.ROOT.dom,
    };
  });

  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as PaymentMethodProps,
  }));

  return (
    <EmbedPaymentMethod
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      portal={rootElement}
      {...props}
    />
  );
};

const PaymentMethodSettings = () => {
  const { theme } = useEditor((state) => {
    return {
      theme: state.nodes.ROOT.data.props.settings.theme as ThemeSettings,
    };
  });

  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as PaymentMethodProps,
  }));

  const { setLayout } = useEmbed();

  const typographyOptions: { value: FontStyle; label: string }[] = useMemo(
    () => [
      ...Object.keys(theme.typography).map((key) => ({
        value: key as FontStyle,
        label: titlecase(key),
      })),
    ],
    [theme.typography],
  );

  return (
    <Settings.Root title="💳 Payment Method" category="Billing">
      <Settings.Section>
        <Settings.Header>Related Views</Settings.Header>
        <Settings.Input
          label={
            <>
              <div>Payment Method Manager</div>
              <div className="text-[0.8125rem] text-[#939393]">
                Triggered edit button
              </div>
            </>
          }
        >
          <Button
            className="py-1 px-3 !text-[#194BFB] rounded-[5px] hover:!text-white !border-[#E2E5E9]"
            color="white"
            size="xs"
            onClick={() => {
              setLayout("payment");
            }}
          >
            View
          </Button>
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.header.isVisible}
          onVibibilityChange={() => {
            setProp((props: PaymentMethodProps) => {
              const updated = !props.header.isVisible;
              props.header.isVisible = updated;
            });
          }}
        >
          Header
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PaymentMethodProps) => {
                props.header.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header>Functions</Settings.Header>

        <Settings.Input label="Allow Edit">
          <Checkbox
            checked={nodeProps.functions.allowEdit}
            onChange={() => {
              setProp((props: PaymentMethodProps) => {
                const updated = !props.functions.allowEdit;
                props.functions.allowEdit = updated;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

PaymentMethod.craft = {
  displayName: "Payment Method",
  props: {
    header: {
      isVisible: true,
      fontStyle: "heading4",
    },
    functions: {
      allowEdit: true,
    },
  } satisfies PaymentMethodProps,
  related: {
    settings: PaymentMethodSettings,
  },
};
