import { get } from "@data/index";
import {
  ClerkOrganizationMembershipPublicUserData,
  ClerkOrganizationMembershipPublicUserDataFilter,
} from "@models/clerkUser";

export const listClerkOrganizationUsers = async (
  flt?: ClerkOrganizationMembershipPublicUserDataFilter,
): Promise<ClerkOrganizationMembershipPublicUserData[]> => {
  return get<
    ClerkOrganizationMembershipPublicUserData[],
    ClerkOrganizationMembershipPublicUserDataFilter
  >("users", flt);
};
