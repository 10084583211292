export const SinglePageLoader = () => {
  const LoaderRow = () => {
    return (
      <div className="flex items-center justify-between border-b-2 border-b-gray-100 py-6 px-4">
        <div className="w-26 flex items-center">
          <div className="bg-gray-200 rounded-full h-9 w-16 min-w-[2.25rem] max-w-[2.25rem] min-h-[2.25rem] mr-4" />
          <div className="h-3 bg-gray-200 rounded w-24"></div>
        </div>
        <div className="h-4 bg-gray-200 rounded w-24"></div>
        <div className="h-4 bg-gray-200 rounded w-28"></div>
        <div className="h-4 bg-gray-200 rounded w-20"></div>
        <div className="h-4 bg-gray-200 rounded w-24"></div>
        <div className="h-4 bg-gray-200 rounded w-20"></div>
      </div>
    );
  };

  return (
    <div
      role="status"
      className="fixed top-0 left-0 w-full h-full pt-[81px] z-50 opacity-90 animate-pulse"
    >
      <div className="bg-gray-200">
        <div className="tw-wrapper space-y-12 py-6">
          <div className="flex items-start">
            <div className="flex flex-1 items-start justify-start space-x-4">
              <div className="h-4 bg-gray-300 rounded w-20 w-"></div>
              <div className="h-4 bg-gray-300 rounded w-20"></div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex space-x-4">
              <div className="bg-gray-300 rounded-full w-12 h-12"></div>
              <div className="space-y-2">
                <div className="h-6 bg-gray-300 rounded w-28"></div>
                <div className="h-4 bg-gray-300 rounded w-28"></div>
              </div>
            </div>

            <div className="flex flex-1 items-end justify-end space-x-8">
              <div className="h-9 bg-gray-300 rounded w-28"></div>
              <div className="h-9 bg-gray-300 rounded w-28"></div>
              <div className="h-9 bg-gray-300 rounded w-28"></div>
            </div>
          </div>
          <div className="flex items-center pt-2">
            <div className="flex flex-1 items-start justify-start space-x-4">
              <div className="h-5 bg-gray-300 rounded w-20"></div>
              <div className="h-5 bg-gray-300 rounded w-20"></div>
              <div className="h-5 bg-gray-300 rounded w-20"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-wrapper mt-10 hidden">
        <div className="w-full h-full">
          <div className="flex items-center">
            <div className="h-6 bg-gray-200 rounded w-48"></div>
            <div className="flex flex-1 items-end justify-end space-x-2">
              <div className="h-9 bg-gray-200 rounded-full w-60"></div>
              <div className="h-9 bg-gray-200 rounded w-20 w-"></div>
              <div className="h-9 bg-gray-200 rounded w-20"></div>
            </div>
          </div>
          <div className="p-0 border-2 border-gray-100 rounded-lg">
            <div className="flex items-start  justify-between p-4 border-b-2 border-b-gray-100">
              <div className="flex items-center">
                <div className="h-3 bg-gray-200 rounded w-36"></div>
              </div>
              <div className="h-4 bg-gray-200 rounded w-24"></div>
              <div className="h-4 bg-gray-200 rounded w-28"></div>
              <div className="h-4 bg-gray-200 rounded w-20"></div>
              <div className="h-4 bg-gray-200 rounded w-24"></div>
              <div className="h-4 bg-gray-200 rounded w-20"></div>
            </div>

            <div className="flex flex-col pb-0">
              {Array.from({ length: 10 }, (_, i) => (
                <LoaderRow key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
