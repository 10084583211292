import { FormikControl } from "@components/forms/FormikControl";
import { Alert } from "@components/ui/Alert";
import { Button } from "@components/ui/Button";
import { FormRow } from "@components/ui/FormParts";
import * as integrationsApi from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import { StripeIntegrationDeleteOverlay } from "@modules/settings/components/overlays/StripeIntegrationOverlay/StripeIntegrationDeleteOverlay";
import { StripeIntegrationInstallOverlay } from "@modules/settings/components/overlays/StripeIntegrationOverlay/StripeIntegrationInstallOverlay";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useMutation } from "@tanstack/react-query";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Toast } from "@ui/Toast";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

export const stripeApiKeySchema = Yup.string()
  .matches(/^(sk_|sk_test|rk_|rk_test)/, {
    message: (
      <>
        API key must start with
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          sk_
        </span>{" "}
        or
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          rk_
        </span>
      </>
    ),
    excludeEmptyString: true,
  })
  .test("is-valid-key", "API key must not start with 'pk_'", (value) => {
    return !/^pk_/.test(value || "");
  })
  .required("API key is required");

export const stripePublicApiKeySchema = Yup.string()
  .matches(/^(pk_|pk_test)/, {
    message: (
      <>
        Public API key must start with
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          pk_
        </span>{" "}
        or
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          pk_test_
        </span>
      </>
    ),
    excludeEmptyString: true,
  })
  .test("is-valid-key", "API key must not start with 'sk_'", (value) => {
    return !/^sk_/.test(value || "");
  })
  .required("Public API key is required");

export const StripeIntegrationForm = () => {
  const getIntegrations = useContextQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => integrationsApi.listIntegrations({ type: "stripe" }),
  });

  const customerImportEnabled = useSchematicFlag(
    "integration.stripe.customers.import",
    {
      fallback: true,
    },
  );

  const [refreshedIntegrationData, setRefreshedIntegrationData] = useState<
    boolean | null
  >(false);

  const refreshIntegrationMutation = useMutation({
    mutationFn: integrationsApi.refreshIntegrationData,
    onMutate: async () => {
      setRefreshedIntegrationData(true);
    },
  });

  const [removeIntegration, setRemoveIntegration] = useState<string | null>(
    null,
  );

  const [showCustomerImportInstallFlow, setShowCustomerImportInstallFlow] =
    useState<boolean>(false);

  const [stripeIntegrationSecretKey, setStripeIntegrationSecretKey] = useState<
    string | null
  >(null);

  const [stripeIntegrationPublicKey, setStripeIntegrationPublicKey] = useState<
    string | null
  >();

  const installIntegrationValidationSchema = Yup.object({
    api_key: stripeApiKeySchema,
    public_key: stripePublicApiKeySchema,
  });

  const initialValues = {
    api_key: "",
    public_key: "",
  };

  const installIntegrationMutation = useMutation({
    mutationFn: integrationsApi.installIntegration,
  });

  if (getIntegrations.isPending) {
    return <div></div>;
  }

  const callStripeInstallOverlay = async (props: {
    api_key: string;
    public_key: string;
  }) => {
    if (customerImportEnabled) {
      setStripeIntegrationSecretKey(props.api_key);
      setStripeIntegrationPublicKey(props.public_key);
      setShowCustomerImportInstallFlow(true);
    } else {
      await installIntegrationMutation.mutateAsync({
        type: "stripe",
        config: {
          api_key: props.api_key,
          public_key: props.public_key,
        },
      });
      getIntegrations.refetch();
    }
  };

  if (getIntegrations.data?.length === 0) {
    return (
      <div>
        {showCustomerImportInstallFlow && (
          <StripeIntegrationInstallOverlay
            onClose={() => {
              getIntegrations.refetch();
              setShowCustomerImportInstallFlow(false);
            }}
            stripeApiKey={stripeIntegrationSecretKey!}
            stripePublicKey={stripeIntegrationPublicKey!}
          />
        )}
        <Formik
          className="flex flex-col"
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={callStripeInstallOverlay}
          validationSchema={installIntegrationValidationSchema}
        >
          {(form) => {
            const keyValue: string = form.getFieldMeta("api_key")
              .value as string;

            const rkKey = keyValue.startsWith("rk_");

            return (
              <Form>
                <FormRow className="items-start">
                  <div className="flex-1">
                    <FormikControl
                      control="input"
                      label="Stripe API key"
                      name="api_key"
                      placeholder="Enter API key to connect"
                      type="text"
                      description={
                        <>
                          Use a Stripe {` `}
                          <Link
                            className="text-blue-400 hover:underline hover:opacity-80"
                            to={`https://docs.stripe.com/keys#obtain-api-keys`}
                            target="_blank"
                          >
                            Secret Key or Restricted Key
                          </Link>
                        </>
                      }
                    />

                    {rkKey && (
                      <Alert size="xs" style="yellow" className="mt-4">
                        {" "}
                        Because you are using a{" "}
                        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
                          rk_
                        </span>{" "}
                        key, permisions must be added manually to this key
                      </Alert>
                    )}
                  </div>

                  <div className="flex-1">
                    <FormikControl
                      control="input"
                      label="Stripe Publishable API key"
                      name="public_key"
                      placeholder="Enter Publishable API key to connect"
                      type="text"
                      description={
                        <>
                          Use a Stripe {` `}
                          <Link
                            className="text-blue-400 hover:underline hover:opacity-80"
                            to={`https://docs.stripe.com/keys#obtain-api-keys`}
                            target="_blank"
                          >
                            Publishable Key
                          </Link>
                        </>
                      }
                    />
                  </div>

                  <Button
                    type="submit"
                    className="mt-[25px]"
                    color="blue"
                    size="lg"
                    disabled={!form.dirty || !form.isValid}
                  >
                    Connect to Stripe
                  </Button>
                </FormRow>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }

  return (
    <div className="flex items-stretch space-x-12">
      <div>
        {removeIntegration && (
          <StripeIntegrationDeleteOverlay
            onClose={() => {
              setRemoveIntegration(null);
              getIntegrations.refetch();
            }}
            integrationId={getIntegrations.data![0].id}
          />
        )}

        <ButtonGroup
          buttons={[
            {
              onClick: () => setRemoveIntegration(getIntegrations.data![0].id),
              children: <>Remove connection</>,
            },
            {
              onClick: () =>
                refreshIntegrationMutation.mutateAsync(
                  getIntegrations.data![0].id,
                ),
              size: "sm",
              color: "white",
              disabled: refreshIntegrationMutation.isPending,
              children: <>Refresh data</>,
            },
          ]}
        ></ButtonGroup>

        <Toast
          title={
            <div className="flex space-x-3 items-center">
              <img
                alt="Schematic Loader"
                src="/schematic-load.gif"
                className=" select-none -ml-2 w-6 h-6 inline-block"
              />
              <div>Integration data is being refreshed</div>
            </div>
          }
          open={refreshedIntegrationData!}
          setOpen={(state) => {
            setRefreshedIntegrationData(state);
          }}
        ></Toast>
      </div>
    </div>
  );
};
