import { ReactNode } from "react";
import { Alert } from "../Alert";
import { BlankState } from "../BlankState";
import { ButtonProps } from "../Button";
import { LinkProps, LinksList } from "../LinksList";

export type BlankStateOnCreate = {
  onCreate?: () => void;
  disabled?: boolean;
};

export interface BlankStateLinksProps {
  links?: LinkProps[] | null;
  title?: string;
}

export interface PageBlankStateProps {
  buttons?: ButtonProps[];
  description?: string | ReactNode;
  links?: BlankStateLinksProps;
  title: string;
  type?: "static" | "fixed";
}

export const PageBlankState = ({
  title = "This is a blank state title",
  description = "Blank state description.",
  links,
  buttons,
  type = "fixed",
}: PageBlankStateProps) => {
  const disableLinks = true;
  return (
    <BlankState
      title={title}
      description={description}
      buttons={buttons}
      type={type}
    >
      {!disableLinks && links && (
        <Alert
          size="sm"
          className="flex flex-col items-center justify-center mt-8"
        >
          <div className="label-lg">{links.title}</div>

          {links?.links && (
            <LinksList
              className="text-center flex flex-col items-center"
              links={links.links}
            />
          )}
        </Alert>
      )}
    </BlankState>
  );
};
