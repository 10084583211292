export enum EntityTraitType {
  Boolean = "boolean",
  Currency = "currency",
  Date = "date",
  Number = "number",
  String = "string",
  URL = "url",
}

export const EntityTraitTypes = [
  EntityTraitType.Boolean,
  EntityTraitType.Currency,
  EntityTraitType.Date,
  EntityTraitType.Number,
  EntityTraitType.String,
  EntityTraitType.URL,
];

export const ComparableEntityTraitTypes = [
  EntityTraitType.Currency,
  EntityTraitType.Date,
  EntityTraitType.Number,
];

export enum EntityType {
  Company = "company",
  User = "user",
}
