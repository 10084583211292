import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import {
  useContextQuery,
  useContextSetQueryData,
} from "@hooks/useContextQuery";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { usePermission } from "@hooks/usePermission";
import {
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
  CreateOrUpdateConditionRequestBodyOperatorEnum,
  CreateOrUpdateRuleRequestBody,
  CreateOrUpdateRuleRequestBodyRuleTypeEnum,
  PlanAudienceDetailResponseData,
} from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { EntityType } from "@models/entityTrait";
import { Plan } from "@models/plan";
import { ApiResponse } from "@modules/features/components/tabs/FeatureFlagTargetingTab/FeatureFlagTargetingTab";
import { PlanAudienceDeleteOverlay } from "@modules/plans/components/overlays/PlanAudienceDeleteOverlay";
import { getAudience, updateAudience } from "@modules/plans/queries";
import { useMutation } from "@tanstack/react-query";
import { Button } from "@ui/Button";
import {
  compareRuleArrays,
  PageNotification,
} from "@ui/PageNotification/PageNotification";
import { RuleBlock } from "@ui/RuleBlock";
import { RuleConditionGroupValidationSchema } from "@ui/RuleBlock/RuleConditionGroupBlock";
import { RuleConditionRowValidationSchema } from "@ui/RuleBlock/RuleConditionRow";
import {
  conditionReqForConditionResponse,
  conditionGroupReqForConditionGroupResponse,
} from "@utils/rules";
import { Form, Formik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { PlanAudiencePreview } from "../PlanAudiencePreview";
import { PlanAudienceReviewChanges } from "../PlanAudienceReviewChanges";

type AudienceValues = {
  audience: PlanAudienceDetailResponseData;
};

const apiResponseInitialState = {
  success: false,
  errors: [],
  response: null,
};

const PlanAudienceValidationSchema = Yup.object().shape({
  audience: Yup.object().shape({
    conditions: Yup.array().of(RuleConditionRowValidationSchema),
    conditionGroups: Yup.array().of(RuleConditionGroupValidationSchema),
  }),
});

export const PlanAudienceTab = () => {
  const {
    plan: { name: planName, id: planId, billingProduct },
  } = useOutletContext<{ plan: Plan }>();
  const setQueryData = useContextSetQueryData();
  const planEntitlementEditAllowed = usePermission(
    ClerkUserPermission.plan_entitlements_edit,
  );

  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [apiResponse, setApiResponse] = useState<
    ApiResponse<PlanAudienceDetailResponseData>
  >(apiResponseInitialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<AudienceValues | null>(
    null,
  );

  const isBillingProductManaged = !!billingProduct;

  // Reset apiResponse when on success
  useEffect(() => {
    if (apiResponse.success) {
      setApiResponse(apiResponseInitialState);
    }
  }, [apiResponse]);

  const { environment } = useCurrentEnvironment();

  const { data, isLoading } = useContextQuery({
    queryKey: ["plan", planId, "audience"],
    queryFn: async () => {
      try {
        return await getAudience(planId);
      } catch (error: any) {
        if (error.responseCode === 404) {
          return false;
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error.responseCode === 404) {
        return false;
      }

      return failureCount < 3;
    },
  });

  const mutation = useMutation({
    mutationFn: (audience: CreateOrUpdateRuleRequestBody) => {
      return updateAudience(planId, audience);
    },
    onSuccess: (updatedAudience) => {
      setQueryData(["plan", planId, "audience"], updatedAudience);

      setApiResponse({
        success: true,
        errors: [],
        response: updatedAudience,
      });
    },
    onError: (error: any) => {
      const updatedErrors = [...apiResponse.errors, error.responseCode];
      setApiResponse({ ...apiResponse, errors: updatedErrors });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (data) {
      setInitialValues({ audience: data });
    }
  }, [data]);

  const onSubmit = async (values: AudienceValues) => {
    setLoading(true);

    const { id, conditions, conditionGroups, priority, name, ruleType, value } =
      values.audience;

    const req: CreateOrUpdateRuleRequestBody = {
      id,
      priority,
      name,
      ruleType: ruleType as CreateOrUpdateRuleRequestBodyRuleTypeEnum,
      value,
      conditions: conditions.map(conditionReqForConditionResponse),
      conditionGroups: conditionGroups.map(
        conditionGroupReqForConditionGroupResponse,
      ),
    };

    mutation.mutate(req);
  };

  if (isLoading) return <SinglePageLoader />;

  return (
    <div className="space-y-12 pb-12 mb-12">
      <div className="flex justify-between align-center">
        <div className="space-y-4">
          <h1 className="text-2xl font-body font-medium">Audience Rules</h1>
          <p className="text-gray-600 text-lg">
            All Companies and Users that meet the conditions below are members
            of this plan.
          </p>
        </div>
        {!isBillingProductManaged && initialValues && (
          <div className="flex items-center">
            <Button
              color="red"
              disabled={!planEntitlementEditAllowed}
              onClick={() => {
                if (data) {
                  setDeleteOverlay(true);
                } else {
                  setInitialValues(null);
                }
              }}
            >
              Remove audience
            </Button>
          </div>
        )}
      </div>

      {initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={PlanAudienceValidationSchema}
        >
          {({
            values,
            dirty,
            resetForm,
            submitForm,
            setFieldValue,
            isValid,
            errors,
          }) => {
            const changes = pageNotificationChanges(initialValues, values);

            return (
              <Form>
                {dirty && (
                  <PageNotification
                    changes={changes}
                    onCancel={() => {
                      resetForm();
                    }}
                    onSubmit={submitForm}
                    environment={environment}
                    apiResponse={apiResponse}
                    onResponse={(response) => {
                      response
                        ? resetForm({ values: { audience: response } })
                        : resetForm();
                    }}
                    loading={loading}
                    errors={((errors.audience?.conditions as any) || [])
                      .filter(Boolean)
                      .flatMap((condition: any) => {
                        const { conditions = [], ...rest } = condition;

                        return [
                          ...conditions
                            .filter(Boolean)
                            .flatMap((condition: any) =>
                              Object.values(condition),
                            ),
                          ...Object.values(rest),
                        ];
                      })}
                    disabled={!isValid}
                  >
                    <PlanAudienceReviewChanges
                      planId={planId}
                      planName={planName}
                      newConditions={values.audience.conditions}
                      newConditionGroups={values.audience.conditionGroups}
                    />
                  </PageNotification>
                )}

                <RuleBlock
                  conditions={values.audience.conditions}
                  conditionGroups={values.audience.conditionGroups}
                  field="audience"
                  permittedConditionTypes={[
                    CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company,
                    CreateOrUpdateConditionRequestBodyConditionTypeEnum.Trait,
                    CreateOrUpdateConditionRequestBodyConditionTypeEnum.Metric,
                    CreateOrUpdateConditionRequestBodyConditionTypeEnum.BillingProduct,
                    CreateOrUpdateConditionRequestBodyConditionTypeEnum.CrmProduct,
                  ]}
                  permittedTraitEntityType={EntityType.Company}
                  ruleValue={values.audience.value}
                  setFieldValue={setFieldValue}
                  showHeader={false}
                  showValue={false}
                  readonly={
                    !planEntitlementEditAllowed || isBillingProductManaged
                  }
                />

                <div className="mt-12">
                  <PlanAudiencePreview
                    dirty={dirty}
                    planId={planId}
                    conditions={values.audience.conditions}
                    conditionGroups={values.audience.conditionGroups}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      )}

      {!isBillingProductManaged && !initialValues && (
        <div className="rounded-lg w-full relative py-8 pr-8 pl-12 bg-white shadow-[0_1px_15px_0px_rgba(16,24,40,0.07)]">
          <div className="bg-gray-300 text-blue-400 w-[52px] h-[52px] rounded-full flex items-center justify-center text-sm font-bold font-body tracking-widest absolute left-0 translate-x-[-50%] top-[50%] translate-y-[-50%]">
            IF
          </div>
          <div className="flex justify-between items-center">
            <div className="text-lg font-medium">Add first audience rule</div>
            <div>
              <Button
                type="button"
                color="blue"
                disabled={!planEntitlementEditAllowed}
                onClick={() =>
                  setInitialValues({ audience: newAudienceRule(planId) })
                }
              >
                Add new rule
              </Button>
            </div>
          </div>
        </div>
      )}

      {deleteOverlay && (
        <PlanAudienceDeleteOverlay
          onClose={() => setDeleteOverlay(false)}
          onDelete={() => setInitialValues(null)}
          planId={planId}
          planName={planName}
        />
      )}
    </div>
  );
};

function pageNotificationChanges(
  initial: AudienceValues,
  current: AudienceValues,
): ReactNode[] {
  const changes: ReactNode[] = [];

  return [
    ...changes,
    ...compareRuleArrays([initial.audience], [current.audience]),
  ];
}

function newAudienceRule(planId: string): PlanAudienceDetailResponseData {
  return {
    createdAt: new Date(),
    environmentId: "",
    id: uuidv4(),
    planId: planId,
    name: `Audience Rule`,
    priority: 1,
    ruleType: CreateOrUpdateRuleRequestBodyRuleTypeEnum.PlanAudience,
    updatedAt: new Date(),
    value: true,
    conditionGroups: [],
    conditions: [
      {
        conditionType:
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company,
        operator: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
        resourceIds: [],
        metricValue: 0,
        id: "",
        planId: planId,
        createdAt: new Date(),
        updatedAt: new Date(),
        ruleId: "",
        environmentId: "",
        resources: [],
        traitValue: "",
      },
    ],
  };
}
