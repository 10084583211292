import { TableLoader } from "@components/loaders/TableLoader";
import { usePermission } from "@hooks/usePermission";
import useTablePagination from "@hooks/useTablePagination";
import { ListCompanyOverridesParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { CompanyOverride } from "@models/entitlement";
import { Feature } from "@models/feature";
import { ListFilter } from "@models/req";
import { CompanySubview } from "@modules/companies/types";
import {
  CompanyOverrideDeleteOverlay,
  EntitlementCell,
} from "@modules/features";
import {
  countCompanyOverrides,
  deleteCompanyOverride,
  listCompanyOverrides,
} from "@modules/features/queries/companyOverrides";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { Button, ButtonProps } from "@ui/Button";
import { DropdownDots } from "@ui/DropdownDots";
import { Logo } from "@ui/Logo";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";

import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { CompanyOverridesTableBlankState } from "../blank-states/CompanyOverridesTableBlankState";

export interface CompanyOverridesTableProps {
  feature: Feature;
  openOverlay: (companyOverride?: CompanyOverride) => void;
}

export const CompanyOverridesTable = ({
  feature,
  openOverlay,
}: CompanyOverridesTableProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListCompanyOverridesParams>({
    featureId: feature.id,
  });
  const [loading, setLoading] = useState(false);
  const [deleteOverride, setDeleteOverride] = useState<
    CompanyOverride | undefined
  >();
  const [successAlert, setSuccessAlert] = useState("");
  const companyOverridesEditAllowed = usePermission(
    ClerkUserPermission.overrides_edit,
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<CompanyOverride>[]>(
    () => [
      {
        id: "company",
        header: "Name",
        accessorKey: "company.name",
        cell: (cellInfo) => {
          const company = cellInfo.row.original.company;
          if (!company) return <></>;

          return (
            <div className="flex flex-row items-center">
              <Logo src={company.logoUrl} alt={company.name} />
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium">{company.name}</div>
                <div className="leading-none text-gray-400">{company.id}</div>
              </div>
            </div>
          );
        },
      },
      {
        id: "entitlement",
        header: "Entitlement",
        accessorKey: "value",
        cell: (cellInfo) => {
          const entitlement = cellInfo.row.original;
          return (
            <EntitlementCell
              entitlement={entitlement}
              entity={entitlement.company}
            />
          );
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const override = cellInfo.row.original;
          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Delete",
                    onClick: () => {
                      setSuccessAlert("");
                      setDeleteOverride(override);
                    },
                    disabled: !companyOverridesEditAllowed,
                  },
                  {
                    label: "Edit",
                    onClick: () => {
                      setSuccessAlert("");
                      openOverlay(override);
                    },
                    disabled: !companyOverridesEditAllowed,
                  },
                  {
                    label: "View company",
                    onClick: () => {
                      navigate(
                        `../../companies/${override.companyId}/${CompanySubview.Entitlements}`,
                      );
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    [feature, navigate, openOverlay, companyOverridesEditAllowed],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const onDelete = async () => {
    if (!deleteOverride) return;

    setLoading(true);

    try {
      await deleteCompanyOverride(deleteOverride.id);

      await queryClient.invalidateQueries();
      setSuccessAlert("Override deleted successfully");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    setFilter({
      featureId: feature.id,
      ...(searchTerm ? { q: searchTerm } : {}),
    });
  }, [feature.id, searchTerm]);

  const getHeaderText = (count: number) => {
    return pluralize("Company Overrides", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<
    CompanyOverride,
    ListCompanyOverridesParams & ListFilter
  >(
    ["company-overrides"],
    listCompanyOverrides,
    countCompanyOverrides,
    filter,
    getHeaderText,
  );

  const onRowClick = (row: CompanyOverride) => {
    openOverlay(row);
  };

  const tableButtons: ButtonProps[] = [
    {
      children: <>Add company override</>,
      color: "blue",
      onClick: () => openOverlay(),
    },
  ];

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noCompanyOverrides = countQuery?.data?.count === 0;
  const isLoading =
    loading || listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderCompanyOverridesTable = () => {
    if (noCompanyOverrides) {
      return (
        <CompanyOverridesTableBlankState
          onCreate={openOverlay}
          disabled={!companyOverridesEditAllowed}
        />
      );
    }

    if (isLoading) {
      return <TableLoader />;
    }

    return (
      listQuery?.data && (
        <Table
          columns={columns}
          data={listQuery.data}
          onRowClick={companyOverridesEditAllowed ? onRowClick : undefined}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
          className="mt-8"
        />
      )
    );
  };

  return (
    <div>
      {successAlert && (
        <div className="mb-4">
          <Alert style="green" size="xs" className="flex">
            {successAlert}
          </Alert>
        </div>
      )}

      {!isLoading && !noCompanyOverrides && (
        <TableHeader
          headerText={headerText}
          searchPlaceholder={"Find company override"}
          onSearch={handleSearch}
          className="pt-8 !pb-0"
        />
      )}

      {renderCompanyOverridesTable()}

      {!noCompanyOverrides && (
        <div className="mt-6 flex flex-row space-x-4">
          {tableButtons.map((b, index) => (
            <Button key={index} color={b.color} onClick={b.onClick}>
              {b.children}
            </Button>
          ))}
        </div>
      )}

      {deleteOverride &&
        createPortal(
          <CompanyOverrideDeleteOverlay
            override={deleteOverride}
            onClose={() => setDeleteOverride(undefined)}
            onDelete={onDelete}
          />,
          document.body,
        )}
    </div>
  );
};
