import { CompanyResponseData } from "@models/api";
import { PreviewObject } from "@models/api";
import { Logo } from "@ui/Logo";
import { logoUrl } from "@utils/companies";

type CompanyCellProps = {
  company?: CompanyResponseData | PreviewObject;
};

export const CompanyCell = ({ company }: CompanyCellProps) => {
  return (
    <div className="flex truncate" title={company?.name}>
      {company && (
        <div className="flex space-x-2 items-center">
          <Logo
            src={logoUrl(company)}
            alt={company.name}
            size="sm"
            className="min-w-[2rem]"
          />
          <div className="leading-none text-gray-400">{company.name}</div>
        </div>
      )}
    </div>
  );
};
