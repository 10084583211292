import { get } from "@data/index";
import {
  BillingCustomerWithSubscriptionsResponseData,
  CountCustomersResponse,
  ListCustomersRequest,
} from "@models/api";

export const listCustomers = async (
  flt?: ListCustomersRequest,
): Promise<BillingCustomerWithSubscriptionsResponseData[]> => {
  return get<
    BillingCustomerWithSubscriptionsResponseData[],
    ListCustomersRequest
  >(`billing/customers`, flt);
};

export const countCustomers = async (
  flt?: ListCustomersRequest,
): Promise<CountCustomersResponse> => {
  return get<CountCustomersResponse, ListCustomersRequest>(
    `billing/customers/count`,
    flt,
  );
};
