import { FormikControl } from "@components/forms/FormikControl";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { Button } from "@components/ui/Button";
import { FormRow } from "@components/ui/FormParts";
import * as integrationsApi from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import { StripeIntegrationDeleteOverlay } from "@modules/settings/components/overlays/StripeIntegrationOverlay/StripeIntegrationDeleteOverlay";
import { useMutation } from "@tanstack/react-query";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Toast } from "@ui/Toast";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

export const clerkApiKeySchema = Yup.string()
  .matches(/^(sk_live_|sk_test)/, {
    message: (
      <>
        API key must start with
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          sk_live_ or sk_test_
        </span>{" "}
      </>
    ),
    excludeEmptyString: true,
  })
  .required("API key is required");

export const clerkWebhookKeySchema = Yup.string()
  .matches(/^(whsec_)/, {
    message: (
      <>
        Webhook signing key must start with
        <span className="bg-gray-300/50 text-black px-2 py-1 rounded-md font-mono">
          whsec_
        </span>{" "}
      </>
    ),
    excludeEmptyString: true,
  })
  .required("Webhook signing key is required");

export const ClerkIntegrationForm = () => {
  const getIntegrations = useContextQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => integrationsApi.listIntegrations({ type: "clerk" }),
  });

  const installIntegrationMutation = useMutation({
    mutationFn: integrationsApi.installIntegration,
  });

  const [refreshedIntegrationData, setRefreshedIntegrationData] = useState<
    boolean | null
  >(false);

  const [removeIntegration, setRemoveIntegration] = useState<string | null>(
    null,
  );

  const installIntegrationValidationSchema = Yup.object({
    api_key: clerkApiKeySchema,
    webhook_secret: clerkWebhookKeySchema,
  });

  const initialValues = {
    api_key: "",
    webhook_secret: "",
  };

  const onInstallIntegration = async (config: any) => {
    installIntegrationMutation
      .mutateAsync({
        type: "clerk",
        config,
      })
      .then(() => {
        getIntegrations.refetch();
      });
  };

  if (getIntegrations.isPending) {
    return <SinglePageLoader />;
  }

  if (getIntegrations.data?.length === 0) {
    return (
      <div>
        <Formik
          className="flex flex-col"
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={onInstallIntegration}
          validationSchema={installIntegrationValidationSchema}
        >
          {(form) => {
            return (
              <Form>
                <FormRow className="items-start">
                  <div className="flex-1">
                    <FormikControl
                      control="input"
                      label="Clerk API key"
                      name="api_key"
                      placeholder="Enter Clerk API key to connect"
                      type="text"
                      description={
                        <>
                          Use a Clerk {` `}
                          <Link
                            className="text-blue-400 hover:underline hover:opacity-80"
                            to={`https://clerk.com/docs/upgrade-guides/api-keys`}
                            target="_blank"
                          >
                            Secret Key
                          </Link>
                        </>
                      }
                    />
                  </div>

                  <div className="flex-1">
                    <FormikControl
                      control="input"
                      label="Clerk Webhook signing secret"
                      name="webhook_secret"
                      placeholder="Signing secret for the new webhook"
                      type="text"
                      description={
                        <>
                          Use Clerk {` `}
                          <Link
                            className="text-blue-400 hover:underline hover:opacity-80"
                            to={`https://clerk.com/docs/integrations/webhooks/overview`}
                            target="_blank"
                          >
                            Webhook Signing secret
                          </Link>
                        </>
                      }
                    />
                  </div>

                  <Button
                    type="submit"
                    className="mt-[25px]"
                    color="blue"
                    size="lg"
                    disabled={
                      !form.dirty ||
                      !form.isValid ||
                      installIntegrationMutation.isPending
                    }
                  >
                    Connect to Clerk
                  </Button>
                </FormRow>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }

  return (
    <div className="flex items-stretch space-x-12">
      <div>
        {removeIntegration && (
          <StripeIntegrationDeleteOverlay
            onClose={() => {
              setRemoveIntegration(null);
              getIntegrations.refetch();
            }}
            integrationId={
              getIntegrations.data!.find((i) => i.type === "clerk")!.id
            }
          />
        )}

        <ButtonGroup
          buttons={[
            {
              onClick: () =>
                setRemoveIntegration(
                  getIntegrations.data!.find((i) => i.type === "clerk")!.id,
                ),
              children: <>Remove connection</>,
            },
          ]}
        ></ButtonGroup>

        <Toast
          title={"Integration data is being refreshed"}
          open={refreshedIntegrationData!}
          setOpen={(state) => {
            setRefreshedIntegrationData(state);
          }}
        ></Toast>
      </div>
    </div>
  );
};
