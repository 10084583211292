import { Alert } from "@ui/Alert";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import React from "react";
import { useSearchParams } from "react-router-dom";

type HubspotInstalledOverlayParams = {
  onClose: () => void;
};

export const HubspotInstalledOverlay = ({
  onClose,
}: HubspotInstalledOverlayParams) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const actionButtons = [
    {
      children: "Close",
      onClick: () => {
        searchParams.delete("result");
        setSearchParams(searchParams);
        onClose();
      },
    },
  ];

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      <OverlayModal size="md" className="pb-12">
        <div
          onClick={onClose}
          className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-3xl" />
        </div>

        <div className="p-12 space-y-4">
          <div className="mb-8 space-y-2">
            <div className="text-2xl">
              Hubspot Integration has been installed
            </div>

            <div>We are good to go. The integration is installed.</div>
          </div>

          <Alert style="green" size="xs" className="flex">
            <div className="mr-3">
              <Icon
                name="exclamation-rounded-filled"
                className="text-2xl leading-none text-green-500"
              />
            </div>
            <div>
              Please, keep in mind that initial sync may take a few minutes.
              Therefore, you may see your data withing a few minutes
            </div>
          </Alert>
        </div>

        <div className="flex justify-end px-12">
          <ButtonGroup buttons={actionButtons} />
        </div>
      </OverlayModal>
    </Overlay>
  );
};
