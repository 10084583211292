import { Pill } from "@components/ui/Pill";
import {
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CompanyDetailResponseData,
  PlanEntitlementResponseData,
  UserDetailResponseData,
} from "@models/api";
import {
  CompanyOverride,
  EntitlementValueType,
  PlanEntitlement,
} from "@models/entitlement";
import { FeatureType } from "@models/feature";
import { MetricPeriodDisplay } from "@modules/plans/consts";
import { Icon } from "@ui/Icon";
import { capitalize } from "@utils/strings";
import { getTraitValue } from "@utils/traits";
import cx from "classnames";
import pluralize from "pluralize";

export interface EntitlementCellProps {
  entitlement: PlanEntitlement | CompanyOverride | PlanEntitlementResponseData;
  entity?: CompanyDetailResponseData | UserDetailResponseData;
  showSmallText?: boolean;
}

export const EntitlementCell = ({
  entitlement,
  entity,
  showSmallText = true,
}: EntitlementCellProps) => {
  if (!entitlement.feature) return <></>;

  const booleanBased = entitlement.feature.featureType === FeatureType.Boolean;
  const traitBased = entitlement.feature.featureType === FeatureType.Trait;
  const eventBased = entitlement.feature.featureType === FeatureType.Event;
  const isUnlimited = entitlement.valueType == EntitlementValueType.Unlimited;
  const isNumeric = entitlement.valueType == EntitlementValueType.Numeric;
  const isTrait = entitlement.valueType == EntitlementValueType.Trait;

  let numericLimit = isUnlimited ? "∞" : "";
  let smallText = isUnlimited ? "No Limit" : "Numerical limit";

  if (isTrait && entity && entitlement.feature.traitId) {
    numericLimit =
      getTraitValue(entity.entityTraits, entitlement.feature.traitId) || "0";
  } else if (isNumeric && entitlement.valueNumeric) {
    numericLimit = entitlement.valueNumeric.toString();
  } else if (
    (isTrait && entitlement.valueTrait && !eventBased) ||
    (eventBased && entitlement.valueTrait && !isUnlimited)
  ) {
    smallText = "Trait-Based limit";
  }

  const metricPeriod = entitlement.metricPeriod
    ? MetricPeriodDisplay[
        entitlement.metricPeriod as CreateOrUpdateConditionRequestBodyMetricPeriodEnum
      ]
    : "";
  const bigText =
    numericLimit == "1"
      ? `1 ${capitalize(entitlement.feature.name)}`
      : `${numericLimit}

      ${
        traitBased && !isUnlimited && !isNumeric
          ? entitlement.valueTrait?.displayName
          : pluralize(capitalize(entitlement.feature.name))
      }${isUnlimited ? "" : ` ${metricPeriod}`}`;

  const renderEntitlementCell = () => {
    switch (true) {
      case booleanBased: {
        const val = entitlement.valueBool === true;
        const iconStyles = cx(
          "text-3xl",
          val ? "text-green-300" : "text-red-500",
        );
        return (
          <div className="flex flex-row space-x-2 items-center">
            <Icon
              className={iconStyles}
              name={val ? "boolean-on" : "boolean"}
            />
            {val ? "On" : "Off"}
          </div>
        );
      }
      case traitBased:
        return (
          <div className="flex flex-col space-y-1">
            {entitlement.valueTrait && !numericLimit && isTrait ? (
              <div>
                <Pill
                  text="normal"
                  type="rounded"
                  className="items-center w-auto inline-flex"
                >
                  <Icon name="hash" className="text-lg leading-none mr-1" />
                  <span className="leading-none">{bigText}</span>
                </Pill>
              </div>
            ) : (
              <div className="leading-none font-medium">{bigText}</div>
            )}

            {showSmallText && (
              <div className="leading-none text-sm text-gray-400">
                {smallText}
              </div>
            )}
          </div>
        );
      case eventBased:
        return (
          <div className="flex flex-col space-y-1">
            {entitlement.valueTrait && !numericLimit && isTrait ? (
              <div>
                <Pill
                  text="normal"
                  type="rounded"
                  className="items-center w-auto inline-flex"
                >
                  <Icon name="hash" className="text-lg leading-none mr-1" />
                  <span className="leading-none">
                    {entitlement.valueTrait.displayName}
                  </span>
                </Pill>

                {entitlement.metricPeriod && (
                  <span className="font-medium">
                    {" "}
                    {
                      MetricPeriodDisplay[
                        entitlement.metricPeriod as CreateOrUpdateConditionRequestBodyMetricPeriodEnum
                      ]
                    }
                  </span>
                )}
              </div>
            ) : (
              <div className="leading-none font-medium">{bigText}</div>
            )}
            {showSmallText && (
              <div className="leading-none text-sm text-gray-400">
                {smallText}
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <div className="flex flex-row items-center">
      <div className="">{renderEntitlementCell()}</div>
    </div>
  );
};
