import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { LabeledTooltip } from "@components/ui/LabeledTooltip";
import { deleteFeatureFlag } from "@data/flags";
import { errorMessage } from "@data/index";
import { FeatureFlag, FeatureType } from "@models/feature";
import { FeatureTypeCell } from "@modules/features/components/FeatureTypeCell";
import { ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { ButtonStyleTypes } from "@ui/Button";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Icon, IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { MiniObject } from "@ui/MiniObject";
import { Overlay, OverlayModal } from "@ui/Overlay";

import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

export const FlagDeleteOverlay = ({
  onClose,
  onDelete,
  flag,
}: {
  onClose: () => void;
  onDelete: () => void;
  flag: FeatureFlag;
}) => {
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const getVariations = useCallback((flag: FeatureFlag) => {
    return [
      ...new Set(
        [
          flag.defaultValue,
          ...(flag.rules || []).map((rule) => rule.value),
        ].filter((v) => typeof v !== "undefined"),
      ),
    ];
  }, []);

  const miniObjectColumns = useMemo<ColumnDef<FeatureFlag>[]>(
    () => [
      {
        id: "feature_name",
        header: "Feature Name",
        accessorKey: "feature_name",
        cell: () => {
          return (
            <Link to={`../features/${flag.featureId}`}>
              <div className="flex flex-row items-center">
                <IconRound
                  name={flag?.feature?.icon as IconNameTypes}
                  size="sm"
                />
                <div className="flex flex-col ml-3 space-y-1">
                  <div className="leading-none font-medium">
                    {flag?.feature?.name}
                  </div>
                  <div className="leading-none text-gray-400">
                    {flag?.feature?.id}
                  </div>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        id: "feature_type",
        header: "Type",
        accessorKey: "feature_type",
        cell: () => {
          return (
            flag?.feature?.featureType && (
              <FeatureTypeCell
                featureType={flag?.feature?.featureType as FeatureType}
              />
            )
          );
        },
      },
    ],
    [flag],
  );

  const anyTrueValue = getVariations(flag).some(Boolean);

  const recentlyChecked =
    flag.lastCheckedAt &&
    new Date().getTime() - flag.lastCheckedAt.getTime() <
      1000 * 60 * 60 * 24 * 30;

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteFeatureFlag(flag.id);
      setApiError(undefined);

      onDelete();
    } catch (error) {
      setApiError(errorMessage(error));
    }
    setLoading(false);
  };

  const actionButtons = [
    {
      children: "Cancel",
      onClick: onClose,
    },
    {
      children: "Delete flag",
      color: "blue" as ButtonStyleTypes,
      disabled: !!flag?.feature,
      onClick: handleDelete,
    },
  ];

  return (
    <Overlay onClose={onClose} className="flex  items-center justify-center">
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="md" className="pb-12">
        <LabeledTooltip
          label="All Environments"
          description="Flags exist in all environments"
          className="!right-14 !top-8"
          position="absolute"
          placement="bottom-center"
        />

        <div
          onClick={onClose}
          className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-3xl" />
        </div>

        <div className="p-12 space-y-8">
          <div className="mb-8 space-y-2">
            <div className="text-2xl">Delete flag?</div>

            <div>Remove flags you no longer need.</div>
          </div>

          {flag?.feature && (
            <Alert style="red" size="xs" className="flex">
              <div className="mr-3">
                <Icon
                  name="close-rounded-filled"
                  className="text-2xl leading-none text-red-400"
                />
              </div>
              <div>
                Can't delete a flag associated with a feature. Associate a
                different flag with this feature first.
              </div>
            </Alert>
          )}

          {recentlyChecked && anyTrueValue && (
            <div>
              <Alert style="yellow" size="xs" className="flex">
                <div className="mr-3">
                  <Icon
                    name="exclamation-rounded-filled"
                    className="text-2xl leading-none text-yellow-300"
                  />
                </div>
                <div>
                  This flag is serving true, or multiple variations or was
                  checked recently. This indicates the targeting is active.
                </div>
              </Alert>
            </div>
          )}

          {flag?.feature && (
            <MiniObject columns={miniObjectColumns} size="sm" />
          )}
        </div>

        {apiError && (
          <div className="px-2">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {apiError}
                </div>
              </div>
            </Alert>
          </div>
        )}

        <div className="flex justify-end px-12">
          <ButtonGroup buttons={actionButtons} />
        </div>
      </OverlayModal>
    </Overlay>
  );
};
