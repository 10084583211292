import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

export interface SidebarOption {
  icon?: ReactNode;
  label: string;
  path: string;
}

interface SidebarNavigationProps {
  title?: string | ReactNode;
  options: SidebarOption[];
}

export const SidebarNavigation = ({
  options,
  title,
}: SidebarNavigationProps) => {
  return (
    <div className="min-w-[168px] mr-12 sticky top-0 left-0 h-[calc(100vh-130px)]">
      <NavigationMenu.Root>
        {title && <h1 className="text-2xl mb-6">{title}</h1>}
        <NavigationMenu.List className="flex flex-col space-y-4">
          {options.map((item, index) => {
            return (
              <div className="flex relative group" key={item.label}>
                <NavigationMenu.Item
                  key={index}
                  className="relative"
                  aria-activedescendant={item.path}
                >
                  <NavigationMenu.Link asChild>
                    <NavLink
                      to={`./${item.path}`}
                      className="menu-link !inline-flex space-x-2 items-center justify-start  w-full flex-row !py-0 !border-b-0"
                      aria-activedescendant={item.label}
                    >
                      {item.icon && item.icon}
                      <span>{item.label}</span>
                    </NavLink>
                  </NavigationMenu.Link>
                </NavigationMenu.Item>
              </div>
            );
          })}
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </div>
  );
};
