import { TableLoader } from "@components/loaders/TableLoader";
import { useInfiniteContextQuery } from "@hooks/useContextQuery";
import { ListFilter } from "@models/req";
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import cx from "classnames";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

export type InfiniteTableProps<T extends object, TFilter extends object> = {
  columns: ColumnDef<T>[];
  queryKey: string[];
  queryFn: (filter: TFilter & ListFilter) => Promise<T[]>;
  filter?: TFilter;
  onRowClick?: (row: T) => void;
  className?: string;
  blankStateComponent?: ReactNode;
};

export const InfiniteTable = <T extends object, TFilter extends object>({
  columns,
  queryKey,
  queryFn,
  filter,
  onRowClick,
  className,
  blankStateComponent,
}: InfiniteTableProps<T, TFilter>) => {
  const optionsKeys = Object.entries({ ...filter }).map(
    ([key, value]) => `${key}:${value}`,
  );

  const { data, fetchNextPage, isFetching, hasNextPage, isLoading, error } =
    useInfiniteContextQuery<T>({
      queryKey: [...queryKey, ...optionsKeys],
      queryFn: ({ pageParam }) =>
        queryFn({
          limit: 10,
          offset: pageParam as number,
          ...(filter as TFilter),
        }),
    });

  const handleRowClick = (row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const flatData = useMemo(
    () => data?.pages.flatMap((page) => page) ?? [],
    [data],
  );

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (!containerRefElement) {
        return;
      }

      const { scrollTop, scrollHeight, clientHeight } = containerRefElement;

      if (
        scrollHeight - scrollTop - clientHeight < 500 &&
        !isFetching &&
        hasNextPage
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isFetching, hasNextPage],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data: flatData,
    columns,
    state: {},
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    // Estimate the size of each row for precise scrolling
    estimateSize: () => 73,
    getScrollElement: () => tableContainerRef.current,
    measureElement: (element) => element?.getBoundingClientRect().height,
    // The number of items to render above and below the visible area
    overscan: 5,
  });

  useEffect(() => {
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex(0);
    }
  }, [filter, rowVirtualizer, table]);

  if (error) {
    throw error;
  }

  if (isLoading) {
    return <TableLoader />;
  }

  if (blankStateComponent && flatData.length === 0) {
    return <>{blankStateComponent}</>;
  }

  return (
    <div className={cx("table-external", className)}>
      <div
        className="relative h-[600px] overflow-auto font-body border-b-0 rounded-tr-lg rounded-tl-lg"
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        ref={tableContainerRef}
      >
        <table className="table-default grid">
          <thead
            style={{ display: "grid", position: "sticky", top: 0, zIndex: 1 }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                style={{ display: "flex", width: "100%" }}
              >
                {headerGroup.headers.map((header) => {
                  const headerWidth = header.getSize();
                  return (
                    <th
                      key={header.id}
                      className="flex flex-1 bg-white"
                      style={{
                        width: headerWidth,
                        minWidth: headerWidth,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody
            style={{
              display: "grid",
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: "relative",
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];

              return (
                <tr
                  data-index={virtualRow.index}
                  ref={(node) => rowVirtualizer.measureElement(node)}
                  key={row.id}
                  className={`border-b flex absolute w-full ${
                    onRowClick ? "cursor-pointer" : ""
                  }`}
                  style={{
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                  onClick={() => handleRowClick(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="flex flex-1 items-center space-x-2"
                      style={{
                        width: cell.column.getSize(),
                        minWidth: cell.column.getSize(),
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
