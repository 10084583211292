import { RedirectToSignIn } from "@clerk/clerk-react";
import React from "react";

export const SignInPage = () => {
  return (
    <React.StrictMode>
      <div className="login-wrapper">
        <RedirectToSignIn />
      </div>
    </React.StrictMode>
  );
};
