import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import { FormikStep, FormikStepper } from "@forms/FormikStepper";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import {
  type BillingPriceResponseData,
  BillingProductResponseData,
} from "@models/api";
import {
  Plan,
  PlanIcon,
  PlanReq,
  PlanType,
  PlanTypeDisplay,
  PlanTypeIcon,
} from "@models/plan";
import {
  createPlan,
  updatePlan,
  updatePlanBillingProduct,
} from "@modules/plans";
import { PlanRoutePaths } from "@modules/plans/consts";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { randomDiamond } from "@ui/Diamond/consts";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { OverlayFormAlert } from "@ui/OverlayFormAlert";
import { DropdownIcons } from "@ui/SelectIcons";

import { FormikHelpers, FormikProps } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { PlanEditBillingStep } from "./PlanEditBillingStep";

export interface PlanEditOverlayProps {
  onClose: () => void;
  plan?: Plan;
}

type PlanValues = PlanReq & {
  billingProduct: BillingProductResponseData;
  billingProductId: string;
  monthlyPrice: BillingPriceResponseData;
  monthlyPriceId: string;
  yearlyPrice: BillingPriceResponseData;
  yearlyPriceId: string;
};

const validationSchema = Yup.object({
  name: Yup.string().required("Must provide a plan name"),
  description: Yup.string(),
  planType: Yup.string().required("Must select a plan type"),
  icon: Yup.string(),
});

export const PlanEditOverlay = ({ onClose, plan }: PlanEditOverlayProps) => {
  const navigate = useNavigateEnvironment();
  const queryClient = useQueryClient();
  const billingFlag = useSchematicFlag("billing");

  const verb = plan?.id ? "Edit" : "Create";

  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [dirty, setDirty] = useState(false);

  const initialValues = {
    description: plan?.description || "",
    name: plan?.name || "",
    planType: (plan?.planType || PlanType.Plan) as PlanType,
    icon: (plan?.icon || randomDiamond()) as PlanIcon,
    billingProduct: plan?.billingProduct,
    billingProductId: plan?.billingProduct?.productId,
    monthlyPrice: plan?.monthlyPrice,
    monthlyPriceId: plan?.monthlyPrice?.id,
    yearlyPrice: plan?.yearlyPrice,
    yearlyPriceId: plan?.yearlyPrice?.id,
  } as PlanValues;

  const onSubmit = async (
    {
      billingProduct,
      billingProductId,
      monthlyPrice,
      monthlyPriceId,
      yearlyPrice,
      yearlyPriceId,
      ...req
    }: PlanValues,
    helpers: FormikHelpers<PlanValues>,
  ) => {
    setLoading(true);

    const saveFn = plan?.id
      ? (values: PlanReq) => updatePlan(plan.id, values)
      : createPlan;

    const onSuccessFn = plan?.id
      ? onClose
      : (createdPlan: Plan) =>
          navigate(
            `${req.planType === PlanType.Plan ? PlanRoutePaths.Plans : PlanRoutePaths.AddOns}/${createdPlan.id}`,
          );

    try {
      const plan = await saveFn(req);

      if (billingProductId) {
        await updatePlanBillingProduct(plan.id, {
          billingProductId,
          monthlyPriceId,
          yearlyPriceId,
        });
      }

      await queryClient.invalidateQueries();
      setApiError(undefined);
      onSuccessFn(plan);
      setLoading(false);
    } catch (err) {
      setApiError(errorMessage(err));
      setLoading(false);
      helpers.setSubmitting(false);
    }
  };

  const handleClose = () => (dirty ? setCloseAlert(true) : onClose());

  return (
    <Overlay
      onClose={handleClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      {closeAlert && (
        <OverlayFormAlert setDirtyForm={setCloseAlert} onClose={onClose} />
      )}
      <OverlayModal size="xl">
        <LabeledTooltip
          label="All Environments"
          description="Plans exist in all environments"
          className="top-10 right-16"
          position="absolute"
          placement="bottom-center"
        />
        <FormikStepper
          className="flex-1 w-full"
          onSubmit={onSubmit}
          onClose={handleClose}
          innerRef={(formikActions: FormikProps<PlanValues>) => {
            formikActions && setDirty(formikActions.dirty);
          }}
          dirty={dirty}
          editMode={!!plan?.id}
          initialValues={initialValues}
        >
          <FormikStep label="Define" validationSchema={validationSchema}>
            <FormHeader
              label={`${verb} plan`}
              title="Define plan"
              description="A plan can be a base plan or an add-on"
            />

            <FormColumn>
              <FormRow>
                <DropdownIcons name="icon" label="Icon" type="diamond" />

                <div className="flex-1">
                  <FormikControl
                    control="input"
                    name="name"
                    type="text"
                    label="Plan name"
                    placeholder="Enter name"
                    description="A human-friendly name for your plan."
                  />
                </div>
              </FormRow>

              <FormikControl
                control="input"
                name="description"
                type="text"
                label="Describe the plan"
                placeholder="Enter description"
                description="Optional"
              />

              <FormikSelect
                label="Type"
                name="planType"
                options={Object.keys(PlanTypeDisplay).map((key) => ({
                  value: key,
                  label: PlanTypeDisplay[key as PlanType],
                  icon: PlanTypeIcon[key as PlanType],
                }))}
              />

              {apiError && (
                <div className="px-2">
                  <Alert size="xs" style="red">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="text-base font-body ">
                        <span className="font-semibold">Uh-oh!</span> {apiError}
                      </div>
                    </div>
                  </Alert>
                </div>
              )}
            </FormColumn>
          </FormikStep>

          {billingFlag && (
            <FormikStep label="Billing">
              <PlanEditBillingStep
                verb={verb}
                apiError={apiError}
                planId={plan?.id}
              />
            </FormikStep>
          )}
        </FormikStepper>
      </OverlayModal>
    </Overlay>
  );
};
