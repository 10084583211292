import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { NavLink } from "react-router-dom";

export type ViewPageNavigationTab = {
  path: string;
  label: string;
};

export interface ViewPageNavigationProps {
  ariaLabel?: string;
  className?: string;
  tabs: ViewPageNavigationTab[];
}

export const ViewPageNavigation = ({
  ariaLabel = "Choose Sub Page",
  tabs,
}: ViewPageNavigationProps) => {
  return (
    <div className="flex flex-row space-x-8 px-8">
      <NavigationMenu.Root className="w-full flex items-center justify-start">
        <NavigationMenu.List className="menu-wrapper" aria-label={ariaLabel}>
          {tabs.map((tab) => (
            <div className="flex relative group" key={tab.label}>
              <NavigationMenu.Item
                key={tab.label}
                className="relative"
                aria-activedescendant={tab.path}
              >
                <NavigationMenu.Link asChild>
                  <NavLink
                    to={`./${tab.path}`}
                    className="menu-link !py-4"
                    aria-activedescendant={tab.label}
                  >
                    {tab.label}
                  </NavLink>
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            </div>
          ))}
        </NavigationMenu.List>

        <div className="ViewportPosition">
          <NavigationMenu.Viewport className="NavigationMenuViewport" />
        </div>
      </NavigationMenu.Root>
    </div>
  );
};
