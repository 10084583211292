import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { PlanEntitlement } from "@models/entitlement";
import { Alert } from "@ui/Alert";
import { ButtonStyleTypes } from "@ui/Button";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";

import { useState } from "react";

export const PlanEntitlementDeleteOverlay = ({
  entitlement,
  onClose,
  onDelete,
}: {
  entitlement: PlanEntitlement;
  onClose: () => void;
  onDelete: (id: string) => Promise<void>;
}) => {
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await onDelete(entitlement.id);
      onClose();
    } catch (error) {
      setApiError(errorMessage(error));
    }
    setLoading(false);
  };

  const actionButtons = [
    {
      children: "Cancel",
      onClick: onClose,
    },
    {
      children: "Delete entitlement",
      color: "blue" as ButtonStyleTypes,
      onClick: handleDelete,
    },
  ];

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="md" className="pb-12">
        <>
          <div
            onClick={onClose}
            className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
          >
            <Icon name="close" className="text-3xl" />
          </div>

          <div className="p-12 space-y-8">
            <div className="mb-8 space-y-2">
              <div className="text-2xl">Delete entitlement?</div>
            </div>

            <div>
              <Alert style="yellow" size="xs">
                Making this change will remove access to{" "}
                <span className="font-bold">
                  {entitlement.feature?.name ?? "Unknown"}
                </span>{" "}
                for {entitlement.plan?.companyCount?.toLocaleString() ?? 0}{" "}
                companies in the{" "}
                <span className="font-bold">
                  {entitlement.plan?.name ?? "Unknown"}
                </span>{" "}
                plan.
              </Alert>
            </div>
          </div>

          {apiError && (
            <div className="px-2">
              <Alert size="xs" style="red">
                <div className="flex items-center justify-center space-x-2">
                  <div className="text-base font-body ">
                    <span className="font-semibold">Uh-oh!</span> {apiError}
                  </div>
                </div>
              </Alert>
            </div>
          )}

          <div className="flex justify-end px-12">
            <ButtonGroup buttons={actionButtons} />
          </div>
        </>
      </OverlayModal>
    </Overlay>
  );
};
