import { CompanyResponseData } from "@models/api";
import { PreviewObject } from "@models/api";

export const logoUrl = (company: CompanyResponseData | PreviewObject) => {
  if ("logoUrl" in company) {
    return company.logoUrl;
  }

  if ("imageUrl" in company) {
    return company.imageUrl;
  }
};
