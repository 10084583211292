import { RuleConditionDetailResponseData } from "@models/api";
import { ArrayHelpers, FieldArray } from "formik";
import * as Yup from "yup";
import {
  AND_CONDITION_MAX_LENGTH,
  ConditionLogicalOperator,
  OR_CONDITION_MAX_LENGTH,
} from "./consts";
import { RuleConditionRow } from "./RuleConditionRow";
import { RuleConditionRowValidationSchema } from "./RuleConditionRow";

export const RuleConditionGroupValidationSchema = Yup.object().shape({
  conditions: Yup.array().of(RuleConditionRowValidationSchema),
});

interface RuleConditionGroupProps {
  conditions: RuleConditionDetailResponseData[];
  newCondition: () => RuleConditionDetailResponseData;
  logicalOperator: ConditionLogicalOperator;
  field: string;
  readonly?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setFieldValue?: (field: string, value: any) => void;
  onAddAndCondition: () => void;
  onRemove: (condition?: RuleConditionDetailResponseData) => void;
  ruleConditionLength: number;
}

export const RuleConditionGroupBlock = ({
  conditions,
  logicalOperator,
  newCondition,
  readonly,
  setFieldValue,
  field,
  onAddAndCondition,
  onRemove,
  ruleConditionLength,
}: RuleConditionGroupProps) => {
  // Group first condition function
  const onAddOrCondition = (arrayHelpers: ArrayHelpers) => () => {
    arrayHelpers.insert(1, newCondition());
  };

  // Group following condition functions
  const onAddCondition = (arrayHelpers: ArrayHelpers, index: number) => () => {
    arrayHelpers.insert(index + 1, newCondition());
  };

  const onRemoveCondition =
    (arrayHelpers: ArrayHelpers, index: number) => () => {
      arrayHelpers.remove(index);

      // In case there is only one OR condition in block, we replace the group with the condition
      if (conditions.length === 2) {
        onRemove(index === 1 ? conditions[0] : undefined);
      }
    };

  return (
    <FieldArray
      name={`${field}.conditions`}
      render={(arrayHelpers: ArrayHelpers) => (
        <div className="space-y-5">
          <RuleConditionRow
            condition={conditions[0]}
            key={conditions[0].id || conditions[0].createdAt.toString()}
            readonly={readonly}
            conditionsLength={conditions.length}
            logicalOperator={logicalOperator}
            field={`${field}.conditions.${0}`}
            setFieldValue={setFieldValue}
            onAddAndCondition={onAddAndCondition}
            onAddOrCondition={onAddOrCondition(arrayHelpers)}
            onRemove={onRemove}
            disableOr={conditions.length >= OR_CONDITION_MAX_LENGTH}
            disableAnd={ruleConditionLength >= AND_CONDITION_MAX_LENGTH}
          />
          <div className="space-y-5 ml-20">
            {conditions.map((condition, index) => {
              if (index === 0) {
                return;
              }

              return (
                <RuleConditionRow
                  condition={condition}
                  key={condition.id || condition.createdAt.toString()}
                  readonly={readonly}
                  conditionsLength={conditions.length}
                  logicalOperator={ConditionLogicalOperator.OR}
                  field={`${field}.conditions.${index}`}
                  setFieldValue={setFieldValue}
                  onAddAndCondition={onAddCondition(arrayHelpers, index)}
                  onRemove={onRemoveCondition(arrayHelpers, index)}
                />
              );
            })}
          </div>
        </div>
      )}
    />
  );
};
