import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import type { EntityKeyDetailResponseData } from "@models/api";
import { EntityType } from "@models/entityKey";
import { listEntityKeyDefinitions } from "@modules/settings/queries/entityKeys";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { FieldArray, useFormikContext } from "formik";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

type CompanyOrCompanyUserEditOverlayKeyStepProps = {
  entityType: EntityType;
  label: string;
};

export const CompanyOrCompanyUserEditOverlayKeyStep = <
  T extends { keys: (EntityKeyDetailResponseData & { uuid: string })[] },
>({
  entityType,
  label,
}: CompanyOrCompanyUserEditOverlayKeyStepProps) => {
  const { setFieldValue, values, errors } = useFormikContext<T>();

  return (
    <>
      <FormHeader
        label={label}
        title="Set keys"
        description={
          <>
            Keys are unique identifiers that can be used via the API or by other
            systems to reference companies and users in Schematic. We recommend
            the {entityType} ID your product uses.
            <Link
              to="https://docs.schematichq.com/developer_resources/concepts#keys-vs-traits"
              target="_blank"
              className="text-blue-400"
            >
              {" "}
              Learn more.
            </Link>
          </>
        }
      />

      <FormColumn>
        <FieldArray
          name="keys"
          render={({ remove }) => {
            return (
              <>
                {(values.keys || []).map((value, index) => {
                  return (
                    <FormRow key={value.uuid}>
                      <div className="flex space-x-2 flex-1 items-end">
                        <FormikAsyncSelect
                          label="Key"
                          name={`keys[${index}].id`}
                          defaultOptions
                          creatable
                          creatableLabel="Key"
                          loadOptions={listEntityKeyDefinitions}
                          loadOptionsMappers={{
                            requestFilter: {
                              entityType,
                            },
                            mapperFunction: (entityKey) => ({
                              value: entityKey.key,
                              label: entityKey.key,
                            }),
                          }}
                          selectedOption={
                            value?.id && {
                              value: value.key,
                              label: value.key,
                            }
                          }
                          onCreate={async (option: string) => {
                            await setFieldValue(`keys[${index}].key`, option);

                            return {
                              value: option,
                              label: option,
                            };
                          }}
                          onChange={async (option: {
                            value: string;
                            label: string;
                          }) => {
                            await setFieldValue(
                              `keys[${index}].key`,
                              option.value,
                            );
                          }}
                        />

                        <FormikControl
                          control="input"
                          label="Value"
                          name={`keys[${index}].value`}
                          setFieldValue={setFieldValue}
                        />

                        <Button
                          className="h-[31px] hover:!bg-red-500 hover:!text-white !text-blue-400 !text-3xl !leading-none !py-[6px] !px-[14.75px]"
                          color="white"
                          type="button"
                          size="lg"
                          onClick={async () => {
                            remove(index);
                          }}
                        >
                          -
                        </Button>
                      </div>
                    </FormRow>
                  );
                })}
              </>
            );
          }}
        />

        <FormRow>
          <div className="flex mr-auto">
            <Button
              className="!text-sm !px-4 !text-blue-700 hover:bg-white hover:border-blue-700"
              color="white"
              type="button"
              size="lg"
              onClick={async () => {
                await setFieldValue("keys", [
                  ...values.keys,
                  { uuid: uuidv4(), value: "", key: "" },
                ]);
              }}
            >
              Add another key
            </Button>
          </div>
        </FormRow>

        {errors && errors.keys && typeof errors.keys === "string" && (
          <div className="px-2">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {errors.keys}
                </div>
              </div>
            </Alert>
          </div>
        )}
      </FormColumn>
    </>
  );
};
