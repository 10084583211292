import * as integrationsApi from "@data/integrations";
import { FormikSelect } from "@forms/FormikSelect";
import { useMutation } from "@tanstack/react-query";
import { Button } from "@ui/Button";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";

type SelectMatchingFieldProps = {
  onSelected: (field: string) => void;
  stripeApiKey: string;
};

export const SelectMatchingFieldCriteria = ({
  onSelected,
  stripeApiKey,
}: SelectMatchingFieldProps) => {
  const keysSample = useMutation({
    mutationFn: integrationsApi.loadSampleDataSet,
  });

  const [selectedField, setSelectedField] = useState<string | null>(null);

  useEffect(() => {
    keysSample.mutate({ config: { api_key: stripeApiKey }, type: "stripe" });
  }, []);

  return (
    <div>
      <div className="mb-8 space-y-2">
        <div className="text-2xl">Choose a metadata field</div>

        <div>We’ve pulled a Stripe customer example for reference.</div>
      </div>

      {keysSample.isPending && (
        <div className="flex flex-col space-y-1 justify-center items-center py-12 text-center">
          <div>
            <img
              alt="Schematic Loader"
              src="/schematic-load.gif"
              className=" select-none w-10 h-10"
            />
          </div>
          <div className="text-sm text-gray-400">Loading your data sample</div>
        </div>
      )}

      {keysSample.isSuccess && (
        <Formik
          initialValues={{ id: "" }}
          onSubmit={(values) => {
            console.log(values);
            onSelected(selectedField!);
          }}
        >
          <Form>
            <FormikSelect
              name="value"
              label="Select unique key"
              onChange={(value: { id: string; value: string }) => {
                setSelectedField(value.value);
              }}
              options={keysSample.data.keys.map((k) => ({
                value: k,
                label: k,
                id: k,
              }))}
            />

            <div className="flex flex-row justify-end">
              <Button
                disabled={selectedField === null}
                type={"submit"}
                className="mt-12"
              >
                Select field
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};
