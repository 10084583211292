import { Overlay, OverlayHeader, OverlayModal } from "@components/ui/Overlay";
import { OverlayLoader } from "@components/ui/Overlay/OverlayLoader";
import { useContextQuery } from "@hooks/useContextQuery";
import { getCodeColor } from "@modules/settings/helpers";
import { getApiRequest } from "@modules/settings/queries/apiRequests";
import * as Tabs from "@radix-ui/react-tabs";
import { ClipCopy } from "@ui/ClipCopy";
import { LabelData, LabelDataGrid } from "@ui/LabelData";
import { Pill } from "@ui/Pill";
import { formatDate } from "@utils/date";
import { formatJson } from "@utils/strings";
import { AuditLogDescription } from "../AuditLogDescription";
import { EnvironmentCell } from "../EnvironmentCell";

type AuditLogOverlayProps = {
  id: string;
  onClose: () => void;
};

export const AuditLogOverlay = ({ id, onClose }: AuditLogOverlayProps) => {
  const { isLoading, error, data } = useContextQuery({
    queryKey: ["api-request", id],
    queryFn: () => getApiRequest(id),
  });

  if (error) throw error;

  return (
    <Overlay onClose={onClose}>
      <OverlayModal size="md" position="right">
        {isLoading || !data ? (
          <OverlayLoader />
        ) : (
          <>
            <OverlayHeader title="Log Detail" onClose={onClose} />
            <div className="px-12 pt-8">
              <LabelDataGrid>
                <LabelData label="Description" className="col-span-2">
                  <AuditLogDescription apiRequest={data} />
                </LabelData>
                <LabelData label="Author" className="col-span-2">
                  {data.requestType === "api" ? "API Request" : data.userName}
                </LabelData>
                <LabelData label="Response Code">
                  <Pill color={getCodeColor(data.respCode)}>
                    {data.respCode}
                  </Pill>
                </LabelData>
                <LabelData label="Request ID">
                  <ClipCopy data={data.id} />
                </LabelData>
                <LabelData label="Start Time">
                  {formatDate(data.startedAt)}
                </LabelData>
                {data.endedAt && (
                  <LabelData label="End Time">
                    {formatDate(data.endedAt)}
                  </LabelData>
                )}
                <LabelData label="API Key">{data.apiKeyId}</LabelData>
                {data.environment && (
                  <LabelData label="Environment">
                    <EnvironmentCell environment={data.environment} />
                  </LabelData>
                )}
                <LabelData label="Method">{data.method}</LabelData>
                <LabelData label="URL Path">
                  <ClipCopy
                    data={data.url}
                    className="!max-w-[200px] break-all"
                  />
                </LabelData>
              </LabelDataGrid>
            </div>
            <div className="px-12 pt-8">
              <LabelData label="Payload" className="pb-3" />
              <Tabs.Root
                className="border border-gray-100"
                defaultValue={data.reqBody ? "request" : "response"}
              >
                <Tabs.List className="tw-wrapper">
                  {data.reqBody && (
                    <Tabs.Trigger className="ml-3 tabs-item" value="request">
                      <div className="tabs-item-text">
                        <div className="relative">Request Body</div>
                      </div>
                    </Tabs.Trigger>
                  )}
                  <Tabs.Trigger className="ml-3 tabs-item" value="response">
                    <div className="tabs-item-text">
                      <div className="relative">Response Body</div>
                    </div>
                  </Tabs.Trigger>
                </Tabs.List>
                {data.respBody && (
                  <Tabs.Content value="request">
                    <div className="border-gray-100 border rounded">
                      <div className="bg-gray-50 p-4">
                        <pre>
                          <ClipCopy
                            position="bottom-right"
                            data={formatJson(data.reqBody || "{}")}
                          />
                        </pre>
                      </div>
                    </div>
                  </Tabs.Content>
                )}
                <Tabs.Content value="response">
                  <div className="border-gray-100 border rounded">
                    <div className="bg-gray-50 p-4">
                      <pre>
                        <ClipCopy
                          position="bottom-right"
                          data={formatJson(data.respBody || "{}")}
                        />
                      </pre>
                    </div>
                  </div>
                </Tabs.Content>
              </Tabs.Root>
            </div>
          </>
        )}
      </OverlayModal>
    </Overlay>
  );
};
