import { EventDetailResponseData } from "@models/api";
import {
  EventSummaryResponseData,
  GetEventSummariesRequest,
  ListEventsParams,
} from "@models/api";
import { get } from "./index";

// Events
export const getEvent = async (
  id: string,
): Promise<EventDetailResponseData> => {
  return get<EventDetailResponseData>(`events/${id}`);
};

export const listEvents = async (
  flt?: ListEventsParams,
): Promise<EventDetailResponseData[]> => {
  return get<EventDetailResponseData[], ListEventsParams>("events", flt);
};

export const getEventType = async (
  eventType: string,
): Promise<EventSummaryResponseData> => {
  return get<EventSummaryResponseData>(`event-types/${eventType}`);
};

export const listEventTypes = async (
  flt?: GetEventSummariesRequest,
): Promise<EventSummaryResponseData[]> => {
  return get<EventSummaryResponseData[], GetEventSummariesRequest>(
    "event-types",
    flt,
  );
};
