import {
  RuleConditionDetailResponseData,
  RuleConditionGroupDetailResponseData,
} from "@models/api";
import { conditionReqForConditionResponse } from "@utils/rules";

const conditionMapper = (condition: RuleConditionDetailResponseData) => {
  const { traitValue, ...rest } = condition;

  return conditionReqForConditionResponse({
    ...rest,
    traitValue: traitValue?.toString(),
  });
};

export const conditionsPreviewReqMapper = (
  conditions: RuleConditionDetailResponseData[],
  conditionGroups: RuleConditionGroupDetailResponseData[],
) => {
  return {
    conditions: conditions.map((condition) => conditionMapper(condition)),
    conditionGroups: conditionGroups.map((conditionGroup) => {
      return {
        id: conditionGroup.id,
        conditions: conditionGroup.conditions.map(
          (condition: RuleConditionDetailResponseData) =>
            conditionMapper(condition),
        ),
      };
    }),
  };
};
