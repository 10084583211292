import { useContextQuery } from "@hooks/useContextQuery";
import { conditionsPreviewReqMapper } from "@modules/plans/helpers";
import {
  getAudience,
  previewCompaniesCount,
  previewUsersCount,
} from "@modules/plans/queries";

type CurrentCompaniesAndUsersProps = {
  planId: string;
};

export const useCurrentCompaniesAndUsers = ({
  planId,
}: CurrentCompaniesAndUsersProps) => {
  const { data: audienceData, isLoading: isAudienceLoading } = useContextQuery({
    queryKey: [`plan`, planId, "audience"],
    queryFn: async () => {
      try {
        return await getAudience(planId);
      } catch (error: any) {
        if (error.responseCode === 404) {
          return false;
        }

        throw error;
      }
    },
  });

  const currentConditions = audienceData ? audienceData.conditions : [];
  const currentConditionGroups = audienceData
    ? audienceData.conditionGroups
    : [];

  const {
    data: currentCompaniesCount,
    isLoading: isCurrentCompaniesCountLoading,
  } = useContextQuery({
    queryKey: [
      `plan`,
      planId,
      "audience",
      "preview",
      "companies",
      "count",
      JSON.stringify(currentConditions),
    ],
    queryFn: async () => {
      const req = {
        ...conditionsPreviewReqMapper(
          currentConditions,
          currentConditionGroups,
        ),
      };

      return previewCompaniesCount(planId, req);
    },
  });

  const { data: currentUsersCount, isLoading: isCurrentUsersCountLoading } =
    useContextQuery({
      queryKey: [
        `plan`,
        planId,
        "audience",
        "preview",
        "users",
        "count",
        JSON.stringify(currentConditions),
      ],
      queryFn: async () => {
        const req = {
          ...conditionsPreviewReqMapper(
            currentConditions,
            currentConditionGroups,
          ),
        };

        return previewUsersCount(planId, req);
      },
    });

  return {
    currentCompaniesCount,
    currentUsersCount,
    currentConditions,
    loading:
      isAudienceLoading ||
      isCurrentCompaniesCountLoading ||
      isCurrentUsersCountLoading,
  };
};
