import { ButtonGroup } from "@ui/ButtonGroup";
import { Icon } from "@ui/Icon";
import { Dispatch, SetStateAction } from "react";
import { createPortal } from "react-dom";

interface OverlayFormAlertProps {
  setDirtyForm: Dispatch<SetStateAction<boolean>>;
  onClose?: any;
}

export const OverlayFormAlert = ({
  setDirtyForm,
  onClose,
}: OverlayFormAlertProps) => {
  return createPortal(
    <div className="flex items-center justify-center fixed z-[50000] w-screen h-screen bg-black/20 left-0 top-0">
      <div className="relative bg-white shadow-lg rounded-lg p-12 max-w-[600px]">
        <div
          className="absolute top-6 right-6 opacity-40 cursor-pointer"
          onClick={() => setDirtyForm(false)}
        >
          <Icon name="close" className="text-3xl" />
        </div>
        <div className="">
          <div className="text-2xl font-medium mb-6">Unsaved changes</div>
          <div>
            There are pending changes on this page. If you leave this page, you
            will lose any unsaved changes.
          </div>
        </div>
        <div className="flex items-center justify-end mt-12">
          <ButtonGroup
            buttons={[
              {
                color: "white",
                children: <>Go back to editing</>,
                onClick: () => setDirtyForm(false),
              },
              {
                color: "blue",
                children: <>Leave and cancel changes</>,
                onClick: () => {
                  setDirtyForm(true);
                  onClose();
                },
              },
            ]}
          />
        </div>
      </div>
    </div>,
    document.body,
  );
};
