import { FeatureType } from "@models/feature";
import { FeatureUsage } from "@models/usage";
import { MetricPeriodDisplayShortHand } from "@modules/plans/consts";
import { Icon } from "@ui/Icon";
import { capitalize } from "@utils/strings";
import cx from "classnames";
import pluralize from "pluralize";

export interface CompanyEntitlementCellProps {
  usage: FeatureUsage;
}

const EntitlementCellBoolean = ({ access }: { access: boolean }) => {
  const iconStyles = cx("text-3xl", access ? "text-green-300" : "text-red-500");
  return (
    <div className="flex flex-row space-x-2 items-center">
      <Icon className={iconStyles} name={access ? "boolean-on" : "boolean"} />
      {access ? "On" : "Off"}
    </div>
  );
};

const EntitlementCellNumeric = ({ usage }: CompanyEntitlementCellProps) => {
  if (!usage.feature) return;

  const numericLimit =
    typeof usage.allocation === "undefined" ? "∞" : usage.allocation.toString();
  const smallText =
    typeof usage.allocation === "undefined" ? "No Limit" : "Numerical limit";

  let bigText = `${numericLimit} ${pluralize(
    capitalize(usage.feature.name),
    usage.allocation ?? 0,
  )}`;
  if (usage.period && MetricPeriodDisplayShortHand[usage.period]) {
    bigText += MetricPeriodDisplayShortHand[usage.period];
  }

  return (
    <div className="flex flex-col space-y-1">
      <div className="leading-none font-medium">{bigText}</div>
      <div className="leading-none text-sm text-gray-400">{smallText}</div>
    </div>
  );
};

export const CompanyEntitlementCell = ({
  usage,
}: CompanyEntitlementCellProps) => {
  if (!usage.feature) return;

  const booleanBased = usage.feature.featureType === FeatureType.Boolean;

  return (
    <div className="flex flex-row items-center">
      {booleanBased ? (
        <EntitlementCellBoolean access={usage.access} />
      ) : (
        <EntitlementCellNumeric usage={usage} />
      )}
    </div>
  );
};
