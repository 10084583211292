import { NotFound } from "@components/blanks/pages/NotFound";
import { companyRoutes } from "@modules/companies/routes";
import { companyUserRoutes } from "@modules/companyUsers/routes";
import { componentRoutes } from "@modules/components/routes";
import { EventsView, EventView } from "@modules/events";
import { featureRoutes, flagRoutes } from "@modules/features/routes";
import { integrationsRoutes } from "@modules/integrations/routes";
import { planRoutes } from "@modules/plans/routes";
import { settingRoutes } from "@modules/settings/routes";
import { BaseRoute } from "@routes/BaseRoute";
import { SignInPage } from "@routes/SignInPage";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { ProtectedComponent } from "./ProtectedComponent";
import App from "../App";

export const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <BaseRoute />,
      },
      {
        path: "sign-in/*",
        element: <SignInPage />,
      },
      {
        path: ":environmentId/*",
        element: <ProtectedComponent />,
        children: [
          { index: true, element: <Navigate to="features" replace /> },
          {
            path: "events",
            element: <EventsView />,
          },
          { path: "events/:id", element: <EventView /> },
          ...featureRoutes,
          ...flagRoutes,
          ...planRoutes,
          ...companyRoutes,
          ...companyUserRoutes,
          ...settingRoutes,
          ...integrationsRoutes,
          ...componentRoutes,
        ],
      },
    ],
  },
]);
