import { createContext } from "react";

interface EnvironmentContextProps {
  environmentId: string | undefined;
  setEnvironmentId: (environmentId: string | undefined) => void;
}

export const EnvironmentContext = createContext<EnvironmentContextProps>({
  environmentId: undefined,
  setEnvironmentId: () => {},
});
