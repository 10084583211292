import { TableLoader } from "@components/loaders/TableLoader";
import useTablePagination from "@hooks/useTablePagination";
import {
  EntityTraitDefinitionResponseData,
  ListEntityTraitDefinitionsParams,
} from "@models/api";
import { EntityTraitType, EntityType } from "@models/entityTrait";
import * as api from "@modules/settings/queries/entityTraits";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@ui/Button";
import { Pill } from "@ui/Pill";
import { Table } from "@ui/Table";

import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { EntityTraitTypeDisplay } from "../../consts";
import { EntityTraitDefinitionOverlay } from "../overlays/EntityTraitDefinitionOverlay";

interface EntityTraitDefinitionsTableProps {
  entityType: EntityType;
}

export const EntityTraitDefinitionsTable = ({
  entityType,
}: EntityTraitDefinitionsTableProps) => {
  const queryClient = useQueryClient();
  const [overlayEntityTraitDefinition, setOverlayEntityTraitDefinition] =
    useState<EntityTraitDefinitionResponseData | null>(null);
  const [filter] = useState<ListEntityTraitDefinitionsParams>({ entityType });

  const columns = useMemo<ColumnDef<EntityTraitDefinitionResponseData>[]>(
    () => [
      {
        id: "trait",
        header: "Trait",
        accessorKey: "trait",
        cell: (cellInfo) => {
          const trait = cellInfo.row.original;
          return <Pill>{trait.hierarchy.join(".")}</Pill>;
        },
      },
      {
        id: "displayName",
        header: "Display Name",
        accessorKey: "displayName",
        cell: (cellInfo) => {
          const trait = cellInfo.row.original;
          return <>{trait.displayName}</>;
        },
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "type",
        cell: (cellInfo) => {
          const trait = cellInfo.row.original;
          return (
            <>{EntityTraitTypeDisplay[trait.traitType as EntityTraitType]}</>
          );
        },
      },
      {
        id: "edit",
        header: "",
        accessorKey: "edit",
        maxSize: 75,
        cell: (cellInfo) => {
          const trait = cellInfo.row.original;
          return (
            <div className=" flex justify-end">
              <Button
                color="white"
                size="sm"
                onClick={() => setOverlayEntityTraitDefinition(trait)}
              >
                Edit
              </Button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const {
    listQuery,
    listQueryKey,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<
    EntityTraitDefinitionResponseData,
    ListEntityTraitDefinitionsParams
  >(
    ["entityTraitDefinitions"],
    api.listEntityTraitDefinitions,
    api.countEntityTraitDefinitions,
    filter,
  );

  const resetTable = () => {
    queryClient.invalidateQueries({
      queryKey: listQueryKey,
    });
  };

  if (listQuery.error) throw listQuery.error;

  if (listQuery.isLoading) return <TableLoader rows={4} />;

  if (!listQuery.data) return null;

  return (
    <>
      <Table
        columns={columns}
        data={listQuery.data}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
      />
      {overlayEntityTraitDefinition &&
        createPortal(
          <EntityTraitDefinitionOverlay
            entityTrait={overlayEntityTraitDefinition}
            onClose={() => setOverlayEntityTraitDefinition(null)}
            onSave={resetTable}
          />,
          document.body,
        )}
    </>
  );
};
