import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

type Breakpoints = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
};

// This is the default tailwind breakpoints list
// If we add future breakpoints that go beyond default
// tailwind breakpoints, we should also update it here

export const defaultBreakpoints: Breakpoints = {
  sm: "(min-width: 640px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
  "2xl": "(min-width: 1536px)",
};

export const useResponsiveness = (
  breakpoints: Breakpoints = defaultBreakpoints,
) => {
  const [isSmall, setIsSmall] = useState<boolean>(false);
  const [isMedium, setIsMedium] = useState<boolean>(false);
  const [isLarge, setIsLarge] = useState<boolean>(false);
  const [isExtraLarge, setIsExtraLarge] = useState<boolean>(false);
  const [isExtraExtraLarge, setIsExtraExtraLarge] = useState<boolean>(false);

  const isSmallQuery = useMediaQuery({ query: breakpoints.sm });
  const isMediumQuery = useMediaQuery({ query: breakpoints.md });
  const isLargeQuery = useMediaQuery({ query: breakpoints.lg });
  const isExtraLargeQuery = useMediaQuery({ query: breakpoints.xl });
  const isExtraExtraLargeQuery = useMediaQuery({ query: breakpoints["2xl"] });

  useEffect(() => {
    setIsSmall(isSmallQuery);
    setIsMedium(isMediumQuery);
    setIsLarge(isLargeQuery);
    setIsExtraLarge(isExtraLargeQuery);
    setIsExtraExtraLarge(isExtraExtraLargeQuery);
  }, [
    isSmallQuery,
    isMediumQuery,
    isLargeQuery,
    isExtraLargeQuery,
    isExtraExtraLargeQuery,
  ]);

  return {
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isExtraExtraLarge,
  };
};
