import { Icon } from "@components/ui/Icon";
import { IconNameTypes } from "@components/ui/Icon/consts";
import {
  SidebarNavigation,
  SidebarOption,
} from "@components/ui/SettingsNavigation";
import { useSchematicFlag } from "@schematichq/schematic-react";
import cx from "classnames";
import { IntegrationsPage } from "../consts";

interface NavigationLabelIconProps {
  name: IconNameTypes;
  className?: string;
}

const NavigationLabelIcon = ({ name, className }: NavigationLabelIconProps) => {
  return (
    <div className="w-6 h-6 flex bg-white justify-center items-center rounded-full border border-gray-300">
      <Icon name={name} className={cx("text-lg leading-none", className)} />
    </div>
  );
};

const IntegrationsNavigation = () => {
  const integrationsOptions: SidebarOption[] = [
    ...(useSchematicFlag("stripe-integration-flag")
      ? [
          {
            icon: (
              <NavigationLabelIcon name="stripe" className="text-[#6772E5]" />
            ),
            label: "Stripe",
            path: IntegrationsPage.StripeIntegration,
          },
        ]
      : []),
    ...(useSchematicFlag("clerk-integration-flag", { fallback: true })
      ? [
          {
            icon: (
              <NavigationLabelIcon
                name="clerk"
                className="text-[#6C47FF] !text-2xl !leading-none"
              />
            ),
            label: "Clerk",
            path: IntegrationsPage.ClerkIntegration,
          },
        ]
      : []),
    ...(useSchematicFlag("segment-integration")
      ? [
          {
            icon: (
              <NavigationLabelIcon name="segment" className="text-[#78B39C]" />
            ),
            label: "Segment",
            path: IntegrationsPage.SegmentIntegration,
          },
        ]
      : []),
    ...(useSchematicFlag("settings.webhook")
      ? [
          {
            icon: (
              <NavigationLabelIcon name="webhook" className="text-[#0042FF]" />
            ),
            label: "Webhooks",
            path: IntegrationsPage.Webhook,
          },
        ]
      : []),
  ];

  return (
    <SidebarNavigation title="Integrations" options={integrationsOptions} />
  );
};

export default IntegrationsNavigation;
