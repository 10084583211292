import { RuleDetailResponseData } from "@models/api";
import cx from "classnames";
import BooleanSelect from "./BooleanSelect";

type RuleBlockDefaultProps = {
  rule: RuleDetailResponseData;
  disabled?: boolean;
};

export const RuleBlockDefault = ({
  rule,
  disabled = true,
}: RuleBlockDefaultProps) => {
  const styles = cx(
    "bg-white rounded-lg shadow-[0_1px_15px_0px_rgba(16,24,40,0.07)] w-full relative space-y-6 py-8",
  );

  return (
    <div className={styles}>
      <div className="text-lg pl-8">Default Rule</div>

      <div className="pr-8 pl-16">
        <div className="bg-gray-300 text-blue-400 w-[52px] h-[52px] rounded-full flex items-center justify-center text-sm font-bold font-body tracking-widest absolute left-0 translate-x-[-50%] top-[66%] translate-y-[-50%]">
          ELSE
        </div>
        <div className="flex space-x-2 items-center max-w-[200px]">
          <BooleanSelect
            label="Value"
            name="defaultRule.value"
            disabled={disabled}
            selected={rule.value}
          />
        </div>
      </div>
    </div>
  );
};
