import { deleteReq, get, post, put } from "@data/index";
import {
  CompanyDetailResponseData,
  ListCompaniesParams,
  UpsertCompanyRequestBody,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";

export const countCompanies = async (
  flt?: ListCompaniesParams,
): Promise<Count> => {
  return get<Count, ListCompaniesParams>("companies/count", flt);
};

export const createCompany = async (
  req: UpsertCompanyRequestBody,
): Promise<CompanyDetailResponseData> => {
  return post<CompanyDetailResponseData, UpsertCompanyRequestBody>(
    "companies",
    req,
  );
};

export const deleteCompany = async (id: string): Promise<Delete> => {
  return deleteReq(`companies/${id}`);
};

export const getCompany = async (
  companyId: string,
): Promise<CompanyDetailResponseData> => {
  return get<CompanyDetailResponseData>(`companies/${companyId}`);
};

export const listCompanies = async (
  flt?: ListCompaniesParams,
): Promise<CompanyDetailResponseData[]> => {
  return get<CompanyDetailResponseData[], ListCompaniesParams>(
    "companies",
    flt,
  );
};

export const updateCompany = async (
  id: string,
  req: UpsertCompanyRequestBody,
): Promise<CompanyDetailResponseData> => {
  return put<CompanyDetailResponseData, UpsertCompanyRequestBody>(
    `companies/${id}`,
    req,
  );
};
