import Clerk from "@clerk/clerk-js";
import { Delete } from "@models/delete";

import { v4 as uuidv4 } from "uuid";

// Set base API URL; assume deployed URL if no env set
const defaultApiBaseUrl = "https://dev-backend.schematichq.com/api";
export const apiBase = import.meta.env.VITE_API_URL
  ? import.meta.env.VITE_API_URL
  : defaultApiBaseUrl;

// Custom error type for including requested URL
export class FetchError extends Error {
  requestId?: string;
  responseCode?: number;
  url?: string;

  constructor(message: string) {
    super(message);
    this.name = "FetchError";
  }
}

// Global variable for storing the current environment ID
let environmentId: string | undefined;
export function setEnvironmentId(id: string) {
  environmentId = id;
}
export function getEnvironmentId() {
  return environmentId;
}

const clerk = new Clerk(import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY);
await clerk.load();

// Get auth headers from Clerk for inclusion in an API request
const getHeaders = async (
  requestId: string,
): Promise<Record<string, string>> => {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  if (clerk?.session) {
    headers["Authorization"] = `Bearer ${await clerk.session.getToken()}`;
    headers["X-Clerk-Session-Id"] = clerk.session.id;
  }

  if (environmentId) {
    headers["X-Schematic-Environment-Id"] = environmentId;
  }

  headers["X-Schematic-Request-Id"] = requestId;

  return headers;
};

export const errorMessage = (error: any) => {
  if ((error as Error).name === "FetchError") {
    const fetchError = error as FetchError;
    return fetchError.message;
  }

  return "Something went wrong";
};

async function handleError(response: Response, url: string, requestId: string) {
  const body = await response.json();
  const error = new FetchError(body?.error ?? body?.message ?? body);
  error.url = url;
  error.responseCode = response.status;
  error.requestId = requestId;
  if (response.status === 401) {
    const clerk = new Clerk(
      import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY,
    );
    await clerk.load();

    clerk.signOut();
    window.location.reload();
  } else {
    throw error;
  }
}

export async function deleteReq(path: string): Promise<Delete> {
  const url = `${apiBase}/${path}`;

  const requestId = uuidv4();
  const response = await fetch(url, {
    headers: await getHeaders(requestId),
    method: "DELETE",
  });

  if (!response.ok) {
    await handleError(response, url, requestId);
  }

  return parseJson(await response.json());
}

export async function get<Response, Filter = Record<string, string>>(
  path: string,
  params?: Filter,
): Promise<Response> {
  const urlObj = new URL(`${apiBase}/${path}`);
  if (params) {
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value !== undefined),
    );
    if (filteredParams) {
      urlObj.search = new URLSearchParams(
        filteredParams as Record<string, string>,
      ).toString();
    }
  }

  const requestId = uuidv4();
  const response = await fetch(urlObj, {
    headers: await getHeaders(requestId),
  });

  if (!response.ok) {
    await handleError(response, urlObj.toString(), requestId);
  }

  return parseJson(await response.json());
}

export async function patch<Response, Request>(
  path: string,
  body: Request,
): Promise<Response> {
  const url = `${apiBase}/${path}`;
  const requestId = uuidv4();
  const response = await fetch(url, {
    method: "PATCH",
    headers: await getHeaders(requestId),
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    await handleError(response, url, requestId);
  }

  return parseJson(await response.json());
}

export async function post<Response, Request>(
  path: string,
  body: Request,
): Promise<Response> {
  const url = `${apiBase}/${path}`;
  const requestId = uuidv4();
  const response = await fetch(url, {
    method: "POST",
    headers: await getHeaders(requestId),
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    await handleError(response, url, requestId);
  }

  return parseJson(await response.json());
}

export async function put<Response, Request>(
  path: string,
  body: Request,
): Promise<Response> {
  const url = `${apiBase}/${path}`;
  const requestId = uuidv4();
  const response = await fetch(url, {
    method: "PUT",
    headers: await getHeaders(requestId),
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    await handleError(response, url, requestId);
  }

  return parseJson(await response.json());
}

// JSON parsing helper for handling dates
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const parseJson = (json: any) => {
  if (typeof json === "string") {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
    if (dateRegex.test(json)) {
      return new Date(json);
    }
  } else if (typeof json === "object") {
    for (const prop in json) {
      json[prop] = parseJson(json[prop]);
    }
  }

  return json;
};
