import { Option } from "@forms/FormikAsyncSelect";
import debounce from "lodash.debounce";
import { ReactNode } from "react";

export interface LoadEntityOptions<Entity, EntityFilter> {
  requestFilter?: EntityFilter;
  mapperFunction?: (entity: Entity) => {
    label: ReactNode | string;
    value: any;
  };
  resultsFilter?: (entity: Entity) => boolean;
}

export const useLoadEntityOptions = <
  Entity extends Record<string, any>,
  EntityFilter extends { q?: string },
>(
  listEntities?: (filter?: EntityFilter) => Promise<Entity[]>,
  mappers?: LoadEntityOptions<Entity, EntityFilter>,
) => {
  const resultsFilter = mappers?.resultsFilter;
  const requestFilter = mappers?.requestFilter;
  const mapperFunction =
    mappers?.mapperFunction ||
    ((entity: Entity) => ({
      label: entity.name,
      value: entity,
    }));
  const debouncedListOptions = debounce(
    async (resolver: (result: Option[]) => void, inputValue: string) => {
      if (!listEntities) {
        return [];
      }

      const data = await listEntities({
        ...(inputValue && { q: inputValue }),
        ...requestFilter,
      } as EntityFilter);

      const options = resultsFilter
        ? data.filter(resultsFilter).map(mapperFunction)
        : data.map(mapperFunction);

      resolver(options);
    },
    300,
  );

  const loadEntityOptions = async (inputValue: string) => {
    return new Promise<Option[]>((resolve) => {
      debouncedListOptions(resolve, inputValue);
    });
  };

  return {
    loadEntityOptions,
  };
};
