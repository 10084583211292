/* tslint:disable */
/* eslint-disable */
/**
 * Schematic API
 * Schematic API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
import type { CreateOrUpdateConditionRequestBody } from "./CreateOrUpdateConditionRequestBody";
import {
  CreateOrUpdateConditionRequestBodyFromJSON,
  CreateOrUpdateConditionRequestBodyFromJSONTyped,
  CreateOrUpdateConditionRequestBodyToJSON,
} from "./CreateOrUpdateConditionRequestBody";
import type { CreateOrUpdateConditionGroupRequestBody } from "./CreateOrUpdateConditionGroupRequestBody";
import {
  CreateOrUpdateConditionGroupRequestBodyFromJSON,
  CreateOrUpdateConditionGroupRequestBodyFromJSONTyped,
  CreateOrUpdateConditionGroupRequestBodyToJSON,
} from "./CreateOrUpdateConditionGroupRequestBody";

/**
 *
 * @export
 * @interface CreateOrUpdateRuleRequestBody
 */
export interface CreateOrUpdateRuleRequestBody {
  /**
   *
   * @type {Array<CreateOrUpdateConditionGroupRequestBody>}
   * @memberof CreateOrUpdateRuleRequestBody
   */
  conditionGroups: Array<CreateOrUpdateConditionGroupRequestBody>;
  /**
   *
   * @type {Array<CreateOrUpdateConditionRequestBody>}
   * @memberof CreateOrUpdateRuleRequestBody
   */
  conditions: Array<CreateOrUpdateConditionRequestBody>;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateRuleRequestBody
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateRuleRequestBody
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CreateOrUpdateRuleRequestBody
   */
  priority: number;
  /**
   *
   * @type {string}
   * @memberof CreateOrUpdateRuleRequestBody
   */
  ruleType?: CreateOrUpdateRuleRequestBodyRuleTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof CreateOrUpdateRuleRequestBody
   */
  value: boolean;
}

/**
 * @export
 */
export const CreateOrUpdateRuleRequestBodyRuleTypeEnum = {
  GlobalOverride: "global_override",
  CompanyOverride: "company_override",
  PlanEntitlement: "plan_entitlement",
  Standard: "standard",
  Default: "default",
  PlanAudience: "plan_audience",
} as const;
export type CreateOrUpdateRuleRequestBodyRuleTypeEnum =
  (typeof CreateOrUpdateRuleRequestBodyRuleTypeEnum)[keyof typeof CreateOrUpdateRuleRequestBodyRuleTypeEnum];

/**
 * Check if a given object implements the CreateOrUpdateRuleRequestBody interface.
 */
export function instanceOfCreateOrUpdateRuleRequestBody(
  value: object,
): value is CreateOrUpdateRuleRequestBody {
  if (!("conditionGroups" in value) || value["conditionGroups"] === undefined)
    return false;
  if (!("conditions" in value) || value["conditions"] === undefined)
    return false;
  if (!("name" in value) || value["name"] === undefined) return false;
  if (!("priority" in value) || value["priority"] === undefined) return false;
  if (!("value" in value) || value["value"] === undefined) return false;
  return true;
}

export function CreateOrUpdateRuleRequestBodyFromJSON(
  json: any,
): CreateOrUpdateRuleRequestBody {
  return CreateOrUpdateRuleRequestBodyFromJSONTyped(json, false);
}

export function CreateOrUpdateRuleRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateOrUpdateRuleRequestBody {
  if (json == null) {
    return json;
  }
  return {
    conditionGroups: (json["condition_groups"] as Array<any>).map(
      CreateOrUpdateConditionGroupRequestBodyFromJSON,
    ),
    conditions: (json["conditions"] as Array<any>).map(
      CreateOrUpdateConditionRequestBodyFromJSON,
    ),
    id: json["id"] == null ? undefined : json["id"],
    name: json["name"],
    priority: json["priority"],
    ruleType: json["rule_type"] == null ? undefined : json["rule_type"],
    value: json["value"],
  };
}

export function CreateOrUpdateRuleRequestBodyToJSON(
  value?: CreateOrUpdateRuleRequestBody | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    condition_groups: (value["conditionGroups"] as Array<any>).map(
      CreateOrUpdateConditionGroupRequestBodyToJSON,
    ),
    conditions: (value["conditions"] as Array<any>).map(
      CreateOrUpdateConditionRequestBodyToJSON,
    ),
    id: value["id"],
    name: value["name"],
    priority: value["priority"],
    rule_type: value["ruleType"],
    value: value["value"],
  };
}
