import {
  HubSpotIntegrationPage,
  SegmentIntegrationPage,
  StripeIntegrationPage,
  WebhooksPage,
} from "@modules/integrations/components/pages";
import { ClerkIntegrationPage } from "@modules/integrations/components/pages/ClerkIntegrationPage";
import { Navigate, RouteObject } from "react-router-dom";
import { IntegrationsPage } from "./consts";
import { IntegrationsView } from "./IntegrationsView";

export const integrationsRoutes: RouteObject[] = [
  {
    path: "integrations/:page?",
    element: <IntegrationsView />,
    children: [
      {
        index: true,
        element: <Navigate to={IntegrationsPage.StripeIntegration} replace />,
      },
      {
        path: IntegrationsPage.HubSpotIntegration,
        element: <HubSpotIntegrationPage />,
      },
      {
        path: IntegrationsPage.StripeIntegration,
        element: <StripeIntegrationPage />,
      },
      {
        path: IntegrationsPage.ClerkIntegration,
        element: <ClerkIntegrationPage />,
      },
      {
        path: IntegrationsPage.SegmentIntegration,
        element: <SegmentIntegrationPage />,
      },
      {
        path: IntegrationsPage.Webhook,
        element: <WebhooksPage />,
      },
    ],
  },
];
