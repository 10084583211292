import { deleteReq, get, post, put } from "@data/index";
import {
  CreateWebhookRequestBody,
  ListWebhookEventsParams,
  ListWebhooksParams,
  UpdateWebhookRequestBody,
  WebhookResponseData,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";
import { WebhookEvent } from "@models/webhook";

export const countWebhooks = async (
  flt?: ListWebhooksParams,
): Promise<Count> => {
  return get<Count, ListWebhooksParams>("webhooks/count", flt);
};

export const listWebhooks = async (
  flt?: ListWebhooksParams,
): Promise<WebhookResponseData[]> => {
  return get<WebhookResponseData[], ListWebhooksParams>("webhooks", flt);
};

export const getWebhook = async (id: string): Promise<WebhookResponseData> => {
  return get(`webhooks/${id}`);
};

export const createWebhook = async (
  req: CreateWebhookRequestBody,
): Promise<WebhookResponseData> => {
  return post<WebhookResponseData, CreateWebhookRequestBody>("webhooks", req);
};

export const updateWebhook = async (
  id: string,
  req: UpdateWebhookRequestBody,
): Promise<WebhookResponseData> => {
  return put<WebhookResponseData, UpdateWebhookRequestBody>(
    `webhooks/${id}`,
    req,
  );
};

export const deleteWebhook = async (id: string): Promise<Delete> => {
  return deleteReq(`webhooks/${id}`);
};

export const listWebhookEvents = async (
  flt?: ListWebhookEventsParams,
): Promise<WebhookEvent[]> => {
  return get<WebhookEvent[], ListWebhookEventsParams>("webhook-events", flt);
};
