import { SidebarBlankState } from "./SidebarBlankState";
import { SidebarBlock } from "./SidebarBlock";

export interface SidebarBlankProps {
  onClick?: () => void;
}

export const SidebarBlankData = () => {
  return (
    <SidebarBlock>
      <SidebarBlankState
        icon="connection"
        title="No utilization data"
        description="Associate an event or trait with this feature to begin tracking utilization"
      />
    </SidebarBlock>
  );
};
