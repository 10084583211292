import { get } from "@data/index";
import { Count } from "@models/count";
import { ListFilter } from "@models/req";
import { FeatureCompany, FeatureUsage, FeatureUser } from "@models/usage";

export type FeatureUsageFilter = {
  q?: string;
} & ListFilter;

export const listFeatureUsage = (companyId?: string) => {
  return async (flt?: FeatureUsageFilter): Promise<FeatureUsage[]> => {
    if (!companyId) {
      return [];
    }

    return get<FeatureUsage[], ListFilter>(`usage/${companyId}`, flt);
  };
};

export const countFeatureUsage = (companyId?: string) => {
  return async (flt?: FeatureUsageFilter): Promise<Count> => {
    if (!companyId) {
      return {
        count: 0,
      };
    }

    return get<Count, FeatureUsageFilter>(`usage/${companyId}/count`, flt);
  };
};

export const listFeatureCompanies = (featureId: string) => {
  return async (flt?: FeatureUsageFilter): Promise<FeatureCompany[]> => {
    return get<FeatureCompany[], ListFilter>(
      `feature-companies/${featureId}`,
      flt,
    );
  };
};

export const countFeatureCompanies = (featureId: string) => {
  return async (flt?: FeatureUsageFilter): Promise<Count> => {
    return get<Count, FeatureUsageFilter>(
      `feature-companies/${featureId}/count`,
      flt,
    );
  };
};

export const listFeatureUsers = (featureId: string) => {
  return async (flt?: FeatureUsageFilter): Promise<FeatureUser[]> => {
    return get<FeatureUser[], ListFilter>(`feature-users/${featureId}`, flt);
  };
};

export const countFeatureUsers = (featureId: string) => {
  return async (flt?: FeatureUsageFilter): Promise<Count> => {
    return get<Count, FeatureUsageFilter>(
      `feature-users/${featureId}/count`,
      flt,
    );
  };
};
