import { Alert } from "@ui/Alert";

export const FeatureChecksBlankState = () => {
  return (
    <Alert className="mb-6" size="lg" style="gray" background="schematic">
      <div className="relative z-10 flex justify-between items-center font-body">
        <div>
          <div className="text-3xl mb-4">No checks yet</div>
          <div className="text-lg font-body">
            <>
              This flag has not been checked yet. It might not be active in your{" "}
              <br /> codebase yet or has not yet been used.{" "}
            </>
          </div>
        </div>
      </div>
    </Alert>
  );
};
