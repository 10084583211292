import { ViewWrapper } from "@ui/ViewWrapper";
import { CompaniesTable } from "./tables/CompaniesTable";

export const CompaniesView = () => {
  return (
    <ViewWrapper>
      <CompaniesTable />
    </ViewWrapper>
  );
};
