import {
  ViewPageNavigationTab,
  ViewPageNavigation,
} from "@ui/ViewPageNavigation";
import cx from "classnames";
import { ReactNode } from "react";
import { BreadCrumbTypes, BreadCrumbs } from "../BreadCrumbs";
import { TruncatableData } from "../TruncatableData";

interface ViewPageHeaderProps {
  tabsData?: ViewPageNavigationTab[];
  crumbsData: BreadCrumbTypes[];
  children?: ReactNode;
  description?: string | ReactNode;
}

export const ViewPageHeader = ({
  children,
  crumbsData,
  tabsData,
  description,
}: ViewPageHeaderProps) => {
  return (
    <>
      <div
        className={cx(
          "relative bg-[#FAFAFA]",
          tabsData ? "pb-8" : "border-b border-px border-[#D9D9D9] pb-12",
        )}
      >
        <div className="">
          {crumbsData && <BreadCrumbs data={crumbsData} />}
          <div className="">
            <div className="tw-wrapper px-8">
              <div className="flex justify-between">{children}</div>
              {description && (
                <div className="mt-6">
                  <TruncatableData
                    truncateLimit={100}
                    truncateAt={60}
                    data={description}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {tabsData && (
        <div className="sticky top-[0] z-50 left-0 px-0 bg-[#FAFAFA] border-b border-px border-[#D9D9D9] ">
          <div className="tw-wrapper">
            <ViewPageNavigation className="" tabs={tabsData} />
          </div>
        </div>
      )}
    </>
  );
};
