import cx from "classnames";
import React from "react";

type ElevateProps = {
  className?: string;
  children?: React.ReactNode;
};

export const Elevate = ({ className, children }: ElevateProps) => {
  return (
    <div
      className={cx(
        "shadow-sm rounded-lg font-body border border-gray-200 bg-white m-4 p-8",
        className,
      )}
    >
      {children}
    </div>
  );
};
