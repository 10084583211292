import cx from "classnames";

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  suffix?: React.ReactNode;
  textAlign?: "left" | "center" | "right";
}

export const TextInput = ({
  className,
  suffix,
  textAlign = "right",
  ...props
}: TextInputProps) => {
  return (
    <div
      className={cx(
        "group relative flex items-center text-sm font-body border border-[#E2E5E9] rounded-[5px] has-[:focus]:outline-2 has-[:focus]:outline-[#194BFB]",
      )}
    >
      <input
        className={cx(
          "appearance-none w-20 h-7 py-1 bg-transparent focus:outline-none",
          `text-${textAlign}`,
          className,
          { "px-3": !suffix },
        )}
        type="text"
        {...props}
      />
      {suffix && (
        <div className="relative z-[1] flex items-center h-full pr-3">
          {suffix}
        </div>
      )}
    </div>
  );
};
