import { Alert } from "@ui/Alert";

type CompanyEventsBlankStateProps = {
  title: string;
  message: string;
};

export const CompanyEventsBlankState = ({
  title,
  message,
}: CompanyEventsBlankStateProps) => {
  return (
    <Alert
      background="schematic"
      size="lg"
      style="gray"
      className="text-left mt-6"
    >
      <div className="flex flex-col justify-between w-full flex-1 relative z-10 text-gray space-y-4">
        <div className="text-3xl">{title}</div>
        <div className="text-lg">{message}</div>
      </div>
    </Alert>
  );
};
