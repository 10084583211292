import { ViewWrapper } from "@ui/ViewWrapper";
import { Outlet } from "react-router-dom";
import SettingsNavigation from "./SettingsNavigation";

export const SettingsView = () => {
  return (
    <ViewWrapper>
      <div className="flex flex-row relative">
        <SettingsNavigation />
        <Outlet />
      </div>
    </ViewWrapper>
  );
};
