import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { WebhookResponseData } from "@models/api";
import { WebhookReq, WebhookRequestType, WebhookStatus } from "@models/webhook";
import { WebhookRequestTypeDescription } from "@modules/settings/consts";
import { sortWebhookTypesAlphabetically } from "@modules/settings/helpers";
import {
  createWebhook,
  updateWebhook,
} from "@modules/settings/queries/webhooks";
import * as Checkbox from "@radix-ui/react-checkbox";
import { CheckboxIndicator } from "@radix-ui/react-checkbox";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { FormColumn, FormHeader } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { Separator } from "@ui/Separator";
import { Switch } from "@ui/Switch";
import cx from "classnames";
import { ArrayHelpers, FieldArray, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  url: Yup.string().required("URL is required"),
  requestTypes: Yup.array()
    .of(
      Yup.mixed<WebhookRequestType>().oneOf(Object.values(WebhookRequestType)),
    )
    .min(1),
});

export type WebhookEditOverlayProps = {
  webhook: WebhookResponseData | null;
  onClose: () => void;
};

export const WebhookEditOverlay = ({
  webhook,
  onClose,
}: WebhookEditOverlayProps) => {
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const [allChecked, setAllChecked] = useState<boolean | "indeterminate">(
    webhook
      ? webhook.requestTypes.length === Object.values(WebhookRequestType).length
        ? true
        : "indeterminate"
      : false,
  );

  const initialValues = {
    name: webhook?.name || "",
    url: webhook?.url || "",
    status: webhook?.status,
    requestTypes: (webhook?.requestTypes as WebhookRequestType[]) || [],
  } as WebhookReq;

  const onSubmit = async (
    values: WebhookReq,
    helpers: FormikHelpers<WebhookReq>,
  ) => {
    setLoading(true);
    const saveFn = webhook?.id
      ? (values: WebhookReq) => updateWebhook(webhook.id, values)
      : createWebhook;

    try {
      await saveFn(values);

      queryClient.invalidateQueries();
      setApiError(undefined);
      onClose();
      setLoading(false);
    } catch (err) {
      setApiError(errorMessage(err));
      setLoading(false);
      helpers.setSubmitting(false);
    }
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      <OverlayModal>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form className="flex flex-col">
              <div className="flex h-full flex-1 max-h-full overflow-hidden relative">
                <div
                  onClick={onClose}
                  className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
                >
                  <Icon name="close" className="text-3xl" />
                </div>

                <div className="flex flex-col flex-1 px-12 py-12 h-full min-h-[632px] max-h-[750px]  max-w-full overflow-hidden overflow-y-scroll">
                  <div className="flex-1 overflow-y-auto">
                    <div className="flex justify-between items-center">
                      <FormHeader
                        title={
                          webhook?.id ? "Edit endpoint" : "Create endpoint"
                        }
                        compact
                      />
                      {webhook && (
                        <div className="mb-8 mr-4">
                          <Switch
                            name="status"
                            label={
                              values?.status === WebhookStatus.Active
                                ? "Active"
                                : "Inactive"
                            }
                            labelPlacement="left"
                            checked={values?.status === WebhookStatus.Active}
                            onCheckedChange={async (checked) => {
                              await setFieldValue(
                                "status",
                                checked
                                  ? WebhookStatus.Active
                                  : WebhookStatus.Inactive,
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <FormColumn>
                      <FormikControl
                        control="input"
                        name="name"
                        type="text"
                        value={values.name}
                        label="Name"
                        placeholder="Enter name"
                        description="A user friendly name for your webhook."
                      />

                      <FormikControl
                        control="input"
                        name="url"
                        type="text"
                        value={values.url}
                        label="URL"
                        placeholder="Enter url"
                        description="A HTTP POST request will be sent to this URL for every event type you listen for."
                      />

                      <Separator />

                      <FieldArray
                        name="requestTypes"
                        render={(arrayHelpers: ArrayHelpers) => (
                          <div className="space-y-5">
                            <div className="flex items-center ">
                              <FormikControl
                                control="select-search"
                                name="requestTypeSearch"
                                type="text"
                                label="Select triggers to subscribe to"
                                placeholder="Search for triggers"
                                isClerable
                                options={Object.values(WebhookRequestType)
                                  .filter((requestType) => {
                                    return !values.requestTypes.includes(
                                      requestType,
                                    );
                                  })
                                  .sort(sortWebhookTypesAlphabetically)
                                  .map((requestType) => ({
                                    value: requestType,
                                    label: requestType,
                                  }))}
                                onChange={async (option) => {
                                  arrayHelpers.push(option.value);
                                  setAllChecked(
                                    values.requestTypes.length <
                                      Object.values(WebhookRequestType).length -
                                        1
                                      ? "indeterminate"
                                      : true,
                                  );
                                }}
                              />

                              <div className="flex space-x-2 items-center  justify-center ml-4  mt-6 cursor-pointer">
                                <Checkbox.Root
                                  id="requestTypesCheckbox"
                                  className={cx(
                                    "w-[1.2rem] h-[1.2rem] min-w-[1.2rem] min-h-[1.2rem] rounded-md border-solid border-[2px] border-gray-300 inline-flex items-center justify-center text-[1.3rem] appearance-none outline-none",
                                    allChecked
                                      ? "bg-blue-400 !border-blue-400"
                                      : "bg-white hover:border-gray-400/40",
                                  )}
                                  checked={allChecked}
                                  onCheckedChange={async (checked) => {
                                    setAllChecked(checked);
                                    await setFieldValue(
                                      "requestTypes",
                                      checked
                                        ? Object.values(WebhookRequestType)
                                        : [],
                                    );
                                  }}
                                >
                                  <CheckboxIndicator className="">
                                    {allChecked === "indeterminate" && (
                                      <Icon
                                        name="bell"
                                        className="text-violet-500"
                                      />
                                    )}
                                    {allChecked === true && (
                                      <Icon
                                        name="check"
                                        className="text-white"
                                      />
                                    )}
                                  </CheckboxIndicator>
                                </Checkbox.Root>

                                <label
                                  htmlFor="requestTypesCheckbox"
                                  className={cx(
                                    "cursor-pointer label-md !leading-none whitespace-nowrap !mb-0",
                                    !allChecked &&
                                      "opacity-75 hover:opacity-100",
                                  )}
                                >
                                  Select all
                                </label>

                                <LabeledTooltip
                                  description="Subscribe to all triggers that currently exist, but not new triggers that may be added in the future."
                                  position="relative"
                                  placement="top-left"
                                  size="lg"
                                />
                              </div>
                            </div>

                            <div>
                              {values.requestTypes
                                .sort(sortWebhookTypesAlphabetically)
                                .map(
                                  (
                                    requestType: WebhookRequestType,
                                    index: number,
                                  ) => {
                                    const onRemove = () => {
                                      arrayHelpers.remove(index);
                                      setAllChecked(
                                        values.requestTypes.length > 1
                                          ? "indeterminate"
                                          : false,
                                      );
                                    };

                                    return (
                                      <div
                                        key={requestType}
                                        className="flex flex-col relative py-2 border-b-2 border-r-gray-500"
                                      >
                                        <div className="font-normal">
                                          {requestType}
                                        </div>
                                        <div className="text-sm text-gray-500">
                                          {
                                            WebhookRequestTypeDescription[
                                              requestType
                                            ]
                                          }
                                        </div>
                                        <div
                                          onClick={onRemove}
                                          className="inline-flex absolute z-[500] top-2 right-2 hover:cursor-pointer text-black/50 hover:text-blue-400"
                                        >
                                          <Icon
                                            name="close"
                                            className="text-3xl"
                                          />
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                            </div>
                          </div>
                        )}
                      />

                      {apiError && (
                        <div className="px-2">
                          <Alert size="xs" style="red">
                            <div className="flex items-center justify-center space-x-2">
                              <div className="text-base font-body ">
                                <span className="font-semibold">Uh-oh!</span>{" "}
                                {apiError}
                              </div>
                            </div>
                          </Alert>
                        </div>
                      )}
                    </FormColumn>
                  </div>

                  <div className="flex mt-6 items-end justify-end space-x-2">
                    <button
                      className="button button-sm button-blue"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
