import { newPillColorLookup } from "@consts/colors";
import { EventStatus } from "@models/events";

export const pillColorEventStatus = newPillColorLookup({
  [EventStatus.Enriched]: "green",
  [EventStatus.Failed]: "red",
  [EventStatus.Pending]: "yellow",
  [EventStatus.Processed]: "green",
  [EventStatus.Unknown]: "red",
});
