import { get } from "@data/index";
import { ListApiRequestsParams } from "@models/api";
import { ApiKeyRequest } from "@models/apiKey";

export const getApiRequest = async (id: string): Promise<ApiKeyRequest> => {
  return get<ApiKeyRequest>(`api-requests/${id}`);
};

export const listApiRequests = async (
  flt?: ListApiRequestsParams,
): Promise<ApiKeyRequest[]> => {
  return get<ApiKeyRequest[], ListApiRequestsParams>("api-requests", flt);
};
