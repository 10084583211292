import cx from "classnames";
import { Icon } from "../Icon";

type SeparatorProps = {
  className?: string;
  type?: "normal" | "schematic";
};

export const Separator = ({
  className,
  type = "schematic",
}: SeparatorProps) => {
  const styles = cx("flex items-center justify-center relative", className);

  return (
    <div className={styles}>
      <div className="w-full absolute top-[50%] left-0 translate-y-[-50%] bg-gradient-to-r from-gray-300 via-gray-400/40 to-gray-300 h-px"></div>
      {!(type == "normal") && (
        <div className="bg-white px-2 relative">
          <Icon
            name="schematic"
            className="text-orange-500 text-2xl leading-none"
          />
        </div>
      )}
    </div>
  );
};
