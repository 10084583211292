import { EnvironmentPill } from "@components/ui/EnvironmentPill";
import { AccountEnvironment } from "@models/account";
import cx from "classnames";

type EnvironmentCellProps = {
  environment: AccountEnvironment;
  className?: string;
};

export const EnvironmentCell = ({
  environment,
  className,
}: EnvironmentCellProps) => {
  return (
    <div className={cx("space-x-2 items-center", className)}>
      <span className="text-gray-400/80">{environment.name}</span>
      <EnvironmentPill environmentType={environment.environmentType} />
    </div>
  );
};
