import { PlanType } from "@models/plan";
import { PlansConfigurationView } from "@modules/plans/components/PlansConfigurationView";
import { Navigate, RouteObject } from "react-router-dom";
import { PlansView, PlanView } from "./components";
import {
  PlanAudienceTab,
  PlanBillingTab,
  PlanEntitlementsTab,
} from "./components/tabs";
import { PlanRoutePaths } from "./consts";
import { PlanSubview } from "./types";

export const planRoutes: RouteObject[] = [
  { path: PlanRoutePaths.Plans, element: <PlansView type={PlanType.Plan} /> },
  { path: PlanRoutePaths.AddOns, element: <PlansView type={PlanType.AddOn} /> },
  { path: PlanRoutePaths.Configuration, element: <PlansConfigurationView /> },
  {
    path: `${PlanRoutePaths.Plans}/:id/:subview?`,
    element: <PlanView type={PlanType.Plan} />,
    children: [
      {
        index: true,
        element: <Navigate to={PlanSubview.Entitlements} replace />,
      },
      {
        path: PlanSubview.Entitlements,
        element: <PlanEntitlementsTab />,
      },
      { path: PlanSubview.Audience, element: <PlanAudienceTab /> },
      { path: PlanSubview.Billing, element: <PlanBillingTab /> },
    ],
  },
  {
    path: `${PlanRoutePaths.AddOns}/:id/:subview?`,
    element: <PlanView type={PlanType.AddOn} />,
    children: [
      {
        index: true,
        element: <Navigate to={PlanSubview.Entitlements} replace />,
      },
      {
        path: PlanSubview.Entitlements,
        element: <PlanEntitlementsTab />,
      },
      { path: PlanSubview.Audience, element: <PlanAudienceTab /> },
      { path: PlanSubview.Billing, element: <PlanBillingTab /> },
    ],
  },
];
