type TableLoaderProps = {
  rows?: number;
};
export const TableLoader = ({ rows = 10 }: TableLoaderProps) => {
  const LoaderRow = () => {
    return (
      <div className="flex items-center justify-between border-b-2 border-b-gray-100 py-2.5 px-4">
        <div className="w-26 flex items-center">
          <div className="bg-gray-200 rounded-full h-9 w-16 min-w-[2.25rem] max-w-[2.25rem] min-h-[2.25rem] mr-4" />
          <div className="h-3 bg-gray-200 rounded w-24"></div>
        </div>
        <div className="h-4 bg-gray-200 rounded w-24"></div>
        <div className="h-4 bg-gray-200 rounded w-28"></div>
        <div className="h-4 bg-gray-200 rounded w-20"></div>
        <div className="h-4 bg-gray-200 rounded w-24"></div>
        <div className="h-4 bg-gray-200 rounded w-20"></div>
      </div>
    );
  };

  return (
    <div role="status" className="w-full h-full  animate-pulse">
      <div className="flex items-center">
        <div className="h-6 bg-gray-200 rounded w-48"></div>
        <div className="flex flex-1 items-end justify-end space-x-2">
          <div className="h-9 bg-gray-200 rounded-full w-60"></div>
          <div className="h-9 bg-gray-200 rounded w-20 w-"></div>
          <div className="h-9 bg-gray-200 rounded w-20"></div>
        </div>
      </div>
      <div className="p-0 border-2 border-gray-100 rounded-lg">
        <div className="flex items-start  justify-between p-4 border-b-2 border-b-gray-100">
          <div className="flex items-center">
            <div className="h-3 bg-gray-200 rounded w-36"></div>
          </div>
          <div className="h-4 bg-gray-200 rounded w-24"></div>
          <div className="h-4 bg-gray-200 rounded w-28"></div>
          <div className="h-4 bg-gray-200 rounded w-20"></div>
          <div className="h-4 bg-gray-200 rounded w-24"></div>
          <div className="h-4 bg-gray-200 rounded w-20"></div>
        </div>

        <div className="flex flex-col pb-0">
          {Array.from({ length: rows }, (_, i) => (
            <LoaderRow key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};
