import { BillingProductDetailResponseData } from "@models/api";
import { Plan } from "@models/plan";
import { ClipCopy } from "@ui/ClipCopy";
import { Icon } from "@ui/Icon";
import { Pill } from "@ui/Pill";
import { formatCurrency } from "@utils/strings";
import { ReactNode } from "react";

interface HeaderBlockProps {
  title?: string;
  label: string | ReactNode;
}

const HeaderBlock = ({ title, label }: HeaderBlockProps) => {
  return (
    <div className="flex flex-col">
      <div className="pr-8">
        {title && (
          <span className="text-sm text-black font-medium leading-none inline-block mb-2">
            {title}
          </span>
        )}

        <div className="text-gray-400">{label}</div>
      </div>
    </div>
  );
};

interface BillingCardProps {
  plan: Plan;
  billingProduct: BillingProductDetailResponseData;
}

export const BillingCard = ({ plan, billingProduct }: BillingCardProps) => {
  return (
    <div className="bg-white shadow-[0_1px_15px_0px_rgba(16,24,40,0.07)] rounded-lg">
      {/*Header*/}
      <div className="relative flex justify-between items-center p-4">
        <Icon
          name="stripe"
          className="absolute top-0 right-0 w-6 h-6 text-blue-400 border rounded-full text-center inline-table m-2"
        />

        <HeaderBlock
          title={billingProduct.name}
          label={
            <div className="flex">
              <Icon name="stripe" />
              <ClipCopy data={billingProduct.externalId} />
            </div>
          }
        />

        <HeaderBlock
          label={
            <Pill color="green" type="rounded">
              Active
            </Pill>
          }
        />

        <HeaderBlock
          title="Default Price"
          label={
            <>
              <span className="block text-center">
                {plan.monthlyPrice || plan.yearlyPrice ? "" : "-"}
              </span>
              <span className="block">
                {plan.monthlyPrice
                  ? `${formatCurrency(plan.monthlyPrice.price)} per month`
                  : ""}
              </span>
              <span className="block">
                {plan.yearlyPrice
                  ? `${formatCurrency(plan.yearlyPrice.price)} per year`
                  : ""}
              </span>
            </>
          }
        />

        <HeaderBlock
          label={
            <a
              href={`https://dashboard.stripe.com/products/${billingProduct.externalId}`}
              target="_blank"
              className="text-blue-400 hover:opacity-80 space-x-2"
            >
              <span>See in Stripe</span>
              <Icon name="folder" />
            </a>
          }
        />
      </div>
      {/*  Body*/}
      <div className="bg-gray-100 px-8 py-4">
        <table className="table-compact w-full">
          <thead>
            <tr>
              <th className="w-full">Prices</th>
              <th>Currency</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {billingProduct.prices.map((productPrice) => (
              <tr key={productPrice.id} className="border-b border-gray-300">
                <td>
                  {formatCurrency(productPrice.price)} per{" "}
                  {productPrice.interval}
                </td>
                <td className="uppercase text-gray-400">
                  {billingProduct.currency}
                </td>
                <td className="text-gray-400">
                  {productPrice.interval ? "Recurring" : "One-time"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
