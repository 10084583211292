import { Alert } from "@ui/Alert";

export const WebhookEventsBlankState = () => {
  return (
    <Alert
      background="schematic"
      size="lg"
      style="gray"
      className="text-center mt-6"
    >
      <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
        <div className="flex flex-col text-left items-start space-y-2">
          <div className="text-[25px] font-medium font-body">
            No webhook events yet
          </div>
          <div className="text-lg leading-6 text-gray-600">
            Once events are received, status and payload will appear here
          </div>
        </div>
      </div>
    </Alert>
  );
};
