interface SidebarDataBlockProps {
  value?: number;
  description?: string;
  label?: string;
}

export const SidebarDataBlock = ({
  value,
  description,
  label,
}: SidebarDataBlockProps) => {
  return (
    <div className="">
      <div className="flex items-end space-x-2">
        <div className="text-4xl font-medium ">{value?.toLocaleString()}</div>
        <div className="text-lg font-medium">{label}</div>
      </div>
      <div className="text-gray-400 text-sm">{description}</div>
    </div>
  );
};
