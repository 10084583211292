import { Delete } from "@models/delete";
import {
  InstalledIntegration,
  InstallIntegrationReq,
  InstallIntegrationResult,
  IntegrationDataSetSampleResult,
  IntegrationFilter,
  IntegrationWebhook,
} from "@models/integrations";
import { get, post, deleteReq } from "./index";

export const listIntegrations = async (
  flt?: IntegrationFilter,
): Promise<InstalledIntegration[]> => {
  return get<InstalledIntegration[], IntegrationFilter>(
    "integrations/installed",
    flt,
  );
};

export const installIntegration = async (
  params: InstallIntegrationReq,
): Promise<InstallIntegrationResult> => {
  return post<InstallIntegrationResult, InstallIntegrationReq>(
    "integrations/install",
    params,
  );
};

export const removeIntegration = async (id: string): Promise<Delete> => {
  return deleteReq(`integrations/uninstall/${id}`);
};

export const refreshIntegrationData = async (id: string): Promise<Delete> => {
  return get(`integrations/refresh-data/${id}`);
};

export const loadSampleDataSet = async (
  params: InstallIntegrationReq,
): Promise<IntegrationDataSetSampleResult> => {
  return post<IntegrationDataSetSampleResult, InstallIntegrationReq>(
    "integrations/sample",
    params,
  );
};

export const getClerkWebhookData = async (): Promise<IntegrationWebhook> => {
  return get(`integrations/webhook/clerk`);
};
