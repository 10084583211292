import { UserInitials } from "@ui/UserInitials";

interface SidebarBlockUsersProps {
  users: { name: string }[];
}

export const SidebarBlockUsers = ({ users }: SidebarBlockUsersProps) => {
  const userList = (users ?? []).slice(0, 5);

  return (
    <div className="space-y-2">
      {userList.map((user, index) => (
        <div className="flex flex-row justify-between" key={index}>
          <div className="flex space-x-2 items-center">
            <UserInitials name={user.name} showName={true} size="xs" />
          </div>
        </div>
      ))}
    </div>
  );
};
