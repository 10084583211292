import cx from "classnames";
import { ReactNode } from "react";

export interface LabelDataProps {
  label?: string | ReactNode;
  variant?: "column" | "row";
  className?: string;
  children?: ReactNode;
}

interface LayoutProps {
  spacings?: "normal" | "wide";
  className?: string;
  children?: ReactNode;
}

export const LabelDataRow = ({
  spacings = "normal",
  children,
  className,
}: LayoutProps) => {
  const spacingsMap = {
    normal: "space-x-8",
    wide: "space-x-16",
  };
  const styles = cx("flex", spacingsMap[spacings], className);
  return <div className={styles}>{children}</div>;
};

export const LabelDataColumn = ({ children, className }: LayoutProps) => {
  return <div className={cx("space-y-6 p-8", className)}>{children}</div>;
};

interface LabelDataGridProps {
  columns?: "1" | "2" | "3" | "4" | "5";
  className?: string;
  wrapperClass?: string;
  children?: ReactNode;
}

export const LabelDataGrid = ({
  columns = "2",
  children,
}: LabelDataGridProps) => {
  const columnMap = {
    1: "grid-cols-1 gap-8",
    2: "grid-cols-2 gap-8",
    3: "grid-cols-3 gap-10",
    4: "grid-cols-4 gap-12",
    5: "grid-cols-5 gap-3",
  };

  const styles = cx("grid", columnMap[columns]);
  return <div className={styles}>{children}</div>;
};

export const LabelData = ({
  label,
  variant = "column",
  className,
  children,
}: LabelDataProps) => {
  const variantMap = {
    column: "flex-col",
    row: "flex-row justify-between",
  };

  const labelMap = {
    column: "mb-2",
    row: "",
  };

  const labelStyles = cx("capitalize flex-1", labelMap[variant]);
  const styles = cx("flex", variantMap[variant], className);

  return (
    <div className={styles}>
      {label && <div className={labelStyles}>{label}</div>}
      <div className="text-gray-400 text-sm flex space-x-1 items-center">
        {children}
      </div>
    </div>
  );
};
