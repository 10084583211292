import { ViewWrapper } from "@ui/ViewWrapper";
import { CompanyUsersTable } from "./tables";

export const CompanyUsersView = () => {
  return (
    <ViewWrapper>
      <CompanyUsersTable />
    </ViewWrapper>
  );
};
