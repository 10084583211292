import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import {
  EntityTraitDefinitionResponseData,
  UpdateEntityTraitDefinitionRequestBody,
} from "@models/api";
import { EntityTraitType, EntityTraitTypes } from "@models/entityTrait";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { FormColumn } from "@ui/FormParts";
import {
  Overlay,
  OverlayFooter,
  OverlayHeader,
  OverlayModal,
} from "@ui/Overlay";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { EntityTraitTypeDisplay } from "../../consts";
import * as api from "../../queries/entityTraits";

export interface EntityTraitDefinitionOverlayProps {
  entityTrait: EntityTraitDefinitionResponseData;
  onClose: () => void;
  onSave: () => void;
}

export const EntityTraitDefinitionOverlay = ({
  entityTrait,
  onClose,
  onSave,
}: EntityTraitDefinitionOverlayProps) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();
  const initialValues = {
    displayName: entityTrait.displayName,
    traitType: entityTrait.traitType as EntityTraitType,
  };

  const onSubmit = (values: UpdateEntityTraitDefinitionRequestBody) => {
    setLoading(true);

    api
      .updateEntityTraitDefinition(entityTrait.id, values)
      .then(() => {
        onSave();
        setLoading(false);
        setApiError(undefined);
        onClose();
      })
      .catch((error) => {
        onSave();
        setLoading(false);
        console.error(error);
        setApiError(errorMessage(error));
      });
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex  items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}

      <OverlayModal size="md">
        <OverlayHeader title="Edit trait" onClose={onClose} />

        <Formik
          className="flex flex-col"
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            displayName: Yup.string().required("Must provide display name"),
            traitType: Yup.string().required("Must select trait type"),
          })}
        >
          {(form) => {
            return (
              <Form className="px-12 pb-12">
                <FormColumn className="pb-4">
                  <FormikControl
                    control="input"
                    label="Display name"
                    name="displayName"
                    placeholder="Enter display name"
                    type="text"
                  />

                  <FormikSelect
                    label="Type"
                    name="traitType"
                    options={EntityTraitTypes.map((traitType) => {
                      return {
                        label: EntityTraitTypeDisplay[traitType],
                        value: traitType,
                      };
                    })}
                  />

                  {apiError && (
                    <Alert size="xs" style="red">
                      <span className="font-semibold">Uh-oh!</span> {apiError}
                    </Alert>
                  )}
                </FormColumn>

                <OverlayFooter>
                  <Button type="submit" color="blue" disabled={!form.dirty}>
                    Save changes
                  </Button>
                </OverlayFooter>
              </Form>
            );
          }}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
