import { useContextQuery } from "@hooks/useContextQuery";
import { WebhookResponseData } from "@models/api";
import { WebhookRequestType } from "@models/webhook";
import { WebhookEventsTable } from "@modules/settings/components/tables/WebhookEventsTable";
import { WebhookRequestTypeDescription } from "@modules/settings/consts";
import { getWebhook } from "@modules/settings/queries/webhooks";
import { Button } from "@ui/Button";
import { ClipCopy } from "@ui/ClipCopy";
import { LabelData } from "@ui/LabelData";
import { Overlay, OverlayHeader, OverlayModal } from "@ui/Overlay";
import { OverlayLoader } from "@ui/Overlay/OverlayLoader";
import React, { useState } from "react";

type WebhookDetailsOverlayProps = {
  webhookId: string;
  onClose: () => void;
  onEdit: (webhook: WebhookResponseData) => void;
};

export const WebhookDetailsOverlay = ({
  webhookId,
  onClose,
  onEdit,
}: WebhookDetailsOverlayProps) => {
  const [showAll, setShowAll] = useState(false);

  const { isLoading, error, data } = useContextQuery({
    queryKey: [`webhook`, webhookId],
    queryFn: () => getWebhook(webhookId),
  });

  const handleShowAll = () => setShowAll(!showAll);

  const requestTypeData = (data?.requestTypes as WebhookRequestType[]) || [];
  const truncatableData = showAll
    ? requestTypeData
    : requestTypeData.slice(0, 5);
  if (error) throw error;

  return (
    <Overlay onClose={onClose}>
      <OverlayModal position="right">
        {isLoading || !data ? (
          <OverlayLoader />
        ) : (
          <>
            <OverlayHeader title="Webhook Detail" onClose={onClose}>
              <Button
                size="sm"
                color="white"
                className="mr-6 mb-8"
                onClick={() => {
                  onEdit(data);
                }}
              >
                Edit
              </Button>
            </OverlayHeader>
            <div className="px-12 pt-8 space-y-6">
              <LabelData label="Name">
                <ClipCopy data={data.name} title={data.name} />
              </LabelData>

              <LabelData label="URL">
                <ClipCopy data={data.url} title={data.url} />
              </LabelData>

              <div className="flex flex-col ">
                <div className="flex flex-row justify-between items-center">
                  <LabelData label="Listening for" />

                  <span
                    onClick={handleShowAll}
                    className="text-blue-400 cursor-pointer mt-4 hover:underline"
                  >
                    {showAll ? "Hide" : "See all"}
                  </span>
                </div>
                <div className="w-full flex flex-col">
                  {truncatableData.map((requestType) => {
                    return (
                      <div
                        key={requestType}
                        className="flex flex-col relative py-2 border-b-2 border-r-gray-500 last-of-type:border-b-0"
                      >
                        <div className="font-bold text-black">
                          {requestType}
                        </div>
                        <div className="text-sm text-gray-500">
                          {WebhookRequestTypeDescription[requestType]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <WebhookEventsTable webhookId={data.id} />
            </div>
          </>
        )}
      </OverlayModal>
    </Overlay>
  );
};
