import { Diamond, DiamondStyleTypes } from "@ui/Diamond";
import { diamondsList } from "@ui/Diamond/consts";
import { Icon } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { useField } from "formik";
import { ReactNode, useState } from "react";
import Select from "react-select";
import icons from "../../icons/files/icons.json";

type IconsSelectTypes = "icon" | "diamond";

export interface IconsSelectProps {
  label?: string | ReactNode;
  name: string;
  type: IconsSelectTypes;
}

const IconsSelectDropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
}) => (
  <div className="relative select-icons">
    {target}
    {isOpen && <Menu>{children}</Menu>}
    {isOpen && <Blanket onClick={onClose} />}
  </div>
);

const Menu = (props: JSX.IntrinsicElements["div"]) => (
  <div className="bg-white rounded shadow-lg mt-2 absolute z-20" {...props} />
);

const Blanket = (props: JSX.IntrinsicElements["div"]) => (
  <div className="fixed inset-0 z-10" {...props} />
);

export const DropdownIcons = ({ type, name, label }: IconsSelectProps) => {
  const [field, , helpers] = useField(name);
  const [isOpen, setIsOpen] = useState(false);

  const formatOptionLabel = (value: any) => (
    <div className="inline-flex items-center justify-center w-full">
      {type === "diamond" ? (
        <span title={value.label} className="flex-1">
          <Diamond
            style={value.value as DiamondStyleTypes}
            size="sm"
            className="mt-[4px] mb-0 !leading-none"
          />
        </span>
      ) : (
        <div title={value.label}>
          <Icon
            name={value.value as IconNameTypes}
            className="text-2xl text-gray-400"
          />
        </div>
      )}
    </div>
  );

  const options = [
    {
      label: type === "icon" ? "Icons" : "Diamonds",
      options:
        type === "icon"
          ? Object.keys(icons).map((icon) => ({ label: icon, value: icon }))
          : Object.keys(diamondsList).map((diamond) => ({
              label: diamond,
              value: diamond,
            })),
    },
  ];

  return (
    <IconsSelectDropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <div className="flex flex-col">
          {label && (
            <label htmlFor={name} className="label-md">
              {label}
            </label>
          )}
          <div
            onClick={() => setIsOpen((prev) => !prev)}
            className="flex w-11 h-11 items-center justify-center rounded-full overflow-hidden border border-blue-100"
          >
            {type === "icon" ? (
              <Icon
                name={field.value as IconNameTypes}
                className="text-2xl text-black leading-none"
              />
            ) : (
              <Diamond size="sm" style={field.value as DiamondStyleTypes} />
            )}
          </div>
        </div>
      }
    >
      <Select
        formatOptionLabel={formatOptionLabel}
        autoFocus
        components={{
          DropdownIndicator: () => (
            <Icon
              name="search"
              className="text-2xl leading-none mr-2 text-gray-400/60"
            />
          ),
          IndicatorSeparator: null,
        }}
        controlShouldRenderValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        onChange={(value) => {
          helpers.setValue(value?.value);
          setIsOpen(false);
        }}
        isSearchable
        menuIsOpen
        options={options}
        classNamePrefix="select-icons"
        className="select-icons-wrapper"
        placeholder="Search..."
        tabSelectsValue={false}
        value={field.value && { label: field.value, value: field.value }}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        type={type}
      />
    </IconsSelectDropdown>
  );
};
