import { listEvents } from "@data/events";
import {
  EventBodyFlagCheck,
  EventDetailResponseData,
  ListEventsParams,
} from "@models/api";
import { EventType } from "@models/events";
import { FeatureFlag } from "@models/feature";
import { FlagCheckOverlay } from "@modules/features/components/overlays/FlagCheckOverlay";
import {
  getCheckStatusColor,
  getCheckStatusText,
  getCheckStatus,
} from "@modules/features/helpers";
import { ColumnDef } from "@tanstack/react-table";
import { CompanyCell } from "@ui/CompanyCell";
import { InfiniteTable } from "@ui/InfiniteTable";
import { Pill } from "@ui/Pill";
import { TableHeader } from "@ui/TableHeader";
import { UserInitials } from "@ui/UserInitials";
import { formatDate } from "@utils/date";
import { useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FeatureChecksBlankState } from "../blank-states/FeatureChecksBlankState";

export const FlagChecksTab = () => {
  const { flag } = useOutletContext<{ flag: FeatureFlag }>();
  const [detailsOverlay, setDetailsOverlay] = useState<string | null>(null);

  const [filter] = useState<ListEventsParams>({
    eventTypes: [EventType.FlagCheck],
    flagId: flag.id,
  });

  const columns = useMemo<ColumnDef<EventDetailResponseData>[]>(
    () => [
      {
        id: "createdAt",
        header: "Timestamp",
        accessorKey: "createdAt",
        cell: (cellInfo) => {
          const event = cellInfo.row.original;
          return <span>{formatDate(event.capturedAt)}</span>;
        },
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
        cell: (cellInfo) => {
          const event = cellInfo.row.original;
          const status = getCheckStatus(event.body as EventBodyFlagCheck);
          return (
            <Pill color={getCheckStatusColor(status)}>
              {getCheckStatusText(status)}
            </Pill>
          );
        },
      },
      {
        id: "company",
        header: "Company",
        accessorKey: "company",
        cell: (cellInfo) => {
          const company = cellInfo.row.original.company;
          return <CompanyCell company={company} />;
        },
      },
      {
        id: "user",
        header: "User",
        accessorKey: "user",
        cell: (cellInfo) => {
          const event = cellInfo.row.original;
          return event.user ? (
            <UserInitials name={event.user.name} showName={true} size="xs" />
          ) : (
            ""
          );
        },
      },
      {
        id: "value",
        header: "Evaluation",
        accessorKey: "value",
        cell: (cellInfo) => {
          const event = cellInfo.row.original;
          const body = event.body as EventBodyFlagCheck;
          return <span className="pr-1">{body.value ? "🟢" : "🔴"}</span>;
        },
      },
    ],
    [],
  );

  return (
    <>
      <TableHeader headerText="Flag Check Log" />

      <InfiniteTable
        queryKey={["events", "event_type:flag_check", `flag_id:${flag.id}`]}
        queryFn={listEvents}
        filter={filter}
        columns={columns}
        onRowClick={(row: EventDetailResponseData) => setDetailsOverlay(row.id)}
        blankStateComponent={<FeatureChecksBlankState />}
      />

      {detailsOverlay && (
        <FlagCheckOverlay
          eventId={detailsOverlay}
          onClose={() => setDetailsOverlay(null)}
        />
      )}
    </>
  );
};
