import { Navigate, RouteObject } from "react-router-dom";
import { CompanyUsersView, CompanyUserView } from "./components";
import { CompanyUserOverviewTab, CompanyUserUsageTab } from "./components/tabs";
import { CompanyUserSubview } from "./types";

export const companyUserRoutes: RouteObject[] = [
  { path: "users", element: <CompanyUsersView /> },
  {
    path: "users/:id/:subview?",
    element: <CompanyUserView />,
    children: [
      {
        index: true,
        element: <Navigate to={CompanyUserSubview.Overview} replace />,
      },

      {
        path: CompanyUserSubview.Overview,
        element: <CompanyUserOverviewTab />,
      },

      {
        path: CompanyUserSubview.Usage,
        element: <CompanyUserUsageTab />,
      },
    ],
  },
];
