import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import cx from "classnames";
import { ReactNode, useState } from "react";
import { TruncatableData } from "../TruncatableData";

interface MiniObjectInterface {
  title?: string | ReactNode;
  description?: string | ReactNode;
  columns: any[];
  className?: string;
  size?: "sm" | "md";
  onClick?: () => void;
  showHeader?: boolean;
}

export const MiniObject = ({
  title,
  description,
  columns,
  className,
  onClick,
  size = "md",
  showHeader = true,
}: MiniObjectInterface) => {
  const [data] = useState(() => [[]]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const sizeMap = {
    sm: "p-0",
    md: "p-4",
  };
  return (
    <div className={cx(className)}>
      {title && <div className="text-3xl mb-6 leading-none">{title}</div>}
      {description && (
        <div className="mb-6">
          <TruncatableData
            truncateLimit={100}
            truncateAt={50}
            data={description}
          />
        </div>
      )}
      <div
        className={cx(
          "table-wrapper shadow-sm rounded-lg font-body border border-gray-200",
          sizeMap[size],
        )}
      >
        <table className="table-default table-simple !border-0 !m-0 !p-0">
          {showHeader && (
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} onClick={onClick} className="group">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cx(
                      onClick && "group-hover:!bg-blue-50 cursor-pointer",
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </div>
  );
};
