import { FeatureCompany } from "@models/usage";
import { EntitlementUsageCell } from "@modules/features";
import { Logo } from "@ui/Logo";

interface SidebarBlockCompaniesProps {
  companies: FeatureCompany[];
}

export const SidebarBlockCompanies = ({
  companies,
}: SidebarBlockCompaniesProps) => {
  const companyList = (companies ?? [])
    .filter((company) => company.company)
    .slice(0, 5);

  return (
    <div className="space-y-6">
      {companyList.map((featureCompany, index) => {
        const { company } = featureCompany;
        if (!company) {
          return <></>;
        }

        return (
          <div className="flex flex-col space-y-1" key={index}>
            <div className="flex flex-col space-y-3">
              <div className="flex space-x-2 items-center">
                <Logo
                  src={company.logoUrl}
                  className="!mr-0"
                  size="xs"
                  alt={company.name}
                />
                <div className="text-sm font-medium tracking-wide">
                  {company.name}
                </div>
              </div>
              <div className="flex items-center">
                <EntitlementUsageCell
                  parent="feature-sidebar"
                  featureUsage={featureCompany}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
