import { SettingsView } from "@modules/settings/components";
import {
  ApiKeysPage,
  AuditLogPage,
  KeysAndTraitsPage,
  MiscPage,
  PlanPage,
  TeamPage,
} from "@modules/settings/components/pages";
import { SettingsPage } from "@modules/settings/consts";
import { Navigate, RouteObject } from "react-router-dom";

export const settingRoutes: RouteObject[] = [
  {
    path: "settings/:page?",
    element: <SettingsView />,
    children: [
      { index: true, element: <Navigate to={SettingsPage.AuditLog} replace /> },
      {
        path: SettingsPage.AuditLog,
        element: <AuditLogPage />,
      },
      {
        path: SettingsPage.Team,
        element: <TeamPage />,
      },
      {
        path: SettingsPage.KeysAndTraits,
        element: <KeysAndTraitsPage />,
      },
      {
        path: SettingsPage.ApiKeys,
        element: <ApiKeysPage />,
      },
      {
        path: SettingsPage.Plan,
        element: <PlanPage />,
      },
      {
        path: SettingsPage.Misc,
        element: <MiscPage />,
      },
    ],
  },
];
