import { SidebarBlankState } from "./SidebarBlankState";
import { SidebarBlock } from "./SidebarBlock";

export const SidebarBlankEntitlements = () => {
  return (
    <SidebarBlock>
      <SidebarBlankState
        icon="entitlements"
        title="No companies yet"
        description="Add a company to plan audience with this feature or add a company override to give access to this feature"
      />
    </SidebarBlock>
  );
};
