import { FormikSelect } from "@forms/FormikSelect";
import { Elevate } from "@ui/Elevate";

export const PlanChangeRules = () => {
  return (
    <Elevate className="flex flex-col">
      <div className="flex flex-col space-y-2 pb-8">
        <div className="text-xl">Plan Change Rules</div>
        <div className="text-xs">
          These rules apply when companies make plan changes using a component
        </div>
      </div>
      <div className="flex py-6">
        <div className="flex flex-col space-y-2 pr-8">
          <div className="text-lg">On upgrade</div>
          <div className="text-xs text-blue-300">
            Switching to a higher cost plan
          </div>
        </div>
        <div className="flex flex-col grow">
          <FormikSelect
            disabled
            label="Billing Rule"
            name="upgradeRule"
            options={[
              {
                label: "Upgrade immediately, prorate next bill",
              },
            ]}
          />
          <div className="flex flex-col space-y-2 bg-gray-100 px-8 pt-8 pb-4 -mt-4 rounded-xl">
            <div className="font-semibold text-sm">Entitlement Behaviour</div>
            <div>Increase limits immediately.</div>
          </div>
        </div>
      </div>
      <div className="flex pt-6 border-t">
        <div className="flex flex-col space-y-2 pr-8">
          <div className="text-lg">On downgrade</div>
          <div className="text-xs text-blue-300">
            Switching to a lower cost plan
          </div>
        </div>
        <div className="flex flex-col grow bg-gray-100 rounded-xl">
          <div className="flex flex-col space-y-2 px-8 py-4">
            <div className="font-semibold text-sm">Billing Behaviour</div>
            <div>Downgrade immediately, provide credits for unused time.</div>
          </div>
          <div className="flex flex-col space-y-2 px-8 py-4">
            <div className="font-semibold text-sm">Entitlement Behaviour</div>
            <div>
              Require user to be compliant with new limits before allowing
              downgrade.
            </div>
          </div>
        </div>
      </div>
    </Elevate>
  );
};
