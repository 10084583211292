import { deleteReq, get, post, put } from "@data/index";
import {
  BillingProductResponseData,
  ListPlansParams,
  type UpsertBillingProductRequestBody,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";
import { Plan, PlanReq, PlanType } from "@models/plan";

export const countPlans = (type?: PlanType) => {
  return async (flt?: ListPlansParams): Promise<Count> => {
    const params = {
      ...(type ? { planType: type } : {}),
      ...flt,
    };

    return get<Count, ListPlansParams>("plans/count", params);
  };
};

export const createPlan = async (req: PlanReq): Promise<Plan> => {
  return post<Plan, PlanReq>("plans", req);
};

export const deletePlan = async (id: string): Promise<Delete> => {
  return deleteReq(`plans/${id}`);
};

export const getPlan = async (planId: string): Promise<Plan> => {
  return get<Plan>(`plans/${planId}`);
};

export const listPlans = (type?: PlanType) => {
  return async (flt?: ListPlansParams): Promise<Plan[]> => {
    const params = {
      ...(type ? { planType: type } : {}),
      ...flt,
    };

    return get<Plan[], ListPlansParams>("plans", params);
  };
};

export const updatePlan = async (
  planId: string,
  req: PlanReq,
): Promise<Plan> => {
  return put<Plan, PlanReq>(`plans/${planId}`, req);
};

export const updatePlanBillingProduct = async (
  planId: string,
  req: UpsertBillingProductRequestBody,
): Promise<BillingProductResponseData> => {
  return put<BillingProductResponseData, UpsertBillingProductRequestBody>(
    `plans/${planId}/billing_products`,
    req,
  );
};
