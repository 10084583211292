import { useEmbed, type EmbedContextProps } from "@schematichq/schematic-react";
import cx from "classnames";

interface NavItemProps {
  value: EmbedContextProps["layout"];
  label: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  isActive?: boolean;
}

const NavItem = ({ label, onClick, isActive }: NavItemProps) => {
  return (
    <li
      className={cx(
        "flex items-center h-full border-b-[3px] hover:cursor-pointer hover:text-black",
        {
          "border-b-white": !isActive,
          "font-bold text-black border-b-[#194BFB]": isActive,
        },
      )}
      onClick={onClick}
    >
      <span data-text={label}>{label}</span>
    </li>
  );
};

export const Layout = () => {
  const { layout, setLayout } = useEmbed();

  const navItems: { value: EmbedContextProps["layout"]; label: string }[] = [
    { value: "portal", label: "Portal" },
    { value: "checkout", label: "Checkout" },
    { value: "payment", label: "Payment Methods" },
    { value: "disabled", label: "Disabled" },
  ];

  const handleNavItemClick = (value: EmbedContextProps["layout"]) => {
    setLayout(value);
  };

  return (
    <nav className="h-14 px-9 bg-white border-b border-[#E2E2E2]">
      <ul className="flex justify-center items-center gap-8 w-fit h-full mx-auto font-body font-medium text-[#7B7B7B]">
        {navItems.map(({ value, label }) => (
          <NavItem
            key={value}
            value={value}
            label={label}
            onClick={() => {
              handleNavItemClick(value);
            }}
            isActive={layout === value}
          />
        ))}
      </ul>
    </nav>
  );
};
