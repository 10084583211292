import React, { ReactNode, createContext, useContext } from "react";

interface OverlayContextTypes {
  onClose?: () => void;
}

interface OverlayContextProviderProps {
  children: ReactNode;
  value: OverlayContextTypes;
}

const OverlayContext = createContext<OverlayContextTypes | undefined>(
  undefined,
);

export const OverlayContextProvider = ({
  children,
  value,
}: OverlayContextProviderProps) => {
  return (
    <OverlayContext.Provider value={value}>{children}</OverlayContext.Provider>
  );
};

export const useOverlayContext = () => {
  const context = useContext(OverlayContext);

  if (!context) {
    throw new Error(
      "Can't be used outside of <OverlayContext.Provider>..</OverlayContext.Provider>",
    );
  }

  return context;
};
