import { Icon } from "@components/ui/Icon";
import * as integrationsApi from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import { BillingCustomersTable } from "@modules/integrations/tables/BillingCustomersTable";
import { BillingProductsTable } from "@modules/integrations/tables/BillingProductsTable";
import { SettingsBox } from "@ui/SettingsBox";
import { StripeIntegrationForm } from "../../../settings/components/StripeIntegrationForm";

export const StripeIntegrationPage = () => {
  const getIntegrations = useContextQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => integrationsApi.listIntegrations({ type: "stripe" }),
  });
  return (
    <div className="flex flex-col space-y-8 max-w-full w-full">
      <div>
        <div>
          <SettingsBox
            title="Stripe Integration"
            description={
              <>
                Rollout flags, define entitlements, and build plan audiences
                based on Stripe subscriptions.{" "}
                <a
                  target="_blank"
                  href="https://docs.schematichq.com/integrations/stripe"
                  className="cursor-pointer text-blue-400 hover:underline hover:opacity-80"
                >
                  Learn more in the docs
                  <Icon
                    name="arrow-right"
                    className="-rotate-45 inline-block ml-1 text-base"
                  />
                </a>
                .
              </>
            }
            style="boxed"
            className="h-auto"
          >
            <div className=" absolute right-12 top-12 flex p-1 items-center rounded-full border border-gray-300">
              <Icon
                name="stripe"
                className="text-[#6772E5] text-2xl leading-none"
              />
            </div>
            <StripeIntegrationForm />
          </SettingsBox>
        </div>
      </div>

      {getIntegrations.isSuccess && getIntegrations.data.length !== 0 && (
        <div>
          <BillingCustomersTable />
        </div>
      )}

      {getIntegrations.isSuccess && getIntegrations.data.length !== 0 && (
        <div>
          <BillingProductsTable />
        </div>
      )}

      <div>
        <SettingsBox
          title="Unique Identifier Key"
          description="Map Stripe Customers to Companies in Schematic. Include the Stripe customer ID in any API request or event sent to Schematic."
          style="boxed"
          className="h-auto"
        >
          <div className="flex space-x-6 items-center">
            <div className="flex items-center space-x-4">
              <div className="inline-flex p-1 items-center rounded-full border border-gray-300">
                <Icon
                  name="stripe"
                  className="text-[#6772E5] text-2xl leading-none"
                />
              </div>
              <div className="font-medium text-lg">customer_id</div>
            </div>
            <div>
              <Icon name="arrow-right" className="text-3xl leading-none" />
            </div>
            <div className="flex items-center space-x-4">
              <div className="inline-flex p-1 items-center rounded-full border border-gray-300">
                <Icon
                  name="schematic"
                  className="text-[#DB6769] text-2xl leading-none"
                />
              </div>
              <div className="font-medium text-lg">stripe_customer_id</div>
            </div>
          </div>
        </SettingsBox>
      </div>
    </div>
  );
};
