import { get } from "@data/index";
import {
  BillingProductResponseData,
  CountBillingProductsRequest,
  CountBillingProductsResponse,
  CountCustomersResponse,
  ListBillingProductsRequest,
} from "@models/api";

export const listProducts = async (
  flt?: ListBillingProductsRequest,
): Promise<BillingProductResponseData[]> => {
  return get<BillingProductResponseData[], ListBillingProductsRequest>(
    `billing/products`,
    flt,
  );
};

export const countProducts = async (
  flt?: CountBillingProductsRequest,
): Promise<CountCustomersResponse> => {
  return get<CountBillingProductsResponse, CountBillingProductsRequest>(
    `billing/products/count`,
    flt,
  );
};
