import { useLayoutEffect, useRef, useState } from "react";
import { ColorPicker, ColorService, useColor } from "react-color-palette";
import { createPortal } from "react-dom";
import "react-color-palette/css";

interface OverlayProps {
  onClick: () => void;
}

const Overlay = ({ onClick }: OverlayProps) => {
  return (
    <div
      id="color-overlay"
      className="fixed inset-0 z-[999]"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === "Escape") {
          onClick();
        }
      }}
    />
  );
};

interface ColorProps {
  value: string;
  onChange: (value: string) => void;
}

export const Color = ({ value, onChange }: ColorProps) => {
  const [colorPickerPosition, setColorPickerPosition] = useState({
    top: 0,
    right: 0,
  });
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const ref = useRef<HTMLDivElement>(null);

  const [color, setColor] = useColor(value);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);

    const parsedColor = ColorService.convert("hex", event.target.value);
    if (parsedColor.hex === "#000000" && event.target.value !== "#000000")
      return;
    setColor(parsedColor);
    onChange(parsedColor.hex);
  };

  useLayoutEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    if (!rect) return;
    setColorPickerPosition({
      top: rect.bottom,
      right: window.innerWidth - rect.right,
    });
  }, [isColorPickerVisible]);

  return (
    <>
      <div ref={ref} className="relative">
        <div
          className="absolute top-1/2 -translate-y-1/2 left-1 w-5 h-5 rounded-full"
          style={{ backgroundColor: color.hex }}
          onClick={() => {
            setIsColorPickerVisible((prev) => !prev);
          }}
        />

        <input
          className="appearance-none w-28 px-4 py-1 text-right text-sm font-body border border-[#E2E5E9] rounded-[5px]"
          type="text"
          value={inputValue}
          onChange={handleChange}
        />
      </div>

      {isColorPickerVisible &&
        createPortal(
          <>
            <Overlay
              onClick={() => {
                setIsColorPickerVisible(false);
              }}
            />

            <div
              className="absolute z-[1000]"
              style={{
                top: colorPickerPosition.top,
                right: colorPickerPosition.right,
              }}
            >
              <ColorPicker
                height={128}
                hideInput={["rgb", "hsv"]}
                hideAlpha
                color={color}
                onChange={(color) => {
                  setColor(color);
                  onChange(color.hex);
                  setInputValue(color.hex);
                }}
              />
            </div>
          </>,
          document.body,
        )}
    </>
  );
};
