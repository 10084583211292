export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatCurrency(amount: number): string {
  try {
    const dollars = amount / 100;

    const formatValue = (value: number, symbol: string): string => {
      let formatted = value.toFixed(1);
      if (formatted.endsWith(".0")) {
        formatted = formatted.slice(0, -2);
      }
      return `$${formatted}${symbol}`;
    };

    if (dollars >= 1_000_000) {
      return formatValue(dollars / 1_000_000, "M");
    } else if (dollars >= 1_000) {
      return formatValue(dollars / 1_000, "k");
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(dollars);
    }
  } catch (error) {
    console.error("Error formatting currency", error);

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount / 100);
  }
}

export function formatInitials(name: string): string {
  const cleaned = name.trim();
  if (cleaned === "") {
    return "";
  }

  const names = cleaned.split(" ");
  const initials = names.map((n) => n[0]);
  if (initials.length === 1) {
    return initials[0].toUpperCase();
  }

  return [initials[0], initials[initials.length - 1]]
    .filter(Boolean)
    .join("")
    .toUpperCase();
}

export function titlecase(str: string): string {
  str = str.replace(/_/g, " ");

  const crmChars = str.slice(0, 3);
  if (crmChars === "crm") {
    const title = str.toLowerCase().split(" ");
    const firstPart = title[0].toUpperCase();
    const secondPart = title[1].replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
    return `${firstPart} ${secondPart}`;
  }

  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function formatJson(json: string) {
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch {
    return json;
  }
}
