import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { Button } from "@components/ui/Button";
import { errorMessage } from "@data/index";
import { listIntegrations } from "@data/integrations";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import useSecondaryTableHeader from "@hooks/useSecondaryTableHeader";
import {
  PlanGroupPlanDetailResponseData,
  UpdatePlanGroupRequestBody,
} from "@models/api";
import { PlanType } from "@models/plan";
import { countPlans, listPlans } from "@modules/plans";
import { ConnectStripeBreakdown } from "@modules/plans/components/ConnectStripeBreakdown";
import { AddActivePlansOverlay } from "@modules/plans/components/overlays";
import { PlanChangeRules } from "@modules/plans/components/PlanChangeRules";
import { PlanConfigurationCard } from "@modules/plans/components/PlanConfigurationCard";
import { PlanRoutePaths } from "@modules/plans/consts";
import {
  createPlansConfiguration,
  getPlansConfiguration,
  updatePlansConfiguration,
} from "@modules/plans/queries/planConfiguration";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Elevate } from "@ui/Elevate";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { PlanLabel } from "@ui/PlanLabel";
import { TableHeader } from "@ui/TableHeader";
import { Toast } from "@ui/Toast";
import { ViewWrapper } from "@ui/ViewWrapper";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

type FormValues = {
  id?: string;
  plans: PlanGroupPlanDetailResponseData[];
  planIds: string[];
  defaultPlan?: PlanGroupPlanDetailResponseData;
  defaultPlanId?: string;
};

type DefaultPlanOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

const validationSchema = Yup.object().shape({
  plans: Yup.array().of(
    Yup.object()
      .shape({
        yearlyPrice: Yup.object(),
        monthlyPrice: Yup.object(),
      })
      .test(
        "monthlyPrice or yearlyPrice",
        "Either monthly or yearly plan price is required",
        ({ monthlyPrice, yearlyPrice }) => !!monthlyPrice || !!yearlyPrice,
      ),
  ),
  planIds: Yup.array().of(Yup.string()).min(0).max(100),
  defaultPlanId: Yup.string(),
});

export const PlansConfigurationView = () => {
  const { environmentId } = useParams() as {
    environmentId: string;
  };
  const [addActivePlansOverlay, setAddActivePlansOverlay] = useState(false);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();
  const [toastOpen, setToastOpen] = useState(false);

  const planConfigurationFlag = useSchematicFlag("plan-configuration");

  const plansHeaderText = useSecondaryTableHeader(
    "plans",
    countPlans(PlanType.Plan),
  );
  const addonsHeaderText = useSecondaryTableHeader(
    "add ons",
    countPlans(PlanType.AddOn),
  );

  const onSubmit = async (
    { id, plans, defaultPlan }: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    setLoading(true);

    const req = {
      planIds: plans.map((p) => p.id),
      defaultPlanId: defaultPlan?.id,
    };

    const saveFn = id
      ? (values: UpdatePlanGroupRequestBody) =>
          updatePlansConfiguration(id, values)
      : createPlansConfiguration;

    try {
      await saveFn(req);

      await queryClient.invalidateQueries();
      setApiError(undefined);
      helpers.setSubmitting(false);

      setToastOpen(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  const { data, isLoading } = useContextQuery({
    queryKey: ["plans", "configuration"],
    queryFn: async () => {
      try {
        return await getPlansConfiguration();
      } catch (error: any) {
        if (error.responseCode === 404) {
          return {
            id: undefined,
            defaultPlan: undefined,
            defaultPlanId: undefined,
            plans: [],
            planIds: [],
          };
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error.responseCode === 404) {
        return false;
      }

      return failureCount < 3;
    },
  });

  const { data: integrationsData, isLoading: isIntegrationsLoading } =
    useContextQuery({
      queryKey: ["integrations", "stripe"],
      queryFn: async () => {
        try {
          return await listIntegrations({ type: "stripe" });
        } catch (error: any) {
          // TODO: Fiz integrations returning 500
          if (error.responseCode <= 500) {
            return [];
          }

          throw error;
        }
      },
      retry: (failureCount, error: any) => {
        // TODO: Fiz integrations returning 500
        if (error.responseCode <= 500) {
          return false;
        }

        return failureCount < 3;
      },
    });

  const stripeConnected = integrationsData?.[0]?.state;

  if (isLoading || isIntegrationsLoading) {
    return <SinglePageLoader />;
  }

  const initialValues: FormValues = {
    id: data?.id,
    plans: data?.plans || [],
    planIds: (data?.plans || []).map((plan) => plan.id),
    defaultPlan: data?.defaultPlan,
    defaultPlanId: data?.defaultPlan?.id,
  };

  if (!planConfigurationFlag) {
    return;
  }

  return (
    <ViewWrapper>
      <Toast
        title="Plan configuration changes saved."
        open={toastOpen}
        setOpen={setToastOpen}
        position="center"
        duration={2000}
      />
      <div className="pb-16">
        {/*TODO: Maybe change to some slimmer loader*/}
        {loading && <SchematicOverlayLoader />}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          enableReinitialize
        >
          {({
            values,
            setFieldValue,
            dirty,
            resetForm,
            submitForm,
            errors,
            touched,
          }) => (
            <>
              <TableHeader
                headerTabs={[
                  {
                    label: plansHeaderText,
                    url: `/${environmentId}/${PlanRoutePaths.Plans}`,
                  },
                  {
                    label: addonsHeaderText,
                    url: `/${environmentId}/${PlanRoutePaths.AddOns}`,
                  },
                  {
                    active: true,
                    label: "Configuration",
                    url: `/${environmentId}/${PlanRoutePaths.Configuration}`,
                  },
                ]}
                buttons={[
                  {
                    children: <>Cancel</>,
                    color: "white",
                    disabled: !dirty,
                    onClick: () => {
                      resetForm();
                    },
                  },
                  {
                    children: <>Save changes</>,
                    color: "blue",
                    disabled: !dirty,
                    onClick: () => {
                      submitForm();
                    },
                  },
                ]}
              />

              <Form>
                {apiError && (
                  <div className="px-2 mt-4">
                    <Alert size="xs" style="red">
                      <div className="flex items-center justify-center space-x-2">
                        <div className="text-base font-body ">
                          <span className="font-semibold">Uh-oh!</span>{" "}
                          {apiError}
                        </div>
                      </div>
                    </Alert>
                  </div>
                )}

                <Elevate className="flex justify-between items-center">
                  <div className="text-xl">Default plan</div>
                  <div className="flex items-center space-x-1">
                    <div className="text-xs flex justify-center items-center whitespace-nowrap">
                      <LabeledTooltip
                        description="Companies without another base plan will be assigned to this plan"
                        position="relative"
                      />
                    </div>
                    <FormikAsyncSelect
                      className="max-w-xs"
                      name="defaultPlanId"
                      placeholder="Choose default plan"
                      defaultOptions
                      loadOptions={listPlans(PlanType.Plan)}
                      loadOptionsMappers={{
                        mapperFunction: (plan) => ({
                          value: plan.id,
                          label: <PlanLabel plan={plan} font="normal" />,
                          entity: plan,
                        }),
                      }}
                      selectedOption={
                        values?.defaultPlan && {
                          value: values.defaultPlan.id,
                          label: (
                            <PlanLabel
                              plan={values.defaultPlan}
                              font="normal"
                            />
                          ),
                          entity: values.defaultPlan,
                        }
                      }
                      onChange={async (option: DefaultPlanOption) => {
                        await setFieldValue("defaultPlan", option?.entity);
                        await setFieldValue("defaultPlanId", option?.value);
                      }}
                    />
                  </div>
                </Elevate>

                {!stripeConnected && <ConnectStripeBreakdown page="config" />}

                {stripeConnected && (
                  <>
                    <Elevate>
                      <div className="flex justify-between items-center mb-4">
                        <div className="text-xl flex items-center">
                          <span className="h-3 w-3 bg-emerald-300 rounded-full inline-block mr-2"></span>
                          {"  "}
                          Live plans
                        </div>
                        <div className="text-xs flex justify-center items-center whitespace-nowrap">
                          <LabeledTooltip
                            description="Live Plans are visible in components and available for purchase."
                            position="relative"
                          />
                        </div>
                      </div>
                      {values.plans.length === 0 && (
                        <Alert
                          size="lg"
                          style="light-gray"
                          background="schematic"
                        >
                          <div className="flex justify-center items-center">
                            <Button
                              color="blue"
                              type="button"
                              onClick={() => {
                                setAddActivePlansOverlay(true);
                              }}
                            >
                              Add plans
                            </Button>
                          </div>
                        </Alert>
                      )}
                      {values.plans.length > 0 && (
                        <div className="flex mt-6 relative">
                          <div className="relative overflow-x-hidden grow">
                            <div className="flex grow space-x-4 overflow-x-scroll">
                              {values.plans.map((plan) => (
                                <PlanConfigurationCard
                                  plan={plan}
                                  key={plan.id}
                                />
                              ))}
                            </div>
                            {/*<div id="activePlansArea" className="absolute right-0 top-0 bottom-0 w-12 flex justify-center items-center  bg-gradient-to-r from-white/50 backdrop-blur-xs via-white to-white">*/}
                            {/*  {">"}*/}
                            {/*</div>*/}
                          </div>
                          <Button
                            type="button"
                            className="relative right-0 top-0 bottom-0 !text-3xl"
                            color="white"
                            onClick={() => {
                              setAddActivePlansOverlay(true);
                            }}
                          >
                            +
                          </Button>
                        </div>
                      )}
                      {touched.planIds &&
                        errors.plans &&
                        errors.plans.length > 0 && (
                          <Alert size="xs" style="red" className="mt-4">
                            <div className="flex items-center justify-center space-x-2">
                              <div className="text-base font-body ">
                                <span className="font-semibold">Uh-oh!</span>{" "}
                                All plans should have set-up default monthly or
                                yearly price
                              </div>
                            </div>
                          </Alert>
                        )}
                    </Elevate>

                    <PlanChangeRules />
                  </>
                )}
              </Form>
              {addActivePlansOverlay && (
                <AddActivePlansOverlay
                  selectedPlans={values.plans}
                  setActivePlans={async (plans) => {
                    const planIds = plans.map((plan) => plan.id);

                    await setFieldValue("plans", plans);
                    await setFieldValue("planIds", planIds);

                    if (
                      values.defaultPlanId &&
                      !planIds.includes(values.defaultPlanId)
                    ) {
                      await setFieldValue("defaultPlan", undefined);
                      await setFieldValue("defaultPlanId", undefined);
                    }
                  }}
                  onClose={() => {
                    setAddActivePlansOverlay(false);
                  }}
                />
              )}
            </>
          )}
        </Formik>
      </div>
    </ViewWrapper>
  );
};
