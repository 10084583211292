import cx from "classnames";
import { ReactNode } from "react";

export interface FormPartProps {
  className?: string;
  children?: ReactNode;
}

export const FormRow = ({ className, children }: FormPartProps) => {
  const styles = cx("flex flex-row space-x-6 flex-1", className);
  return <div className={styles}>{children}</div>;
};

export const FormColumn = ({ className, children }: FormPartProps) => {
  const styles = cx("flex flex-col space-y-6", className);
  return <div className={styles}>{children}</div>;
};

export interface FormHeaderProps {
  label?: string;
  title?: string | ReactNode;
  description?: string | ReactNode;
  compact?: boolean;
}

export const FormHeader = ({
  label,
  title,
  description,
  compact,
}: FormHeaderProps) => {
  return (
    <div className={cx("mb-8 w-full", compact ? "space-y-2" : "space-y-4")}>
      {label && (
        <div className="text-gray-400 uppercase font-body font-bold text-xs tracking-wider">
          {label}
        </div>
      )}

      {title && (
        <div className="text-black text-2xl font-medium font-body">{title}</div>
      )}

      {description && (
        <div className="font-body text-gray-500 text-base">{description}</div>
      )}
    </div>
  );
};
