import { BlankStateOnCreate, PageBlankState } from "@ui/PageBlankState";

export const ComponentsBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <PageBlankState
      title="💼 Create your first component!"
      description={
        <>
          A component could be put into your own website to show plans and
          entitlement there.
        </>
      }
      buttons={
        onCreate && [
          {
            color: "blue",
            children: <>Create component</>,
            disabled,
            onClick: onCreate,
          },
        ]
      }
    />
  );
};
