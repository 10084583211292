import { TableLoader } from "@components/loaders/TableLoader";
import { Alert } from "@components/ui/Alert";
import { DropdownDots } from "@components/ui/DropdownDots";
import * as featuresApi from "@data/features";
import * as api from "@data/flags";
import { usePermission } from "@hooks/usePermission";
import useSecondaryTableHeader from "@hooks/useSecondaryTableHeader";
import useTablePagination from "@hooks/useTablePagination";
import { ListFlagsParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { FeatureFlag } from "@models/feature";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { ButtonProps } from "@ui/Button";
import { Pill } from "@ui/Pill";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import { formatDate } from "@utils/date";

import moment from "moment";
import pluralize from "pluralize";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";

import { FeatureFlagsBlankState } from "../blank-states/FeatureFlagsBlankState";
import { FlagDeleteOverlay } from "../overlays/FlagDeleteOverlay";

export const FeatureFlagsTableEmptyFilterState = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[30vh] bg-zinc-100 rounded">
      <p className="text-4xl font-bold">No results.</p>
      <p className="text-sm mt-6">Try a different search.</p>
    </div>
  );
};

export interface FeatureFlagsTableProps {
  onCreate: () => void;
}

export const FeatureFlagsTable = ({ onCreate }: FeatureFlagsTableProps) => {
  const { environmentId } = useParams() as {
    environmentId: string;
  };
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteFlag, setDeleteFlag] = useState<FeatureFlag | undefined>();
  const [filter, setFilter] = useState<ListFlagsParams>({});
  const [successAlert, setSuccessAlert] = useState("");
  const flagEditAllowed = usePermission(ClerkUserPermission.flags_edit);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? {} : { q: searchTerm });
  }, [searchTerm]);

  const columns = useMemo<ColumnDef<FeatureFlag>[]>(() => {
    return [
      {
        id: "flag",
        header: "Flag",
        accessorKey: "flag",
        cell: (cellInfo) => {
          const flag = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium">{flag.name}</div>
                <div className="leading-none text-gray-400">{flag.key}</div>
              </div>
            </div>
          );
        },
      },
      {
        id: "lastModified",
        header: "Last Modified",
        accessorKey: "lastModified",
        cell: (cellInfo) => {
          const flag = cellInfo.row.original;

          return (
            <div className="inline-block space-y-1">
              <div className="leading-none font-bold">
                {moment(flag.updatedAt).fromNow()}
              </div>
              <div className="leading-none text-gray-400">
                Created {formatDate(flag.createdAt)}
              </div>
            </div>
          );
        },
      },
      {
        id: "variations",
        header: "Variations",
        accessorKey: "variations",
        cell: (cellInfo) => {
          const flag = cellInfo.row.original;
          const variations = [
            ...new Set([
              flag.defaultValue,
              ...flag.rules.map((rule) => rule.value),
            ]),
          ];

          return (
            <div className="inline-block space-y-1">
              {variations.map((variation, i) => (
                <span className="pr-1" key={i}>
                  {variation ? "🟢" : "🔴"}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        id: "feature",
        header: "Feature",
        accessorKey: "feature",
        cell: (cellInfo) => {
          const flag = cellInfo.row.original;
          if (!flag.feature) return <></>;

          return (
            <div className="flex flex-row items-center">
              <Pill color="gray" type="rounded">
                {flag.feature.name}
              </Pill>
            </div>
          );
        },
      },
      {
        id: "recentCheck",
        header: "Recent check",
        accessorKey: "recentCheck",
        cell: (cellInfo) => {
          const flag = cellInfo.row.original;
          if (!flag.lastCheckedAt) return <></>;

          return (
            <div className="leading-none text-gray-400">
              {moment(flag.lastCheckedAt).fromNow()}
            </div>
          );
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const flag = cellInfo.row.original;
          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Delete",
                    disabled: !flagEditAllowed,
                    onClick: () => {
                      setSuccessAlert("");
                      setDeleteFlag(flag);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [flagEditAllowed]);

  const getHeaderText = (count: number) => {
    return pluralize("Flag", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination(
    ["flags"],
    api.listFeatureFlags,
    api.countFeatureFlags,
    filter,
    getHeaderText,
  );

  const secondaryHeaderText = useSecondaryTableHeader(
    "features",
    featuresApi.countFeatures,
  );

  const detailsLink = (row: FeatureFlag) => {
    return `flags/${row.id}`;
  };

  const onDelete = useCallback(() => {
    queryClient.invalidateQueries();

    setSuccessAlert("Flag deleted successfully");
    setDeleteFlag(undefined);
  }, [queryClient]);

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const headerButtons: ButtonProps[] = [
    {
      children: <>Create</>,
      color: "blue",
      disabled:
        !useSchematicFlag("flags", { fallback: true }) || !flagEditAllowed,
      onClick: onCreate,
    },
  ];

  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const noFeatureFlagsCreated =
    countQuery?.data?.count === 0 && searchTerm === "";

  const renderFeatureFlagsTable = () => {
    switch (true) {
      case noFeatureFlagsCreated:
        return (
          <FeatureFlagsBlankState
            onCreate={onCreate}
            disabled={!flagEditAllowed}
          />
        );
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeatureFlagsTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              detailsLink={detailsLink}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  return (
    <div className="pb-16">
      {successAlert && (
        <div className="mb-4">
          <Alert style="green" size="xs" className="flex">
            {successAlert}
          </Alert>
        </div>
      )}

      {!noFeatureFlagsCreated && (
        <TableHeader
          buttons={headerButtons}
          headerTabs={[
            {
              label: secondaryHeaderText,
              url: `/${environmentId}/features`,
            },
            {
              label: headerText,
              url: `/${environmentId}/flags`,
              active: true,
            },
          ]}
          onSearch={handleSearch}
          searchPlaceholder="Search flags"
        />
      )}

      {renderFeatureFlagsTable()}

      {deleteFlag &&
        createPortal(
          <FlagDeleteOverlay
            flag={deleteFlag}
            onClose={() => setDeleteFlag(undefined)}
            onDelete={onDelete}
          />,
          document.body,
        )}
    </div>
  );
};
