import { EnvironmentResponseData } from "@models/api";

export interface Account {
  environments: AccountEnvironment[];
  name: string;
}

export type AccountEnvironment = EnvironmentResponseData & {
  environmentType: EnvironmentType;
};

export enum EnvironmentType {
  Development = "development",
  Production = "production",
  Staging = "staging",
}

export const EnvironmentTypeDisplay = {
  [EnvironmentType.Development]: "Development",
  [EnvironmentType.Production]: "Production",
  [EnvironmentType.Staging]: "Staging",
} as Record<EnvironmentType, string>;
