import cx from "classnames";

type LogoSize = "sm" | "lg" | "xs";

export interface LogoProps {
  alt?: string;
  size?: LogoSize;
  src?: string;
  className?: string;
}

const logoSizeStyles = {
  sm: "w-8 h-8 min-w-[2rem]",
  lg: "w-12 h-12 min-w-[3rem]",
  xs: "w-5 h-5 min-w-[1.25rem]",
};

export const Logo = ({ src, alt, size = "lg", className }: LogoProps) => {
  const styles = cx("rounded-full mr-2", logoSizeStyles[size], className);
  return (
    <div className="flex items-center justify-center">
      {src ? (
        <img className={styles} src={src} alt={alt} />
      ) : (
        <DummyLogo size={size} />
      )}
    </div>
  );
};

const DummyLogo = ({ alt, size = "lg", className }: LogoProps) => {
  const styles = cx("rounded-full mr-2", logoSizeStyles[size], className);

  return (
    <img
      className={styles}
      src="https://assets.schematichq.com/logo-placeholders/red.png"
      alt={alt}
    />
  );
};
