import { AnalyticsBrowser } from "@segment/analytics-next";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

interface AnalyticsProviderProps {
  children: ReactNode;
}

const writeKey = import.meta.env.VITE_SEGMENT_WRITE_KEY;

const AnalyticsContext = createContext<AnalyticsBrowser | null>(null);

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const [segmentClient, setSegmentClient] = useState<AnalyticsBrowser | null>(
    null,
  );

  useEffect(() => {
    if (writeKey && writeKey.length > 0) {
      const client = AnalyticsBrowser.load({ writeKey });
      setSegmentClient(client);
    }
  }, []);

  return (
    <AnalyticsContext.Provider value={segmentClient}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};
