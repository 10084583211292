import { get } from "@data/index";
import {
  EntityKeyDefinitionResponseData,
  ListEntityKeyDefinitionsParams,
} from "@models/api";
import { Count } from "@models/count";

export const countEntityKeyDefinitions = async (
  flt?: ListEntityKeyDefinitionsParams,
): Promise<Count> => {
  return get<Count, ListEntityKeyDefinitionsParams>(
    "entity-key-definitions/count",
    flt,
  );
};

export const listEntityKeyDefinitions = async (
  flt?: ListEntityKeyDefinitionsParams,
): Promise<EntityKeyDefinitionResponseData[]> => {
  return get<EntityKeyDefinitionResponseData[], ListEntityKeyDefinitionsParams>(
    "entity-key-definitions",
    flt,
  );
};
