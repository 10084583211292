import { UserDetailResponseData } from "@models/api";
import { Metadata } from "@ui/Metadata";
import { useOutletContext } from "react-router-dom";
import { CompanyUserFeaturesTable } from "../tables";

export const CompanyUserOverviewTab = () => {
  const { user } = useOutletContext<{ user: UserDetailResponseData }>();
  return (
    <div className="pb-12 space-y-12">
      <Metadata data={user} />

      <CompanyUserFeaturesTable user={user} />
    </div>
  );
};
