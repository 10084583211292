import { Count } from "@models/count";
import { keepPreviousData, UseQueryResult } from "@tanstack/react-query";
import { titlecase } from "@utils/strings";

import { useEffect, useState, Dispatch, SetStateAction } from "react";

import { useContextQuery } from "./useContextQuery";

export interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

interface FilterOptions {
  limit?: number;
  offset?: number;
}

type CountFn<F extends FilterOptions> = (options: F) => Promise<Count>;

type HeaderTextFn = (count: number) => string;

type ListFn<T, F extends FilterOptions> = (options: F) => Promise<T[]>;

interface TablePaginationResult<T, F> {
  headerText: string;
  pageIndex: number;
  pageSize: number;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pageCount: number;
  listQuery: UseQueryResult<T[], F>;
  listQueryKey: string[];
  countQuery: UseQueryResult<Count, F>;
  countQueryKey: string[];
}

const useTablePagination = <T, F extends FilterOptions>(
  queryKey: string[],
  listFn: ListFn<T, F>,
  countFn: CountFn<F>,
  filter?: F,
  headerTextFn?: HeaderTextFn,
  initialPageSize = 10,
): TablePaginationResult<T, F> => {
  const [headerText, setHeaderText] = useState<string>(titlecase(queryKey[0]));

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });

  const [pageCount, setPageCount] = useState<number>(-1);

  // Reset page index when filter changes
  useEffect(() => {
    setPagination((state) => ({ ...state, pageIndex: 0 }));
  }, [filter]);

  const fetchDataOptions = {
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    ...filter,
  } as F;

  const optionsKeys = Object.entries(fetchDataOptions).map(
    ([key, value]) => `${key}:${value}`,
  );

  const listQueryKey = [...queryKey, ...optionsKeys];
  const listQuery = useContextQuery<T[]>({
    queryKey: listQueryKey,
    queryFn: () => listFn(fetchDataOptions),
    placeholderData: keepPreviousData,
  });

  const countQueryKey = [...queryKey, "count", ...optionsKeys];
  const countQuery = useContextQuery<Count>({
    queryKey: countQueryKey,
    queryFn: () => countFn(fetchDataOptions),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (!countQuery.data) return;

    headerTextFn && setHeaderText(headerTextFn(countQuery.data.count));
    setPageCount(Math.ceil(countQuery.data.count / pagination.pageSize));
  }, [countQuery.data, headerTextFn, pagination.pageSize]);

  return {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    setPagination,
    listQueryKey,
    countQueryKey,
  } as TablePaginationResult<T, F>;
};

export default useTablePagination;
