import { EntityTraitDetailResponseData } from "@models/api";

export function getTraitValue(
  traits: EntityTraitDetailResponseData[] | undefined,
  definitionId: string,
): string | undefined {
  if (!traits) {
    return undefined;
  }

  return traits
    .find((trait) => trait.definitionId === definitionId)
    ?.value.toString();
}

export function getNumericTraitValue(
  traits: EntityTraitDetailResponseData[] | undefined,
  definitionId: string,
): number {
  try {
    const traitValue = getTraitValue(traits, definitionId);

    return traitValue ? parseInt(traitValue, 10) : 0;
  } catch (e) {
    console.error(e);
  }

  return 0;
}
