import { useLocalStorage } from "@hooks/useLocalStorage";
import { Button } from "@ui/Button";
import { SettingsBox } from "@ui/SettingsBox";

export const MiscPage = () => {
  const [, setHideAllCompanyToPlan] = useLocalStorage(
    "settings.cta.hideAddCompanyToPlan",
    false,
  );

  const [, setNumberOfCtaNavigation] = useLocalStorage(
    "settings.cta.numberOfAddCompanyToPlanNavigations",
    0,
  );
  const showAllCta = () => {
    setHideAllCompanyToPlan(false);
    setNumberOfCtaNavigation(0);
  };

  return (
    <SettingsBox title="Misc">
      <Button
        size="sm"
        color="blue"
        className="focus:bg-green-500"
        onClick={showAllCta}
      >
        Show all CTA
      </Button>
    </SettingsBox>
  );
};
