import { useContextQuery } from "@hooks/useContextQuery";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { useLocalStorage } from "@hooks/useLocalStorage";
import {
  CompanyResponseData,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  ListUsersParams,
} from "@models/api";
import {
  ConditionMetricPeriodUsageDisplay,
  FeatureType,
} from "@models/feature";
import { FeatureUsage } from "@models/usage";
import * as users from "@modules/companyUsers/queries";
import { EntitlementUsageCell } from "@modules/features";
import { listFeatureUsage } from "@modules/features/queries/usage";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarBlankEntitlements } from "./SidebarBlankEntitlements";
import { SidebarBlock } from "./SidebarBlock";
import { SidebarBlockUsers } from "./SidebarBlockUsers";
import { SidebarControl } from "./SidebarControl";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarListWidgetLoader } from "./SidebarLoader";
import { Icon } from "../Icon";
import { IconNameTypes } from "../Icon/consts";

interface SideProps {
  onEdit?: () => void;
  company: CompanyResponseData;
}

export const CompanySidebar = ({ company }: SideProps) => {
  const navigate = useNavigate();
  const { environment } = useCurrentEnvironment();
  const environmentId = environment?.id;

  const [showSideOn, setShowSideOn] = useLocalStorage("sidebar-company", true);

  const companyId = company.id;

  const [filter] = useState<ListUsersParams>({ companyId });
  const [featureUsages, setFeatureUsages] = useState<FeatureUsage[]>([]);

  const handleClick = () => {
    setShowSideOn(!showSideOn);
  };

  const queryUsers = useContextQuery({
    queryKey: ["company", companyId, "users"],
    queryFn: () =>
      users.listCompanyUsers({
        offset: 0,
        ...filter,
      }),
  });

  const queryUsage = useContextQuery({
    queryKey: ["feature-usage", companyId, undefined],
    queryFn: () => listFeatureUsage(company.id)(),
  });

  useEffect(() => {
    if (!queryUsage.isLoading) {
      setFeatureUsages(queryUsage.data ?? []);
    }
  }, [queryUsers.isLoading, queryUsage.data]);

  const renderUsersWidget = () => {
    switch (true) {
      case queryUsers.isLoading:
        return <SidebarListWidgetLoader />;
      case featureUsages.length == 0:
        return <></>;
      default:
        return (
          <SidebarBlock>
            <SidebarHeader
              title={`${queryUsers.data?.length} Users`}
              description={`Members of ${company.name}`}
              onClick={() =>
                navigate(`/${environment?.id}/companies/${companyId}/users`)
              }
            />
            <SidebarBlockUsers users={queryUsers?.data || []} />
          </SidebarBlock>
        );
    }
  };

  const renderFeaturesWidget = () => {
    switch (true) {
      case queryUsage.isLoading:
        return <SidebarListWidgetLoader />;
      case featureUsages.length === 0:
        return <></>;
      default:
        return (
          <div className="relative border-b border-gray-300">
            <div className="flex-flex-col">
              <div className="px-8 pt-8 -mb-8">
                <SidebarHeader
                  title="Usage"
                  onClick={() =>
                    navigate(`/${environment?.id}/companies/${companyId}/usage`)
                  }
                  actionLabel="See all usage"
                />
              </div>

              {featureUsages.map((usage, index) => {
                const { feature } = usage;
                if (!feature) return;

                const featureUrl = `/${environmentId}/features/${feature.id}`;

                let periodString = "";
                if (feature.featureType == FeatureType.Trait) {
                  periodString = "Current usage";
                } else if (
                  usage.period &&
                  ConditionMetricPeriodUsageDisplay[
                    usage.period as CreateOrUpdateConditionRequestBodyMetricPeriodEnum
                  ]
                ) {
                  periodString =
                    ConditionMetricPeriodUsageDisplay[
                      usage.period as CreateOrUpdateConditionRequestBodyMetricPeriodEnum
                    ];
                } else if (typeof usage.usage !== "undefined") {
                  periodString = "Last 60 days";
                }

                return (
                  <div
                    className="space-y-4 bg-purple px-8 py-8 border-b border-gray-300 last-of-type:border-b-0"
                    key={index}
                  >
                    <div
                      className="flex flex-row space-x-4 items-center group hover:cursor-pointer"
                      onClick={() => navigate(featureUrl)}
                    >
                      <div className="bg-gray-300 inline-flex w-10 h-10 text-2xl items-center justify-center rounded-full group-hover:opacity-50">
                        <Icon name={feature.icon as IconNameTypes} />
                      </div>
                      <div className="text-lg group-hover:opacity-50">
                        {feature.name}
                      </div>
                    </div>
                    <EntitlementUsageCell
                      parent="company-sidebar"
                      featureUsage={usage}
                    />
                    <div className="text-sm text-gray-400">{periodString}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
    }
  };

  const controlStyles = showSideOn
    ? "text-gray-300 !shadow-none hover:!bg-white hover:text-gray-400"
    : "text-blue-500";

  return (
    <div className="relative">
      <SidebarControl onClick={handleClick} className={controlStyles} />
      {showSideOn && (
        <div className="sidebar border-l border-gray-300 bg-white h-[calc(100vh-91px)] w-[340px] shadow-lg relative overflow-y-auto">
          {renderFeaturesWidget()}
          {renderUsersWidget()}

          {featureUsages.length == 0 && <SidebarBlankEntitlements />}
        </div>
      )}
    </div>
  );
};
