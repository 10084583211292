import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import {
  useEmbed,
  PlanManager as EmbedPlanManager,
  type EmbedContextProps,
  type PlanManagerProps,
} from "@schematichq/schematic-react";
import { Button } from "@ui/Button";
import { titlecase } from "@utils/strings";
import { useMemo } from "react";
import * as Settings from "../../controls/Sidebar/Settings";
import { Checkbox, Select } from "../../ui";

type FontStyle = keyof EmbedContextProps["settings"]["theme"]["typography"];
type ThemeSettings = EmbedContextProps["settings"]["theme"];

export const PlanManager: UserComponent = () => {
  const { rootElement } = useEditor((state) => {
    return {
      rootElement: state.nodes.ROOT.dom,
    };
  });

  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as PlanManagerProps,
  }));

  return (
    <EmbedPlanManager
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      portal={rootElement}
      {...props}
    />
  );
};

const PlanManagerSettings = () => {
  const { theme } = useEditor((state) => {
    return {
      theme: state.nodes.ROOT.data.props.settings.theme as ThemeSettings,
    };
  });

  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as PlanManagerProps,
  }));

  const { setLayout } = useEmbed();

  const typographyOptions: { value: FontStyle; label: string }[] = useMemo(
    () => [
      ...Object.keys(theme.typography).map((key) => ({
        value: key as FontStyle,
        label: titlecase(key),
      })),
    ],
    [theme.typography],
  );

  const buttonSizeOptions: { value: "sm" | "md" | "lg"; label: string }[] = [
    { value: "sm", label: "Small" },
    { value: "md", label: "Medium" },
    { value: "lg", label: "Large" },
  ];

  const buttonStyleOptions: {
    value: "primary" | "secondary" | "tertiary";
    label: string;
  }[] = [
    { value: "primary", label: "Primary" },
    { value: "secondary", label: "Secondary" },
    { value: "tertiary", label: "Tertiary" },
  ];

  return (
    <Settings.Root title="📦 Plan Manager" category="Entitlements">
      <Settings.Section>
        <Settings.Header>Related Views</Settings.Header>
        <Settings.Input
          label={
            <>
              <div>Checkout</div>
              <div className="text-[0.8125rem] text-[#939393]">
                Triggered Change plan button
              </div>
            </>
          }
        >
          <Button
            className="py-1 px-3 !text-[#194BFB] rounded-[5px] hover:!text-white !border-[#E2E5E9]"
            color="white"
            size="xs"
            onClick={() => {
              setLayout("checkout");
            }}
          >
            View
          </Button>
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.header.isVisible}
          onVibibilityChange={() => {
            setProp((props: PlanManagerProps) => {
              const updated = !props.header.isVisible;
              props.header.isVisible = updated;
            });
          }}
        >
          Plan header
        </Settings.Header>

        <Settings.Input label="Primary plan text">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.title.fontStyle as string}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.title.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PlanManagerProps) => {
                props.header.title.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show description">
          <Checkbox
            checked={nodeProps.header.description.isVisible}
            onChange={() => {
              setProp((props: PlanManagerProps) => {
                const updated = !props.header.description.isVisible;
                props.header.description.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Description text">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.description.fontStyle as string}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.description.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PlanManagerProps) => {
                props.header.description.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show price">
          <Checkbox
            checked={nodeProps.header.price.isVisible}
            onChange={() => {
              setProp((props: PlanManagerProps) => {
                const updated = !props.header.price.isVisible;
                props.header.price.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Price text">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.price.fontStyle as string}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.price.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PlanManagerProps) => {
                props.header.price.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.addOns.showLabel}
          onVibibilityChange={() => {
            setProp((props: PlanManagerProps) => {
              const updated = !props.addOns.showLabel;
              props.addOns.showLabel = updated;
            });
          }}
        >
          Add On header
        </Settings.Header>

        <Settings.Input label="Show Add On plans">
          <Checkbox
            checked={nodeProps.addOns.isVisible}
            onChange={() => {
              setProp((props: PlanManagerProps) => {
                const updated = !props.addOns.isVisible;
                props.addOns.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Secondary plan name text">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.addOns.fontStyle as string}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.addOns.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PlanManagerProps) => {
                props.addOns.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.callToAction.isVisible}
          onVibibilityChange={() => {
            setProp((props: PlanManagerProps) => {
              const updated = !props.callToAction.isVisible;
              props.callToAction.isVisible = updated;
            });
          }}
        >
          Change Plan button
        </Settings.Header>

        <Settings.Input label="Button size">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={buttonSizeOptions}
            rawValue={nodeProps.callToAction.buttonSize as string}
            value={buttonSizeOptions.find(
              (opt) => opt.value === nodeProps.callToAction.buttonSize,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PlanManagerProps) => {
                props.callToAction.buttonSize = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Button style">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={buttonStyleOptions}
            rawValue={nodeProps.callToAction.buttonStyle as string}
            value={buttonStyleOptions.find(
              (opt) => opt.value === nodeProps.callToAction.buttonStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PlanManagerProps) => {
                props.callToAction.buttonStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

PlanManager.craft = {
  displayName: "Plan Manager",
  props: {
    header: {
      isVisible: true,
      title: {
        fontStyle: "heading1",
      },
      description: {
        isVisible: true,
        fontStyle: "text",
      },
      price: {
        isVisible: true,
        fontStyle: "text",
      },
    },
    addOns: {
      isVisible: true,
      fontStyle: "heading4",
      showLabel: true,
    },
    callToAction: {
      isVisible: true,
      buttonSize: "lg",
      buttonStyle: "primary",
    },
  } satisfies PlanManagerProps,
  related: {
    settings: PlanManagerSettings,
  },
};
