import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { environmentPillColors } from "@components/overlays/types";
import { EnvironmentTypeShort } from "@consts/environments";
import { AccountEnvironment } from "@models/account";
import { Alert } from "@ui/Alert";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Overlay } from "@ui/Overlay";
import { Pill, PillStyleTypes } from "@ui/Pill";

import { ReactNode } from "react";

const EnvironmentPill = ({
  environment,
}: {
  environment: AccountEnvironment;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="text-gray-500 text-sm">{environment.name}</span>
      <Pill
        color={
          environmentPillColors[environment.environmentType] as PillStyleTypes
        }
      >
        {EnvironmentTypeShort[environment.environmentType]}
      </Pill>
    </div>
  );
};

export interface ReviewOverlayProps {
  environment?: AccountEnvironment;
  errors: string[];
  loading: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSubmit: () => void;
  children: ReactNode;
}

export const ReviewOverlay = ({
  environment,
  errors,
  loading,
  onCancel,
  onClose,
  onSubmit,
  children,
}: ReviewOverlayProps) => {
  return (
    <Overlay
      onClose={onClose}
      className="flex  items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}

      <div className="relative bg-white py-12 rounded-lg overflow-hidden shadow-lg my-6 min-w-[900px] max-w-[900px] space-y-8">
        <div className="px-12 flex justify-between">
          <div className="text-black text-2xl font-body">Save changes</div>

          {environment && <EnvironmentPill environment={environment} />}
        </div>

        <div className="px-12 space-y-8">
          <Alert size="xs" style="light-gray">
            {children}
          </Alert>
        </div>
        <div className="flex justify-end px-12">
          <ButtonGroup
            buttons={[
              {
                size: "sm",
                color: "white",
                children: <>Cancel</>,
                onClick: onCancel,
              },
              {
                size: "sm",
                color: "blue",
                children: <>Save changes</>,
                onClick: () => {
                  onSubmit();
                },
              },
            ]}
          />
        </div>
        {!!errors?.length && (
          <div className="px-12">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> Something went
                  wrong. Please try again.
                </div>
              </div>
            </Alert>
          </div>
        )}
      </div>
    </Overlay>
  );
};
