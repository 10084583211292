import { Error } from "@ui/Error";
import { ErrorMessage, Field } from "formik";

export interface TextareaProps {
  label?: string;
  name: string;
}

export const TextArea = ({ label, name, ...rest }: TextareaProps) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label className="label-md mb-2" htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        className="p-4 border border-gray-300 rounded-lg"
        as="textarea"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} component={Error} />
    </div>
  );
};
