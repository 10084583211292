import { Alert } from "@components/ui/Alert";
import { Icon } from "@components/ui/Icon";
import { getClerkWebhookData } from "@data/integrations";
import { ClerkWebhookEvents } from "@modules/integrations/consts";
import { ClerkIntegrationForm } from "@modules/settings/components/ClerkIntegrationForm";
import { useQuery } from "@tanstack/react-query";
import { ClipCopy } from "@ui/ClipCopy";
import { Pill } from "@ui/Pill";
import { SettingsBox } from "@ui/SettingsBox";

export const ClerkIntegrationPage = () => {
  const clerkWebhookQuery = useQuery({
    queryKey: ["clerkWebhookQuery"],
    queryFn: getClerkWebhookData,
  });

  return (
    <div className="flex flex-col space-y-8 max-w-full w-full">
      <div>
        <div>
          <SettingsBox
            title="Clerk Integration"
            description="Sync Organizations and Users from Clerk to Schematic "
            style="inline"
            className="h-auto relative"
          >
            <div className="bg-white absolute right-0 translate-y-[-50%] top-[50%] flex p-0.5 items-center rounded-full border border-gray-300">
              <Icon
                name="clerk"
                className="text-[#6C47FF] text-4xl leading-none"
              />
            </div>
          </SettingsBox>

          {clerkWebhookQuery.isSuccess && (
            <SettingsBox style="boxed" className="space-y-8">
              <div>
                <div className="text-base text-black font-medium mb-4">
                  Integration setup
                </div>

                <Alert size="sm" style="light-gray" className="mb-12">
                  <div className="flex flex-col space-y-8">
                    <div className="flex flex-col">
                      <div className="flex flex-row space-x-3 items-center">
                        <div className="rounded-full h-7 leading-none font-bold w-7 bg-gray-300 text-gray-400  text-sm flex items-center justify-center">
                          1
                        </div>
                        <div>
                          Create a webhook in your Clerk account with the
                          following URL:
                        </div>
                      </div>
                      <div className="mt-3 ml-10">
                        <ClipCopy
                          data={clerkWebhookQuery.data.url}
                          className="bg-gray-300/80 py-2  px-3.5 rounded-lg inline-flex w-auto text-sm"
                        >
                          {clerkWebhookQuery.data.url}
                        </ClipCopy>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div className="flex flex-row space-x-3 items-center">
                        <div className="rounded-full h-7 leading-none font-bold w-7 bg-gray-300 text-gray-400  text-sm flex items-center justify-center">
                          2
                        </div>
                        <div>Subscribe to the following events:</div>
                      </div>
                      <div className="inline-block  flex-1 mt-3 ml-10  max-w-full">
                        <span className="grid grid-cols-3 gap-2 ">
                          {ClerkWebhookEvents.map((event) => (
                            <div className="inline-block">
                              <Pill className="w-full">{event}</Pill>
                            </div>
                          ))}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col">
                      <div className="flex flex-row space-x-3 items-center">
                        <div className="rounded-full h-7 leading-none font-bold w-7 bg-gray-300 text-gray-400  text-sm flex items-center justify-center">
                          3
                        </div>
                        <div>
                          {" "}
                          Once you have created the webhook, you will be
                          provided with a signing key. Provide this and an API
                          key to finish setup of the integration.
                        </div>
                      </div>
                    </div>
                  </div>
                </Alert>

                <ClerkIntegrationForm />
              </div>
            </SettingsBox>
          )}
        </div>
      </div>
    </div>
  );
};
