import { SignedIn, UserButton } from "@clerk/clerk-react";
import * as api from "@data/accounts";
import { AccountEnvironment } from "@models/account";
import { useMenuLinks } from "@routes/menu-links";
import { useQuery } from "@tanstack/react-query";
import { DropdownEnvironment } from "@ui/DropdownEnvironment";
import cx from "classnames";
import React, { useCallback, useEffect, useState, ReactNode } from "react";
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Icon } from "../Icon";
import { Menu } from "../Menu";

interface NavigationProps extends React.HTMLProps<HTMLDivElement> {
  children?: ReactNode;
}

export const Navigation: React.FC<NavigationProps> = ({ ...rest }) => {
  const navigate = useNavigate();
  const links = useMenuLinks();
  const location = useLocation();
  const { environmentId } = useParams() as { environmentId: string };

  const [selectedEnvironment, setSelectedEnvironment] = useState<
    AccountEnvironment | undefined
  >();

  const url = location.pathname;
  const isSettings = url.includes("/settings/") || url.endsWith("/settings");

  const { data, error, isLoading } = useQuery({
    queryKey: ["accounts", "me"],
    queryFn: () => api.me(),
  });

  const handleEnvironmentChange = useCallback(
    (environment: AccountEnvironment) => {
      setSelectedEnvironment(environment);

      const pathWithoutEnv = location.pathname.split("/").slice(2).join("/");

      navigate(
        generatePath(`/:environmentId/${pathWithoutEnv}`, {
          environmentId: environment.id,
        }),
      );
    },
    [location.pathname, navigate],
  );

  useEffect(() => {
    if (!data || !environmentId) {
      return;
    }

    if (selectedEnvironment && selectedEnvironment.id === environmentId) {
      return;
    }

    const environment = data.environments.find(
      (env) => env.id === environmentId,
    );

    if (environment) {
      setSelectedEnvironment(environment);
    }
  }, [environmentId, handleEnvironmentChange, data, selectedEnvironment]);

  if (isLoading || !data) return null;

  if (error) throw error;

  return (
    <div
      className={cx(
        "flex flex-col z-[52] fixed left-0 top-0 w-full ",
        isSettings
          ? "bg-transparent"
          : "border-b border-b-[#eeeeee] bg-white shadow-sm",
      )}
      {...rest}
    >
      <div className="lg:max-w-[94%] mx-auto w-full px-6">
        <div className="flex flex-row space-x-4 items-center">
          <div className="">
            <div className="flex items-center">
              <Link to="/" className="hidden md:block">
                <div className="border border-transparent border-r-gray-200 pr-6 mr-6 cursor-pointer">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5914 19.3141L16.9934 16.8197L10.852 10.4421C9.33118 8.86284 9.33118 6.28951 10.852 4.71021C12.3728 3.13091 14.8508 3.13091 16.3716 4.71021L22.513 11.0878L24.915 8.59343L18.7736 2.21585C15.9288 -0.738371 11.2948 -0.738371 8.45001 2.21585C5.60521 5.17006 5.60521 9.98228 8.45001 12.9365L14.5914 19.3141Z"
                      fill="#DB6669"
                    />
                    <path
                      d="M14.8201 6.33603C14.1581 5.64857 13.0801 5.64857 12.4181 6.33603C11.7561 7.02349 11.7561 8.14293 12.4181 8.83039L20.1116 16.8198L14.592 22.5517L6.89848 14.5623C6.23648 13.8749 5.1585 13.8749 4.4965 14.5623C3.8345 15.2498 3.8345 16.3692 4.4965 17.0567L14.592 27.5404L24.9156 16.8198L14.8201 6.33603Z"
                      fill="#DB6669"
                    />
                  </svg>
                </div>
              </Link>

              {selectedEnvironment && (
                <DropdownEnvironment
                  account={data}
                  handleEnvironmentChange={handleEnvironmentChange}
                  selectedEnvironment={selectedEnvironment}
                  enviornmentScoped={isSettings}
                />
              )}
            </div>
          </div>

          <div className="flex-1">
            <Menu items={links} />
          </div>

          <div className="flex items-center space-x-4 xl:min-w-[223px] justify-end">
            <Link
              to="settings"
              className={cx(
                "relative text-2xl opacity-70 hover:opacity-100 transition-all leading-none",
                isSettings ? "text-blue-400" : "text-gray-400 ",
              )}
              title="Settings"
            >
              <Icon name="cog" />
            </Link>

            <SignedIn>
              <UserButton afterSignOutUrl={window.location.href} />
            </SignedIn>
          </div>
        </div>
      </div>
    </div>
  );
};
