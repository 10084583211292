import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { environmentPillColors } from "@components/overlays/types";
import { LabelData } from "@components/ui/LabelData";
import { EnvironmentTypeShort } from "@consts/environments";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { AccountEnvironment } from "@models/account";
import { CreateApiKeyRequestBody } from "@models/api";
import { ApiKey } from "@models/apiKey";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { ClipCopy } from "@ui/ClipCopy";
import { FormColumn } from "@ui/FormParts";
import { Overlay, OverlayHeader, OverlayModal } from "@ui/Overlay";
import { Pill, PillStyleTypes } from "@ui/Pill";
import cx from "classnames";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import * as api from "../../queries/apiKeys";

export interface ApiKeyOverlayProps {
  environments: AccountEnvironment[];
  onClose: () => void;
  apiKey?: ApiKey;
}

export const ApiKeyOverlay = ({
  environments,
  onClose,
  apiKey,
}: ApiKeyOverlayProps) => {
  const [loading, setLoading] = useState(true);
  const [createdApiKey, setCreatedApiKey] = useState<ApiKey | null>(null);
  const { environment } = useCurrentEnvironment();

  useEffect(() => {
    !!environment && setLoading(false);
  }, [environment]);

  const [apiError, setApiError] = useState<string | undefined>();
  const queryClient = useQueryClient();
  const initialValues = {
    description: apiKey?.description || "",
    environmentId: apiKey?.environmentId || environment?.id,
    name: apiKey?.name || "",
  };

  const onSubmit = async (values: CreateApiKeyRequestBody) => {
    setLoading(true);

    try {
      const saveFn = apiKey
        ? (values: CreateApiKeyRequestBody) =>
            api.updateApiKey(apiKey.id, values)
        : api.createApiKey;

      const resp = await saveFn(values);

      await queryClient.invalidateQueries();

      setApiError(undefined);
      setLoading(false);

      // Show secret if creating a new key
      if (apiKey) {
        onClose();
      } else {
        setCreatedApiKey(resp);
      }
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      setLoading(false);
    }
  };

  const verb = apiKey?.id ? "Edit" : "Create";

  return (
    <Overlay
      onClose={onClose}
      className="flex  items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader className="!z-[500] fixed" />}
      <OverlayModal size="lg">
        <OverlayHeader title={`${verb} API Key`} onClose={onClose} />
        <div
          className={cx(
            "flex-1 px-12 pb-12 h-full max-h-[700px] max-w-full overflow-hidden overflow-y-scroll relative",
            createdApiKey?.secret && "pt-0",
          )}
        >
          {!createdApiKey && (
            <Formik
              className="flex flex-col"
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={Yup.object({
                description: Yup.string(),
                environmentId: Yup.string().required(
                  "Must select an environment",
                ),
                name: Yup.string().required("Must provide a name"),
              })}
            >
              {(form) => {
                return (
                  <Form>
                    <FormColumn>
                      <FormikControl
                        control="input"
                        label="Name"
                        name="name"
                        placeholder="Enter name"
                        type="text"
                      />

                      <FormikControl
                        control="input"
                        label="Description"
                        name="description"
                        placeholder="Enter description"
                        type="text"
                      />

                      {!apiKey && (
                        <FormikSelect
                          label="Environment"
                          name="environmentId"
                          options={environments.map((environment) => {
                            return {
                              value: environment.id,
                              label: (
                                <div className="space-x-2 flex">
                                  <span className="inline-block">
                                    {environment.name}
                                  </span>
                                  <div>
                                    <Pill
                                      color={
                                        environmentPillColors[
                                          environment.environmentType
                                        ] as PillStyleTypes
                                      }
                                    >
                                      {
                                        EnvironmentTypeShort[
                                          environment.environmentType
                                        ]
                                      }
                                    </Pill>
                                  </div>
                                </div>
                              ),
                            };
                          })}
                        />
                      )}

                      {apiError && (
                        <Alert size="xs" style="red">
                          <span className="font-semibold">Uh-oh!</span>{" "}
                          {apiError}
                        </Alert>
                      )}
                    </FormColumn>
                    <div
                      className={cx(
                        "flex items-end w-full flex-1 space-x-2 mt-12 justify-end",
                      )}
                    >
                      <Button type="submit" color="blue" disabled={!form.dirty}>
                        Save changes
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}

          {createdApiKey?.secret && (
            <div className="flex flex-col space-y-8">
              <div className="space-y-4">
                <div className="text-2xl">API key successfully created!</div>
                <div className="flex items-center space-x-2">
                  Copy your API publishable key and secret below; you won't be
                  able to access the secret again.
                </div>
              </div>

              <div className="text-lg flex flex-col space-y-6 p-8 bg-gray-100 rounded-lg">
                <LabelData variant="row" label="Publishable Key">
                  <ClipCopy data={createdApiKey.id} className="text-lg" />
                </LabelData>
                <LabelData variant="row" label="Secret">
                  <ClipCopy
                    data={createdApiKey.secret}
                    className="text-lg"
                    truncate
                  />
                </LabelData>
              </div>
            </div>
          )}
        </div>
      </OverlayModal>
    </Overlay>
  );
};
