import React, { ReactNode } from "react";

interface SidebarBlockProps {
  children: ReactNode;
}

export const SidebarBlock = ({ children }: SidebarBlockProps) => {
  return (
    <div className="relative px-8 py-8 border-b border-gray-300">
      {children}
    </div>
  );
};
