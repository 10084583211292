import * as events from "@data/events";

import { useContextQuery } from "@hooks/useContextQuery";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { Feature } from "@models/feature";
import {
  countFeatureCompanies,
  countFeatureUsers,
  listFeatureCompanies,
  listFeatureUsers,
} from "@modules/features/queries/usage";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";
import { SidebarBlankData } from "./SidebarBlankData";
import { SidebarBlankEntitlements } from "./SidebarBlankEntitlements";
import { SidebarBlock } from "./SidebarBlock";
import { SidebarBlockCompanies } from "./SidebarBlockCompanies";
import { SidebarBlockUsers } from "./SidebarBlockUsers";
import { SidebarControl } from "./SidebarControl";
import { SidebarDataBlock } from "./SidebarDataBlock";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarListWidgetLoader, SidebarMetricsLoader } from "./SidebarLoader";

interface SideProps {
  feature: Feature;
  onEdit: () => void;
}

const MetricsWidget = ({ feature }: { feature: Feature }) => {
  const navigate = useNavigate();
  const { environment } = useCurrentEnvironment();

  const featureId = feature.id;
  const queryEvent = useContextQuery({
    queryKey: ["features", featureId, "event-metrics"],
    queryFn: async () => {
      try {
        return await events.getEventType(feature.eventSubtype || "");
      } catch (error: any) {
        if (error.responseCode === 404) {
          return false;
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error.responseCode === 404) {
        return false;
      }

      return failureCount < 3;
    },
  });

  if (queryEvent.isLoading) {
    return <SidebarMetricsLoader />;
  }

  return (
    <div className="relative">
      <SidebarBlock>
        <SidebarHeader
          title="Metrics"
          onClick={() =>
            navigate(`/${environment?.id}/features/${feature.id}/usage`)
          }
          actionLabel="See all usage"
        />
        <div className="space-y-6">
          <SidebarDataBlock
            value={queryEvent.data ? queryEvent.data.eventCount : 0}
            label="Total Usage"
            description={`Of ${pluralize(feature.name)} in the last 60 days`}
          />
          <SidebarDataBlock
            value={queryEvent.data ? queryEvent.data.companyCount : 0}
            label="Companies"
            description={`Used ${feature.name} in the last 60 days`}
          />

          {queryEvent.data && queryEvent.data.userCount !== 0 && (
            <SidebarDataBlock
              value={queryEvent.data.userCount}
              label="Users"
              description={`Used ${feature.name} in the last 60 days`}
            />
          )}
        </div>
      </SidebarBlock>
    </div>
  );
};

export const EmptyStateWidget = ({ feature }: { feature: Feature }) => {
  const featureId = feature.id;

  const queryCompanyCount = useContextQuery({
    queryKey: ["feature-companies-count", featureId, undefined],
    queryFn: () => countFeatureCompanies(featureId)(),
  });

  const queryUserCount = useContextQuery({
    queryKey: ["feature-users-count", featureId, undefined],
    queryFn: () => countFeatureUsers(featureId)(),
  });

  if (queryUserCount.isLoading || queryCompanyCount.isLoading) {
    return <></>;
  }

  if (queryUserCount.data?.count === 0 && queryCompanyCount.data?.count == 0) {
    return <SidebarBlankEntitlements />;
  }
};

export const CompaniesWidget = ({ feature }: { feature: Feature }) => {
  const { environment } = useCurrentEnvironment();
  const navigate = useNavigate();
  const featureId = feature.id;

  const queryCompanyList = useContextQuery({
    queryKey: ["feature-companies", featureId, undefined],
    queryFn: () => listFeatureCompanies(featureId)(),
  });

  const queryCompanyCount = useContextQuery({
    queryKey: ["feature-companies-count", featureId, undefined],
    queryFn: () => countFeatureCompanies(featureId)(),
  });

  if (queryCompanyList.isLoading || queryCompanyCount.isLoading) {
    return <SidebarListWidgetLoader />;
  }

  if (queryCompanyCount.data?.count == 0) {
    // No companies have the feature via entitlements
    return <></>;
  }

  const companyCount = queryCompanyCount.data
    ? queryCompanyCount.data.count
    : 0;
  return (
    <SidebarBlock>
      <SidebarHeader
        title={`${companyCount} ${pluralize("Company", companyCount)}`}
        description={`Companies entitled to ${feature.name}.`}
        onClick={() =>
          navigate(`/${environment?.id}/features/${feature.id}/companies`)
        }
      />
      <SidebarBlockCompanies companies={queryCompanyList.data || []} />
    </SidebarBlock>
  );
};

export const UsersWidget = ({ feature }: { feature: Feature }) => {
  const { environment } = useCurrentEnvironment();
  const navigate = useNavigate();

  const featureId = feature.id;
  const queryUserList = useContextQuery({
    queryKey: ["feature-users", featureId, undefined],
    queryFn: () => listFeatureUsers(featureId)(),
  });

  const queryUserCount = useContextQuery({
    queryKey: ["feature-users-count", featureId, undefined],
    queryFn: () => countFeatureUsers(featureId)(),
  });

  if (queryUserList.isLoading || queryUserCount.isLoading) {
    return <SidebarListWidgetLoader />;
  }

  if (queryUserCount.data?.count == 0) {
    return <></>;
  }

  const userCount = queryUserCount.data ? queryUserCount.data.count : 0;

  return (
    <SidebarBlock>
      <SidebarHeader
        title={`${userCount} ${pluralize("User", userCount)}`}
        description={`Users entitled to ${feature.name}.`}
        onClick={() =>
          navigate(`/${environment?.id}/features/${feature.id}/users`)
        }
      />
      <SidebarBlockUsers
        users={
          (queryUserList?.data || []).map((u) => u.user).filter(Boolean) as {
            id: string;
            name: string;
          }[]
        }
      />
    </SidebarBlock>
  );
};

export const FeatureSidebar = ({ feature }: SideProps) => {
  const [showSideOn, setShowSideOn] = useLocalStorage("sidebar-feature", true);

  const handleClick = () => {
    setShowSideOn(!showSideOn);
  };

  return (
    <div className="relative">
      <SidebarControl
        onClick={handleClick}
        className={showSideOn ? "text-gray-300 !shadow-none" : "text-blue-500"}
      />
      {showSideOn && (
        <div className="sidebar border-l border-gray-300 bg-white h-[calc(100vh-91px)] w-[340px] shadow-lg relative overflow-y-auto">
          {feature.eventSubtype && feature.eventSubtype !== "" && (
            <MetricsWidget feature={feature} />
          )}
          <CompaniesWidget feature={feature} />
          <UsersWidget feature={feature} />

          {!feature.trait && !feature.eventSubtype && <SidebarBlankData />}

          <EmptyStateWidget feature={feature} />
        </div>
      )}
    </div>
  );
};
