import { useResponsiveness } from "@hooks/useResponsivness";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { MenuLinks } from "@routes/menu-links";
import { ReactNode } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Icon } from "../Icon";

interface MenuProps {
  children?: ReactNode;
  items: MenuLinks[];
}

interface MenuItemProps {
  path: string;
  label?: string;
}

const MenuItem = ({ path, label }: MenuItemProps) => {
  return (
    <NavLink
      to={path}
      className="text-gray-500 cursor-pointer hover:text-black text-sm lg:text-base leading-none font-body"
    >
      {label}
    </NavLink>
  );
};

export const Menu = ({ children, items, ...props }: MenuProps) => {
  const { environmentId } = useParams() as { environmentId: string };

  const { isExtraLarge } = useResponsiveness();

  const currentItems = !isExtraLarge ? items.slice(0, 3) : items;
  const remainingItems = items.slice(3);

  return (
    <div className="" {...props}>
      <div className="flex flex-row space-x-8">
        <NavigationMenu.Root className="w-full flex items-center justify-center">
          <NavigationMenu.List className="menu-wrapper">
            {currentItems.map((i, index) => {
              return (
                <div className="flex relative group" key={index}>
                  <NavigationMenu.Item
                    className="relative"
                    aria-activedescendant={i.path}
                  >
                    <NavigationMenu.Link asChild>
                      <>
                        <NavLink
                          to={`/${environmentId}${i.path}`}
                          className="menu-link"
                          aria-activedescendant={i.label}
                        >
                          {i.label}
                          {i.extra && (
                            <Icon
                              name="chevron-down"
                              className="ml-2 rotate-0  transition-all group-hover:rotate-180 group-hover:opacity-80 inline-block"
                            />
                          )}
                        </NavLink>
                        {i.extra && (
                          <div className="z-10 bg-white absolute top-[66px] left-0 p-6 rounded-lg shadow-[0px_4px_14px_rgba(0,0,0,0.2)] min-w-[140px] space-y-4 flex flex-col opacity-0 invisible transition-all group-hover:opacity-100 group-hover:translate-y-2 group-hover:visible">
                            {(i.extra || []).map((extra) => {
                              return (
                                <MenuItem
                                  key={extra.path}
                                  path={`/${environmentId}${extra.path}`}
                                  label={extra.label}
                                />
                              );
                            })}
                          </div>
                        )}
                      </>
                    </NavigationMenu.Link>
                  </NavigationMenu.Item>
                </div>
              );
            })}

            {!isExtraLarge && (
              <>
                <div className="group relative flex items-center cursor-pointer">
                  <div className="flex items-center justify-center group-hover:bg-white rounded-full">
                    <div className="menu-link">
                      More
                      <Icon
                        name="chevron-down"
                        className="ml-2 rotate-0  transition-all group-hover:rotate-180 group-hover:opacity-80 inline-block"
                      />
                    </div>
                  </div>
                  <div className="z-10 bg-white absolute top-[66px] left-0 p-6 rounded-lg shadow-[0px_4px_14px_rgba(0,0,0,0.2)] min-w-[140px] space-y-4 flex flex-col opacity-0 invisible transition-all group-hover:opacity-100 group-hover:translate-y-2 group-hover:visible">
                    {remainingItems.map((i) => {
                      return (
                        <MenuItem
                          path={`/${environmentId}${i.path}`}
                          label={i.label}
                          key={i.path}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </NavigationMenu.List>

          <div className="ViewportPosition">
            <NavigationMenu.Viewport className="NavigationMenuViewport" />
          </div>
        </NavigationMenu.Root>
      </div>
      {children}
    </div>
  );
};
