import { Icon } from "@components/ui/Icon";
import { useNode, type UserComponent } from "@craftjs/core";
import {
  MeteredFeatures as EmbedMeteredFeatures,
  type MeteredFeaturesProps,
} from "@schematichq/schematic-react";
import { VisibilityButton } from "../../ui";

export const MeteredFeatures: UserComponent = () => {
  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as MeteredFeaturesProps,
  }));

  return (
    <EmbedMeteredFeatures
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      {...props}
    />
  );
};

const MeteredFeaturesSettings = () => {
  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as MeteredFeaturesProps,
  }));

  return (
    <div className="flex flex-col space-y-6 font-body">
      <div className="space-y-1">
        <div className="text-lg">🎛️ Metered Features</div>
        <div className="text-[0.8125rem] text-[#5D5D5D]">Entitlements</div>
      </div>

      <div className="flex flex-col space-y-6">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            <Icon
              name="chevron-down"
              className="leading-none text-xl text-[#D0D0D0]"
            />
            <span className="text-base leading-none">Feature Meter</span>
          </div>
          <VisibilityButton
            isVisible={nodeProps.isVisible}
            onClick={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.isVisible;
                props.isVisible = updated;
              });
            }}
          />
        </div>

        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            <Icon
              name="chevron-down"
              className="leading-none text-xl text-[#D0D0D0]"
            />
            <span className="text-base leading-none">Upgrade button</span>
          </div>
          <VisibilityButton
            isVisible={nodeProps.callToAction.isVisible}
            onClick={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.callToAction.isVisible;
                props.callToAction.isVisible = updated;
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

MeteredFeatures.craft = {
  displayName: "Metered Features",
  props: {
    isVisible: true,
    header: {
      fontStyle: "heading2",
    },
    description: {
      isVisible: true,
      fontStyle: "text",
    },
    icon: {
      isVisible: true,
    },
    allocation: {
      isVisible: true,
      fontStyle: "heading4",
    },
    usage: {
      isVisible: true,
      fontStyle: "heading5",
    },
    callToAction: {
      isVisible: true,
      buttonSize: "md",
      buttonStyle: "secondary",
    },
  } satisfies MeteredFeaturesProps,
  related: {
    settings: MeteredFeaturesSettings,
  },
};
