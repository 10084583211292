import { ListFilter } from "./req";

export type ClerkOrganizationMembershipPublicUserData = {
  identifier: string;
  firstName: string | null;
  lastName: string | null;
  imageUrl: string;
  hasImage: boolean;
  userId: string;
};

export type ClerkOrganizationMembershipPublicUserDataFilter = ListFilter & {
  q?: string;
};

export enum ClerkUserRole {
  Admin = "org:admin",
  Member = "org:member",
}

export enum ClerkUserPermission {
  flags_edit = "flags_edit",
  flag_rules_edit = "flag_rules_edit",
  features_edit = "features_edit",
  overrides_edit = "overrides_edit",
  plan_entitlements_edit = "plan_entitlements_edit",
  plans_edit = "plans_edit",
  companies_edit = "companies_edit",
  company_users_edit = "company_users_edit",
  components_edit = "components_edit",
  webhooks_edit = "webhooks_edit",
}

export type InviteClerkUserReq = {
  email: string;
  firstName: string;
  lastName: string;
  role: ClerkUserRole;
  permissions: Record<string, ClerkUserPermission[]>;
};

export type UpdateClerkUserReq = {
  userId: string;
  firstName: string;
  lastName: string;
  role: ClerkUserRole;
  permissions: Record<string, ClerkUserPermission[]>;
};
