import {
  CreateFeatureRequestBody,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateFlagRequestBody,
  FeatureDetailResponseData,
  FlagDetailResponseData,
  UpdateFeatureRequestBody,
} from "@models/api";
import { Maintainer } from "@models/maintainer";

export type Feature = FeatureDetailResponseData & {
  featureType: FeatureType;
  icon?: FeatureIcon;
  maintainer?: Maintainer;
  flags?: FeatureFlag[];
};

export type FeatureFlag = FlagDetailResponseData & {
  feature?: Feature;
  maintainer?: Maintainer;
};

export type CreateFeatureReq = CreateFeatureRequestBody & {
  flag?: CreateOrUpdateFlagRequestBody & {
    maintainerClerkId?: string;
  };
  icon?: FeatureIcon;
  maintainerClerkId?: string;
};

export type UpdateFeatureReq = UpdateFeatureRequestBody & {
  flag?: CreateOrUpdateFlagRequestBody & {
    maintainerClerkId?: string;
  };
  icon?: FeatureIcon;
  maintainerClerkId?: string;
};

export type FeatureFlagReq = CreateOrUpdateFlagRequestBody & {
  maintainerClerkId?: string;
};

export enum FeatureIcon {
  ArrowCurved = "arrow-curved",
  ArrowDown = "arrow-down",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  ArrowUp = "arrow-up",
  Bell = "bell",
  Check = "check",
  CheckRounded = "check-rounded",
  ChevronDown = "chevron-down",
  ChevronLeft = "chevron-left",
  ChevronRight = "chevron-right",
  ChevronUp = "chevron-up",
  Close = "close",
  Code = "code",
  Cog = "cog",
  Collapse = "collapse",
  CollapseEmpty = "collapse-empty",
  Copy = "copy",
  DollarRounded = "dollar-rounded",
  DotsHorizontal = "dots-horizontal",
  DotsVertical = "dots-vertical",
  Eye = "eye",
  Filter = "filter",
  Image = "image",
  InfoRounded = "info-rounded",
  Package = "package",
  ServerSearch = "server-search",
}

export enum FeatureType {
  Boolean = "boolean",
  Event = "event",
  Trait = "trait",
}

export const ConditionMetricPeriods = [
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Day,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Month,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Week,
];

export const ConditionMetricPeriodDisplay = {
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Day]: "Daily",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Month]: "Monthly",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Week]: "Weekly",
};

export const ConditionMetricPeriodUsageDisplay = {
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Day]: "Today",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Month]: "This month",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Week]: "This week",
};

export enum FeatureLifecyclePhase {
  AddOn = "add_on",
  Alpha = "alpha",
  Beta = "beta",
  Deprecated = "deprecated",
  GA = "ga",
  InPlan = "in_plan",
  Inactive = "inactive",
  InternalTesting = "internal_testing",
  Legacy = "legacy",
}

export const FeatureLifecyclePhaseDisplay = {
  [FeatureLifecyclePhase.Inactive]: "Inactive",
  [FeatureLifecyclePhase.InternalTesting]: "Internal Testing",
  [FeatureLifecyclePhase.Alpha]: "Alpha",
  [FeatureLifecyclePhase.Beta]: "Beta",
  [FeatureLifecyclePhase.GA]: "Generally Available",
  [FeatureLifecyclePhase.InPlan]: "In Plan",
  [FeatureLifecyclePhase.AddOn]: "Add On",
  [FeatureLifecyclePhase.Legacy]: "Legacy",
  [FeatureLifecyclePhase.Deprecated]: "Deprecated",
} as Record<FeatureLifecyclePhase, string>;

export const FeatureLifecyclePhaseDescription = {
  [FeatureLifecyclePhase.Alpha]:
    "Available to small set of companies and users",
  [FeatureLifecyclePhase.AddOn]: "Available to most as an add-on",
  [FeatureLifecyclePhase.Beta]: "Available to minority of companies and users",
  [FeatureLifecyclePhase.Deprecated]: "No longer available",
  [FeatureLifecyclePhase.GA]: "Available to most but not part of plan",
  [FeatureLifecyclePhase.InPlan]: "Available to most and part of a plan",
  [FeatureLifecyclePhase.Inactive]: "Unreleased and unavailable",
  [FeatureLifecyclePhase.InternalTesting]: "Available to only internal users",
  [FeatureLifecyclePhase.Legacy]: "Available to only some current customers",
} as Record<FeatureLifecyclePhase, string>;

export const ConditionTypesEntity = [
  CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum.User,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum.Plan,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum.BillingProduct,
];

export enum FlagType {
  Boolean = "boolean",
}
