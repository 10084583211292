import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";

export const ConvertToFeatureCta = ({
  onConvert,
}: {
  onConvert: () => void;
}) => {
  return (
    <Alert className="mb-6" size="md" style="gray" background="schematic">
      <div className="relative z-10 flex justify-between items-center font-body">
        <div>
          <div className="text-3xl mb-4">Go beyond rollout</div>
          <div className="text-lg font-body">
            Associate a flag with a feature to enable plan entitlements, company
            <br />
            overrides, tracking usage and ARR attribution.
          </div>
        </div>
        <div>
          <Button onClick={onConvert} color="white">
            Convert to feature
          </Button>
        </div>
      </div>
    </Alert>
  );
};
