import { deleteReq, get, post, put } from "@data/index";
import {
  CreateCompanyOverrideRequestBody,
  ListCompanyOverridesParams,
  UpdateCompanyOverrideRequestBody,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";
import { CompanyOverride } from "@models/entitlement";
import { ListFilter } from "@models/req";

export const countCompanyOverrides = async (
  filter?: ListCompanyOverridesParams,
): Promise<Count> => {
  return get<Count, ListCompanyOverridesParams>(
    "company-overrides/count",
    filter,
  );
};

export const listCompanyOverrides = async (
  filter?: ListCompanyOverridesParams & ListFilter,
): Promise<CompanyOverride[]> => {
  return get<CompanyOverride[], ListCompanyOverridesParams>(
    "company-overrides",
    filter,
  );
};

export const createCompanyOverride = async (
  req: CreateCompanyOverrideRequestBody,
): Promise<CompanyOverride> => {
  return post<CompanyOverride, CreateCompanyOverrideRequestBody>(
    "company-overrides",
    req,
  );
};

export const updateCompanyOverride = async (
  id: string,
  req: UpdateCompanyOverrideRequestBody,
): Promise<CompanyOverride> => {
  return put<CompanyOverride, UpdateCompanyOverrideRequestBody>(
    `company-overrides/${id}`,
    req,
  );
};

export const deleteCompanyOverride = async (id: string): Promise<Delete> => {
  return deleteReq(`company-overrides/${id}`);
};
