import { useUser, ClerkProvider } from "@clerk/clerk-react";
import { AnalyticsProvider, useAnalytics } from "@contexts/AnalyticsContext";
import { EnvironmentContext } from "@contexts/EnvironmentContext";
import { SchematicProvider } from "@schematichq/schematic-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { setEnvironmentId as setFetchEnvironmentId } from "./data";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        if (error.responseCode === 404) {
          return false;
        }

        return failureCount < 2;
      },
    },
  },
});

const OutletWrapper = () => {
  const { isLoaded, isSignedIn, user } = useUser();
  const segmentClient = useAnalytics();

  useEffect(() => {
    if (segmentClient && isLoaded && isSignedIn && user) {
      const clerkOrg = user.organizationMemberships[0]?.organization;
      const koalaProfileId = Cookies.get("ko_id");

      segmentClient.identify(user.id, {
        email: user.emailAddresses[0]?.emailAddress,
        profile_id: koalaProfileId, // https://getkoala.com/docs/developer-guides/sending-identify#can-i-do-this-via-segment
        userId: user.id,
        properties: {
          accountName: clerkOrg?.name,
          clerkOrganizationId: clerkOrg?.id,
        },
      });

      if (clerkOrg) {
        segmentClient.group(clerkOrg.id, { name: clerkOrg.name });
      }
    }
  }, [user, segmentClient, isLoaded, isSignedIn]);

  return <Outlet />;
};

function App() {
  const navigate = useNavigate();
  const clerkPubKey = import.meta.env.VITE_REACT_APP_CLERK_PUBLISHABLE_KEY;
  const schematicPubKey = import.meta.env.VITE_SCHEMATIC_PUBLISHABLE_KEY;

  const [environmentId, setEnvironmentId] = useState<string | undefined>(
    undefined,
  );
  const environment = useMemo(
    () => ({ environmentId, setEnvironmentId }),
    [environmentId],
  );

  useEffect(() => {
    if (environmentId) {
      setFetchEnvironmentId(environmentId);
    }
  }, [environmentId]);

  if (!clerkPubKey) {
    return (
      <div>
        Must set Clerk publishable key (VITE_REACT_APP_CLERK_PUBLISHABLE_KEY)!
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <EnvironmentContext.Provider value={environment}>
        <SchematicProvider publishableKey={schematicPubKey}>
          <ClerkProvider
            publishableKey={clerkPubKey}
            navigate={(to) => navigate(to)}
          >
            <AnalyticsProvider>
              <OutletWrapper />
            </AnalyticsProvider>
          </ClerkProvider>
        </SchematicProvider>
      </EnvironmentContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
