import { useNode, type UserComponent } from "@craftjs/core";
import {
  Root as EmbedRoot,
  defaultSettings,
  type EmbedContextProps,
} from "@schematichq/schematic-react";

interface RootProps
  extends Pick<Partial<EmbedContextProps>, "data" | "settings" | "layout"> {
  children?: React.ReactNode;
}

export const Root: UserComponent<RootProps> = ({ children }) => {
  const {
    connectors: { connect },
  } = useNode();

  return (
    <EmbedRoot
      ref={(ref) => connect(ref!)}
      className="relative flex overflow-auto h-full px-8 bg-[#FBFBFB] bg-[0px_0px] bg-[url('/builder/mesh.png')]"
    >
      {children}
    </EmbedRoot>
  );
};

Root.craft = {
  props: {
    data: {} as EmbedContextProps["data"],
    settings: defaultSettings as EmbedContextProps["settings"],
  },
};
