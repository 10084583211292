import useTablePagination from "@hooks/useTablePagination";
import { CompanyDetailResponseData, UserDetailResponseData } from "@models/api";
import {
  AudienceRequestBody,
  RuleConditionDetailResponseData,
  RuleConditionGroupDetailResponseData,
} from "@models/api";
import { UsersPreviewTable } from "@modules/plans/components/tables/UsersPreviewTable";
import * as PrimitiveTab from "@radix-ui/react-tabs";
import React, { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { CompaniesPreviewTable } from "./tables/CompaniesPreviewTable";
import { conditionsPreviewReqMapper } from "../helpers";
import {
  previewCompanies,
  previewCompaniesCount,
  previewUsers,
  previewUsersCount,
} from "../queries";

type PlanAudiencePreviewProps = {
  dirty?: boolean;
  planId: string;
  conditions: RuleConditionDetailResponseData[];
  conditionGroups: RuleConditionGroupDetailResponseData[];
};

export const PlanAudiencePreview = ({
  planId,
  conditions,
  conditionGroups,
}: PlanAudiencePreviewProps) => {
  const [searchPlaceholder, setSearchPlaceholder] = useState<
    "company" | "user"
  >("company");
  const [searchTerm, setSearchTerm] = useState("");
  // We stringify conditions to make sure that the query key changes when the conditions change
  const [filter, setFilter] = useState<
    Pick<AudienceRequestBody, "limit" | "offset" | "q"> & {
      conditions: string;
      conditionGroups: string;
    }
  >({
    conditions: JSON.stringify(conditions),
    conditionGroups: JSON.stringify(conditionGroups),
    q: searchTerm,
  });

  useEffect(() => {
    setFilter({
      conditions: JSON.stringify(conditions),
      conditionGroups: JSON.stringify(conditionGroups),
      ...(searchTerm ? { q: searchTerm } : {}),
    });
  }, [conditions, conditionGroups, searchTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const queryUsers = useTablePagination<
    UserDetailResponseData,
    Pick<AudienceRequestBody, "limit" | "offset" | "q"> & {
      conditions: string;
      conditionGroups: string;
    }
  >(
    ["plan", planId, "audience", "preview", "users"],
    async (filter) => {
      const { limit, offset, q } = filter;
      const req = {
        ...conditionsPreviewReqMapper(conditions, conditionGroups),
        limit,
        offset,
        q,
      };

      return previewUsers(planId, req);
    },
    async (filter) => {
      const { limit, offset, q } = filter;
      const req = {
        ...conditionsPreviewReqMapper(conditions, conditionGroups),
        limit,
        offset,
        q,
      };

      return previewUsersCount(planId, req);
    },
    filter,
  );

  const queryCompanies = useTablePagination<
    CompanyDetailResponseData,
    Pick<AudienceRequestBody, "limit" | "offset" | "q"> & {
      conditions: string;
      conditionGroups: string;
    }
  >(
    ["plan", planId, "audience", "preview", "companies"],
    async (filter) => {
      const { limit, offset, q } = filter;
      const req = {
        ...conditionsPreviewReqMapper(conditions, conditionGroups),
        limit,
        offset,
        q,
      };

      return previewCompanies(planId, req);
    },

    async (filter) => {
      const { limit, offset, q } = filter;
      const req = {
        ...conditionsPreviewReqMapper(conditions, conditionGroups),
        limit,
        offset,
        q,
      };

      return previewCompaniesCount(planId, req);
    },
    filter,
  );

  const companiesCount = queryCompanies.countQuery.data?.count;
  const usersCount = queryUsers.countQuery.data?.count;

  return (
    <div className="relative z-0">
      <div className="flex flex-col">
        <PrimitiveTab.Root
          defaultValue="companies"
          onValueChange={(value: string) => {
            setSearchPlaceholder(value === "companies" ? "company" : "user");
          }}
        >
          <div className="items-center justify-between flex">
            <PrimitiveTab.List
              className="flex space-x-6 font-body"
              aria-label={"ariaLabel"}
            >
              <PrimitiveTab.Trigger
                className="tabs-item tabs-item-lg capitalize"
                value="companies"
              >
                <div className="tabs-item-text text-2xl">
                  <div className="relative">{companiesCount} Companies</div>
                </div>
              </PrimitiveTab.Trigger>
              <PrimitiveTab.Trigger
                className="tabs-item tabs-item-lg capitalize"
                value="users"
              >
                <div className="tabs-item-text text-2xl">
                  <div className="relative">{usersCount} Users</div>
                </div>
              </PrimitiveTab.Trigger>
            </PrimitiveTab.List>

            <div id="preview-search"></div>
          </div>

          <PrimitiveTab.Content
            className="tabs-content tabs-inview"
            value="companies"
          >
            <CompaniesPreviewTable
              onSearch={handleSearch}
              searchPlaceHolder={searchPlaceholder}
              planId={planId}
              conditions={conditions}
              conditionGroups={conditionGroups}
              filter={filter}
            />
          </PrimitiveTab.Content>

          <PrimitiveTab.Content className="tabs-content" value="users">
            <UsersPreviewTable
              onSearch={handleSearch}
              searchPlaceHolder={searchPlaceholder}
              planId={planId}
              conditions={conditions}
              conditionGroups={conditionGroups}
              filter={filter}
            />
          </PrimitiveTab.Content>
        </PrimitiveTab.Root>
      </div>
    </div>
  );
};

interface PreviewSearchPortalProps {
  children: ReactNode;
}
export const PreviewSearchPortal = ({ children }: PreviewSearchPortalProps) => {
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  const element = document.getElementById("preview-search") as any;

  return domReady ? createPortal(<div>{children}</div>, element) : null;
};
