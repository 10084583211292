import { CompanyDetailResponseData } from "@models/api";
import { EventType } from "@models/events";
import { CompanyEventsTable } from "@modules/companies/components/tables/CompanyEventsTable";
import { useOutletContext } from "react-router-dom";

export const CompanyChecksTab = () => {
  const { company } = useOutletContext<{
    company: CompanyDetailResponseData;
  }>();

  return (
    <CompanyEventsTable
      companyId={company.id}
      eventType={EventType.FlagCheck}
      headerText="Flag checks"
      emptyStateTitle="📦 No flag checks detected for this company"
      emptyStateMessage="So far, no flags have been checked for this company."
    />
  );
};
