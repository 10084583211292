import { SettingsBox } from "@components/ui/SettingsBox";
import { AuditLogTable } from "../tables/AuditLogTable";

export const AuditLogPage = () => {
  return (
    <SettingsBox>
      <AuditLogTable />
    </SettingsBox>
  );
};
