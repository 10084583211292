import { SettingsBox } from "@ui/SettingsBox";
import { HubSpotConnection } from "../../../settings/components/HubSpotConnection";

export const HubSpotIntegrationPage = () => {
  return (
    <div className="flex flex-col space-y-8 max-w-full w-full">
      <div>
        <SettingsBox
          title="HubSpot Integration"
          description="Use HubSpot company data for flag targeting and plan audiences"
          style="boxed"
          className="h-auto"
        >
          <div className=" absolute right-12 top-12 flex p-1 items-center rounded-full border border-gray-300"></div>

          <HubSpotConnection />
        </SettingsBox>
      </div>
    </div>
  );
};
