import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import {
  IncludedFeatures as EmbedIncludedFeatures,
  type EmbedContextProps,
  type IncludedFeaturesProps,
} from "@schematichq/schematic-react";
import { titlecase } from "@utils/strings";
import { useMemo } from "react";
import * as Settings from "../../controls/Sidebar/Settings";
import { Select, TextInput } from "../../ui";

type FontStyle = keyof EmbedContextProps["settings"]["theme"]["typography"];
type ThemeSettings = EmbedContextProps["settings"]["theme"];

export const IncludedFeatures: UserComponent = () => {
  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as IncludedFeaturesProps,
  }));

  return (
    <EmbedIncludedFeatures
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      {...props}
    />
  );
};

const IncludedFeaturesSettings = () => {
  const { theme } = useEditor((state) => {
    return {
      theme: state.nodes.ROOT.data.props.settings.theme as ThemeSettings,
    };
  });

  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as IncludedFeaturesProps,
  }));

  const typographyOptions: { value: FontStyle; label: string }[] = useMemo(
    () => [
      ...Object.keys(theme.typography).map((key) => ({
        value: key as FontStyle,
        label: titlecase(key),
      })),
    ],
    [theme.typography],
  );

  const iconStyleOptions: { value: "light" | "dark"; label: string }[] = [
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
  ];

  return (
    <Settings.Root title="📄 Included Features" category="Entitlements">
      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.header.isVisible}
          onVibibilityChange={() => {
            setProp((props: IncludedFeaturesProps) => {
              const updated = !props.header.isVisible;
              props.header.isVisible = updated;
            });
          }}
        >
          Title
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: IncludedFeaturesProps) => {
                props.header.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Text">
          <TextInput
            className="w-44"
            textAlign="left"
            value={nodeProps.header.text}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProp((props: IncludedFeaturesProps) => {
                const updated = event.target.value;
                props.header.text = updated;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.icons.isVisible}
          onVibibilityChange={() => {
            setProp((props: IncludedFeaturesProps) => {
              const updated = !props.icons.isVisible;
              props.icons.isVisible = updated;
            });
          }}
        >
          Feature Icons
        </Settings.Header>

        <Settings.Input label="Style">
          <Select
            className="w-36"
            isMulti={false}
            options={iconStyleOptions}
            defaultValue={iconStyleOptions[0]}
            onChange={(option) => {
              setProp((props: IncludedFeaturesProps) => {
                props.icons.style = option!.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.entitlement.isVisible}
          onVibibilityChange={() => {
            setProp((props: IncludedFeaturesProps) => {
              const updated = !props.entitlement.isVisible;
              props.entitlement.isVisible = updated;
            });
          }}
        >
          Entitlement
        </Settings.Header>

        <Settings.Input label="Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.entitlement.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.entitlement.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: IncludedFeaturesProps) => {
                props.entitlement.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.usage.isVisible}
          onVibibilityChange={() => {
            setProp((props: IncludedFeaturesProps) => {
              const updated = !props.usage.isVisible;
              props.usage.isVisible = updated;
            });
          }}
        >
          Usage
        </Settings.Header>

        <Settings.Input label="Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.usage.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.usage.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: IncludedFeaturesProps) => {
                props.usage.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

IncludedFeatures.craft = {
  displayName: "Included Features",
  props: {
    header: {
      isVisible: true,
      fontStyle: "heading4",
      text: "Included features",
    },
    icons: {
      isVisible: true,
      fontStyle: "heading5",
      style: "light",
    },
    entitlement: {
      isVisible: true,
      fontStyle: "text",
    },
    usage: {
      isVisible: true,
      fontStyle: "heading6",
    },
  } satisfies IncludedFeaturesProps,
  related: {
    settings: IncludedFeaturesSettings,
  },
};
