import { Plan } from "@models/plan";
import { ConnectStripeBreakdown } from "@modules/plans/components/ConnectStripeBreakdown";
import { BillingCard } from "@modules/plans/components/tabs/BillingCard";
import { Icon } from "@ui/Icon";
import { Pill } from "@ui/Pill";
import moment from "moment";
import { useOutletContext } from "react-router-dom";

export const PlanBillingTab = () => {
  const { plan } = useOutletContext<{ plan: Plan }>();

  const lastUpdated =
    plan.billingProduct && moment().from(plan.billingProduct.updatedAt, true);

  return (
    <div className="space-y-12 pb-12 mb-12">
      {plan.billingProduct && (
        <>
          <div className="flex justify-between align-center">
            <h1 className="text-2xl font-body font-medium">Billing Products</h1>
            {lastUpdated && (
              <Pill
                color="green"
                type="rounded"
                text="normal"
                className="text-lg"
              >
                <Icon name="check" className="text-lg" />
                <span className="text-gray-900">
                  {" "}
                  Last updated {lastUpdated} ago
                </span>
              </Pill>
            )}
          </div>

          <BillingCard plan={plan} billingProduct={plan.billingProduct} />
        </>
      )}

      {!plan.billingProduct && <ConnectStripeBreakdown page="details-tab" />}
    </div>
  );
};
