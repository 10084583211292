import { useNavigateEnvironmentTo } from "@hooks/useNavigateEnvironment";
import {
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ColumnDef, VisibilityState } from "@tanstack/react-table";
import cx from "classnames";
import { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { TablePagination } from "../TablePagination";

export interface ReactTableProps<T extends object> {
  columns: ColumnDef<T>[];
  data: T[];
  onRowClick?: (row: T) => void;
  detailsLink?: (row: T) => string;
  pageCount?: number;
  pageIndex?: number;
  pageSize?: number;
  setPagination?: OnChangeFn<PaginationState>;
  columnVisibility?: VisibilityState;
  setColumnVisibility?: OnChangeFn<VisibilityState>;
  className?: string;
  style?: "simple" | "default" | "compact";
  emptyState?: ReactNode;
}

const defaultPageCount = 0;
const defaultPageSize = 10;
const defaultPageIndex = 0;
const defaultSetPagination: OnChangeFn<PaginationState> = () => {
  // Do nothing...
};

export const Table = <T extends object>({
  columns,
  data,
  onRowClick,
  detailsLink,
  pageCount = defaultPageCount,
  pageIndex = defaultPageIndex,
  pageSize = defaultPageSize,
  setPagination = defaultSetPagination,
  columnVisibility,
  setColumnVisibility,
  className,
  style = "default",
  emptyState,
}: ReactTableProps<T>) => {
  const detailsToLink = useNavigateEnvironmentTo(detailsLink);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: pageCount || -1,
    state: {
      pagination,
      columnVisibility,
    },
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  const handleRowClick = (row: T) => {
    if (detailsLink) {
      return;
    }

    if (onRowClick) {
      onRowClick(row);
    }
  };

  const tableStyles = {
    default: "table-default",
    simple: "table-simple",
    compact: "table-compact",
  };

  const paginated = pageCount > 1;

  return (
    <div
      className={cx(
        style == tableStyles.compact && "table-external",
        paginated && "table-paginated",
        className,
      )}
    >
      <div className="table-wrapper font-body border-b-0 rounded-tr-lg rounded-tl-lg">
        <table className={tableStyles[style]}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {detailsLink && (
                  <th
                    key="extra-column"
                    aria-label="Search Engine Links"
                    className="!w-0 !p-0"
                  />
                )}

                {headerGroup.headers.map((header) => {
                  const headerWidth =
                    header.getSize() !== 150 ? header.getSize() : undefined;
                  return (
                    <th
                      key={header.id}
                      style={{
                        width: headerWidth,
                        minWidth: headerWidth,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={`relative border-b ${onRowClick ? "cursor-pointer" : ""}`}
                onClick={() => handleRowClick(row.original)}
              >
                {detailsLink && (
                  <td className="!w-0 !p-0">
                    <Link
                      to={detailsToLink(row.original)}
                      aria-label={detailsToLink(row.original)}
                      className="absolute top-0 left-0 content-none w-full h-full"
                    />
                  </td>
                )}
                {row.getVisibleCells().map((cell) => (
                  <td className="" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {table.getRowModel().rows.length < 1 && (
              <tr>
                {emptyState ? (
                  <td colSpan={100}>{emptyState}</td>
                ) : (
                  <td colSpan={100} className="text-center">
                    No data found
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {paginated && <TablePagination table={table} />}
    </div>
  );
};
