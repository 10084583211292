import { useLocalStorage } from "@hooks/useLocalStorage";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { PlanEntitlement } from "@models/entitlement";
import { Plan } from "@models/plan";
import {
  PlanEntitlementsTable,
  PlanEntitlementEditOverlay,
  PlanEntitlementCreateMultipleOverlay,
} from "@modules/plans";
import { PlanSubview } from "@modules/plans/types";
import { CallToAction } from "@ui/CallToAction";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";

export const PlanEntitlementsTab = () => {
  const navigate = useNavigateEnvironment();
  const { plan } = useOutletContext<{ plan: Plan }>();
  const [addOverlay, setAddOverlay] = useState(false);
  const [editOverlay, setEditOverlay] = useState(false);
  const [planEntitlement, setPlanEntitlement] = useState<PlanEntitlement>();
  const [hideCta, setHideCta] = useLocalStorage(
    "settings.cta.hideAddCompanyToPlan",
    false,
  );
  const [numberOfCtaNavigation, setNumberOfCtaNavigation] = useLocalStorage(
    "settings.cta.numberOfAddCompanyToPlanNavigations",
    0,
  );

  const onOverlayClose = () => {
    setPlanEntitlement(undefined);
    setAddOverlay(false);
    setEditOverlay(false);
  };

  const openAddOverlay = () => {
    setAddOverlay(true);
  };

  const openEditOverlay = (planEntitlement?: PlanEntitlement) => {
    if (planEntitlement) setPlanEntitlement(planEntitlement);
    setEditOverlay(true);
  };

  const onCTAClose = () => {
    setHideCta(true);
  };

  const onCtaAction = async () => {
    const updateLocalStorage = async () => {
      if (numberOfCtaNavigation >= 1) {
        onCTAClose();
      }

      setNumberOfCtaNavigation(numberOfCtaNavigation + 1);
    };

    await updateLocalStorage();

    navigate(`plans/${plan.id}/${PlanSubview.Audience}`);
  };

  return (
    <div className="">
      {!hideCta && (
        <CallToAction
          title="Add company to plan"
          description="Create a rule targeting any company"
          actionText="Go to Audience"
          onAction={() => {
            onCtaAction();
          }}
          onClose={() => {
            onCTAClose();
          }}
        />
      )}

      <PlanEntitlementsTable
        planId={plan.id}
        openAddOverlay={openAddOverlay}
        openEditOverlay={openEditOverlay}
      />
      {editOverlay && (
        <PlanEntitlementEditOverlay
          onClose={onOverlayClose}
          plan={plan}
          planEntitlement={planEntitlement}
        />
      )}
      {addOverlay && (
        <PlanEntitlementCreateMultipleOverlay
          onClose={onOverlayClose}
          plan={plan}
        />
      )}
    </div>
  );
};
