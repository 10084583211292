import { Article } from "@consts/links";
import { Alert } from "@ui/Alert";
import { LinksList } from "@ui/LinksList";

export const CompanyUsersBlankState = () => {
  return (
    <Alert
      background="schematic"
      size="lg"
      style="gray"
      className="text-center mt-6"
    >
      <div className="flex flex-col items-start justify-between w-full flex-1 relative z-10 text-gray space-y-6">
        <div className="text-3xl">⏳ No data yet</div>
        <div className="text-lg">
          Send events into Schematic to begin tracking and targeting based on
          Companies, Users, and usage.
        </div>
        <div className="pt-6 hidden">
          <div className="label-lg">Learn how to:</div>
          <LinksList
            className="text-center flex flex-col items-center"
            links={[
              {
                label: "Emit events to Schematic",
                url: Article.EMIT_EVENTS_TO_SCHEMATIC,
              },
              {
                label: "Identify Companies and Users",
                url: Article.IDENTIFY_COMPANY_AND_USERS,
              },
            ]}
          />
        </div>
      </div>
    </Alert>
  );
};
