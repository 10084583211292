import {
  CreateOrUpdateConditionGroupRequestBody,
  CreateOrUpdateConditionRequestBody,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyOperatorEnum,
  RuleConditionDetailResponseData,
  RuleConditionGroupDetailResponseData,
} from "@models/api";

export const conditionReqForConditionResponse = (
  req: RuleConditionDetailResponseData,
): CreateOrUpdateConditionRequestBody => {
  const { conditionType, metricPeriod, operator, ...rest } = req;
  return {
    ...rest,
    conditionType:
      conditionType as CreateOrUpdateConditionRequestBodyConditionTypeEnum,
    metricPeriod:
      metricPeriod as CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
    operator: operator as CreateOrUpdateConditionRequestBodyOperatorEnum,
  };
};

export const conditionGroupReqForConditionGroupResponse = (
  req: RuleConditionGroupDetailResponseData,
): CreateOrUpdateConditionGroupRequestBody => {
  const { conditions, ...rest } = req;
  return {
    ...rest,
    conditions: conditions.map(conditionReqForConditionResponse),
  };
};
