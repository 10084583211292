import { EnvironmentContext } from "@contexts/EnvironmentContext";
import { getEnvironment } from "@data/environments";
import { AccountEnvironment } from "@models/account";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

type CurrentEnvironmentData = {
  environment: AccountEnvironment | undefined;
};

export const useCurrentEnvironment = (): CurrentEnvironmentData => {
  const { environmentId } = useContext(EnvironmentContext);

  return {
    environment: useQuery<AccountEnvironment>({
      queryKey: ["env", environmentId],
      queryFn: () => getEnvironment(environmentId as string),
    }).data,
  };
};
