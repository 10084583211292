import { ViewWrapper } from "@ui/ViewWrapper";
import React from "react";
import { EventsTable } from "./EventsTable";

export const EventsView = () => {
  return (
    <ViewWrapper>
      <EventsTable />
    </ViewWrapper>
  );
};
