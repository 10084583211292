import { ViewWrapper } from "@ui/ViewWrapper";
import { useState } from "react";
import { ComponentsTable } from "./ComponentsTable";
import { ComponentCreateOverlay } from "./overlays";

export const ComponentsView = () => {
  const [overlay, setOverlay] = useState(false);
  const onOverlayClose = () => setOverlay(false);
  const onCreate = () => {
    setOverlay(true);
  };

  return (
    <ViewWrapper useStrictMode={false}>
      <ComponentsTable onCreate={onCreate} />
      {overlay && <ComponentCreateOverlay onClose={onOverlayClose} />}
    </ViewWrapper>
  );
};
