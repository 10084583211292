import { useUser } from "@clerk/clerk-react";
import { EnvironmentContext } from "@contexts/EnvironmentContext";
import * as api from "@data/accounts";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export const useValidEnvironment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setEnvironmentId } = useContext(EnvironmentContext);
  const { environmentId: pathEnvironmentId } = useParams() as {
    environmentId: string;
  };

  // We must have a logged-in Clerk user to proceed.
  // If this Clerk user does not have an organization associated, they can't
  // use the app, so we just want to redirect them to our waitlist signup page.
  const { isLoaded, isSignedIn } = useUser();

  const { data } = useQuery({
    queryKey: ["accounts", "me"],
    queryFn: () => api.me(),
  });

  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      navigate("/sign-in");
    }
  }, [isLoaded, isSignedIn, navigate]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const validEnvironmentIds = data.environments.map(({ id }) => id);

    if (validEnvironmentIds.includes(pathEnvironmentId)) {
      setEnvironmentId(pathEnvironmentId);
    } else {
      const pathWithoutEnv = location.pathname.split("/").slice(2).join("/");

      navigate(
        generatePath(`/:environmentId/${pathWithoutEnv}`, {
          environmentId: validEnvironmentIds[0],
        }),
      );
    }
  }, [data, location.pathname, navigate, pathEnvironmentId, setEnvironmentId]);
};
