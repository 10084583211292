import { deleteReq, get, post, put } from "@data/index";
import {
  AudienceRequestBody,
  CompanyDetailResponseData,
  CreateOrUpdateRuleRequestBody,
  PlanAudienceDetailResponseData,
  UserDetailResponseData,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";

export const getAudience = async (
  planId: string,
): Promise<PlanAudienceDetailResponseData> => {
  return get<PlanAudienceDetailResponseData>(`plans/${planId}/audiences`);
};

export const previewCompanies = async (
  planId: string,
  req: AudienceRequestBody,
): Promise<Array<CompanyDetailResponseData>> => {
  return post<Array<CompanyDetailResponseData>, AudienceRequestBody>(
    `plans/${planId}/audiences/preview-companies`,
    req,
  );
};

export const previewUsers = async (
  planId: string,
  req: AudienceRequestBody,
): Promise<Array<UserDetailResponseData>> => {
  return post<Array<UserDetailResponseData>, AudienceRequestBody>(
    `plans/${planId}/audiences/preview-users`,
    req,
  );
};

export const previewCompaniesCount = async (
  planId: string,
  req: AudienceRequestBody,
): Promise<Count> => {
  return post<Count, AudienceRequestBody>(
    `plans/${planId}/audiences/preview-companies-count`,
    req,
  );
};

export const previewUsersCount = async (
  planId: string,
  req: AudienceRequestBody,
): Promise<Count> => {
  return post<Count, AudienceRequestBody>(
    `plans/${planId}/audiences/preview-users-count`,
    req,
  );
};

export const updateAudience = async (
  planId: string,
  req: CreateOrUpdateRuleRequestBody,
): Promise<PlanAudienceDetailResponseData> => {
  return put<PlanAudienceDetailResponseData, CreateOrUpdateRuleRequestBody>(
    `plans/${planId}/audiences`,
    req,
  );
};

export const deleteAudience = async (planId: string): Promise<Delete> => {
  return deleteReq(`plans/${planId}/audiences`);
};
