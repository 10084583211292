import cx from "classnames";
import pluralize from "pluralize";
import { ReactNode, useState } from "react";
import { Icon } from "../Icon";

interface CollapsableRuleProps {
  number: number;
  ruleCount: number;
  title: string;
  children: ReactNode;
}

export const CollapsableRule = ({
  number,
  title,
  children,
  ruleCount,
}: CollapsableRuleProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <div
        className="flex items-center justify-between mb-6 group"
        onClick={handleCollapse}
      >
        <div className="flex items-center space-x-4">
          <div className="hover:cursor-help group/tooltip relative z-30 border-gray-300 text-black font-medium text-sm w-6 h-6 flex items-center justify-center rounded-full border">
            {number}
            <OrderTooltip />
          </div>
          <div className="text-2xl cursor-pointer leading-none group-hover:opacity-90 group-hover:text-underline">
            {title}
          </div>
        </div>
        <div className="text-blue-400 flex items-center space-x-1 cursor-pointer hover:opacity-80">
          <Icon
            name="chevron-up"
            className={cx("text-2xl transition-all", collapsed && "rotate-180")}
          />
          <span className="text-sm font-medium">
            {collapsed ? "Expand" : "Collapse"}{" "}
          </span>
        </div>
      </div>
      {collapsed ? (
        <div
          className="rounded-lg border bg-[#FBFBFB] border-[#D9D9D9] p-8 items-center flex hover:cursor-pointer"
          onClick={handleCollapse}
        >
          <div className="text-lg leading-none">
            {pluralize("Active Rule", ruleCount, true)}
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

const OrderTooltip = () => {
  return (
    <div className="absolute bottom-full -translate-y-4 left-[50%] min-w-[290px] -translate-x-[50%] invisible opacity-0  group-hover/tooltip:opacity-100 group-hover/tooltip:visible">
      <div className="py-4 px-4 font-body text-sm rounded-lg shadow-lg bg-white">
        This number reflects this rule’s evaluation order. Schematic evaluates
        first to last.
      </div>
      <div className="absolute left-[50%] translate-x-[-50%] h-0 w-0 border-x-[12px] border-x-transparent border-t-[10px] border-t-white"></div>
    </div>
  );
};
