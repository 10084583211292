import { environmentPillColors } from "@components/overlays/types";
import { EnvironmentTypeShort } from "@consts/environments";
import { EnvironmentType } from "@models/account";
import { Pill, PillStyleTypes } from "@ui/Pill";

export const EnvironmentPill = ({
  environmentType,
}: {
  environmentType: EnvironmentType;
}) => {
  return (
    <Pill
      className="ml-0 !p-0.5 !px-1.5 !text-xs"
      color={environmentPillColors[environmentType] as PillStyleTypes}
    >
      {EnvironmentTypeShort[environmentType]}
    </Pill>
  );
};
