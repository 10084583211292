import {
  RuleConditionDetailResponseData,
  RuleConditionGroupDetailResponseData,
  CreateOrUpdateConditionRequestBodyOperatorEnum,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
} from "@models/api";
import { EntityType } from "@models/entityTrait";
import { Icon } from "@ui/Icon";
import { RuleConditionGroupBlock } from "@ui/RuleBlock/RuleConditionGroupBlock";
import cx from "classnames";
import { ArrayHelpers, FieldArray } from "formik";

import BooleanSelect from "./BooleanSelect";
import { ConditionLogicalOperator } from "./consts";
import { RuleBlockHeader } from "./RuleBlockHeader";
import { RuleConditionRow } from "./RuleConditionRow";

export interface RuleBlockProps {
  onRemove?: () => void;
  conditions: RuleConditionDetailResponseData[];
  conditionGroups: RuleConditionGroupDetailResponseData[];
  ruleValue: boolean;
  showValue?: boolean;
  showHeader?: boolean;
  field: string;
  permittedConditionTypes?: CreateOrUpdateConditionRequestBodyConditionTypeEnum[];
  permittedTraitEntityType?: EntityType;
  readonly?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setFieldValue?: (field: string, value: any) => void;
}

const newCondition = (): RuleConditionDetailResponseData => {
  return {
    conditionType: CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company,
    createdAt: new Date(),
    environmentId: "",
    id: "",
    metricValue: 0,
    operator: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
    resourceIds: [],
    resources: [],
    ruleId: "",
    traitValue: "",
    updatedAt: new Date(),
  };
};

export const RuleBlock = ({
  onRemove,
  readonly = false,
  conditions,
  conditionGroups,
  ruleValue,
  showValue = true,
  showHeader = true,
  field,
  permittedConditionTypes,
  permittedTraitEntityType,
  setFieldValue,
}: RuleBlockProps) => {
  const styles = cx(
    "rounded-lg w-full relative space-y-6 pb-8 pt-6",
    !readonly && "bg-white shadow-[0_1px_15px_0px_rgba(16,24,40,0.07)]",
    readonly && "border bg-[#FBFBFB] border-[#D9D9D9]",
  );

  const onAddAndCondition = () => {
    setFieldValue?.(`${field}.conditions`, [...conditions, newCondition()]);
  };

  const onAddOrCondition =
    (arrayHelpers: ArrayHelpers, index: number) => () => {
      const group = {
        environmentId: "",
        id: "",
        ruleId: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        conditions: [conditions[index], newCondition()],
      };

      const updatedConditionGroups = [...conditionGroups, group];

      arrayHelpers.remove(index);
      setFieldValue?.(`${field}.conditionGroups`, updatedConditionGroups);
    };

  const onRemoveCondition =
    (arrayHelpers: ArrayHelpers, index: number) => () => {
      arrayHelpers.remove(index);
    };

  const onRemoveConditionGroup =
    (arrayHelpers: ArrayHelpers, index: number) =>
    (condition?: RuleConditionDetailResponseData) => {
      // We return condition if there is only one condition in group
      condition &&
        setFieldValue?.(`${field}.conditions`, [...conditions, condition]);

      arrayHelpers.remove(index);

      // Remove whole RuleBlock if deleting last condition group
      if (conditions.length + conditionGroups.length === 1) {
        setFieldValue?.(`${field}.conditions`, [newCondition()]);
        setFieldValue?.(`${field}.conditionGroups`, []);
        onRemove?.();
      }
    };

  return (
    <div className={styles} key={field}>
      {!readonly && showHeader && (
        <RuleBlockHeader
          onRemove={onRemove}
          field={field}
          readonly={readonly}
        />
      )}

      <FieldArray
        name={`${field}.conditions`}
        render={(arrayHelpers: ArrayHelpers) => (
          <div className="space-y-5">
            {conditions.map((condition, conditionIndex) => {
              const logicalOperator =
                conditionIndex === 0
                  ? ConditionLogicalOperator.IF
                  : ConditionLogicalOperator.AND;

              return (
                <RuleConditionRow
                  condition={condition}
                  logicalOperator={logicalOperator}
                  key={condition.id || conditionIndex}
                  readonly={readonly}
                  conditionsLength={conditions.length}
                  field={`${field}.conditions.${conditionIndex}`}
                  setFieldValue={setFieldValue}
                  onAddAndCondition={onAddAndCondition}
                  onAddOrCondition={onAddOrCondition(
                    arrayHelpers,
                    conditionIndex,
                  )}
                  onRemove={onRemoveCondition(arrayHelpers, conditionIndex)}
                  disableAnd={conditions.length > 2}
                  permittedConditionTypes={permittedConditionTypes}
                  permittedTraitEntityType={permittedTraitEntityType}
                />
              );
            })}
          </div>
        )}
      />

      <FieldArray
        name={`${field}.conditionGroups`}
        render={(arrayHelpers: ArrayHelpers) => (
          <div className="space-y-5">
            {conditionGroups.map((group, groupIndex) => {
              const logicalOperator =
                groupIndex === 0
                  ? ConditionLogicalOperator.IF
                  : ConditionLogicalOperator.AND;

              return (
                <RuleConditionGroupBlock
                  conditions={group.conditions}
                  logicalOperator={logicalOperator}
                  key={group.id || groupIndex}
                  newCondition={newCondition}
                  readonly={readonly}
                  field={`${field}.conditionGroups.${groupIndex}`}
                  setFieldValue={setFieldValue}
                  onAddAndCondition={onAddAndCondition}
                  onRemove={onRemoveConditionGroup(arrayHelpers, groupIndex)}
                  ruleConditionLength={
                    conditions.length + conditionGroups.length
                  }
                />
              );
            })}
          </div>
        )}
      />

      {showValue && (
        <div className="pr-8 pl-16">
          <div className="flex space-x-2 items-center">
            <div className="flex flex-col mr-6">
              <Icon
                name="arrow-curved"
                className="text-4xl text-gray-300 leading-none"
              />
              <span className="uppercase tracking-wide font-bold text-sm text-blue-400 leading-none">
                Serve
              </span>
            </div>

            <BooleanSelect
              disabled={readonly}
              label="Value"
              name={`${field}.value`}
              selected={ruleValue}
            />
          </div>
        </div>
      )}
    </div>
  );
};
