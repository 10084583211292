import { useOrganization } from "@clerk/clerk-react";
import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { SettingsBox } from "@components/ui/SettingsBox";
import * as api from "@data/accounts";
import { useRole } from "@hooks/useRole";
import { Account } from "@models/account";
import { ClerkUserRole } from "@models/clerkUser";
import { useQuery } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { ButtonProps } from "@ui/Button";
import { useState } from "react";
import { TeammateDeleteOverlay } from "../overlays/TeammateDeleteOverlay";
import {
  TeammateOverlay,
  TeammateServerData,
} from "../overlays/TeammateOverlay";
import { InvitationsTable } from "../tables/InvitationsTable";
import { OrganizationMembership, TeamTable } from "../tables/TeamTable";

export const TeamPage = () => {
  const [editOverlay, setEditOverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [teammate, setTeammate] = useState<TeammateServerData>();
  const [organizationMembership, setOrganizationMembership] =
    useState<OrganizationMembership>();
  const teammateEditAllowed = useRole(ClerkUserRole.Admin);

  const { isLoaded, invitations, memberships } = useOrganization({
    invitations: true,
    memberships: true,
  });

  const onClose = () => {
    setTeammate(undefined);
    setOrganizationMembership(undefined);
    setEditOverlay(false);
    setDeleteOverlay(false);
    invitations?.revalidate?.();
    memberships?.revalidate?.();
  };

  const buttons: ButtonProps[] = [
    {
      children: "Add teammate",
      color: "blue",
      size: "sm",
      disabled: !teammateEditAllowed,
      onClick: () => setEditOverlay(true),
    },
  ];

  const { data, error, isLoading } = useQuery<Account>({
    queryKey: ["accounts", "me"],
    queryFn: () => api.me(),
  });

  if (isLoading || !isLoaded) {
    return <SchematicOverlayLoader className="!z-[500] fixed" />;
  }

  if (error) {
    throw error;
  }

  if (!data) {
    return <Alert style="red">No data</Alert>;
  }

  return (
    <>
      <div className="flex flex-col w-full space-y-8">
        <SettingsBox
          title="Team"
          buttons={buttons}
          className="overflow-visible "
        >
          <TeamTable
            environments={data.environments}
            onDelete={(organizationMembership: OrganizationMembership) => {
              setOrganizationMembership(organizationMembership);
              setDeleteOverlay(true);
            }}
            onEdit={(teammate: TeammateServerData) => {
              setTeammate(teammate);
              setEditOverlay(true);
            }}
          />
        </SettingsBox>

        {teammateEditAllowed && (
          <SettingsBox title="Invitations">
            <InvitationsTable />
          </SettingsBox>
        )}
      </div>

      {editOverlay && (
        <TeammateOverlay
          teammate={teammate}
          environments={data.environments}
          onClose={onClose}
        />
      )}
      {deleteOverlay && organizationMembership && (
        <TeammateDeleteOverlay
          onClose={onClose}
          organizationMembership={organizationMembership}
        />
      )}
    </>
  );
};
