export interface UserProps {
  image?: string;
  name?: string;
}

export const User = ({ image, name }: UserProps) => {
  return (
    <div className="flex items-center justify-center">
      {image && (
        <img className="w-5 h-5 rounded-full mr-2" src={image} alt={name} />
      )}
      {name && <span className="text-sm">{name}</span>}
    </div>
  );
};
