import { TableLoader } from "@components/loaders/TableLoader";
import { Option } from "@forms/FormikAsyncSelect";
import useTablePagination from "@hooks/useTablePagination";
import { UserDetailResponseData } from "@models/api";
import { FeatureUsage } from "@models/usage";
import { CompanyEntitlementCell } from "@modules/companies/components/CompanyEntitlementCell";
import {
  countFeatureUsage,
  FeatureUsageFilter,
  listFeatureUsage,
} from "@modules/features/queries/usage";
import { ColumnDef } from "@tanstack/react-table";
import { IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { Logo } from "@ui/Logo";
import { Pill } from "@ui/Pill";
import { Select } from "@ui/Select";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";

export interface CompanyUserFeaturesTableProps {
  user: UserDetailResponseData;
}

export const CompanyUserFeaturesTable = ({
  user,
}: CompanyUserFeaturesTableProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<FeatureUsageFilter>({});
  const [companyId, setCompanyId] = useState<string | undefined>(
    user?.companyMemberships?.[0].company?.id,
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter({
      q: searchTerm === "" ? undefined : searchTerm,
    });
  }, [searchTerm]);

  const columns: ColumnDef<FeatureUsage>[] = [
    {
      id: "feature",
      header: "Feature",
      accessorKey: "feature",
      cell: (cellInfo) => {
        const feature = cellInfo.row.original.feature;
        if (!feature) return <></>;

        return (
          <div className="flex flex-row items-center">
            <IconRound name={feature.icon as IconNameTypes} size="sm" />
            <div className="flex flex-col ml-3 space-y-1">
              <div className="leading-none font-medium">{feature.name}</div>
              <div className="leading-none text-gray-400">{feature.id}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: "entitlement",
      header: "Company Entitlement",
      accessorKey: "entitlement",
      cell: (cellInfo) => {
        return <CompanyEntitlementCell usage={cellInfo.row.original} />;
      },
    },
    {
      id: "plan",
      header: "From plan",
      cell: (cellInfo) => {
        const entitlementType = cellInfo.row.original.entitlementType;
        if (entitlementType == "company_override") {
          return (
            <Pill
              color="blue"
              text="normal"
              type="rounded"
              className="truncate-md"
            >
              Override
            </Pill>
          );
        }

        const planName = cellInfo.row.original.plan?.name;
        if (!planName) return <></>;

        return (
          <Pill text="normal" type="rounded" className="truncate-md">
            {planName}
          </Pill>
        );
      },
    },
  ];

  const getHeaderText = (count: number) => {
    return pluralize("Feature", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<FeatureUsage, FeatureUsageFilter>(
    ["feature-usage", companyId as string, searchTerm],
    listFeatureUsage(companyId),
    countFeatureUsage(companyId),
    filter,
    getHeaderText,
  );

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderCompanyUserFeaturesTable = () => {
    if (loading) {
      return <TableLoader />;
    }

    return (
      <Table
        columns={columns}
        data={listQuery.data || []}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
      />
    );
  };

  return (
    <div>
      <TableHeader
        headerText={headerText}
        className="pt-8"
        onSearch={handleSearch}
        searchPlaceholder="Search features"
      >
        {user.companyMemberships.length > 1 && (
          <Select
            onChange={({ value }: Option) => {
              setCompanyId(value);
            }}
            options={user.companyMemberships.map(({ company }) => ({
              value: company?.id,
              label: (
                <span className="inline-flex items-center">
                  <Logo src={company?.logoUrl} alt={company?.name} size="xs" />{" "}
                  {company?.name}
                </span>
              ),
            }))}
            selectedOption={{
              value: user.companyMemberships[0].company?.id,
              label: (
                <span className="inline-flex items-center">
                  <Logo
                    src={user.companyMemberships[0].company?.logoUrl}
                    alt={user.companyMemberships[0].company?.name}
                    size="xs"
                  />{" "}
                  {user.companyMemberships[0].company?.name}
                </span>
              ),
            }}
          />
        )}
      </TableHeader>

      {renderCompanyUserFeaturesTable()}
    </div>
  );
};
