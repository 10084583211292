import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { WebhookResponseData } from "@models/api";
import { deleteWebhook } from "@modules/settings/queries/webhooks";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { ButtonProps } from "@ui/Button";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { useState } from "react";

type WebhookDeleteOverlayProps = {
  webhook: WebhookResponseData;
  onClose: () => void;
};

export const WebhookDeleteOverlay = ({
  webhook,
  onClose,
}: WebhookDeleteOverlayProps) => {
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    try {
      await deleteWebhook(webhook.id);

      queryClient.invalidateQueries();

      setApiError(undefined);
      onClose();
    } catch (error) {
      setApiError(errorMessage(error));
    }

    setLoading(false);
  };

  const actionButtons: ButtonProps[] = [
    {
      children: "Cancel",
      onClick: onClose,
    },
    {
      children: "Delete webhook",
      color: "blue",
      onClick: handleDelete,
    },
  ];

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="md" className="pb-12">
        <div
          onClick={onClose}
          className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-3xl" />
        </div>

        <div className="p-12 space-y-4">
          <div className="mb-8 space-y-2">
            <div className="text-2xl">Delete webhook?</div>

            <div>Remove webhooks you no longer need.</div>
          </div>
        </div>

        {apiError && (
          <div className="px-2">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {apiError}
                </div>
              </div>
            </Alert>
          </div>
        )}

        <div className="flex justify-end px-12">
          <ButtonGroup buttons={actionButtons} />
        </div>
      </OverlayModal>
    </Overlay>
  );
};
