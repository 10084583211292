import { FeatureType } from "@models/feature";
import { IconNameTypes } from "@ui/Icon/consts";

export const FeatureTypes = [
  FeatureType.Boolean,
  FeatureType.Event,
  FeatureType.Trait,
];

export const FeatureTypeDisplay = {
  [FeatureType.Boolean]: "Boolean",
  [FeatureType.Event]: "Event-based",
  [FeatureType.Trait]: "Trait-based",
};

export const FeatureTypeIcon: Record<FeatureType, IconNameTypes> = {
  [FeatureType.Boolean]: "boolean",
  [FeatureType.Event]: "arrow-upward",
  [FeatureType.Trait]: "hash",
};
