import { useContextQuery } from "@hooks/useContextQuery";
import {
  RuleConditionDetailResponseData,
  RuleConditionGroupDetailResponseData,
} from "@models/api";
import { conditionsPreviewReqMapper } from "@modules/plans/helpers";
import {
  previewCompaniesCount,
  previewUsersCount,
} from "@modules/plans/queries";
import { useCurrentCompaniesAndUsers } from "@modules/plans/useCurrentCompaniesAndUsers";
import { ProgressBar } from "@ui/ProgressBar";

type PlanAudienceReviewChangesProps = {
  planId: string;
  planName: string;
  newConditions: RuleConditionDetailResponseData[];
  newConditionGroups: RuleConditionGroupDetailResponseData[];
};

export const PlanAudienceReviewChanges = ({
  planId,
  planName,
  newConditions,
  newConditionGroups,
}: PlanAudienceReviewChangesProps) => {
  const {
    currentCompaniesCount,
    currentUsersCount,
    currentConditions,
    loading: isCurrentLoading,
  } = useCurrentCompaniesAndUsers({ planId });

  const { data: newCompaniesCount, isLoading: isNewCompaniesCountLoading } =
    useContextQuery({
      queryKey: [
        `plan`,
        planId,
        "audience",
        "preview",
        "companies",
        "count",
        JSON.stringify(newConditions),
        JSON.stringify(newConditionGroups),
      ],
      queryFn: async () => {
        const req = {
          ...conditionsPreviewReqMapper(newConditions, newConditionGroups),
        };

        return previewCompaniesCount(planId, req);
      },
    });

  const { data: newUsersCount, isLoading: isNewUsersCountLoading } =
    useContextQuery({
      queryKey: [
        `plan`,
        planId,
        "audience",
        "preview",
        "users",
        "count",
        JSON.stringify(newConditions),
        JSON.stringify(newConditionGroups),
      ],
      queryFn: async () => {
        const req = {
          ...conditionsPreviewReqMapper(newConditions, newConditionGroups),
        };

        return previewUsersCount(planId, req);
      },
    });

  if (
    isCurrentLoading ||
    isNewCompaniesCountLoading ||
    isNewUsersCountLoading
  ) {
    const progress =
      (+isCurrentLoading +
        +isNewCompaniesCountLoading +
        +isNewUsersCountLoading) *
      33;

    return (
      <div className="p-6 flex flex-col space-y-6">
        <ProgressBar progress={progress} />
      </div>
    );
  }

  return (
    <div className="p-6 flex flex-col space-y-6">
      {currentConditions.length > 0 && (
        <div className="flex flex-row flex-1 w-full">
          <div className="text-red-400 min-w-[120px] font-bold">Current:</div>
          <div className="flex-1">
            Audience of{" "}
            <span className="font-bold">{currentCompaniesCount?.count}</span>{" "}
            Companies and{" "}
            <span className="font-bold">{currentUsersCount?.count}</span> Users
            are currently on the <span className="font-bold">{planName}</span>{" "}
            plan.
          </div>
        </div>
      )}
      <div className="flex flex-row flex-1 w-full">
        <div className="text-green-400 min-w-[120px] font-bold">New:</div>
        <div className="flex-1">
          Audience of{" "}
          <span className="font-bold">{newCompaniesCount?.count}</span>{" "}
          Companies and{" "}
          <span className="font-bold">{newUsersCount?.count}</span> Users will
          be on the <span className="font-bold">{planName}</span> plan.
        </div>
      </div>
    </div>
  );
};
