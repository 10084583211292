import { Icon } from "@ui/Icon";
import cx from "classnames";
import { cloneElement, useId } from "react";
import { VisibilityButton } from "../../../ui";

interface RootProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  category?: React.ReactNode;
  description?: React.ReactNode;
  bordered?: boolean;
}

export const Root = ({
  children,
  title,
  category,
  description,
  bordered = true,
}: RootProps) => {
  return (
    <>
      <div
        className={cx("space-y-1 p-6", {
          "border-b border-[#EDECEC]": bordered,
          "pb-0": !bordered,
        })}
      >
        {title && <div className="text-lg">{title}</div>}
        {category && (
          <div className="text-[0.8125rem] text-[#5D5D5D]">{category}</div>
        )}
        {description && <div className="leading-5">{description}</div>}
      </div>
      {children}
    </>
  );
};

interface SectionProps {
  children?: React.ReactNode;
}

export const Section = ({ children }: SectionProps) => {
  return (
    <div className="space-y-4 p-6 pb-8 border-b border-[#EDECEC]">
      {children}
    </div>
  );
};

interface HeaderProps {
  children?: React.ReactNode;
  isVisible?: boolean;
  onVibibilityChange?: () => void;
}

export const Header = ({
  children,
  isVisible,
  onVibibilityChange,
}: HeaderProps) => {
  return (
    <div className="flex justify-between pb-2">
      <div className="flex items-center gap-2">
        <Icon
          name="chevron-down"
          className="leading-none text-xl text-[#D0D0D0]"
        />
        {children}
      </div>

      {typeof isVisible === "boolean" &&
        typeof onVibibilityChange === "function" && (
          <VisibilityButton
            isVisible={isVisible}
            onClick={onVibibilityChange}
          />
        )}
    </div>
  );
};

interface InputProps {
  label: React.ReactNode;
  children: React.ReactElement;
}

export const Input = ({ children, label }: InputProps) => {
  const id = useId();
  return (
    <div className="flex justify-between items-center gap-4">
      <label htmlFor={id} className="label-sm">
        {label}
      </label>
      {cloneElement(children, { id })}
    </div>
  );
};
