import { ViewWrapper } from "@ui/ViewWrapper";
import { Outlet } from "react-router-dom";
import IntegrationsNavigation from "./components/IntegrationsNavigation";

export const IntegrationsView = () => {
  return (
    <ViewWrapper>
      <div className="flex flex-row relative">
        <IntegrationsNavigation />
        <Outlet />
      </div>
    </ViewWrapper>
  );
};
