import { IconNameTypes } from "@components/ui/Icon/consts";
import { CreatePlanRequestBody, PlanDetailResponseData } from "@models/api";
import { PlanEntitlement } from "@models/entitlement";

export type Plan = PlanDetailResponseData & {
  icon?: PlanIcon;
  planType: PlanType;
  audienceType: PlanAudienceType;
};

export type PlanWithEntitlements = Plan & { entitlements: PlanEntitlement[] };

export type PlanReq = CreatePlanRequestBody & {
  icon?: PlanIcon;
};

export enum PlanIcon {
  Blue = "blue",
  Gray = "gray",
  Green = "green",
  Red = "red",
  Yellow = "yellow",
}

export enum PlanAudienceType {
  Explicit = "explicit",
  Derived = "derived",
}

export enum PlanType {
  Plan = "plan",
  AddOn = "add_on",
}

export const PlanTypeDisplay = {
  [PlanType.Plan]: "Plan",
  [PlanType.AddOn]: "Add-On",
} as Record<PlanType, string>;

export const PlanTypeIcon = {
  [PlanType.Plan]: "plan",
  [PlanType.AddOn]: "plus-rounded-outline",
} as Record<PlanType, IconNameTypes>;
