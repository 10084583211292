export enum FeatureSubview {
  Companies = "companies",
  Entitlements = "entitlements",
  Flag = "flag",
  Usage = "usage",
  Users = "users",
}

export enum FlagSubview {
  Targeting = "targeting",
  Checks = "checks",
}
