import { FeatureType } from "@models/feature";
import { FeatureTypeDisplay, FeatureTypeIcon } from "@modules/features";
import { Icon } from "@ui/Icon";

type FeatureTypeCellProps = {
  featureType: FeatureType;
};

export const FeatureTypeCell = ({ featureType }: FeatureTypeCellProps) => {
  return (
    <div className="flex flex-row items-center text-sm space-x-1">
      <Icon
        className="text-2xl text-gray-500"
        name={FeatureTypeIcon[featureType]}
      />
      <span>{FeatureTypeDisplay[featureType]}</span>
    </div>
  );
};
