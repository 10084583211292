import React from "react";

interface WrapperProps {
  children: React.ReactNode;
  notification?: React.ReactNode;
}

const Wrapper = ({ children, notification }: WrapperProps) => {
  return (
    <div className="overflow-hidden">
      {notification && notification}
      <div id="portal-notifications" className="sticky top-0 z-50" />
      <div className="flex overflow-y-auto relative">{children}</div>
    </div>
  );
};

interface PageWrapperProps extends WrapperProps {
  useStrictMode?: boolean;
}

export const PageWrapper = ({
  useStrictMode = true,
  ...props
}: PageWrapperProps) => {
  if (import.meta.env.DEV && useStrictMode) {
    return (
      <React.StrictMode>
        <Wrapper {...props} />
      </React.StrictMode>
    );
  }

  return <Wrapper {...props} />;
};
