import { formatDate } from "@utils/date";

import { ReactNode } from "react";

interface TimelineListProps {
  children: ReactNode;
}

export const TimelineList = ({ children }: TimelineListProps) => {
  return <div className="timeline-list">{children}</div>;
};

interface TimelineItemProps {
  date: Date;
  label: ReactNode;
}

export const TimelineItem = ({ label, date }: TimelineItemProps) => {
  return (
    <div className="timeline-item">
      <div className="timeline-item-circle">
        <div className="timeline-item-bar"></div>
      </div>
      <div className="flex-1 text-gray-600 font-normal">{label}</div>
      <div className="text-gray-400">{formatDate(date)}</div>
    </div>
  );
};
