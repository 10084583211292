import { SettingsBox } from "@components/ui/SettingsBox";
import * as environmentsApi from "@data/environments";
import { useRole } from "@hooks/useRole";
import { AccountEnvironment } from "@models/account";
import { ApiKey } from "@models/apiKey";
import { ClerkUserRole } from "@models/clerkUser";
import { ButtonProps } from "@ui/Button";
import { useEffect, useState } from "react";
import { ApiKeyOverlay } from "../overlays/ApiKeyOverlay";
import { ApiKeysTable } from "../tables/ApiKeysTable";

export const ApiKeysPage = () => {
  const [environments, setEnvironments] = useState<AccountEnvironment[]>([]);
  const [showCreateOverlay, setShowCreateOverlay] = useState(false);
  const [apiKey, setApiKey] = useState<ApiKey>();
  const apiKeysEditAllowed = useRole(ClerkUserRole.Admin);

  useEffect(() => {
    environmentsApi.listEnvironments().then((envs) => setEnvironments(envs));
  }, []);

  const buttons: ButtonProps[] = [
    {
      children: "Create",
      color: "blue",
      onClick: () => setShowCreateOverlay(true),
      disabled: !apiKeysEditAllowed,
      size: "sm",
    },
  ];

  return (
    <>
      <SettingsBox
        title="API Keys"
        description="These keys allow authentication through the API."
        buttons={buttons}
      >
        <ApiKeysTable
          onEdit={(apiKey) => {
            setShowCreateOverlay(true);
            setApiKey(apiKey);
          }}
        />
      </SettingsBox>

      {showCreateOverlay && (
        <ApiKeyOverlay
          onClose={() => {
            setShowCreateOverlay(false);
            setApiKey(undefined);
          }}
          environments={environments}
          apiKey={apiKey}
        />
      )}
    </>
  );
};
