import { useEditor } from "@craftjs/core";
import { useEffect, useState } from "react";

export const useComponentUsage = (componentName: string) => {
  const { nodes } = useEditor((_, query) => ({
    nodes: query.getNodes(),
  }));

  const [isUsed, setIsUsed] = useState(false);

  useEffect(() => {
    const isComponentUsed = Object.values(nodes).some(
      (node) => node.data.name === componentName,
    );
    setIsUsed(isComponentUsed);
  }, [nodes, componentName]);

  return isUsed;
};
