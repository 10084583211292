import {
  BillingPriceResponseData,
  BillingProductResponseData,
  ListBillingProductsParams,
  ListProductPricesParams,
} from "@models/api";
import { get } from "./index";

export const listBillingProducts = async (
  flt?: ListBillingProductsParams,
): Promise<BillingProductResponseData[]> => {
  return get<BillingProductResponseData[], ListBillingProductsParams>(
    "billing/products",
    flt,
  );
};

export const listBillingProductPrices = async (
  flt: ListProductPricesParams & { billingProductId: string },
): Promise<BillingPriceResponseData[]> => {
  const { billingProductId, ...req } = flt;
  return get<BillingPriceResponseData[], ListProductPricesParams>(
    `billing/products/${billingProductId}/prices`,
    req,
  );
};
