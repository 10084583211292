import cx from "classnames";
import { ReactNode, useState } from "react";

interface TruncatableDataProps {
  data?: string | ReactNode;
  truncateLimit?: number;
  truncateAt?: number;
}

export const TruncatableData = ({
  data,
  truncateLimit = 100,
  truncateAt = 50,
}: TruncatableDataProps) => {
  const [truncate, setTruncate] = useState(true);
  const isTruncatable = data && data.toString().length > truncateAt;
  const truncatedDescription = data?.toString().substring(0, truncateLimit);

  return (
    <>
      {truncate ? (
        <>
          {truncatedDescription}
          {isTruncatable && "..."}
        </>
      ) : (
        data
      )}
      {isTruncatable && (
        <span
          onClick={() => setTruncate(!truncate)}
          className={cx("text-blue-400 cursor-pointer", !truncate && "ml-2")}
        >
          {truncate ? "see all" : "hide"}
        </span>
      )}
    </>
  );
};
