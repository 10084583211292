import * as PrimitiveSwitch from "@radix-ui/react-switch";
import cx from "classnames";
export interface ToggleProps extends PrimitiveSwitch.SwitchProps {
  label?: string;
  labelPlacement?: "right" | "left";
  name?: string;
}

export const Switch = ({
  label,
  checked,
  labelPlacement = "left",
  onCheckedChange,
  name,
  ...props
}: ToggleProps) => {
  const labelPlacementMap = {
    right: "flex-row-reverse space-x-4 space-x-reverse",
    left: "flex-row space-x-4",
  };

  const toggleStyles = cx("SwitchRoot");
  const styles = cx(
    "items-center flex hover:cursor-pointer group",
    labelPlacementMap[labelPlacement],
  );

  return (
    <div className={styles}>
      {label && (
        <label
          className="group-hover:cursor-pointer"
          htmlFor={name || "airplane-mode"}
        >
          {label}
        </label>
      )}
      <PrimitiveSwitch.Root
        className={toggleStyles}
        id={name || "airplane-mode"}
        checked={checked}
        onCheckedChange={onCheckedChange}
        {...props}
      >
        <PrimitiveSwitch.Thumb className="SwitchThumb" />
      </PrimitiveSwitch.Root>
    </div>
  );
};
