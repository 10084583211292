import { formatInitials } from "@utils/strings";
import cx from "classnames";

export interface UserInitialProps {
  name: string;
  size?: "xs" | "lg" | "xl";
  showName?: boolean;
  truncate?: boolean;
  className?: string;
}

export const UserInitials = ({
  name,
  showName = false,
  truncate = false,
  size = "xs",
  className,
}: UserInitialProps) => {
  const sizeMap = {
    xs: "w-6 h-6 text-sm min-w-6 min-h-6",
    lg: "",
    xl: "w-14 h-14 text-lg min-w-14 min-h-14",
  };
  const styles = cx(
    "font-medium border-2 border-gray-300 items-center rounded-full justify-center flex text-purple-500",
    sizeMap[size],
  );
  return (
    <div
      className={cx(
        "flex space-x-2 items-center",
        truncate && "inline-flex max-w-full overflow-hidden",
        className,
      )}
    >
      <div className={styles}>{formatInitials(name)}</div>
      {showName && <div className="truncate hover:text-wrap">{name}</div>}
    </div>
  );
};
