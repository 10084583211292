import { getEventType, listEvents } from "@data/events";
import { useContextQuery } from "@hooks/useContextQuery";
import { usePermission } from "@hooks/usePermission";
import { ListEventsParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { Feature } from "@models/feature";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { Icon } from "@ui/Icon";

import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { FeatureEventStreamTable } from "../tables/FeatureEventStreamTable";

interface EventMetricsProps {
  eventSubtype: string;
}

export const EventMetrics = ({ eventSubtype }: EventMetricsProps) => {
  const { data, isLoading } = useContextQuery({
    queryKey: [`event-metrics`, eventSubtype],
    queryFn: () => getEventType(eventSubtype),
  });

  if (isLoading || !data) return <></>;

  if (!data.eventCount) {
    return (
      <Alert style="gray">
        <div className="text-2xl font-semibold">No data yet</div>
        <div className="font-semibold font-body">
          No events detected for this metric in the last 60 days.
        </div>
      </Alert>
    );
  }

  return (
    <div className="flex space-x-6">
      <div className="bg-gray-100 p-6 rounded-md flex-1">
        <span className="text-2xl font-semibold">{data.eventCount}</span>
        <div className="font-semibold font-body text-lg">Usage in last 60</div>
      </div>
      <div className="bg-gray-100 p-6 rounded-md flex-1">
        <span className="text-2xl font-semibold">{data.companyCount}</span>
        <div className="font-semibold font-body text-lg">
          Companies in last 60
        </div>
      </div>
      <div className="bg-gray-100 p-6 rounded-md flex-1">
        <span className="text-2xl font-semibold">{data.userCount}</span>
        <div className="font-semibold font-body text-lg">Users in last 60</div>
      </div>
    </div>
  );
};

export const FeatureEventsTab = () => {
  const { feature, onEdit } = useOutletContext<{
    feature: Feature;
    onEdit: () => void;
  }>();
  const eventSubtype = feature.eventSubtype;
  const featureEditAllowed = usePermission(ClerkUserPermission.features_edit);

  const [filter] = useState<ListEventsParams>({ eventSubtype });

  const queryEvents = useContextQuery({
    queryKey: ["events"],
    queryFn: () =>
      listEvents({
        offset: 0,
        ...filter,
      }),
  });

  return (
    <div className="space-y-12 pt-4 pb-12">
      {eventSubtype && queryEvents.data?.length !== 0 && (
        <div>
          <div className="text-3xl flex items-center leading-none mb-8">
            <Icon name="thunder" className="mr-2" />
            <span>Key Metric</span>
          </div>
          <EventMetrics eventSubtype={eventSubtype} />
        </div>
      )}

      {!eventSubtype && (
        <div>
          <div className="mb-8">
            Associate the event needed to track this feature’s usage.
          </div>

          <Alert background="schematic" style="gray">
            <div className="flex justify-between w-full flex-1 relative z-10 text-gra">
              <div className="text-2xl font-medium">Associate event</div>
              <div>
                <Button
                  color="blue"
                  size="sm"
                  onClick={onEdit}
                  disabled={!featureEditAllowed}
                >
                  Add event
                </Button>
              </div>
            </div>
          </Alert>
        </div>
      )}
      {eventSubtype && <FeatureEventStreamTable eventSubtype={eventSubtype} />}
    </div>
  );
};
