export const OverlayLoader = () => {
  // TODO: Import and implement OverlayPosition type as a prop
  const LoaderLabeledData = () => {
    return (
      <div className="flex flex-col space-y-3 items-start w-auto">
        <div className="h-5 bg-gray-200 rounded w-24"></div>
        <div className="h-3 bg-gray-200 rounded w-32"></div>
      </div>
    );
  };

  return (
    <div role="status" className="w-full h-full animate-pulse px-10">
      <div className="flex items-center py-8 justify-between">
        <div className="h-7 bg-gray-200 rounded w-24"></div>
        <div className="h-7 bg-gray-200 rounded w-8"></div>
      </div>
      <div className="p-0 rounded-lg mt-2 w-full space-y-10">
        <div className="flex flex-row  justify-between w-full">
          <LoaderLabeledData />
          <div className="pr-32">
            <LoaderLabeledData />
          </div>
        </div>

        <div className="flex flex-row  justify-between w-full">
          <LoaderLabeledData />
          <div className="pr-32">
            <LoaderLabeledData />
          </div>
        </div>

        <div className="flex flex-col justify-between w-full">
          <div className="h-5 bg-gray-200 rounded w-24 mb-4"></div>
          <div className="space-y-2">
            <div className="h-5 bg-gray-200 rounded w-full"></div>
            <div className="h-5 bg-gray-200 rounded w-full"></div>
            <div className="h-5 bg-gray-200 rounded w-full"></div>
            <div className="h-5 bg-gray-200 rounded w-full"></div>
          </div>
        </div>

        <div className="flex flex-col justify-between w-full">
          <div className="h-5 bg-gray-200 rounded w-24 mb-4"></div>
          <div className="space-x-2 flex">
            <div className="h-5 bg-gray-200 rounded w-20"></div>
            <div className="h-5 bg-gray-200 rounded w-20"></div>
          </div>
          <div className="bg-gray-100 h-[200px] rounded w-full mt-4"></div>
        </div>
      </div>
    </div>
  );
};
