import cx from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "../Icon";

export type BreadCrumbTypes = {
  name: string;
  active: boolean; // Wondering if it should not be a prop and a last-child styling instead...
  url?: string;
};

interface BreadCrumbsProps {
  data?: BreadCrumbTypes[];
}

export const BreadCrumbs = ({ data: crumbData }: BreadCrumbsProps) => {
  if (!crumbData) return null;

  return (
    <div className="flex pb-4 lg:pb-8 pt-8">
      <div className="tw-wrapper  px-8">
        <div className="crumb-wrapper">
          {crumbData.map((i, idx) => {
            const styles = cx("crumb-item", i.active && "crumb-item-active");
            return (
              <div className={styles} key={idx}>
                <div className="crumb-item-text">
                  {i.url ? <Link to={i.url}>{i.name}</Link> : i.name}
                </div>
                <Icon name="chevron-right" className="text-gray-400/60" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
