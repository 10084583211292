import { BillingPriceResponseData } from "@models/api";
import { PriceIntervalDisplayName } from "@modules/plans/consts";
import { formatCurrency } from "@utils/strings";

type PriceOptionLabelProps = {
  price: BillingPriceResponseData;
};

export const PriceOptionLabel = ({ price }: PriceOptionLabelProps) => {
  return (
    <div
      key={price.id}
      className="text-black font-semibold flex items-baseline font-display"
    >
      {formatCurrency(price.price)}
      {PriceIntervalDisplayName[price.interval] || ""}
    </div>
  );
};
