import { WebhookRequestType } from "@models/webhook";
import camelCase from "lodash.camelcase";

export const getCodeColor = (code?: number | null) => {
  if (!code) {
    return "gray";
  }

  if (code >= 500) {
    return "red";
  }

  if (code >= 400) {
    return "yellow";
  }

  return "green";
};

// Reliably groups WebhookRequestTypes based around entities
export const sortWebhookTypesAlphabetically = (
  a: WebhookRequestType,
  b: WebhookRequestType,
) => {
  const aArray = a.split(".");
  const bArray = b.split(".");

  const aOperation = aArray.pop();
  const bOperation = bArray.pop();

  const aCamel = camelCase(aArray.join(" "));
  const bCamel = camelCase(bArray.join(" "));

  return [aCamel, aOperation]
    .join(".")
    .localeCompare([bCamel, bOperation].join("."));
};
