import { SearchBar } from "@ui/SearchBar";
import cx from "classnames";
import { ReactNode } from "react";
import { ButtonProps } from "../Button";
import { ButtonGroup } from "../ButtonGroup";

export interface SettingsBoxProps {
  title?: string | ReactNode;
  description?: string | ReactNode;
  children: ReactNode;
  buttons?: ButtonProps[];
  className?: string;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
  style?: "boxed" | "inline";
}

export const SettingsBox = ({
  title,
  description,
  children,
  buttons,
  className,
  onSearch,
  searchPlaceholder,
  style = "inline",
}: SettingsBoxProps) => {
  const boxStyles = {
    boxed: "p-12 rounded-lg bg-white bg-white border border-gray-200 shadow-md",
    inline: "pb-4",
  };

  const styles = cx(
    "flex-grow justify-items-center align-center max-w-full w-full overflow-hidden w-full flex-1",
    boxStyles[style],
    className,
  );

  return (
    <div className={styles}>
      {(title || description || buttons) && (
        <div className="flex flex-row space-x-4">
          <div className="mb-6 space-y-3 flex-1 items">
            {title && (
              <h1 className="text-2xl font-body font-medium">{title}</h1>
            )}
            {description && (
              <p className="text-gray-600 text-lg">{description}</p>
            )}
          </div>
          {searchPlaceholder && onSearch && (
            <SearchBar
              size="sm"
              placeholder={searchPlaceholder}
              onChange={onSearch}
            />
          )}

          {buttons && <ButtonGroup buttons={buttons} />}
        </div>
      )}
      {children}
    </div>
  );
};
