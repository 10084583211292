import { Alert } from "@ui/Alert";

type ImportStartedProps = {
  matching_criteria: string;
};

export const ImportStarted = ({ matching_criteria }: ImportStartedProps) => {
  return (
    <div>
      <div className="mb-8 space-y-2 justify-end">
        <div className="text-2xl">Import in progress</div>

        <div>
          Schematic is importing companies, subscriptions, and products from
          Stripe.{" "}
        </div>
      </div>

      {matching_criteria === "billing_meta_object" && (
        <Alert style={"yellow"} className="mb-10" size="sm">
          Going forward, Schematic will associate Stripe Customers to Schematic
          Companies using the selected metadata field. Include that identifier
          as a Company key for each company you'd like to keep synchronized with
          Stripe.
        </Alert>
      )}

      {matching_criteria === "manual_upsert" && (
        <Alert style={"yellow"} className="mb-10" size="sm">
          Going forward, Schematic will associate Stripe Customers to Schematic
          Companies using the <b>stripe_customer_id</b> key. Include that Stripe
          identifier as a Company key for companies you'd like to keep
          synchronized with Stripe.
        </Alert>
      )}

      {matching_criteria === "none" && (
        <Alert style={"yellow"} className="mb-10" size="sm">
          To ensure Stripe customers are matched with Schematic companies, we
          recommend storing the Stripe <b>customer_id</b> in your product
          database and then including that data in API upserts to Schematic.
        </Alert>
      )}
    </div>
  );
};
