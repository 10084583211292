import {
  SidebarNavigation,
  SidebarOption,
} from "@components/ui/SettingsNavigation";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { SettingsPage } from "../consts";

const SettingsNavigation = () => {
  const settingsOptions: SidebarOption[] = [
    {
      label: "Audit Log",
      path: SettingsPage.AuditLog,
    },
    {
      label: "Team",
      path: SettingsPage.Team,
    },
    {
      label: "Keys and Traits",
      path: SettingsPage.KeysAndTraits,
    },
    {
      label: "API Keys",
      path: SettingsPage.ApiKeys,
    },
    ...(useSchematicFlag("components")
      ? [
          {
            label: "Plan and Usage",
            path: SettingsPage.Plan,
          },
        ]
      : []),
    ...(useSchematicFlag("settings.misc")
      ? [
          {
            label: "Misc",
            path: SettingsPage.Misc,
          },
        ]
      : []),
  ];

  return <SidebarNavigation title="Settings" options={settingsOptions} />;
};

export default SettingsNavigation;
