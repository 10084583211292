import { Button } from "@components/ui/Button";
import { usePermission } from "@hooks/usePermission";
import { ClerkUserPermission } from "@models/clerkUser";
import { Feature } from "@models/feature";
import { PlanEntitlementEditOverlay } from "@modules/plans";
import { Alert } from "@ui/Alert";
import { useState } from "react";

interface FeatureCompaniesBlankStateProps {
  feature: Feature;
}

export const FeatureCompaniesBlankState = ({
  feature,
}: FeatureCompaniesBlankStateProps) => {
  const [planEntitlementOverlay, setPlanEntitlementOverlay] = useState(false);
  const onPlanEntitlementOverlayClose = () => {
    setPlanEntitlementOverlay(false);
  };

  const planEntitlementEditAllowed = usePermission(
    ClerkUserPermission.plan_entitlements_edit,
  );

  return (
    <>
      <Alert
        background="schematic"
        size="lg"
        style="gray"
        className="text-center mt-6"
      >
        <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
          <div className="flex flex-col text-left items-start space-y-2">
            <div className="text-[25px] font-body">
              ⛔️ No companies have access yet
            </div>
            <div className="text-lg leading-6 text-gray-600">
              Create plan entitlements or company overrides to begin
              <br />
              giving companies access to this features.
            </div>
          </div>
          <div className="flex items-center">
            <Button
              color="blue"
              disabled={!planEntitlementEditAllowed}
              onClick={() => setPlanEntitlementOverlay(true)}
            >
              Add entitlement
            </Button>
          </div>
        </div>
      </Alert>

      {planEntitlementOverlay && (
        <PlanEntitlementEditOverlay
          onClose={onPlanEntitlementOverlayClose}
          feature={feature}
        />
      )}
    </>
  );
};
