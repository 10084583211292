import { deleteReq, get, post, put } from "@data/index";
import {
  CreateApiKeyRequestBody,
  ListApiKeysParams,
  UpdateApiKeyRequestBody,
} from "@models/api";
import { ApiKey } from "@models/apiKey";
import { Count } from "@models/count";
import { Delete } from "@models/delete";

export const countApiKeys = async (flt?: ListApiKeysParams): Promise<Count> => {
  return get<Count, ListApiKeysParams>("api-keys/count", flt);
};

export const createApiKey = async (
  req: CreateApiKeyRequestBody,
): Promise<ApiKey> => {
  return post<ApiKey, CreateApiKeyRequestBody>("api-keys", req);
};

export const updateApiKey = async (
  id: string,
  req: UpdateApiKeyRequestBody,
): Promise<ApiKey> => {
  return put<ApiKey, UpdateApiKeyRequestBody>(`api-keys/${id}`, req);
};

export const deleteApiKey = async (id: string): Promise<Delete> => {
  return deleteReq(`api-keys/${id}`);
};

export const getApiKey = async (id: string): Promise<ApiKey> => {
  return get(`api-keys/${id}`);
};

export const listApiKeys = async (
  flt?: ListApiKeysParams,
): Promise<ApiKey[]> => {
  return get<ApiKey[], ListApiKeysParams>("api-keys", flt);
};
