import cx from "classnames";
import { ReactNode } from "react";

interface AlertProps {
  className?: string;
  size?: "xs" | "sm" | "md" | "lg";
  style?: "blue" | "gray" | "light-gray" | "green" | "red" | "yellow";
  background?: "schematic";
  children?: ReactNode;
}

export const Alert = ({
  size = "md",
  style = "gray",
  background,
  className,
  children,
}: AlertProps) => {
  const styleMap = {
    blue: "bg-[#ECF1FF]",
    gray: "bg-[#F8FAFF]",
    green: "bg-[#EBF6EB] border border-[#ECECEC]",
    red: "bg-[#DB6769]/10 border border-[#DB6769]",
    yellow: "bg-yellow-50 border-2 border-yellow-200",
    "light-gray": "bg-[#FBFBFB] border border-[#ECECEC]",
  };

  const sizeMap = {
    xs: "p-4",
    sm: "p-8",
    md: "p-10",
    lg: "p-14",
  };

  const styles = cx(
    "relative overflow-hidden rounded-lg ",
    styleMap[style],
    sizeMap[size],
    className,
  );
  return (
    <div className={styles}>
      {children}

      {background == "schematic" && (
        <svg
          width="265"
          height="349"
          viewBox="0 0 265 349"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-[50%] translate-y-[-50%] right-[12%]"
        >
          <path
            d="M134.192 244.707L164.625 213.103L86.8134 132.299C67.5448 112.29 67.5448 79.6857 86.8134 59.676C106.082 39.6663 137.479 39.6663 156.747 59.676L234.558 140.48L264.991 108.876L187.18 28.0724C151.137 -9.35748 92.424 -9.35748 56.3804 28.0724C20.3367 65.5024 20.3367 126.473 56.3804 163.903L134.192 244.707Z"
            fill="#194BFB"
            fillOpacity="0.05"
          />
          <path
            d="M137.09 80.2743C128.703 71.5642 115.045 71.5642 106.657 80.2743C98.2699 88.9844 98.2699 103.168 106.657 111.878L204.134 213.103L134.2 285.727L36.7237 184.501C28.3362 175.791 14.6781 175.791 6.29063 184.501C-2.09688 193.211 -2.09688 207.395 6.29063 216.105L134.2 348.934L265 213.103L137.09 80.2743Z"
            fill="#194BFB"
            fillOpacity="0.05"
          />
        </svg>
      )}
    </div>
  );
};
