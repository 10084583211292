import { Count } from "@models/count";
import { titlecase } from "@utils/strings";
import pluralize from "pluralize";
import { useEffect, useState, useRef } from "react";
import { useContextQuery } from "./useContextQuery";

type HeaderTextFn = (count: number) => string;

const useSecondaryTableHeader = <F,>(
  queryKey: string,
  countFn: (options?: F) => Promise<Count>,
  headerTextFn?: HeaderTextFn,
): string => {
  const [headerText, setHeaderText] = useState<string>(titlecase(queryKey));
  const headerTextFnRef = useRef<HeaderTextFn | undefined>();

  const countQuery = useContextQuery<Count>({
    queryKey: [queryKey, `count`, "limit:10", "offset:0"],
    queryFn: () => countFn(),
  });

  useEffect(() => {
    if (!countQuery.data) return;

    headerTextFnRef.current =
      headerTextFn ||
      ((count: number) => {
        return pluralize(titlecase(queryKey), count, true);
      });

    setHeaderText(headerTextFnRef.current(countQuery.data.count));
  }, [countQuery.data, queryKey, headerTextFn, headerTextFnRef]);

  return headerText;
};

export default useSecondaryTableHeader;
