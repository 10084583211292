import { get, post, put } from "@data/index";
import {
  type CreatePlanGroupRequestBody,
  UpdatePlanGroupRequestBody,
  PlanGroupDetailResponseData,
  ListPlansParams,
} from "@models/api";
import { Plan, PlanType } from "@models/plan";

export const getPlansConfiguration =
  async (): Promise<PlanGroupDetailResponseData> => {
    return get<PlanGroupDetailResponseData>("plan-groups");
  };

export const createPlansConfiguration = async (
  req: CreatePlanGroupRequestBody,
): Promise<PlanGroupDetailResponseData> => {
  return post<PlanGroupDetailResponseData, CreatePlanGroupRequestBody>(
    "plan-groups",
    req,
  );
};

export const updatePlansConfiguration = async (
  planConfigurationId: string,
  req: UpdatePlanGroupRequestBody,
): Promise<PlanGroupDetailResponseData> => {
  return put<PlanGroupDetailResponseData, UpdatePlanGroupRequestBody>(
    `plan-groups/${planConfigurationId}`,
    req,
  );
};

export const listPlansWithEntitlements = async (
  flt?: ListPlansParams,
): Promise<Plan[]> => {
  const params = {
    planType: PlanType.Plan,
    withEntitlements: true,
    ...flt,
  };

  return get<Plan[], ListPlansParams>("plans", params);
};
