import cx from "classnames";
import { Icon } from "../Icon";

export const DynamicTitle = ({ fromLabel, toLabel, from, to }: any) => {
  return (
    <div className="space-x-2 flex">
      <div
        title={fromLabel}
        className={cx(from ? "text-black" : "text-gray-400/80 font-normal")}
      >
        {fromLabel}
      </div>

      <Icon
        name="arrow-right"
        className="text-3xl leading-none text-blue-400"
      />

      <div
        title={toLabel}
        className={cx(
          "truncate-md",
          to ? "text-black" : "text-gray-400/80 font-normal",
        )}
      >
        {toLabel}
      </div>
    </div>
  );
};
