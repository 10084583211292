export enum AuditLogTableRequest {
  All = "all",
  Api = "api",
  App = "app",
}

export type AuditLogTableRequestOptions = {
  label: string;
  value: AuditLogTableRequest;
};
