import { Article } from "@consts/links";
import { BlankStateOnCreate, PageBlankState } from "@ui/PageBlankState";

export const FeaturesBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <PageBlankState
      title="📦 Create your first feature!"
      description={
        <>
          Create a feature flag and roll it out to user, attach it
          <br />
          to plans, and target based on usage.
        </>
      }
      buttons={
        onCreate && [
          {
            color: "blue",
            children: <>Create feature</>,
            disabled,
            onClick: onCreate,
          },
        ]
      }
      links={{
        title: "Learn how to:",
        links: [
          {
            label: "Create a feature",
            url: Article.CREATE_FEATURE,
          },
        ],
      }}
    />
  );
};
