import { Error } from "@ui/Error";
import cx from "classnames";
import { ErrorMessage, Field } from "formik";

type InputTypes = "text" | "number" | "numeric-string";

export interface InputProps {
  disabled?: boolean;
  label?: string;
  name: string;
  style?: "default" | "inline";
  description?: string;
  className?: string;
  setFieldValue?: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  type?: InputTypes;
  value?: string;
}

function handleNumericChange(
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (
    field: string,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
) {
  const parsedValue = parseFloat(e.target.value);

  setFieldValue(e.target.name, parsedValue);
}

export const Input = ({
  label,
  name,
  description,
  className,
  style = "default",
  type = "text",
  disabled = false,
  setFieldValue,
  ...rest
}: InputProps) => {
  const styleMap = {
    default: "px-4 border border-gray-300 h-[44px]",
    inline: "py-2 border border-transparent !text-2xl",
  };

  const handleOnChange =
    type === "number" && setFieldValue
      ? {
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            handleNumericChange(e, setFieldValue),
        }
      : {};

  // both "number" and "numeric-string" types are treated as numeric in the UI,
  // but "number" is converted to a numeric value while "numeric-string" is left as a string
  const htmlType = type === "numeric-string" ? "text" : type;

  const inputStyles = cx("rounded-lg ", styleMap[style], className);
  const wrapperStyles = cx("flex flex-col", style === "inline" && "w-full");

  return (
    <div className={wrapperStyles}>
      {label && (
        <label htmlFor={name} className="label-md">
          {label}
        </label>
      )}

      <Field
        id={name}
        name={name}
        type={htmlType}
        disabled={disabled}
        {...rest}
        {...handleOnChange}
        className={inputStyles}
      />

      {description && (
        <div className="text-xs text-gray-400 mt-2">{description}</div>
      )}

      <ErrorMessage name={name} component={Error} />
    </div>
  );
};
