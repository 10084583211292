import { Icon } from "@ui/Icon";
import cx from "classnames";
import { useState, ReactNode } from "react";

interface ClipCopyProps {
  data: string;
  position?: "right" | "bottom-right" | "top-right";
  size?: "xs" | "sm" | "md";
  className?: string;
  truncate?: boolean;
  title?: string;
  children?: ReactNode;
}

export const ClipCopy = ({
  data,
  position = "right",
  size = "sm",
  truncate,
  className,
  title,
  children,
}: ClipCopyProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(data);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const textMap = {
    xs: "text-xs",
    sm: "text-[1.3rem] ",
    md: "text-[1.6rem]",
  };

  const positionMap = {
    right: "",
    "bottom-right": "absolute bottom-[-2px] right-[-2px]",
    "top-right":
      "absolute top-[5px] right-[5px] bg-white rounded-[.290rem] p-[.20rem]",
  };

  const iconStyles = cx(
    "transition-all leading-none text-2xl ml-2 hover:text-blue-300 cursor-pointer",
    copied ? "text-green-300 animate-click" : "text-blue-400 animate-opacity",
    textMap[size],
    positionMap[position],
  );

  const styles = cx("relative flex items-center", className);

  return (
    <span className={styles}>
      <span
        className={cx(
          "inline-block w-full",
          truncate ? "max-w-[350px] truncate" : "overflow-x-auto",
        )}
        title={title}
      >
        {children || data}
      </span>
      <span
        onClick={(e) => {
          e.stopPropagation();
          handleCopy();
        }}
      >
        <Icon name={copied ? "check" : "copy"} className={iconStyles} />
      </span>
    </span>
  );
};
