import { Overlay, OverlayHeader, OverlayModal } from "@components/ui/Overlay";
import { OverlayLoader } from "@components/ui/Overlay/OverlayLoader";
import { getEvent } from "@data/events";
import { useContextQuery } from "@hooks/useContextQuery";
import { EventBodyFlagCheck } from "@models/api";
import {
  getCheckStatus,
  getCheckStatusColor,
  getCheckStatusText,
} from "@modules/features/helpers";
import { CompanyCell } from "@ui/CompanyCell";
import { LabelData, LabelDataGrid } from "@ui/LabelData";
import { Pill } from "@ui/Pill";
import { UserInitials } from "@ui/UserInitials";
import { formatDate } from "@utils/date";

type FlagCheckOverlayProps = {
  eventId: string;
  onClose: () => void;
};

export const FlagCheckOverlay = ({
  eventId,
  onClose,
}: FlagCheckOverlayProps) => {
  const { isLoading, error, data } = useContextQuery({
    queryKey: [`event`, eventId],
    queryFn: () => getEvent(eventId),
  });

  if (error) throw error;

  const body = data?.body as EventBodyFlagCheck;
  const status = getCheckStatus(body);

  return (
    <Overlay onClose={onClose}>
      {isLoading || !data ? (
        <OverlayLoader />
      ) : (
        <OverlayModal position="right">
          <OverlayHeader title="Flag Check" onClose={onClose} />
          <div className="px-12 pt-8">
            <LabelDataGrid>
              <LabelData label="Timestamp">
                {formatDate(data.capturedAt)}
              </LabelData>
              <LabelData label="Evaluation">
                <span className="pr-1">
                  {body.value ? "🟢 True" : "🔴 False"}
                </span>
              </LabelData>
              {data.company && (
                <LabelData label="Company">
                  <CompanyCell company={data.company} />
                </LabelData>
              )}
              {data.user && (
                <LabelData label="User">
                  <UserInitials
                    name={data.user.name}
                    showName={true}
                    size="xs"
                  />
                </LabelData>
              )}
              <LabelData label="Status">
                <Pill color={getCheckStatusColor(status)}>
                  {getCheckStatusText(status)}
                </Pill>
              </LabelData>
              <LabelData label="Reason">{body.reason}</LabelData>
            </LabelDataGrid>
          </div>
        </OverlayModal>
      )}
    </Overlay>
  );
};
