import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { ViewWrapper } from "@ui/ViewWrapper";
import { useParams } from "react-router-dom";

import { EventOverlay } from "./EventOverlay";

export const EventView = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigateEnvironment();
  const onClose = () => {
    navigate("events");
  };

  return (
    <ViewWrapper>
      <EventOverlay id={id} onClose={onClose} />
    </ViewWrapper>
  );
};
