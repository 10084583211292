import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Icon } from "@ui/Icon";
import cx from "classnames";
import { ReactNode } from "react";

type DropdownLinkProps = {
  label: ReactNode | string;
  disabled?: boolean;
  onClick: () => void;
};

interface DropdownDotsProps {
  links: DropdownLinkProps[];
  size?: "sm" | "md";
}

export const DropdownDots = ({ links, size = "sm" }: DropdownDotsProps) => {
  const dotsSize = {
    sm: "px-[3px]",
    md: "px-[6px] h-[41px] items-center justify-center flex",
  };
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        asChild
        className="rounded-[10px] flex items-center cursor-pointer"
      >
        <button aria-label="Select action" className="">
          <Icon
            name="dots-vertical"
            className={cx(
              "hover:text-white hover:bg-blue-400 text-black text-2xl rounded-md border bg-white border-gray-300 shadow-sm",
              dotsSize[size],
            )}
          />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          side="bottom"
          align="end"
          className="bg-white px-6 py-4 shadow-md rounded-lg w-full mt-2 flex flex-col space-y-2 min-w-[200px] cursor-pointer !z-40"
        >
          <div className="space-y-4">
            {links.map((link, index) => {
              return (
                <DropdownMenu.Item
                  className="flex text-sm justify-between hover:opacity-70 data-[disabled]:pointer-events-none data-[disabled]:opacity-10"
                  key={index}
                  disabled={link.disabled}
                  onSelect={(event) => {
                    event.stopPropagation();
                    link.onClick();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <div className="text-lg mr-4">{link.label}</div>
                  </div>
                </DropdownMenu.Item>
              );
            })}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
