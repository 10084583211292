import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { Pill } from "@ui/Pill";

import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

interface PillGroupProps {
  limit?: number;
  enableLinks?: boolean;
  items: {
    id?: string;
    name: string;
  }[];
}

export const PillGroup = ({
  items,
  limit = 2,
  enableLinks = true,
}: PillGroupProps) => {
  const { environment } = useCurrentEnvironment();
  const navigate = useNavigate();
  const [showRemaining, setShowRemaining] = useState(false);

  return (
    <div className="flex items-center space-x-1.5">
      {items.map((item, index) => {
        const onPillClick = () => {
          // TODO: Add other id types if they are used with Pill Group
          // Currently only being used for features and plans
          const isFeature = item.id?.startsWith("feat_");
          const isPlan = item.id?.startsWith("plan_");

          isFeature && navigate(`/${environment?.id}/features/${item.id}`);
          isPlan && navigate(`/${environment?.id}/plans/${item.id}`);
        };

        const handlePillClick = (e: SyntheticEvent) => {
          e.stopPropagation();
          e.preventDefault();
          enableLinks && onPillClick();
        };

        return (
          (index < limit || showRemaining) && (
            <Pill
              className="group-hover:bg-gray-300 transition-all hover:!bg-gray-300 hover:cursor-pointer truncate-sm"
              key={item.name}
              text="normal"
              type="rounded"
              title={item.name}
              onClick={handlePillClick}
            >
              {item.name}
            </Pill>
          )
        );
      })}

      {!showRemaining && items.length > limit && (
        <Pill
          className="hover:bg-blue-400 transition-all hover:text-white hover:cursor-pointer"
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setShowRemaining(true);
          }}
          text="normal"
          type="rounded"
        >
          +{items.length - limit}
        </Pill>
      )}
    </div>
  );
};
