interface SidebarHeaderProps {
  title: string;
  description?: string;
  onClick?: () => void;
  actionLabel?: string;
}

export const SidebarHeader = ({
  title,
  description,
  onClick,
  actionLabel,
}: SidebarHeaderProps) => {
  return (
    <div className="mb-8">
      <div className="flex justify-between items-center">
        <div className="font-body text-[21px] leading-none">{title}</div>
        {onClick && (
          <div
            onClick={onClick}
            className="cursor-pointer hover:underline text-blue-400 text-sm leading-none "
          >
            {actionLabel ? actionLabel : "See all"}
          </div>
        )}
      </div>
      {description && (
        <div className="text-gray-400 mt-2 w-full text-sm">{description}</div>
      )}
    </div>
  );
};
