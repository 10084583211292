import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { Icon } from "@components/ui/Icon";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { CreateComponentRequestBody } from "@models/api";
import { ComponentType } from "@models/component";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { Error } from "@ui/Error";
import { FormColumn, FormRow } from "@ui/FormParts";
import { Overlay, OverlayHeader, OverlayModal } from "@ui/Overlay";
import cx from "classnames";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { createComponent } from "../../queries";

const validationSchema = Yup.object({
  name: Yup.string().required("Must provide a component name"),
  entityType: Yup.mixed<ComponentType>()
    .oneOf(Object.values(ComponentType))
    .required("Must select a component type"),
});

export interface ComponentCreateOverlayProps {
  onClose: () => void;
}

export const ComponentCreateOverlay = ({
  onClose,
}: ComponentCreateOverlayProps) => {
  // TODO: Add navigate to component view on submission
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    name: "",
    entityType: {},
    ast: {},
  } as CreateComponentRequestBody;

  const onSubmit = async (
    values: CreateComponentRequestBody,
    helpers: FormikHelpers<CreateComponentRequestBody>,
  ) => {
    setLoading(true);

    try {
      await createComponent(values);

      queryClient.invalidateQueries();
      setApiError(undefined);
      onClose();
      setLoading(false);
    } catch (err) {
      setApiError(errorMessage(err));
      setLoading(false);
      helpers.setSubmitting(false);
    }
  };

  const templates = [
    {
      value: ComponentType.Entitlement,
      title: "Customer Portal",
      description:
        " All in one. Let users upgrade/downgrade and view their plan, usage, and billing information.",
      image: "/builder/templates/customer-portal.png",
    },
    {
      value: ComponentType.Billing,
      title: "Blank",
      description: "Start from scratch. Use only the elements you need.",
      image: "/builder/templates/blank.png",
    },
  ];

  return (
    <Overlay
      onClose={onClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="lg">
        <Formik
          className="flex-1 w-full"
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ dirty, setFieldValue, values, errors }) => {
            return (
              <Form>
                <OverlayHeader
                  label="New component"
                  title="Define component"
                  description="Embed Schematic components to give your users a monetization experience that reports usage and limits, and enables upgrades."
                  onClose={onClose}
                />

                <FormColumn className="px-12">
                  <FormRow>
                    <div className="flex-1">
                      <FormikControl
                        control="input"
                        name="name"
                        type="text"
                        label="Name"
                        placeholder="Enter a component name"
                      />
                    </div>
                  </FormRow>

                  <FormRow>
                    <div className="flex flex-col space-y-2">
                      <div className="label-md">Choose template</div>
                      <div className="flex flex-col space-y-4">
                        {templates.map((template) => {
                          const active = values.entityType === template.value;
                          return (
                            <div
                              key={template.value}
                              className={cx(
                                "flex flex-row justify-start items-center relative cursor-pointer p-5 border rounded-lg transition",
                                active ? "border-blue-400 " : "border-gray-300",
                              )}
                              onClick={() =>
                                setFieldValue("entityType", template.value)
                              }
                            >
                              {active && (
                                <Icon
                                  name="check-rounded"
                                  className="text-2xl absolute right-2 leading-none top-2 text-blue-400"
                                />
                              )}

                              {template?.image && (
                                <div className="mr-8 min-w-[38%] max-w-[38%] shadow-sm rounded-lg overflow-hidden">
                                  <img
                                    src={template.image}
                                    className="w-full h-auto"
                                  />
                                </div>
                              )}

                              <div className="flex flex-col space-y-2 flex-1">
                                <div className="text-[1.25rem] leading-none">
                                  {template.title}
                                </div>
                                <div className="text-gray-400 text-sm">
                                  {template.description}
                                </div>
                              </div>
                              <div></div>
                            </div>
                          );
                        })}
                      </div>

                      {errors?.entityType && (
                        <Error>Must choose a template type</Error>
                      )}
                    </div>
                  </FormRow>

                  {apiError && (
                    <div className="px-2">
                      <Alert size="xs" style="red">
                        <div className="flex items-center justify-center space-x-2">
                          <div className="text-base font-body ">
                            <span className="font-semibold">Uh-oh!</span>{" "}
                            {apiError}
                          </div>
                        </div>
                      </Alert>
                    </div>
                  )}
                </FormColumn>

                <div className="flex mt-12 px-12 pb-12 items-end justify-end space-x-2">
                  <Button type="submit" color="blue" disabled={!dirty}>
                    Create component
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
