import { useUser } from "@clerk/clerk-react";
import { ClerkUserRole } from "@models/clerkUser";
import { OrganizationMembership } from "@modules/settings/components/tables/TeamTable";

export const useRole = (requiredRole: ClerkUserRole) => {
  const { isLoaded, user } = useUser();

  if (!isLoaded) return false;

  const membership = user?.organizationMemberships[0] as OrganizationMembership;

  if (membership?.role === "org:admin") return true;

  return membership?.role === requiredRole;
};
