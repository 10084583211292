import cx from "classnames";

export interface LinkProps {
  url?: string;
  label?: string;
}

interface LinksListProps {
  className?: string;
  label?: string;
  align?: "left" | "center";
  links: LinkProps[] | null;
}

export const LinksList = ({
  className,
  links,
  align = "left",
}: LinksListProps) => {
  const alignMap = {
    left: "flex items-center justify-start",
    center: "flex items-center justify-center",
  };
  const styles = cx("hidden space-y-0.5", className);

  return (
    <div className={styles}>
      {links &&
        links.map((l, index) => {
          return (
            <div className={alignMap[align]} key={index}>
              <span className="mr-2 text-blue-400 text-lg leading-none">•</span>
              <a
                href={l.url}
                target="_blank"
                className="text-blue-400 hover:underline hover:opacity-80"
              >
                {l.label}
              </a>
            </div>
          );
        })}
    </div>
  );
};
