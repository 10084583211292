import { PageBlankState } from "@components/ui/PageBlankState";

interface EventsBlankStateProps {
  type?: "fixed" | "static";
}
export const EventsBlankState = ({ type }: EventsBlankStateProps) => {
  return (
    <PageBlankState
      title="⏳ No data yet"
      type={type}
      description={
        <>
          Send events into Schematic to begin tracking and targeting
          <br />
          based on Companies, Users, and usage.
        </>
      }
    />
  );
};
