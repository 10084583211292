import { CompanyDetailResponseData } from "@models/api";
import { CompanyOverride } from "@models/entitlement";
import { PlanType } from "@models/plan";
import {
  CompanyDetails,
  CompanyFeaturesTable,
  CompanyOverridesTable,
  CompanyPlansTable,
} from "@modules/companies";
import { CompanyOverrideOverlay } from "@modules/features";
import { useState } from "react";
import { useOutletContext } from "react-router-dom";

export const CompanyEntitlementsTab = () => {
  const { company } = useOutletContext<{
    company: CompanyDetailResponseData;
  }>();
  const [companyOverrideOverlay, setCompanyOverrideOverlay] = useState(false);
  const [companyOverride, setCompanyOverride] = useState<CompanyOverride>();
  const onCompanyOverrideOverlayClose = () => {
    setCompanyOverride(undefined);
    setCompanyOverrideOverlay(false);
  };

  const openCompanyOverrideOverlay = (companyOverride?: CompanyOverride) => {
    if (companyOverride) setCompanyOverride(companyOverride);
    setCompanyOverrideOverlay(true);
  };

  return (
    <div className="space-y-8 pb-16">
      <CompanyDetails company={company} />
      <CompanyOverridesTable
        companyId={company.id}
        openOverlay={openCompanyOverrideOverlay}
      />
      <CompanyPlansTable companyId={company.id} planType={PlanType.Plan} />
      <CompanyPlansTable companyId={company.id} planType={PlanType.AddOn} />
      {companyOverrideOverlay && (
        <CompanyOverrideOverlay
          company={company}
          companyOverride={companyOverride}
          onClose={onCompanyOverrideOverlayClose}
        />
      )}
      <CompanyFeaturesTable company={company} />
    </div>
  );
};
