import { TableLoader } from "@components/loaders/TableLoader";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import useTablePagination from "@hooks/useTablePagination";
import { Feature } from "@models/feature";
import { FeatureUser } from "@models/usage";
import { CompanyUserSubview } from "@modules/companyUsers/types";
import {
  countFeatureUsers,
  listFeatureUsers,
  FeatureUsageFilter,
} from "@modules/features/queries/usage";
import { ColumnDef } from "@tanstack/react-table";
import { Logo } from "@ui/Logo";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import { UserInitials } from "@ui/UserInitials";

import moment from "moment";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";

import { FeatureUsersBlankState } from "../blank-states/FeatureUsersBlankState";

export interface FeatureUsersTableProps {
  feature: Feature;
}

export const FeatureUsersTable = ({ feature }: FeatureUsersTableProps) => {
  const featureId = feature.id;
  const navigate = useNavigateEnvironment();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<FeatureUsageFilter>({});
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? {} : { q: searchTerm });
  }, [searchTerm]);

  const columns = useMemo<ColumnDef<FeatureUser>[]>(
    () => [
      {
        id: "lastUsed",
        header: "Last Used",
        accessorKey: "lastUsed",
        cell: (cellInfo) => {
          const user = cellInfo.row.original.user;
          if (!user) return <></>;

          const lastUsedAt = user.lastSeenAt
            ? moment(user.lastSeenAt).fromNow()
            : "Never";
          return <div className="leading-none text-gray-400">{lastUsedAt}</div>;
        },
      },
      {
        id: "user",
        header: "User",
        accessorKey: "user",
        cell: (cellInfo) => {
          const user = cellInfo.row.original.user;
          if (!user) return <></>;

          return <UserInitials name={user.name} showName={true} size="xs" />;
        },
      },
      {
        id: "company",
        header: "Company",
        accessorKey: "company",
        cell: (cellInfo) => {
          const company = cellInfo.row.original.company;
          if (!company) return <></>;

          return (
            <div className="flex space-x-2 items-center">
              <div className="flex space-x-2 items-center">
                <Logo src={company.logoUrl} size="sm" alt={company.name} />
                <div>{company.name}</div>
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  const getHeaderText = (count: number) => {
    return pluralize("User", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<FeatureUser, FeatureUsageFilter>(
    ["feature-users", featureId, searchTerm],
    listFeatureUsers(featureId),
    countFeatureUsers(featureId),
    filter,
    getHeaderText,
  );

  const onRowClick = (row: FeatureUser) => {
    if (!row.user) return;

    navigate(`users/${row.user.id}/${CompanyUserSubview.Overview}`);
  };

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noFeatureUsers = countQuery?.data?.count === 0;
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderTable = () => {
    switch (true) {
      case noFeatureUsers:
        return <FeatureUsersBlankState feature={feature} />;
      case loading:
        return <TableLoader />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              onRowClick={onRowClick}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  return (
    <>
      {!noFeatureUsers && (
        <TableHeader
          headerText={headerText}
          onSearch={handleSearch}
          searchPlaceholder={!noFeatureUsers ? "Search users" : ""}
        />
      )}
      {renderTable()}
    </>
  );
};
