import { AccountEnvironment } from "@models/account";
import {
  CreateEnvironmentRequestBody,
  UpdateEnvironmentRequestBody,
} from "@models/api";

import { Delete } from "@models/delete";
import { deleteReq, get, post, put } from "./index";

export const createEnvironment = async (
  req: CreateEnvironmentRequestBody,
): Promise<AccountEnvironment> => {
  return post<AccountEnvironment, CreateEnvironmentRequestBody>(
    "environments",
    req,
  );
};

export const deleteEnvironment = async (id: string): Promise<Delete> => {
  return deleteReq(`environments/${id}`);
};

export const getEnvironment = async (
  id: string,
): Promise<AccountEnvironment> => {
  return get(`environments/${id}`);
};

export const listEnvironments = async (
  flt?: object,
): Promise<AccountEnvironment[]> => {
  return get<AccountEnvironment[], object>("environments", flt);
};

export const updateEnvironment = async (
  id: string,
  req: UpdateEnvironmentRequestBody,
): Promise<AccountEnvironment> => {
  return put<AccountEnvironment, UpdateEnvironmentRequestBody>(
    `environments/${id}`,
    req,
  );
};
