import { EnvironmentOverlayForm } from "@components/overlays/EnvironmentOverlayForm";
import { environmentPillColors } from "@components/overlays/types";
import { EnvironmentTypeShort } from "@consts/environments";
import * as api from "@data/environments";
import { useRole } from "@hooks/useRole";
import { AccountEnvironment } from "@models/account";
import { ClerkUserRole } from "@models/clerkUser";
import { Button } from "@ui/Button";
import { Icon } from "@ui/Icon";
import { Overlay } from "@ui/Overlay";
import { Pill, PillStyleTypes } from "@ui/Pill";

import cx from "classnames";
import { useState } from "react";
import SchematicOverlayLoader from "../loaders/SchematicOverlayLoader";

export interface EnvironmentOverlayProps {
  currentEnvironment?: AccountEnvironment;
  environments: AccountEnvironment[];
  onClose: () => void;
  setCurrentEnvironment: (environment: AccountEnvironment) => void;
}

export const EnvironmentOverlay = ({
  currentEnvironment,
  environments,
  onClose,
  setCurrentEnvironment,
}: EnvironmentOverlayProps) => {
  const [selectedEnvironment, setSelectedEnvironment] = useState<
    AccountEnvironment | undefined
  >(currentEnvironment);
  const [loading, setLoading] = useState(false);
  const environmentEditAllowed = useRole(ClerkUserRole.Admin);

  const handleCreateEnvironment = () => {
    setSelectedEnvironment(undefined);
  };

  const handleDelete = async (environment: AccountEnvironment) => {
    await api.deleteEnvironment(environment.id);

    setSelectedEnvironment(currentEnvironment);
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex  items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      <div className="relative bg-white rounded-lg overflow-hidden shadow-lg min-w-[900px] max-w-[900px]">
        <div className="p-8 border-b border-b-gray-300 flex justify-between flex-1">
          <div className="flex items-center space-x-4">
            <div className="text-2xl flex-1 w-full">Manage environments</div>
          </div>
          <span
            onClick={onClose}
            className="opacity-60 hover:opacity-100 cursor-pointer"
          >
            <Icon name="close" className="text-2xl" />
          </span>
        </div>
        <div className="flex h-full flex-1 max-h-full overflow-hidden">
          <div className="flex flex-col  bg-gray-100 py-6 space-y-8 min-w-[300px]">
            <div className="px-8 w-full flex text-center">
              <Button
                onClick={handleCreateEnvironment}
                disabled={!environmentEditAllowed}
                className="w-full cursor-pointer"
              >
                New environment
              </Button>
            </div>

            {environments.map((env) => {
              const styles = cx(
                "px-8 border-l-[3px] cursor-pointer font-medium",
                env.id == selectedEnvironment?.id
                  ? "border-l-blue-400"
                  : "border-l-transparent",
              );

              return (
                <div
                  className={styles}
                  key={env.id}
                  onClick={() => setSelectedEnvironment(env)}
                >
                  <div className="flex justify-between items-center w-full">
                    <span
                      className={cx(
                        env.id == selectedEnvironment?.id ? "font-bold" : "",
                      )}
                    >
                      {env.name}
                    </span>
                    <Pill
                      color={
                        environmentPillColors[
                          env.environmentType
                        ] as PillStyleTypes
                      }
                    >
                      {EnvironmentTypeShort[env.environmentType]}
                    </Pill>
                  </div>
                </div>
              );
            })}
          </div>

          <EnvironmentOverlayForm
            currentEnvironment={currentEnvironment}
            environment={selectedEnvironment}
            onDelete={handleDelete}
            setCurrentEnvironment={setCurrentEnvironment}
            setEnvironment={setSelectedEnvironment}
            setLoading={setLoading}
            onClose={onClose}
          />
        </div>
      </div>
    </Overlay>
  );
};
