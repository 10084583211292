import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import {
  UpcomingBill as EmbedUpcomingBill,
  type EmbedContextProps,
  type UpcomingBillProps,
} from "@schematichq/schematic-react";
import { titlecase } from "@utils/strings";
import { useMemo } from "react";
import * as Settings from "../../controls/Sidebar/Settings";
import { Select, TextInput } from "../../ui";

type FontStyle = keyof EmbedContextProps["settings"]["theme"]["typography"];
type ThemeSettings = EmbedContextProps["settings"]["theme"];

export const UpcomingBill: UserComponent = () => {
  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as UpcomingBillProps,
  }));

  return (
    <EmbedUpcomingBill
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      {...props}
    />
  );
};

const UpcomingBillSettings = () => {
  const { theme } = useEditor((state) => {
    return {
      theme: state.nodes.ROOT.data.props.settings.theme as ThemeSettings,
    };
  });

  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as UpcomingBillProps,
  }));

  const typographyOptions: { value: FontStyle; label: string }[] = useMemo(
    () => [
      ...Object.keys(theme.typography).map((key) => ({
        value: key as FontStyle,
        label: titlecase(key),
      })),
    ],
    [theme.typography],
  );

  return (
    <Settings.Root title="🧾 Upcoming Bill" category="Billing">
      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.header.isVisible}
          onVibibilityChange={() => {
            setProp((props: UpcomingBillProps) => {
              const updated = !props.header.isVisible;
              props.header.isVisible = updated;
            });
          }}
        >
          Header
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: UpcomingBillProps) => {
                props.header.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Prefix">
          <TextInput
            className="w-36"
            textAlign="left"
            value={nodeProps.header.prefix}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProp((props: UpcomingBillProps) => {
                const updated = event.target.value;
                props.header.prefix = updated;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.price.isVisible}
          onVibibilityChange={() => {
            setProp((props: UpcomingBillProps) => {
              const updated = !props.price.isVisible;
              props.price.isVisible = updated;
            });
          }}
        >
          Price
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            defaultValue={typographyOptions.find(
              (opt) => opt.value === nodeProps.price.fontStyle,
            )}
            onChange={(option) => {
              setProp((props: UpcomingBillProps) => {
                props.price.fontStyle = option!.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.contractEndDate.isVisible}
          onVibibilityChange={() => {
            setProp((props: UpcomingBillProps) => {
              const updated = !props.contractEndDate.isVisible;
              props.contractEndDate.isVisible = updated;
            });
          }}
        >
          Contract End Date
        </Settings.Header>

        <Settings.Input label="Font Style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.contractEndDate.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.contractEndDate.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: UpcomingBillProps) => {
                props.contractEndDate.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Prefix">
          <TextInput
            className="w-36"
            textAlign="left"
            value={nodeProps.contractEndDate.prefix}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProp((props: UpcomingBillProps) => {
                const updated = event.target.value;
                props.contractEndDate.prefix = updated;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

UpcomingBill.craft = {
  displayName: "Upcoming Bill",
  props: {
    header: {
      isVisible: true,
      fontStyle: "heading4",
      prefix: "Next bill due",
    },
    price: {
      isVisible: true,
      fontStyle: "heading1",
    },
    contractEndDate: {
      isVisible: true,
      fontStyle: "heading6",
      prefix: "Contract ends",
    },
  } satisfies UpcomingBillProps,
  related: {
    settings: UpcomingBillSettings,
  },
};
