export const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[calc(100vh-81px)]">
      <div className="flex flex-col justify-center items-center space-y-3 mb-16">
        <div className="text-[7.5rem] leading-none font-medium font-sans ">
          404
        </div>
        <div className="text-3xl">Something went wrong</div>

        <div className="text-center">
          The address/page you requested may <br /> have been mistyped or moved
          to another place
        </div>
      </div>
    </div>
  );
};
