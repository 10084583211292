import { TableLoader } from "@components/loaders/TableLoader";
import { ClipCopy } from "@components/ui/ClipCopy";
import { Pill } from "@components/ui/Pill";
import { usePermission } from "@hooks/usePermission";
import useTablePagination from "@hooks/useTablePagination";
import { ListPlanEntitlementsParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { PlanEntitlement } from "@models/entitlement";
import { EntitlementCell } from "@modules/features";
import { FeatureTypeCell } from "@modules/features/components/FeatureTypeCell";
import { FeatureSubview } from "@modules/features/types";
import { PlanEntitlementDeleteOverlay } from "@modules/plans";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { Button, ButtonProps } from "@ui/Button";
import { DropdownDots } from "@ui/DropdownDots";
import { IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import {
  countPlanEntitlements,
  deletePlanEntitlement,
  listPlanEntitlements,
} from "../../queries";
import { PlanEntitlementsBlankState } from "../blank-states/PlanEntitlementsBlankState";

export interface PlanEntitlementsTableProps {
  planId: string;
  openAddOverlay: () => void;
  openEditOverlay: (planEntitlement?: PlanEntitlement) => void;
}

export const PlanEntitlementsTable = ({
  planId,
  openAddOverlay,
  openEditOverlay,
}: PlanEntitlementsTableProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListPlanEntitlementsParams>({ planId });
  const [loading, setLoading] = useState(false);
  const [deleteEntitlement, setDeleteEntitlement] = useState<
    PlanEntitlement | undefined
  >();
  const [successAlert, setSuccessAlert] = useState("");
  const planEntitlementEditAllowed = usePermission(
    ClerkUserPermission.plan_entitlements_edit,
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<PlanEntitlement>[]>(
    () => [
      {
        id: "feature",
        header: "Feature",
        accessorKey: "feature.name",
        cell: (cellInfo) => {
          const { feature } = cellInfo.row.original;
          if (!feature) return <></>;

          return (
            <div className="flex flex-row items-center">
              <IconRound name={feature.icon as IconNameTypes} size="sm" />
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium mb-1">
                  {feature.name}
                </div>

                <Pill color="gray" type="tag" text="code" className="lowercase">
                  <ClipCopy
                    data={feature.id}
                    className="leading-[1rem]"
                    size="sm"
                  />
                </Pill>
              </div>
            </div>
          );
        },
      },
      {
        id: "entitlement",
        header: "Entitlement",
        accessorKey: "value",
        cell: (cellInfo) => {
          return <EntitlementCell entitlement={cellInfo.row.original} />;
        },
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "valueType",
        cell: (cellInfo) => {
          const { feature } = cellInfo.row.original;
          if (!feature) return <></>;

          return <FeatureTypeCell featureType={feature.featureType} />;
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const entitlement = cellInfo.row.original;
          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Delete",
                    disabled: !planEntitlementEditAllowed,
                    onClick: () => {
                      setSuccessAlert("");
                      setDeleteEntitlement(entitlement);
                    },
                  },
                  {
                    label: "Edit",
                    disabled: !planEntitlementEditAllowed,
                    onClick: () => {
                      setSuccessAlert("");
                      openEditOverlay(entitlement);
                    },
                  },
                  {
                    label: "View feature",
                    onClick: () => {
                      navigate(
                        `../../features/${entitlement.featureId}/${FeatureSubview.Entitlements}`,
                      );
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    [openEditOverlay, navigate, planEntitlementEditAllowed],
  );

  const onDelete = async () => {
    if (!deleteEntitlement) return;

    setLoading(true);

    try {
      await deletePlanEntitlement(deleteEntitlement.id);

      await queryClient.invalidateQueries();
      setSuccessAlert("Entitlement deleted successfully");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter({ planId, ...(searchTerm ? { q: searchTerm } : {}) });
  }, [planId, searchTerm]);

  const getHeaderText = (count: number) => {
    return pluralize("Entitlement", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<PlanEntitlement, ListPlanEntitlementsParams>(
    ["plan-entitlements"],
    listPlanEntitlements,
    countPlanEntitlements,
    filter,
    getHeaderText,
  );

  const onRowClick = (row: PlanEntitlement) => {
    openEditOverlay(row);
  };

  const tableButtons: ButtonProps[] = [
    {
      children: <>Add entitlement</>,
      color: "blue",
      disabled: !planEntitlementEditAllowed,
      onClick: () => openAddOverlay(),
    },
  ];

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noPlanEntitlements = countQuery?.data?.count === 0;
  const isLoading =
    loading || listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderPlanEntitlementsTable = () => {
    if (noPlanEntitlements) {
      return (
        <PlanEntitlementsBlankState
          onCreate={openAddOverlay}
          disabled={!planEntitlementEditAllowed}
        />
      );
    }

    if (isLoading) {
      return <TableLoader />;
    }

    return (
      listQuery?.data && (
        <Table
          columns={columns}
          data={listQuery.data}
          onRowClick={planEntitlementEditAllowed ? onRowClick : undefined}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
        />
      )
    );
  };

  return (
    <div className="pb-16">
      {successAlert && (
        <div className="mb-4">
          <Alert style="green" size="xs" className="flex">
            {successAlert}
          </Alert>
        </div>
      )}

      {!noPlanEntitlements && (
        <TableHeader
          headerText={headerText}
          searchPlaceholder={"Find entitlement"}
          onSearch={handleSearch}
        />
      )}

      {renderPlanEntitlementsTable()}

      {!noPlanEntitlements && (
        <div className="mt-6 flex flex-row space-x-4">
          {tableButtons.map((b, index) => (
            <Button
              key={index}
              color={b.color}
              onClick={b.onClick}
              disabled={b.disabled}
            >
              {b.children}
            </Button>
          ))}
        </div>
      )}

      {deleteEntitlement &&
        createPortal(
          <PlanEntitlementDeleteOverlay
            entitlement={deleteEntitlement}
            onClose={() => setDeleteEntitlement(undefined)}
            onDelete={onDelete}
          />,
          document.body,
        )}
    </div>
  );
};
