import cx from "classnames";

import { DiamondStyleTypes, DiamondSizeTypes } from "./types";

export interface DiamondProps {
  style?: DiamondStyleTypes;
  size?: DiamondSizeTypes;
  className?: string;
}

export const Diamond = ({
  style = "gray",
  size = "md",
  className,
}: DiamondProps) => {
  const sizeMap = {
    xs: "h-4 w-4",
    sm: "h-5 w-5",
    md: "h-7 w-7",
    lg: "h-10 w-10",
  };

  const styleMap = {
    blue: "from-sky-200 to-blue-600",
    yellow: "from-yellow-200 to-pink-400",
    green: "from-green-200 to-purple-400",
    gray: "from-gray-300 to-gray-400",
    red: "from-red-500 to-red-300",
    purple: "from-purple-400 to-pink-500 to-red-500",
    cyan: "from-cyan-400 to-sky-500 to-blue-500",
    indigo: "from-indigo-500 to-purple-500 to-pink-500",
    pink: "from-pink-300 to-fuchsia-500 to-purple-700",
    lime: "from-lime-300 to-teal-400 to-cyan-600",
    amber: "from-amber-300 to-orange-400 to-red-500",
    teal: "from-teal-300 to-cyan-400 to-light-blue-500",
    fuchsia: "from-fuchsia-300 to-pink-400 to-red-500",
    rose: "from-rose-300 to-pink-400 to-amber-500",
    emerald: "from-emerald-300 to-green-400 to-teal-500",
    violet: "from-violet-400 to-indigo-500 to-blue-600",
    sky: "from-sky-300 to-blue-400 to-indigo-500",
    orange: "from-orange-300 to-yellow-400 to-amber-500",
    slate: "from-slate-300 to-gray-400 to-zinc-500",
    warmGray: "from-gray-100 to-gray-400 to-cool-gray-500",
    lightBlue: "from-sky-100 to-sky-400 to-cyan-500",
    trueGray: "from-gray-100 via-gray-400 to-gray-600 to-blue-gray-500",
    blueGray: "from-blue-200 to-blue-300 to-blue-400",
    redOrange: "from-red-400 to-orange-500 to-yellow-600",
    blueGreen: "from-blue-400 to-teal-500 to-green-600",
  };

  const diamondStyles = cx(
    "bg-gradient-to-r w-full h-full rotate-45 rounded-[6px]",
    styleMap[style],
  );

  const styles = cx(sizeMap[size], className);

  return (
    <div className={styles}>
      <div className={diamondStyles} />
    </div>
  );
};

export type { DiamondSizeTypes, DiamondStyleTypes };
