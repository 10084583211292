import { useUser } from "@clerk/clerk-react";
import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { LabeledTooltip } from "@components/ui/LabeledTooltip";
import * as api from "@data/flags";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import { FeatureFlag, FeatureFlagReq, FlagType } from "@models/feature";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { Select } from "@ui/Select";

import { Formik, Form, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import * as Yup from "yup";
import { listClerkOrganizationUsers } from "../../queries/users";

const validationSchema = Yup.object({
  description: Yup.string().max(
    1024,
    "Description must be at most 1024 characters long",
  ),
  flagType: Yup.string().required("Must provide a flagType"),
  key: Yup.string()
    .required("Must provide a key")
    .matches(
      /^[A-Za-z0-9._-]+$/,
      "Key must contain only letters, numbers, ., _ or -",
    )
    .max(255, "Key must be at most 255 characters long")
    .min(1, "Key must be at least 1 character long"),
  name: Yup.string()
    .required("Must provide a name")
    .max(512, "Name must be at most 512 characters long")
    .min(1, "Name must be at least 1 character long"),
  maintainerClerkId: Yup.string().required("Required"),
});

export interface FeatureFlagOverlayProps {
  flag?: FeatureFlag;
  onClose: () => void;
}

export const FeatureFlagOverlay = ({
  flag,
  onClose,
}: FeatureFlagOverlayProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();
  const { user } = useUser();
  const [maintainer] = useState(flag?.maintainer || user);

  const initialValues = {
    defaultValue: flag?.defaultValue || false,
    description: flag?.description || "",
    featureId: flag?.featureId,
    flagType: flag?.flagType || "boolean",
    key: flag?.key || "",
    name: flag?.name || "",
    maintainerClerkId: maintainer?.id || null,
  } as FeatureFlagReq;

  const onSubmit = (
    values: FeatureFlagReq,
    helpers: FormikHelpers<FeatureFlagReq>,
  ) => {
    setLoading(true);
    const saveFn = flag?.id
      ? (values: FeatureFlagReq) => api.updateFeatureFlag(flag.id, values)
      : api.createFeatureFlag;

    const onSuccessFn = flag?.id
      ? onClose
      : (createdFlag: FeatureFlag) => navigate(createdFlag.id);

    saveFn(values)
      .then((flag: FeatureFlag) => {
        queryClient.invalidateQueries();
        onSuccessFn(flag);
        setApiError(undefined);
        helpers.setSubmitting(false);
        setLoading(false);
      })
      .catch((error) => {
        setApiError(errorMessage(error));
        helpers.setSubmitting(false);
        setLoading(false);
      });
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      <OverlayModal>
        <LabeledTooltip
          label="All Environments"
          description="Flags exist in all environments"
          className="!top-8 !right-14"
          position="absolute"
          placement="bottom-center"
        />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values, touched }) => {
            return (
              <Form className="flex flex-col">
                <div className="flex h-full flex-1 max-h-full overflow-hidden relative">
                  <div
                    onClick={onClose}
                    className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
                  >
                    <Icon name="close" className="text-3xl" />
                  </div>

                  <div className="flex flex-col flex-1 px-12 py-12 h-full min-h-[632px] max-h-[750px]  max-w-full overflow-hidden overflow-y-scroll">
                    <div className="flex-1 overflow-y-auto">
                      <FormHeader
                        label="Flag"
                        title={flag?.id ? flag.name : "Create flag"}
                        compact
                      />
                      <FormColumn>
                        <FormikControl
                          control="controlled-input"
                          name="name"
                          type="text"
                          value={values.name}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            if (initialValues.key || touched.key) {
                              return;
                            }
                            const key = slugify(event.target.value, {
                              lower: true,
                            });

                            setFieldValue("key", key);
                          }}
                          label="Name"
                          placeholder="Name"
                        />
                        <FormRow>
                          <div className="w-full">
                            <FormikControl
                              control="input"
                              description="Keys are case-insensitive. Keys must only contain letters, numbers, ., _ or -."
                              label="Key"
                              name="key"
                              placeholder="Flag key"
                              type="text"
                            />
                          </div>
                        </FormRow>

                        <FormikControl
                          control="input"
                          description="Optionally add a note, link, or description of this flag"
                          label="Description"
                          name="description"
                          placeholder="Description"
                          type="text"
                        />

                        <FormikAsyncSelect
                          defaultOptions
                          label="Select maintainer user"
                          loadOptions={listClerkOrganizationUsers}
                          loadOptionsMappers={{
                            mapperFunction: (maintainer) => ({
                              value: maintainer.userId,
                              label: maintainer.identifier,
                            }),
                          }}
                          name="maintainerClerkId"
                          selectedOption={
                            maintainer && {
                              value: maintainer.id,
                              label: maintainer.emailAddresses[0].emailAddress,
                            }
                          }
                        />

                        <FormRow>
                          <FormikSelect
                            label="Flag variations"
                            name="flagType"
                            disabled
                            options={[
                              {
                                value: FlagType.Boolean,
                                label: "Boolean",
                              },
                            ]}
                          />
                        </FormRow>

                        <FormRow>
                          <Select
                            label="Variation 1"
                            name="variationOne"
                            disabled
                            selectedOption={{
                              value: true,
                              label: (
                                <span className="inline-flex items-center">
                                  <span className="mr-2 inline-block text-xs">
                                    🟢
                                  </span>{" "}
                                  False
                                </span>
                              ),
                            }}
                            options={[
                              {
                                value: true,
                                label: (
                                  <span className="inline-flex items-center">
                                    <span className="mr-2 inline-block text-xs">
                                      🟢
                                    </span>{" "}
                                    True
                                  </span>
                                ),
                              },
                              {
                                value: false,
                                label: (
                                  <span className="inline-flex items-center">
                                    <span className="mr-2 inline-block text-xs">
                                      🔴
                                    </span>{" "}
                                    False
                                  </span>
                                ),
                              },
                            ]}
                          />

                          <Select
                            label="Variation 2"
                            name="variationTwo"
                            disabled
                            options={[
                              {
                                value: true,
                                label: (
                                  <span className="inline-flex items-center">
                                    <span className="mr-2 inline-block text-xs">
                                      🟢
                                    </span>{" "}
                                    True
                                  </span>
                                ),
                              },
                              {
                                value: false,
                                label: (
                                  <span className="inline-flex items-center">
                                    <span className="mr-2 inline-block text-xs">
                                      🔴
                                    </span>{" "}
                                    False
                                  </span>
                                ),
                              },
                            ]}
                            selectedOption={{
                              value: false,
                              label: (
                                <span className="inline-flex items-center">
                                  <span className="mr-2 inline-block text-xs">
                                    🔴
                                  </span>{" "}
                                  False
                                </span>
                              ),
                            }}
                          />
                        </FormRow>

                        {apiError && (
                          <div className="px-2">
                            <Alert size="xs" style="red">
                              <div className="flex items-center justify-center space-x-2">
                                <div className="text-base font-body ">
                                  <span className="font-semibold">Uh-oh!</span>{" "}
                                  {apiError}
                                </div>
                              </div>
                            </Alert>
                          </div>
                        )}
                      </FormColumn>
                    </div>
                    <div className="flex mt-6 items-end justify-end space-x-2">
                      <button
                        className="button button-sm button-blue"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
