import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { PlanGroupPlanDetailResponseData } from "@models/api";
import { EntitlementCell } from "@modules/features";
import { PriceInfoBlock } from "@modules/plans/components/PriceInfoBlock";
import { PlanSubview } from "@modules/plans/types";
import { Button } from "@ui/Button";
import { Icon, IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { PlanLabel } from "@ui/PlanLabel";
import pluralize from "pluralize";
import React from "react";
import { Link } from "react-router-dom";

type PlanConfigurationCardProps = {
  plan: PlanGroupPlanDetailResponseData;
};

export const PlanConfigurationCard = ({ plan }: PlanConfigurationCardProps) => {
  const navigate = useNavigateEnvironment();

  const onAddEnvironmentButtonClick = () => {
    navigate(`plans/${plan.id}/${PlanSubview.Entitlements}`);
  };

  return (
    <div className="min-h-80 min-w-60 bg-gray-100 flex flex-col p-6 border border-blue-100 rounded-lg relative">
      <div className="h-1/3 flex flex-col space-y-1 border-b border-blue-100">
        <PlanLabel plan={plan} />

        <div className="text-sm text-gray-600">
          {pluralize("Company", plan.companyCount, true)}
        </div>
        <div className="text-sm text-gray-600">
          {plan.billingProduct && (
            <div className="flex items-center">
              <Icon
                name="stripe"
                className="w-5 h-5 text-blue-400 border border-blue-100 rounded-full text-center inline-table mr-1"
              />
              <PriceInfoBlock
                planId={plan.id}
                items={plan.billingProduct.prices}
              />
              {!plan.monthlyPrice && !plan.yearlyPrice && (
                <div className="bg-red-400 text-white p-1 rounded-xl">
                  No default price on product
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {plan.entitlements?.length === 0 && (
        <div className="flex flex-col pt-2 space-y-2">
          <div className="text-sm">No entitlement yet</div>
          <Button
            color="white"
            className="text-sm"
            onClick={onAddEnvironmentButtonClick}
          >
            Add Entitlements
          </Button>
        </div>
      )}
      {plan.entitlements?.length > 0 && (
        <div className="flex flex-col pt-2 space-y-2">
          {plan.entitlements.map((entitlement, index) => (
            <React.Fragment key={entitlement.id}>
              {index < 3 && (
                <div className="flex">
                  <IconRound
                    name={entitlement.feature!.icon as IconNameTypes}
                    size="sm"
                  />
                  <EntitlementCell
                    entitlement={entitlement}
                    showSmallText={false}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
          {plan.entitlements?.length > 3 && (
            <Link
              to={`../plans/${plan.id}/${PlanSubview.Entitlements}`}
              className="pl-2 text-blue-400 hover:text-blue-800"
            >
              + {plan.entitlements.length - 3} More
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
