import { listBillingProductPrices, listBillingProducts } from "@data/billing";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import {
  BillingPriceResponseData,
  BillingProductResponseData,
} from "@models/api";
import { Plan } from "@models/plan";
import { PriceOptionLabel } from "@modules/plans/components/overlays/PlanEditOverlay/PriceOptionLabel";
import { getAudience } from "@modules/plans/queries";
import { EnvironmentCell } from "@modules/settings/components/EnvironmentCell";
import { Alert } from "@ui/Alert";
import { BillingProductOptionLabel } from "@ui/BillingProductOptionLabel";
import { FormColumn, FormHeader } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { useFormikContext } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";

type PlanEditBillingStepProps = {
  verb: string;
  apiError?: string;
  planId?: string;
};

type BillingProductOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingProductResponseData;
};

type PriceOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingPriceResponseData;
};

export const PlanEditBillingStep = ({
  verb,
  apiError,
  planId,
}: PlanEditBillingStepProps) => {
  const {
    setFieldValue,
    values: { billingProduct, monthlyPrice, yearlyPrice },
  } = useFormikContext<Plan>();

  const { environment } = useCurrentEnvironment();

  const { data: audienceData, isLoading: audienceIsLoading } = useContextQuery({
    queryKey: ["plan", planId, "audience"],
    queryFn: async () => {
      if (!planId) return null;

      try {
        return await getAudience(planId);
      } catch (error: any) {
        if (error.responseCode === 404) {
          return false;
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error.responseCode === 404) {
        return false;
      }

      return failureCount < 3;
    },
    enabled: !!planId,
  });

  const [audienceWillChange, setAudienceWillChange] = useState(false);

  useEffect(() => {
    if (audienceIsLoading || !audienceData) {
      setAudienceWillChange(false);
      return;
    }

    if (billingProduct) {
      setAudienceWillChange(
        audienceData.conditionGroups.length > 0 ||
          audienceData.conditions.length != 1 ||
          audienceData.conditions[0].conditionType != "billing_product" ||
          audienceData.conditions[0].resourceIds.length != 1 ||
          audienceData.conditions[0].resourceIds[0] !=
            billingProduct.productId ||
          audienceData.conditions[0].operator != "eq",
      );
    } else {
      setAudienceWillChange(
        audienceData.conditionGroups.length > 0 ||
          audienceData.conditions.length > 0,
      );
    }
  }, [audienceData, audienceIsLoading, billingProduct]);

  return (
    <>
      <FormHeader
        label={`${verb} plan`}
        title="Sync plan with billing system"
        description="Required for use with components"
      />

      <FormColumn>
        <Alert size="xs" style="yellow" className="flex space-x-8 px-8">
          <div>
            This plan's audience will be synchronized with a billing product on
            this environment only.
          </div>

          {environment && (
            <EnvironmentCell
              environment={environment}
              className="flex justify-center"
            />
          )}
        </Alert>
        {audienceWillChange && (
          <Alert size="xs" style="yellow" className="flex">
            <div className="mr-3">
              <Icon
                name="exclamation-rounded-filled"
                className="text-2xl leading-none text-yellow-300"
              />
            </div>

            <div>
              Changing the billing product will overwrite the currently
              configured audience for this plan.
            </div>
          </Alert>
        )}
        <div>
          <div className="flex justify-between">
            <div>
              <Icon
                name="stripe"
                className="w-8 h-8 text-blue-400 text-xl border rounded-full text-center inline-table m-2 ml-0"
              />

              <span className="font-medium text-xl">
                Sync with Stripe Product
              </span>
            </div>
          </div>
          <div>
            Companies that have this product in Stripe will belong to this plan
            in Schematic.{" "}
            <Link
              to="https://docs.schematichq.com/integrations/stripe"
              target="_blank"
              className="text-blue-400"
            >
              Learn more.
            </Link>
          </div>
        </div>
        <FormikAsyncSelect
          className="flex-1"
          defaultOptions
          label="Stripe product"
          loadOptions={listBillingProducts}
          loadOptionsMappers={{
            requestFilter: {
              limit: 10,
            },
            mapperFunction: (
              product: BillingProductResponseData,
            ): BillingProductOption => ({
              name: product.name,
              value: product.productId,
              label: <BillingProductOptionLabel product={product} />,
              resource: product,
            }),
          }}
          name="billingProductId"
          placeholder="Type to select Stripe product..."
          onChange={async (option: BillingProductOption) => {
            await setFieldValue("billingProduct", option.resource);
            await setFieldValue("monthlyPrice", null);
            await setFieldValue("monthlyPriceId", null);
            await setFieldValue("yearlyPrice", null);
            await setFieldValue("yearlyPriceId", null);
          }}
          selectedOption={
            billingProduct && {
              label: <BillingProductOptionLabel product={billingProduct} />,
              name: billingProduct?.name,
              resource: billingProduct,
              value: billingProduct.productId,
            }
          }
        />
        {billingProduct && (
          <>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="font-medium text-xl">Define prices</span>
              </div>
              <div>
                These prices will be shown in components. If you do not select a
                price, the plan will be shown as free of charge (e.g. free
                plan/trial).{" "}
                <Link
                  to="https://docs.schematichq.com/components/overview"
                  target="_blank"
                  className="text-blue-400"
                >
                  {" "}
                  Learn more.
                </Link>
              </div>
            </div>
            <div className="flex space-x-4">
              <FormikAsyncSelect
                key={`monthly-price-${billingProduct.productId}`}
                className="flex-1"
                defaultOptions
                label="Default monthly price"
                description="Optional"
                loadOptions={listBillingProductPrices}
                loadOptionsMappers={{
                  requestFilter: {
                    billingProductId: billingProduct.productId,
                    limit: 10,
                  },
                  resultsFilter: (price) => price.interval === "month",
                  mapperFunction: (
                    price: BillingPriceResponseData,
                  ): PriceOption => ({
                    name: price.price.toString(),
                    value: price.id,
                    label: <PriceOptionLabel price={price} />,
                    resource: price,
                  }),
                }}
                name="monthlyPriceId"
                placeholder="Select price"
                onChange={async (option: PriceOption) => {
                  await setFieldValue("monthlyPrice", option.resource);
                }}
                selectedOption={
                  monthlyPrice && {
                    name: monthlyPrice.price.toString(),
                    value: monthlyPrice.id,
                    label: <PriceOptionLabel price={monthlyPrice} />,
                    resource: monthlyPrice,
                  }
                }
              />

              <FormikAsyncSelect
                key={`yearly-price-${billingProduct.productId}`}
                className="flex-1"
                defaultOptions
                label="Default yearly price"
                description="Optional"
                loadOptions={listBillingProductPrices}
                loadOptionsMappers={{
                  requestFilter: {
                    billingProductId: billingProduct.productId,
                    limit: 10,
                  },
                  resultsFilter: (price) => price.interval === "year",
                  mapperFunction: (
                    price: BillingPriceResponseData,
                  ): PriceOption => ({
                    name: price.price.toString(),
                    value: price.id,
                    label: <PriceOptionLabel price={price} />,
                    resource: price,
                  }),
                }}
                name="yearlyPriceId"
                placeholder="Select price"
                onChange={async (option: PriceOption) => {
                  await setFieldValue("yearlyPrice", option.resource);
                }}
                selectedOption={
                  yearlyPrice && {
                    name: yearlyPrice.price.toString(),
                    value: yearlyPrice.id,
                    label: <PriceOptionLabel price={yearlyPrice} />,
                    resource: yearlyPrice,
                  }
                }
              />
            </div>
          </>
        )}

        {apiError && (
          <div className="px-2">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {apiError}
                </div>
              </div>
            </Alert>
          </div>
        )}
      </FormColumn>
    </>
  );
};
