import { DiamondStyleTypes } from "./types";

export const diamondsList = {
  blue: "blue",
  gray: "gray",
  green: "green",
  red: "red",
  yellow: "yellow",
  purple: "purple",
  cyan: "cyan",
  indigo: "indigo",
  pink: "pink",
  lime: "lime",
  amber: "amber",
  teal: "teal",
  fuchsia: "fuchsia",
  rose: "rose",
  emerald: "emerald",
  violet: "violet",
  sky: "sky",
  orange: "orange",
  slate: "slate",
  warmGray: "warmGray",
  lightBlue: "lightBlue",
  trueGray: "trueGray",
  blueGray: "blueGray",
  redOrange: "redOrange",
  blueGreen: "blueGreen",
};

export const randomDiamond = () => {
  const diamondsLength = Object.keys(diamondsList).length;
  return Object.keys(diamondsList)[
    Math.floor(Math.random() * diamondsLength)
  ] as DiamondStyleTypes;
};
