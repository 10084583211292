import { CompanyResponseData } from "@models/api";
import { PreviewObject } from "@models/api";
import { Logo } from "@ui/Logo";
import { Pill } from "@ui/Pill";
import { logoUrl } from "@utils/companies";

interface CompaniesGroupProps {
  companies: (CompanyResponseData | PreviewObject)[];
}

export const CompaniesGroup = ({ companies }: CompaniesGroupProps) => {
  const [firstCompany, ...remainingCompanies] = companies;

  return (
    <div className="flex items-center space-x-4">
      {firstCompany && (
        <div className="flex space-x-2 items-center">
          <Logo src={logoUrl(firstCompany)} size="sm" alt={firstCompany.name} />
          <div>{firstCompany.name}</div>
        </div>
      )}

      {remainingCompanies.length > 0 && (
        <div className="relative group">
          <Pill
            type="rounded"
            text="normal"
            className="group-hover:bg-gray-300 hover:cursor-pointer"
          >
            +{remainingCompanies.length} more
          </Pill>
          <div className="absolute pt-2 opacity-0 invisible group-hover:visible group-hover:opacity-100 left-0 z-[100]">
            <div className="space-y-2 p-4 border border-gray-100 shadow-lg rounded-lg  bg-white">
              {remainingCompanies.map((company) => {
                return (
                  <div className="flex space-x-1 items-center" key={company.id}>
                    <Logo src={logoUrl(company)} size="sm" alt={company.name} />
                    <div className="text-sm">{company.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
