import { Button } from "@ui/Button";
import cx from "classnames";

export const VisibilityButton = ({
  isVisible,
  onClick,
}: {
  isVisible: boolean;
  onClick: React.MouseEventHandler;
}) => {
  return (
    <Button
      className={cx(
        "flex justify-center items-center rounded-[5px] !w-7 !h-7 !p-0 hover:!bg-gray-50 !border-[#E2E5E9]",
        {
          "!text-[#194BFB]": isVisible,
          "!text-[#777777]": !isVisible,
        },
      )}
      color="white"
      size="2xs"
      onClick={onClick}
    >
      {isVisible ? (
        <svg viewBox="0 0 16 10" width={16} height={10}>
          <path
            d="M15.2983 4.29331C10.3661 -1.4311 5.18947 -1.4311 0.257236 4.29331C-0.0857453 4.69781 -0.0857453 5.28581 0.257236 5.69032C5.19498 11.4366 10.3606 11.4366 15.2983 5.69032C15.6413 5.28581 15.6413 4.69781 15.2983 4.29331ZM7.77787 8.19515C3.48443 8.08055 3.48443 1.9032 7.77787 1.78848C12.0713 1.90307 12.0713 8.08042 7.77787 8.19515Z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 15 15" width={15} height={15}>
          <path
            d="M13.5206 9.58053L6.7952 2.84959C7.02596 2.82556 7.26258 2.80915 7.49978 2.80915C11.6417 2.80915 15 6.56185 15 7.50032C15 7.87957 14.4448 8.71714 13.5206 9.58053ZM14.7218 13.4141C15.0838 13.7764 15.0838 14.3649 14.7218 14.7283C14.3575 15.0906 13.7718 15.0906 13.4069 14.7283L10.3087 11.6275C9.44068 11.9698 8.49415 12.1914 7.49978 12.1914C3.35841 12.1914 0 8.35958 0 7.50019C0 6.9486 1.16609 5.43506 2.96063 4.2727L0.27813 1.58633C-0.0844137 1.22348 -0.0844137 0.637319 0.27813 0.272134C0.64009 -0.0907112 1.22811 -0.0907112 1.59124 0.272134L14.7218 13.4141ZM8.71388 10.0307L4.97134 6.28326C4.79212 6.65138 4.68787 7.06228 4.68787 7.50016C4.68787 9.05583 5.94592 10.315 7.50036 10.315C7.93787 10.315 8.34781 10.2107 8.71388 10.0307Z"
            fill="currentColor"
          />
        </svg>
      )}
    </Button>
  );
};
