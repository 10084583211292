import {
  useEditor,
  useNode,
  type Node,
  type UserComponent,
} from "@craftjs/core";
import {
  Column as EmbedColumn,
  type EmbedContextProps,
} from "@schematichq/schematic-react";

/* const Indicators = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
      <div className="absolute z-[1] top-1/2 -left-[2px] -translate-y-1/2 w-[5px] h-4 bg-white border-2 border-black" />
      <div className="absolute z-[1] top-1/2 -right-[2px] -translate-y-1/2 w-[5px] h-4 bg-white border-2 border-black" />
    </div>
  );
}; */

export interface ColumnProps {
  id: string;
  children?: React.ReactNode;
  basis?: string;
}

export const Column: UserComponent<ColumnProps> = ({ children }) => {
  const { query, numberOfColumns } = useEditor((state) => {
    return {
      numberOfColumns: (
        state.nodes.ROOT.data.props.settings as EmbedContextProps["settings"]
      ).theme.numberOfColumns,
    };
  });

  const {
    connectors: { connect },
  } = useNode<{
    isSelected: boolean;
    parent?: Node;
    basis: string;
  }>((node) => {
    const { basis } = node.data.props;

    let parent: Node | undefined;
    if (node.data.parent) {
      parent = query.node(node.data.parent).get();
    }

    return {
      isSelected: node.events.selected,
      parent,
      basis,
    };
  });

  return (
    <EmbedColumn
      ref={(ref) => connect(ref!)}
      className="layout"
      style={{ flexBasis: `${100 / numberOfColumns}%` }}
    >
      {children}
    </EmbedColumn>
  );
};

Column.craft = {
  displayName: "Column",
  props: {
    basis: "50%",
  },
  rules: {
    canDrag: () => false,
    canDrop: () => false,
  },
};
