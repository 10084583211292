import cx from "classnames";

interface OverlayLoaderProps {
  className?: string;
}

const SchematicOverlayLoader = ({ className }: OverlayLoaderProps) => {
  const styles = cx(
    "flex flex-col h-screen w-screen items-center justify-center p-4 absolute z-40 backdrop-blur-xs bg-white/80",
    className,
  );

  return (
    <div className={styles}>
      <img
        alt="Schematic Loader"
        src="/schematic-load.gif"
        className=" select-none w-10 h-10"
      />
    </div>
  );
};

export default SchematicOverlayLoader;
