import { deleteReq, get, post, put } from "@data/index";
import {
  ComponentResponseData,
  ComponentHydrateResponseData,
  CreateComponentRequestBody,
  ListComponentsParams,
  PreviewComponentDataRequest,
  PreviewComponentDataResponse,
  UpdateComponentRequestBody,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";

export const countComponents = async (
  flt?: ListComponentsParams,
): Promise<Count> => {
  return get<Count, ListComponentsParams>("components/count", flt);
};

export const createComponent = async (
  req: CreateComponentRequestBody,
): Promise<ComponentResponseData> => {
  return post<ComponentResponseData, CreateComponentRequestBody>(
    "components",
    req,
  );
};

export const deleteComponent = async (id: string): Promise<Delete> => {
  return deleteReq(`components/${id}`);
};

export const getComponent = async (
  componentId: string,
): Promise<ComponentResponseData> => {
  return get<ComponentResponseData>(`components/${componentId}`);
};

export const previewComponent = async (
  flt?: PreviewComponentDataRequest,
): Promise<ComponentHydrateResponseData> => {
  return get<ComponentHydrateResponseData, PreviewComponentDataRequest>(
    `components/preview-data`,
    flt,
  );
};

export const listComponents = async (
  flt?: ListComponentsParams,
): Promise<ComponentResponseData[]> => {
  return get<ComponentResponseData[], ListComponentsParams>("components", flt);
};

export const updateComponent = async (
  componentId: string,
  req: UpdateComponentRequestBody,
): Promise<ComponentResponseData> => {
  return put<ComponentResponseData, UpdateComponentRequestBody>(
    `components/${componentId}`,
    req,
  );
};

export const previewComponentData = async (
  req: PreviewComponentDataRequest,
): Promise<PreviewComponentDataResponse> => {
  return get<PreviewComponentDataResponse, PreviewComponentDataRequest>(
    "components/preview-data",
    req,
  );
};
