import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { TableLoader } from "@components/loaders/TableLoader";
import { ButtonGroup } from "@components/ui/ButtonGroup";
import { DropdownDots } from "@components/ui/DropdownDots";
import { useRole } from "@hooks/useRole";
import useTablePagination from "@hooks/useTablePagination";
import { ListApiKeysParams } from "@models/api";
import { ApiKey } from "@models/apiKey";
import { ClerkUserRole } from "@models/clerkUser";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { ClipCopy } from "@ui/ClipCopy";
import { Overlay, OverlayHeader, OverlayModal } from "@ui/Overlay";
import { Table } from "@ui/Table";
import { formatDate } from "@utils/date";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import * as api from "../../queries/apiKeys";
import { EnvironmentCell } from "../EnvironmentCell";

type ApiKeysTableProp = {
  onEdit: (apiKey: ApiKey) => void;
};

export const ApiKeysTable = ({ onEdit }: ApiKeysTableProp) => {
  const queryClient = useQueryClient();
  const [confirmRevokeApiKey, setConfirmRevokeApiKey] = useState<ApiKey | null>(
    null,
  );
  const [revoking, setRevoking] = useState(false);
  const [revokeError, setRevokeError] = useState(false);
  const apiKeysEditAllowed = useRole(ClerkUserRole.Admin);

  const handleRevoke = (apiKey: ApiKey) => {
    setRevoking(true);

    api
      .deleteApiKey(apiKey.id)
      .then(() => {
        queryClient.invalidateQueries();
        setRevokeError(false);
        setRevoking(false);
        setConfirmRevokeApiKey(null);
      })
      .catch((error) => {
        setRevokeError(true);
        setRevoking(false);
        setConfirmRevokeApiKey(null);
        console.error(error);
      });
  };

  const columns = useMemo<ColumnDef<ApiKey>[]>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        size: 140,
        cell: (cellInfo) => {
          const apiKey = cellInfo.row.original;
          return <span title={apiKey.description}>{apiKey.name}</span>;
        },
      },
      {
        id: "environment",
        header: "Environment",
        accessorKey: "environment",
        size: 200,
        cell: (cellInfo) => {
          const apiKey = cellInfo.row.original;
          return apiKey.environment ? (
            <EnvironmentCell environment={apiKey.environment} />
          ) : (
            <></>
          );
        },
      },
      {
        id: "lastUsedAt",
        header: "Last Used",
        accessorKey: "lastUsedAt",
        size: 120,
        cell: (cellInfo) => {
          const apiKey = cellInfo.row.original;
          return apiKey.lastUsedAt ? (
            <>{formatDate(apiKey.lastUsedAt)}</>
          ) : (
            <>Never</>
          );
        },
      },
      {
        id: "createdAt",
        header: "Created",
        accessorKey: "createdAt",
        size: 160,
        cell: (cellInfo) => {
          const apiKey = cellInfo.row.original;
          return <>{formatDate(apiKey.createdAt)}</>;
        },
      },
      {
        id: "keyId",
        header: "API Key ID",
        accessorKey: "keyId",
        size: 210,
        cell: (cellInfo) => {
          const apiKey = cellInfo.row.original;
          return <ClipCopy data={apiKey.id} />;
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        maxSize: 75,
        cell: (cellInfo) => {
          const apiKey = cellInfo.row.original;
          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Edit",
                    onClick: () => onEdit(apiKey),
                    disabled: !apiKeysEditAllowed,
                  },
                  {
                    label: "Revoke",
                    onClick: () => setConfirmRevokeApiKey(apiKey),
                    disabled: !apiKeysEditAllowed,
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    [onEdit, apiKeysEditAllowed],
  );

  const { listQuery, pageCount, pageIndex, pageSize, setPagination } =
    useTablePagination<ApiKey, ListApiKeysParams>(
      ["api-keys"],
      api.listApiKeys,
      api.countApiKeys,
    );

  if (listQuery.error) throw listQuery.error;

  if (listQuery.isLoading) return <TableLoader rows={4} />;

  if (!listQuery.data) return null;

  return (
    <>
      <Table
        columns={columns}
        data={listQuery.data}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
      />

      {confirmRevokeApiKey &&
        createPortal(
          <>
            {revoking && (
              <SchematicOverlayLoader className="!z-[500] fixed left-0 top-0" />
            )}
            <Overlay onClose={() => setConfirmRevokeApiKey(null)}>
              <OverlayModal size="md">
                <OverlayHeader
                  title="Are you sure you want to revoke this API key?"
                  description="Once deleted, anything referencing this key will not work"
                  onClose={() => setConfirmRevokeApiKey(null)}
                />
                <div className="p-12 pt-0">
                  {revokeError && (
                    <Alert size="xs" style="red">
                      <span className="font-semibold">Uh-oh!</span> Something
                      went wrong. Please try again.
                    </Alert>
                  )}

                  <ButtonGroup
                    className="mt-12 flex justify-end"
                    buttons={[
                      {
                        size: "md",
                        color: "white",
                        onClick: () => setConfirmRevokeApiKey(null),
                        children: "Cancel",
                      },
                      {
                        size: "md",
                        color: "blue",
                        onClick: () => handleRevoke(confirmRevokeApiKey),
                        children: "Confirm and revoke",
                      },
                    ]}
                  />
                </div>
              </OverlayModal>
            </Overlay>
          </>,
          document.body,
        )}
    </>
  );
};
