import { FormikControl } from "@forms/FormikControl";
import { DropdownDots } from "@ui/DropdownDots";

interface RuleBlockHeaderProps {
  field: string;
  onRemove?: () => void;
  readonly?: boolean;
}

export const RuleBlockHeader = ({
  field,
  onRemove,
  readonly = false,
}: RuleBlockHeaderProps) => {
  return (
    <div className="flex justify-between px-8">
      <FormikControl
        className="pl-4 -ml-4 text-lg leading-none w-full max-w-[50%]"
        control="input"
        disabled={readonly}
        name={`${field}.name`}
        style="inline"
      />
      <div className="flex flex-row  items-center space-x-6 justify-end text-xs text-gray-400">
        <DropdownDots
          links={[
            {
              label: <div className="text-red-500">Delete</div>,
              onClick: onRemove ?? (() => {}),
            },
          ]}
        />
      </div>
    </div>
  );
};
