import { ToggleButtonGroup } from "@components/ToggleButtonGroup";
import { ClipCopy } from "@components/ui/ClipCopy";
import { Select } from "@components/ui/Select";
import { UserInitials } from "@components/ui/UserInitials";
import * as api from "@data/accounts";
import { Account } from "@models/account";
import { ListApiRequestsParams } from "@models/api";
import { ApiKeyRequest } from "@models/apiKey";
import { AuditLogDescription } from "@modules/settings/components/AuditLogDescription";
import { AuditLogOverlay } from "@modules/settings/components/overlays/AuditLogOverlay";
import { getCodeColor } from "@modules/settings/helpers";
import { listApiRequests } from "@modules/settings/queries/apiRequests";
import {
  AuditLogTableRequest,
  AuditLogTableRequestOptions,
} from "@modules/settings/types";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { InfiniteTable } from "@ui/InfiniteTable";
import { Pill } from "@ui/Pill";
import { TableHeader } from "@ui/TableHeader";
import { formatDate } from "@utils/date";
import React, { useEffect, useMemo, useState } from "react";
import { EnvironmentCell } from "../EnvironmentCell";

export const AuditLogTable = () => {
  const [logOverlay, setLogOverlay] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [requestType, setRequestTypeFilter] = useState(
    AuditLogTableRequest.App as string,
  );
  const [environmentId, setEnvironmentId] = useState("");
  const [filter, setFilter] = useState<ListApiRequestsParams>({});
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const { data } = useQuery<Account>({
    queryKey: ["accounts", "me"],
    queryFn: () => api.me(),
  });

  useEffect(() => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      q: searchTerm === "" ? undefined : searchTerm,
    }));
  }, [searchTerm]);

  useEffect(() => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      requestType: requestType === "all" ? undefined : requestType,
    }));
  }, [requestType]);

  useEffect(() => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      environmentId: environmentId === "" ? undefined : environmentId,
    }));
  }, [environmentId]);

  const columns = useMemo<ColumnDef<ApiKeyRequest>[]>(
    () => [
      {
        id: "time",
        header: "Start Time",
        accessorKey: "time",
        size: 170,
        cell: (cellInfo) => {
          const apiRequest = cellInfo.row.original;
          return (
            <span className="text-gray-400 text-sm">
              {formatDate(apiRequest.startedAt)}
            </span>
          );
        },
      },
      {
        id: "description",
        header: "Description",
        accessorKey: "description",
        size: 352,
        cell: (cellInfo) => {
          const apiRequest = cellInfo.row.original;
          return <AuditLogDescription apiRequest={apiRequest} />;
        },
      },
      {
        id: "id",
        header: "Request ID",
        accessorKey: "id",
        size: 190,
        cell: (cellInfo) => {
          const id = cellInfo.row.original.id;
          return <ClipCopy data={id} className="w-full" truncate />;
        },
      },
      {
        id: "author",
        header: "Author",
        accessorKey: "author",
        size: 210,
        cell: (cellInfo) => {
          const apiRequest = cellInfo.row.original;
          const userName: string = apiRequest.userName || "";
          return apiRequest.requestType === "api" && userName == "" ? (
            <div className="text-sm">
              <ClipCopy data={apiRequest.apiKeyId} />
            </div>
          ) : (
            userName && <UserInitials name={userName} showName truncate />
          );
        },
      },
      {
        id: "code",
        header: "Code",
        accessorKey: "code",
        size: 90,
        cell: (cellInfo) => {
          const apiRequest = cellInfo.row.original;
          return (
            <Pill color={getCodeColor(apiRequest.respCode)}>
              {apiRequest.respCode}
            </Pill>
          );
        },
      },
      {
        id: "environment",
        header: "Environment",
        accessorKey: "environment",
        size: 200,
        cell: (cellInfo) => {
          const apiRequest = cellInfo.row.original;
          const environment = apiRequest.environment;
          return environment ? (
            <EnvironmentCell environment={environment} />
          ) : (
            <></>
          );
        },
      },
      {
        id: "url",
        header: "URL",
        accessorKey: "url",
        size: 300,
        cell: (cellInfo) => {
          const url = cellInfo.row.original.url;
          return (
            <div className="max-w-[400px] text-sm truncate" title={url}>
              {url}
            </div>
          );
        },
      },
    ],
    [],
  );

  const requestTypeOptions: AuditLogTableRequestOptions[] = [
    {
      label: "All",
      value: AuditLogTableRequest.All,
    },
    {
      label: "User",
      value: AuditLogTableRequest.App,
    },
    {
      label: "API",
      value: AuditLogTableRequest.Api,
    },
  ];

  return (
    <>
      <TableHeader
        headerText={
          <div>
            <h1 className="text-2xl font-body font-medium">Audit Log</h1>
            <p className="text-gray-600 text-lg">
              Logs are stored for 14 days.
            </p>
          </div>
        }
        searchPlaceholder="Search author, resource, request id, URL"
        onSearch={handleSearch}
      >
        <ToggleButtonGroup
          defaultValue={AuditLogTableRequest.App}
          buttons={requestTypeOptions}
          onToggleClick={setRequestTypeFilter}
        />
        <Select
          placeholder="All Environments"
          onChange={(option) => {
            const environmentId = option !== null ? option.value : "";
            setEnvironmentId(environmentId);
          }}
          options={data?.environments.map((environment) => {
            return {
              value: environment.id,
              label: <EnvironmentCell environment={environment} />,
            };
          })}
          isClearable={true}
        />
      </TableHeader>

      <InfiniteTable
        queryKey={["api-requests"]}
        queryFn={listApiRequests}
        filter={filter}
        columns={columns}
        onRowClick={(row: ApiKeyRequest) => setLogOverlay(row.id)}
      />

      {logOverlay && (
        <AuditLogOverlay id={logOverlay} onClose={() => setLogOverlay(null)} />
      )}
    </>
  );
};
