import React from "react";

export const SidebarMetricsLoader = () => {
  return (
    <div className="opacity-90 animate-pulse left-0 top-0 w-full p-8">
      <div className="space-y-12 ">
        <div className="flex items-center justify-between">
          <div className="flex flex-1 items-center justify-between space-x-4 w-full">
            <div className="h-5 bg-gray-200 rounded w-20"></div>
            <div className="h-3 bg-gray-200 rounded w-20"></div>
          </div>
        </div>
        <div className="space-y-10">
          {Array.from({ length: 3 }, (_, index) => (
            <div className="flex flex-col space-y-2" key={index}>
              <div className="flex flex-row space-x-2">
                <div className="h-5 bg-gray-200 rounded w-5"></div>
                <div className="h-5 bg-gray-200 rounded w-24"></div>
              </div>
              <div className="h-3 bg-gray-200 rounded w-44"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const SidebarListWidgetLoader = () => {
  return (
    <div className="opacity-60 aenimate-pulse left-0 top-0 w-full p-8">
      <div className="space-y-8">
        <div className="flex flex-col space-y-3 items-center justify-between">
          <div className="flex flex-1 items-center justify-between space-x-4 w-full">
            <div className="h-5 bg-gray-200 rounded w-20"></div>
            <div className="h-3 bg-gray-200 rounded w-20"></div>
          </div>
          <div className="flex flex-1 flex-col items-start space-y-2 w-full">
            <div className="h-3 bg-gray-200 rounded w-full"></div>
            <div className="h-3 bg-gray-200 rounded w-32"></div>
          </div>
        </div>

        <div className="space-y-6">
          <div className="flex flex-col space-y-4">
            {Array.from({ length: 3 }, (_, index) => (
              <div className="flex flex-row justify-between" key={index}>
                <div className="flex flex-row space-x-2 items-center">
                  <div className="h-6 bg-gray-200 rounded-full w-6"></div>
                  <div className="h-5 bg-gray-200 rounded w-28"></div>
                </div>
                <div className="h-5 bg-gray-200 rounded w-5"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
