import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { ApiKeyRequestResponseData } from "@models/api";
import { ReactNode, SyntheticEvent } from "react";
import { renderToString } from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { AuditLogMapping } from "../consts";

export interface AuditLogDescriptionProps {
  apiRequest: ApiKeyRequestResponseData;
}

export const AuditLogDescription = ({
  apiRequest,
}: AuditLogDescriptionProps) => {
  const navigate = useNavigate();
  const { environment } = useCurrentEnvironment();

  const mapMethod = (method: string): string => {
    let action: string;

    switch (method.toLocaleLowerCase()) {
      case "put":
        action = "updated";
        break;
      case "post":
        action = "created";
        break;
      case "delete":
        action = "deleted";
        break;
      default:
        action = "";
        break;
    }

    return action;
  };

  const generateDescription = (
    apiRequest: ApiKeyRequestResponseData,
  ): ReactNode => {
    if (apiRequest.resourceType != null) {
      const mapping = AuditLogMapping[apiRequest.resourceType];
      const requestMethod = apiRequest.method.toLocaleLowerCase();
      const method =
        (mapping?.action && mapping.action[requestMethod]) ||
        mapMethod(apiRequest.method);

      let secondaryResource = "";

      if (apiRequest.secondaryResource) {
        secondaryResource = " with " + apiRequest.secondaryResource;
      }

      const environmentId = apiRequest.environmentId
        ? apiRequest.environmentId
        : environment?.id;

      const handleResourceClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        navigate(
          `/${environmentId}/${mapping.path}/${apiRequest.resourceIdString}`,
        );
      };

      if (mapping && mapping.path && environmentId) {
        return (
          <>
            <span
              className="text-blue-400 hover:underline"
              onClick={handleResourceClick}
            >
              {apiRequest.resourceName}
            </span>{" "}
            {apiRequest.resourceType.replace(/[_-]/, " ")}
            {secondaryResource} <span className="font-medium">{method}</span>
          </>
        );
      } else {
        return (
          <>
            {apiRequest.resourceName} {apiRequest.resourceType}{" "}
            <span className="font-medium">{method}</span>
          </>
        );
      }
    }

    return "N/A";
  };

  const description = generateDescription(apiRequest);
  const title = renderToString(description).replace(/\s\s+|<[^>]*>?/gm, "");

  return (
    <span className="truncate" title={title}>
      {description}
    </span>
  );
};
