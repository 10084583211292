import { EnvironmentContext } from "@contexts/EnvironmentContext";

import { useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigateEnvironment = () => {
  const navigate = useNavigate();
  const { environmentId } = useContext(EnvironmentContext);

  const navigateEnvironment = useCallback(
    (to: string) => {
      navigate(`/${environmentId}/${to}`);
    },
    [environmentId, navigate],
  );

  return navigateEnvironment;
};

export const useNavigateEnvironmentTo = <T>(prefixFn?: (row: T) => string) => {
  const { environmentId } = useContext(EnvironmentContext);

  const navigateEnvironmentTo = useCallback(
    (row: T) => {
      if (!prefixFn) {
        return `/${environmentId}`;
      }

      const to = prefixFn(row);

      return `/${environmentId}/${to}`;
    },
    [environmentId, prefixFn],
  );

  return navigateEnvironmentTo;
};
