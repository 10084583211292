import { Diamond, DiamondStyleTypes } from "@ui/Diamond";
import cx from "classnames";

type PlanLabelProps = {
  plan: {
    name: string;
    id: string;
    icon: string | DiamondStyleTypes;
  };
  font?: "medium" | "normal";
};

export const PlanLabel = ({ plan, font = "medium" }: PlanLabelProps) => {
  const fontStyle = {
    medium: "font-medium",
    normal: "font-normal",
  };

  return (
    <div className="flex flex-row items-center">
      <Diamond style={plan.icon as DiamondStyleTypes} size="xs" />
      <div className="flex flex-col ml-4">
        <div
          title={plan.name || plan.id}
          className={cx(
            "text-[18px] !leading-none truncate-md",
            fontStyle[font],
          )}
        >
          {plan.name || plan.id}
        </div>
      </div>
    </div>
  );
};
