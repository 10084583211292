import { deleteReq, get, post, put } from "@data/index";
import {
  ListPlanEntitlementsParams,
  CreatePlanEntitlementRequestBody,
  UpdatePlanEntitlementRequestBody,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";
import { PlanEntitlement } from "@models/entitlement";
import { ListFilter } from "@models/req";

export const countPlanEntitlements = async (
  filter?: ListPlanEntitlementsParams,
): Promise<Count> => {
  return get<Count, ListPlanEntitlementsParams>(
    "plan-entitlements/count",
    filter,
  );
};

export const getPlanEntitlement = async (
  id: string,
): Promise<PlanEntitlement> => {
  return get<PlanEntitlement>(`plan-entitlements/${id}`);
};

export const listPlanEntitlements = async (
  filter?: ListPlanEntitlementsParams & ListFilter,
): Promise<PlanEntitlement[]> => {
  return get<PlanEntitlement[], ListPlanEntitlementsParams>(
    "plan-entitlements",
    filter,
  );
};

export const createPlanEntitlement = async (
  req: CreatePlanEntitlementRequestBody,
): Promise<PlanEntitlement> => {
  return post<PlanEntitlement, CreatePlanEntitlementRequestBody>(
    "plan-entitlements",
    req,
  );
};

export const createMultiplePlanEntitlements = async (
  req: CreatePlanEntitlementRequestBody[],
): Promise<PlanEntitlement[]> => {
  return post<PlanEntitlement[], CreatePlanEntitlementRequestBody[]>(
    "plan-entitlements/batch",
    req,
  );
};

export const updatePlanEntitlement = async (
  id: string,
  req: UpdatePlanEntitlementRequestBody,
): Promise<PlanEntitlement> => {
  return put<PlanEntitlement, UpdatePlanEntitlementRequestBody>(
    `plan-entitlements/${id}`,
    req,
  );
};

export const deletePlanEntitlement = async (id: string): Promise<Delete> => {
  return deleteReq(`plan-entitlements/${id}`);
};
