import { CompanyDetailResponseData } from "@models/api";
import { useOutletContext } from "react-router-dom";
import { CompanyEventsTable } from "../tables/CompanyEventsTable";

export const CompanyUsageTab = () => {
  const { company } = useOutletContext<{
    company: CompanyDetailResponseData;
  }>();

  return (
    <CompanyEventsTable
      companyId={company.id}
      headerText="Event Stream"
      emptyStateTitle="📦 No usage detected for this company"
      emptyStateMessage="So far, no events associated with this company have been received."
    />
  );
};
