import { Alert } from "@ui/Alert";
import { Icon } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import React, { ReactNode } from "react";

interface SidebarBlankStateProps {
  icon?: IconNameTypes;
  title?: string | ReactNode;
  description?: string | ReactNode;
}

export const SidebarBlankState = ({
  icon,
  title,
  description,
}: SidebarBlankStateProps) => {
  return (
    <Alert
      style="gray"
      className="space-y-4 items-center justify-center text-center"
    >
      {icon && (
        <div className="text-center">
          <Icon name={icon} className="text-5xl leading-none text-blue-400" />
        </div>
      )}
      <div className="text-2xl">{title}</div>
      <div className="text-sm text-gray-400 -mx-2">{description}</div>
    </Alert>
  );
};
