import { useUser } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import React from "react";
import { Navigate } from "react-router-dom";

export const BaseRoute = () => {
  const { isLoaded, isSignedIn, user } = useUser();
  const { curEnvironmentId } = user?.publicMetadata || {};

  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
        {isLoaded && !isSignedIn && <Navigate to="/sign-in" replace />}
        {isLoaded && isSignedIn && (
          <Navigate to={`/${curEnvironmentId}/features`} replace />
        )}
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
};
