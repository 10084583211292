import { FormikSelect } from "@forms/FormikSelect";

const booleanValueOptions = [
  {
    value: true,
    label: (
      <span className="inline-flex items-center">
        <span className="mr-2 inline-block text-xs">🟢</span> True
      </span>
    ),
  },
  {
    value: false,
    label: (
      <span className="inline-flex items-center">
        <span className="mr-2 inline-block text-xs">🔴</span> False
      </span>
    ),
  },
];

interface BooleanSelectProps {
  disabled?: boolean;
  name: string;
  label: string;
  placeholder?: string;
  selected?: boolean;
}

const BooleanSelect = ({
  disabled = false,
  label,
  name,
  placeholder = "Select value...",
  selected = false,
}: BooleanSelectProps) => {
  return (
    <FormikSelect
      disabled={disabled}
      className=" max-w-fit"
      label={label}
      name={name}
      options={booleanValueOptions}
      placeholder={placeholder}
      selectedOption={booleanValueOptions.find((c) => c.value === selected)}
    />
  );
};

export default BooleanSelect;
