import { EventBodyFlagCheck } from "@models/api";

export const getCheckStatus = (check?: EventBodyFlagCheck) => {
  if (!check) return "gray";

  if (check.error) {
    return "error";
  }

  if (check.reason && check.reason.toLowerCase().includes("not found")) {
    return "warning";
  }

  return "ok";
};

export const getCheckStatusColor = (status: string) => {
  if (status === "error") {
    return "red";
  }

  if (status === "warning") {
    return "yellow";
  }

  if (status === "ok") {
    return "green";
  }

  return "gray";
};

export const getCheckStatusText = (status: string) => {
  if (status === "error") {
    return "Failed";
  }

  if (status === "warning") {
    return "Warning";
  }

  if (status === "ok") {
    return "Success";
  }

  return "Unknown";
};
