import * as ToggleGroup from "@radix-ui/react-toggle-group";
import cx from "classnames";
import { useState } from "react";

interface ToggleButton {
  value: string;
  label: string;
  onClick?: () => void;
}

interface ToggleButtonGroup {
  size?: "sm" | "md" | "lg";
  type?: "single" | "multiple";
  defaultValue?: string;
  buttons: ToggleButton[];
  onToggleClick: (value: string) => void;
}

export const ToggleButtonGroup = ({
  buttons,
  defaultValue,
  onToggleClick,
  size = "sm",
}: ToggleButtonGroup) => {
  const [value, setValue] = useState<any>(
    defaultValue ? defaultValue : buttons[0].value,
  );

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
    onToggleClick(newValue); // Call the callback function with the new value
  };

  const sizeStyles = {
    sm: "toggle-group-sm",
    md: "toggle-group-md",
    lg: "toggle-group-lg",
  };

  return (
    <div>
      <ToggleGroup.Root
        type="single"
        defaultValue={defaultValue ? defaultValue : buttons[0].value}
        value={value}
        onValueChange={(value: string) => {
          handleValueChange(value);
        }}
        className={cx("toggle-group", sizeStyles[size])}
        aria-label="Text alignment"
      >
        {buttons.map((b, index) => {
          return (
            <ToggleGroup.Item
              value={b.value}
              aria-label={b.value}
              onClick={b.onClick}
              key={index}
            >
              {b.label}
            </ToggleGroup.Item>
          );
        })}
      </ToggleGroup.Root>
    </div>
  );
};
