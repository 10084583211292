import { CompanyDetailResponseData } from "@models/api";
import { useOutletContext } from "react-router-dom";
import { CompanyUsersTable } from "../tables/CompanyUsersTable";

export const CompanyUsersTab = () => {
  const { company } = useOutletContext<{
    company: CompanyDetailResponseData;
  }>();

  return <CompanyUsersTable companyId={company.id} />;
};
