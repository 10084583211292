import { CrmDeal, Product, ProductFilter } from "@models/crm";
import { get } from "./index";

export const listCrmProducts = async (
  flt?: ProductFilter,
): Promise<Product[]> => {
  return get<Product[], ProductFilter>("crm/products/list", flt);
};

export const listActiveDeals = async (
  companyId: string,
): Promise<CrmDeal[]> => {
  return get<CrmDeal[]>(`crm/deal/by-company/${companyId}`);
};
