import cx from "classnames";
import { ReactNode } from "react";
import { Icon } from "../Icon";
import { IconNameTypes } from "../Icon/consts";
import { Pill } from "../Pill";

export type DataBlockTypes = {
  label?: string | ReactNode;
  title?: string;
  className?: string;
  children?: ReactNode;
};

export interface DataBlockProps {
  data?: Array<DataBlockTypes | null>;
}

export const DataBlock = ({ data }: DataBlockProps) => {
  const filteredData = data?.flatMap((d) => (d ? [d] : []));
  return (
    <div className="flex items-start justify-center">
      <div className="flex space-x-6">
        {filteredData?.map((d, idx) => {
          const styles = cx(
            "border-r border-r-gray-300 last:border-r-0",
            d.className,
          );
          return (
            <div key={idx} className={styles}>
              {d.children ? (
                <div className="flex items-center h-full">{d.children}</div>
              ) : (
                <div className="pr-8">
                  <span className="text-sm text-gray-400 leading-none inline-block mb-2">
                    {d.title}
                  </span>
                  <div className="text-black">{d.label}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface DataPillBlockProps {
  icon?: IconNameTypes;
  data: string | ReactNode;
}

export const DataPillBlock = ({ data, icon }: DataPillBlockProps) => {
  return (
    <Pill
      text="normal"
      color="gray"
      type="rounded"
      className="flex items-center"
    >
      {icon && (
        <span className="text-lg leading-none mr-2">
          <Icon name={icon} className="opacity" />
        </span>
      )}
      <span>{data}</span>
    </Pill>
  );
};
