import cx from "classnames";
import { useState } from "react";

type OptionValue = string | number;

interface Option {
  label: string;
  value: OptionValue;
}

interface ToggleProps {
  value?: OptionValue;
  options: Option[];
  onChange: (value: OptionValue) => void;
}

export const Toggle = ({ value, options, onChange }: ToggleProps) => {
  const [selectedOption, setSelectedOption] = useState(() =>
    options.find((option) => option.value === value),
  );

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    onChange(option.value);
  };

  return (
    <div className="flex justify-between">
      <div className="flex gap-1 p-0.5 text-xs leading-none border border-[#E6E6E6] rounded-md">
        {options.map((option) => (
          <div
            key={option.value}
            tabIndex={0}
            className={cx(
              "flex items-center px-3 py-1.5 rounded-md cursor-pointer",
              selectedOption?.value === option.value
                ? "bg-[#E6E6E6] text-[#194BFB]"
                : "hover:bg-[#F5F5F5]",
            )}
            onClick={() => {
              handleOptionClick(option);
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};
