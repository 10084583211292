import { CompanySubscriptionTab } from "@modules/companies/components/CompanySubscriptionTab";
import { Navigate, RouteObject } from "react-router-dom";
import { CompaniesView, CompanyView } from "./components";
import {
  CompanyChecksTab,
  CompanyEntitlementsTab,
  CompanyUsageTab,
  CompanyUsersTab,
} from "./components/tabs";
import { CompanySubview } from "./types";

export const companyRoutes: RouteObject[] = [
  { path: "companies", element: <CompaniesView /> },
  {
    path: "companies/:id/:subview?",
    element: <CompanyView />,
    children: [
      {
        index: true,
        element: <Navigate to={CompanySubview.Entitlements} replace />,
      },
      {
        path: CompanySubview.Entitlements,
        element: <CompanyEntitlementsTab />,
      },
      {
        path: CompanySubview.Usage,
        element: <CompanyUsageTab />,
      },
      {
        path: CompanySubview.Users,
        element: <CompanyUsersTab />,
      },
      // {
      //   path: CompanySubview.CRM,
      //   element: <CompanyCrmTab />,
      // },
      {
        path: CompanySubview.Checks,
        element: <CompanyChecksTab />,
      },
      {
        path: CompanySubview.Subscription,
        element: <CompanySubscriptionTab />,
      },
    ],
  },
];
