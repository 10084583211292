import { ViewWrapper } from "@ui/ViewWrapper";

import React, { useState } from "react";

import { FeatureOverlay } from "./overlays/FeatureOverlay";
import { FeaturesTable } from "./tables/FeaturesTable";

export const FeaturesView = () => {
  const [overlay, setOverlay] = useState(false);
  const onOverlayClose = () => setOverlay(false);
  const onCreate = () => setOverlay(true);

  return (
    <ViewWrapper>
      <FeaturesTable onCreate={onCreate} />
      {overlay && <FeatureOverlay onClose={onOverlayClose} />}
    </ViewWrapper>
  );
};
