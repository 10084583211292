import { EntitlementValueType } from "@models/entitlement";
import { Feature, FeatureType } from "@models/feature";
import * as Yup from "yup";

export const MAX_NUMBER_OF_CREATE_ENTITLEMENT = 10;

export const editEntitlementValidationSchema = Yup.object().shape({
  featureId: Yup.string().required("Required"),
  metricPeriod: Yup.string().when(["feature", "valueType"], {
    is: (feature: Feature, valueType: EntitlementValueType) =>
      feature?.featureType == FeatureType.Event &&
      valueType !== EntitlementValueType.Unlimited,
    then: () => Yup.string().required("Required"),
  }),
  planId: Yup.string().required("Required"),
  valueNumeric: Yup.string().when("valueType", {
    is: (valueType: EntitlementValueType) =>
      valueType === EntitlementValueType.Numeric,
    then: () => Yup.string().required("Required"),
  }),
  valueTraitId: Yup.string().when("valueType", {
    is: (valueType: EntitlementValueType) =>
      valueType === EntitlementValueType.Trait,
    then: () => Yup.string().required("Required"),
  }),
  valueType: Yup.string().required("Required"),
});

export const addMultipleEntitlementsValidationSchema = Yup.object().shape({
  entitlements: Yup.array()
    .of(editEntitlementValidationSchema)
    .max(MAX_NUMBER_OF_CREATE_ENTITLEMENT),
});
