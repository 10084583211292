import { ViewWrapper } from "@ui/ViewWrapper";
import { useState } from "react";
import { FeatureFlagOverlay } from "./overlays/FlagOverlay";
import { FeatureFlagsTable } from "./tables/FlagsTable";

export const FlagsView = () => {
  const [overlay, setOverlay] = useState(false);
  const onOverlayClose = () => setOverlay(false);
  const onCreate = () => setOverlay(true);

  return (
    <ViewWrapper>
      <FeatureFlagsTable onCreate={onCreate} />
      {overlay && <FeatureFlagOverlay onClose={onOverlayClose} />}
    </ViewWrapper>
  );
};
