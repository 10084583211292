import cx from "classnames";
import React from "react";
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  MultiValueRemoveProps,
  OptionProps,
  SingleValueProps,
} from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Icon } from "../Icon";

type SelectSizes = "sm" | "md" | "lg";
export interface SelectProps {
  options?: any;
  placeholder?: string;
  isMulti?: boolean;
  maxNumberOfSelected?: number;
  className?: string;
  label?: string;
  value?: string | object;
  isLoading?: any;
  onBlur?: () => void;
  name?: string;
  isClearable?: boolean;
  selectedOption?: any;
  onChange?: (value: any) => void;
  onCreate?: (value: any) => void;
  disabled?: boolean;
  loadOptions?: (inputValue: string) => Promise<any[]>;
  defaultOptions?: boolean;
  description?: string | React.ReactNode;
  creatable?: boolean;
  creatableLabel?: string;
  size?: SelectSizes;
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="chevron-down" className="text-lg text-gray-400" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon name="close" className="text-lg text-gray-400" />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon name="close" className="text-lg text-gray-400" />
    </components.MultiValueRemove>
  );
};

const SingleValue = (props: SingleValueProps<any>) => {
  const { data, children } = props;
  return (
    <components.SingleValue {...props}>
      <div className="flex items-center">
        {data?.icon && (
          <Icon name={data?.icon} className=" text-2xl leading-none mr-1" />
        )}
        {children}
      </div>
    </components.SingleValue>
  );
};

const Option = (props: OptionProps<any>) => {
  const { isSelected, children, isMulti, data } = props;

  const styles = cx(
    "!flex !flex-row space-between items-center",
    isSelected && "tw-select-option-selected",
  );

  return (
    <components.Option className={styles} {...props}>
      {data?.icon && (
        <Icon name={data?.icon} className=" text-2xl leading-none mr-3" />
      )}
      <div className="flex-1 text-lg leading-none">{children}</div>
      {isSelected && isMulti && (
        <Icon name="check" className="text-blue-400 text-3xl leading-none" />
      )}
    </components.Option>
  );
};

export const Select = ({
  size = "md",
  options,
  placeholder,
  isMulti,
  className,
  label,
  value,
  onBlur,
  onChange,
  selectedOption,
  disabled = false,
  name,
  description,
  ...rest
}: SelectProps) => {
  const sizeStyles = {
    sm: "tw-select-sm",
    md: "",
    lg: "",
  };

  const styles = cx("tw-select", sizeStyles[size], className);
  return (
    <div className="w-full flex flex-col">
      {label && <span className="label-md">{label}</span>}
      <ReactSelect
        name={name}
        defaultValue={selectedOption}
        options={options}
        className={styles}
        classNamePrefix="tw-select"
        placeholder={placeholder}
        isMulti={isMulti}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove,
          SingleValue,
          Option,
        }}
        {...rest}
      />
      {description && (
        <div className="text-xs text-gray-400 mt-2">{description}</div>
      )}
    </div>
  );
};

export const SelectAsync = ({
  options,
  placeholder,
  className,
  label,
  value,
  onBlur,
  onChange,
  isLoading,
  onCreate,
  isClearable = true,
  disabled = false,
  isMulti = true,
  // 0 represents "no limit"
  maxNumberOfSelected = 0,
  creatable = false,
  creatableLabel,
  loadOptions,
  name,
  ...rest
}: SelectProps) => {
  const styles = cx("tw-select", isMulti && "tw-select-multi", className);

  const SelectComponent = creatable ? AsyncCreatableSelect : AsyncSelect;

  return (
    <div className="w-full flex flex-col">
      {label && <span className="label-md">{label}</span>}
      <SelectComponent
        name={name}
        options={options}
        className={styles}
        classNamePrefix="tw-select"
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        isDisabled={disabled}
        isLoading={isLoading}
        loadOptions={loadOptions}
        onCreateOption={onCreate}
        menuPortalTarget={document.querySelector("body")}
        formatCreateLabel={(value) => {
          return (
            <div className="flex items-center justify-between cursor-pointer py-0.5 text-base">
              <div>
                <span className="text-blue-400 text-bold">
                  Create{creatableLabel && ` ${creatableLabel}`}:{" "}
                </span>
                {value}
              </div>
              <div className="text-sm text-gray-400">Never seen before</div>
            </div>
          );
        }}
        components={{
          IndicatorSeparator: () => null,
          MultiValueRemove,
          Option,
        }}
        isMulti={isMulti}
        isOptionDisabled={() =>
          isMulti &&
          !!maxNumberOfSelected &&
          !!value &&
          (value as unknown[]).length >= maxNumberOfSelected
        }
        isClearable={isClearable}
        {...rest}
      />
    </div>
  );
};
