import {
  BlankStateOnCreate,
  PageBlankState,
} from "@components/ui/PageBlankState";
import { Article } from "@consts/links";

export const UsersBlankState = ({ onCreate, disabled }: BlankStateOnCreate) => {
  // TODO: Check with Gio if blank state is correct
  return (
    <PageBlankState
      title="⏳ No users yet"
      type="static"
      description={
        <>
          Send events into Schematic to begin tracking and targeting
          <br />
          based on Companies, Users, and usage.
        </>
      }
      buttons={
        onCreate && [
          {
            color: "blue",
            children: <>Create user</>,
            disabled,
            onClick: onCreate,
          },
        ]
      }
      links={{
        title: "Learn how to:",
        links: [
          {
            label: "Identify Companies and Users",
            url: Article.IDENTIFY_COMPANY_AND_USERS,
          },
        ],
      }}
    />
  );
};
