import { Error } from "@ui/Error";
import cx from "classnames";
import { ErrorMessage, Field, useField } from "formik";
import React from "react";

type InputTypes = "text" | "number" | "numeric-string";

export interface InputProps {
  disabled?: boolean;
  label?: string;
  name: string;
  style?: "default" | "inline";
  description?: string;
  className?: string;
  setFieldValue?: (
    field: string,
    value: never,
    shouldValidate?: boolean | undefined,
  ) => void;
  type?: InputTypes;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ControlledInput = ({
  label,
  name,
  description,
  className,
  style = "default",
  type = "text",
  disabled = false,
  setFieldValue,
  value,
  onChange,
  ...rest
}: InputProps) => {
  const [, , helpers] = useField(name);

  const styleMap = {
    default: "px-4 border border-gray-300 h-[44px]",
    inline: "py-2 border border-transparent !text-2xl",
  };

  const inputStyles = cx("rounded-lg ", styleMap[style], className);
  const wrapperStyles = cx("flex flex-col", style === "inline" && "w-full");

  return (
    <div className={wrapperStyles}>
      {label && (
        <label htmlFor={name} className="label-md">
          {label}
        </label>
      )}

      <Field
        id={name}
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event);

          return helpers.setValue(event?.target.value);
        }}
        {...rest}
        className={inputStyles}
      />

      {description && (
        <div className="text-xs text-gray-400 mt-2">{description}</div>
      )}

      <ErrorMessage name={name} component={Error} />
    </div>
  );
};
