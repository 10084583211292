import { deleteReq, post, put } from "@data/index";
import { InviteClerkUserReq, UpdateClerkUserReq } from "@models/clerkUser";
import { Delete } from "@models/delete";

export const inviteTeammate = async (
  req: InviteClerkUserReq,
): Promise<{ success: boolean }> => {
  return post<{ success: boolean }, InviteClerkUserReq>("users/invite", req);
};

export const updateTeammate = async (
  req: UpdateClerkUserReq,
): Promise<{ success: boolean }> => {
  return put<{ success: boolean }, UpdateClerkUserReq>("users", req);
};

export const deleteTeammate = async (userId: string): Promise<Delete> => {
  return deleteReq(`users/${userId}`);
};
