import { Metadata } from "@components/ui/Metadata";
import { CompanyDetailResponseData } from "@models/api";

interface CompanyDetailsProps {
  company: CompanyDetailResponseData;
}

export const CompanyDetails = ({ company }: CompanyDetailsProps) => {
  return <Metadata data={company} />;
};
