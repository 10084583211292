import { DropdownDots } from "@components/ui/DropdownDots";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { FeatureFlag, FeatureType } from "@models/feature";
import { FeatureTypeCell } from "@modules/features/components/FeatureTypeCell";
import { IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { MiniObject } from "@ui/MiniObject";

interface AssociatedFeatureProps {
  flag: FeatureFlag;
}

export const AssociatedFeature = ({ flag }: AssociatedFeatureProps) => {
  const navigate = useNavigateEnvironment();
  const handleClick = () => navigate(`features/${flag.featureId}`);

  const miniObjectColumns = [
    {
      id: "feature_name",
      header: "Feature Name",
      accessorKey: "feature_name",
      cell: () => {
        return (
          <div className="flex flex-row items-center">
            <IconRound name={flag?.feature?.icon as IconNameTypes} size="sm" />
            <div className="flex flex-col ml-3 space-y-1">
              <div className="leading-none font-medium">
                {flag?.feature?.name}
              </div>
              <div className="leading-none text-gray-400">
                {flag?.feature?.id}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: "feature_type",
      header: "Type",
      accessorKey: "feature_type",
      cell: () => {
        return (
          flag?.feature?.featureType && (
            <FeatureTypeCell
              featureType={flag?.feature?.featureType as FeatureType}
            />
          )
        );
      },
    },
    // {
    //   id: "feature_plans",
    //   header: "Plans",
    //   accessorKey: "feature_plans",
    //   cell: () => {
    //     const plans = (flag?.feature?.planEntitlements || []).map(
    //       (entitlement) => entitlement.plan,
    //     );

    //     return <PillGroup items={plans} />;
    //   },
    // },
    // {
    //   id: "used_by",
    //   header: "Used By",
    //   accessorKey: "used_by",
    //   cell: () => {
    //     return (
    //       <div>{flag?.feature?.companyOverrides?.length || 0} Companies</div>
    //     );
    //   },
    // },
    {
      id: "actions",
      header: "",
      accessorKey: "actions",
      cell: () => {
        return (
          <div className="flex flex-row items-end justify-end">
            <DropdownDots
              links={[
                {
                  label: "View",
                  onClick: handleClick,
                },
              ]}
            />
          </div>
        );
      },
    },
  ];

  return (
    <MiniObject
      title="Associated feature"
      columns={miniObjectColumns}
      onClick={handleClick}
      className="mb-8"
    />
  );
};
