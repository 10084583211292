import * as ReactToast from "@radix-ui/react-toast";
import cx from "classnames";
import React from "react";

interface ToastProps {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  position?: "bottom-right" | "center";
  duration?: number;
}

export const Toast = ({
  title,
  description,
  open,
  setOpen,
  position = "bottom-right",
  duration = 5000,
}: ToastProps) => {
  const positionStyle = {
    "bottom-right": "fixed bottom-0 right-0",
    center: "absolute left-1/2 -translate-x-1/2",
  };

  return (
    <ReactToast.Provider>
      <ReactToast.Root
        className="bg-white border border-gray-400/20 rounded-lg shadow-md py-3 px-6 items-center flex flex-col space-y-4"
        open={open}
        onOpenChange={setOpen}
        duration={duration}
      >
        <ReactToast.Title className="font-medium text-base">
          {title}
        </ReactToast.Title>
        {description && (
          <ReactToast.Description className="m-0 text-xs" asChild>
            {description}
          </ReactToast.Description>
        )}
      </ReactToast.Root>
      <ReactToast.Viewport
        className={cx(
          positionStyle[position],
          "flex flex-col p-6 gap-2.5 max-w-full m-0 list-none z-50 outline-none",
        )}
      />
    </ReactToast.Provider>
  );
};
