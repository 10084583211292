import { get, post, put } from "@data/index";
import {
  CreateEntityTraitDefinitionRequestBody,
  EntityTraitDefinitionResponseData,
  EntityTraitValue,
  GetEntityTraitValuesParams,
  ListEntityTraitDefinitionsParams,
  UpdateEntityTraitDefinitionRequestBody,
} from "@models/api";
import { Count } from "@models/count";

export const countEntityTraitDefinitions = async (
  flt?: ListEntityTraitDefinitionsParams,
): Promise<Count> => {
  return get<Count, ListEntityTraitDefinitionsParams>(
    "entity-trait-definitions/count",
    flt,
  );
};

export const createEntityTraitDefinition = async (
  req: CreateEntityTraitDefinitionRequestBody,
): Promise<EntityTraitDefinitionResponseData> => {
  return post<
    EntityTraitDefinitionResponseData,
    CreateEntityTraitDefinitionRequestBody
  >(`entity-trait-definitions`, req);
};

export const getEntityTraitDefinition = async (
  id: string,
): Promise<EntityTraitDefinitionResponseData> => {
  return get<EntityTraitDefinitionResponseData>(
    `entity-trait-definitions/${id}`,
  );
};

export const listEntityTraitDefinitionValues = async (
  flt?: GetEntityTraitValuesParams & { id: string },
): Promise<EntityTraitValue[]> => {
  if (!flt?.id) {
    return [];
  }

  const { id, ...rest } = flt;

  return get<EntityTraitValue[], GetEntityTraitValuesParams>(
    `entity-trait-definitions/${id}/values`,
    { ...rest },
  );
};

export const listEntityTraitDefinitions = async (
  flt?: ListEntityTraitDefinitionsParams,
): Promise<EntityTraitDefinitionResponseData[]> => {
  return get<
    EntityTraitDefinitionResponseData[],
    ListEntityTraitDefinitionsParams
  >("entity-trait-definitions", flt);
};

export const updateEntityTraitDefinition = async (
  id: string,
  req: UpdateEntityTraitDefinitionRequestBody,
): Promise<EntityTraitDefinitionResponseData> => {
  return put<
    EntityTraitDefinitionResponseData,
    UpdateEntityTraitDefinitionRequestBody
  >(`entity-trait-definitions/${id}`, req);
};
