import { TableLoader } from "@components/loaders/TableLoader";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import useTablePagination from "@hooks/useTablePagination";
import { UserDetailResponseData, ListUsersParams } from "@models/api";
import * as api from "@modules/companyUsers/queries";
import { CompanyUserSubview } from "@modules/companyUsers/types";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import { UserInitials } from "@ui/UserInitials";
import { formatDate } from "@utils/date";
import pluralize from "pluralize";
import React, { useEffect, useMemo, useState } from "react";

import { CompanyUsersBlankState } from "../blank-states/CompanyUsersBlankState";

export interface CompanyUsersTableProps {
  companyId: string;
}

export const CompanyUsersTable = ({ companyId }: CompanyUsersTableProps) => {
  const navigate = useNavigateEnvironment();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListUsersParams>({ companyId });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? { companyId } : { companyId, q: searchTerm });
  }, [companyId, searchTerm]);

  const columns = useMemo<ColumnDef<UserDetailResponseData>[]>(
    () => [
      {
        id: "lastSeen",
        header: "Last Seen",
        accessorKey: "lastSeen",
        cell: (cellInfo) => {
          const user = cellInfo.row.original;
          return (
            <div className="leading-none text-gray-400">
              {user.lastSeenAt ? (
                <div className="leading-none text-gray-400">
                  {formatDate(user.lastSeenAt)}
                </div>
              ) : (
                "Never"
              )}
            </div>
          );
        },
      },
      {
        id: "user",
        header: "User",
        accessorKey: "user",
        cell: (cellInfo) => {
          const user = cellInfo.row.original;
          return <UserInitials name={user.name} showName={true} size="xs" />;
        },
      },
    ],
    [],
  );

  const onRowClick = (row: UserDetailResponseData) => {
    navigate(`users/${row.id}/${CompanyUserSubview.Overview}`);
  };

  const getHeaderText = (count: number) => {
    return pluralize("User", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<UserDetailResponseData, ListUsersParams>(
    ["users"],
    api.listCompanyUsers,
    api.countCompanyUsers,
    filter,
    getHeaderText,
  );

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noCompanyUsersCreated = countQuery?.data?.count === 0;

  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderCompanyUsersTable = () => {
    switch (true) {
      case noCompanyUsersCreated:
        return <CompanyUsersBlankState />;
      case loading:
        return <TableLoader />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              onRowClick={onRowClick}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
              className="mt-8"
            />
          )
        );
    }
  };

  return (
    <>
      {!noCompanyUsersCreated && (
        <TableHeader
          headerText={headerText}
          searchPlaceholder="Find user"
          onSearch={handleSearch}
        />
      )}
      {renderCompanyUsersTable()}
    </>
  );
};
