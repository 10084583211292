import { NotFound } from "@components/blanks/pages/NotFound";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { getFeatureFlag } from "@data/flags";
import { useContextQuery } from "@hooks/useContextQuery";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { Feature, FeatureFlag } from "@models/feature";
import { FlagSubview } from "@modules/features/types";
import { ColumnDef } from "@tanstack/react-table";
import { DropdownDots } from "@ui/DropdownDots";
import { MiniObject } from "@ui/MiniObject";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { FeatureFlagTargetingTab } from "./FeatureFlagTargetingTab/FeatureFlagTargetingTab";

export const FeatureFlagTab = () => {
  const { feature } = useOutletContext<{ feature: Feature }>();
  const flagId = feature.flags[0]?.id;

  // The flag object included within features does not include rules for the
  // flag, so re-fetch the flag here so that we have its rules for the current
  // environment.
  const { data: flag, isLoading } = useContextQuery({
    queryKey: [`flag`, flagId],
    queryFn: () => getFeatureFlag(flagId),
    retry: false,
  });

  const navigate = useNavigateEnvironment();
  const handleClick = useCallback(
    () => navigate(`flags/${flagId}/${FlagSubview.Targeting}`),
    [flagId, navigate],
  );

  const miniObjectColumns = useMemo<ColumnDef<FeatureFlag>[]>(() => {
    if (!flag) return [];

    const variations = [
      ...new Set(
        [
          flag.defaultValue,
          ...(flag.rules || []).map((rule) => rule.value),
        ].filter((v) => typeof v !== "undefined"),
      ),
    ];

    return [
      {
        id: "flag_name",
        header: "Flag Name",
        accessorKey: "flag_name",
        cell: () => {
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium">{flag.name}</div>
                <div className="leading-none text-gray-400">{flag.key}</div>
              </div>
            </div>
          );
        },
      },
      {
        id: "variations",
        header: "Variations",
        accessorKey: "variations",
        cell: () => {
          return (
            <>
              {variations.map((variation, i) => (
                <span className="pr-1" key={i}>
                  {variation ? "🟢" : "🔴"}
                </span>
              ))}
            </>
          );
        },
      },
      {
        id: "last_checked",
        header: "Last Checked",
        accessorKey: "last_checked",
        cell: () => {
          return (
            <div>
              {flag.lastCheckedAt
                ? moment(flag.lastCheckedAt).fromNow()
                : "Never"}
            </div>
          );
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: () => {
          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "View",
                    onClick: handleClick,
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [flag, handleClick]);

  if (isLoading) {
    return <SinglePageLoader />;
  } else if (!flag) {
    return <NotFound />;
  }

  return (
    <div className="pt-8">
      <MiniObject
        title="Associated Flag"
        description="Choose a flag that is associated with this feature. When entitlements or overrides are added to this feature, it will apply those rules to this flag’s targeting so that companies have the right access based on their plan or exceptions."
        columns={miniObjectColumns}
        onClick={handleClick}
        size="sm"
      />
      <FeatureFlagTargetingTab flag={flag} />
    </div>
  );
};
