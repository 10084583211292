export enum EventStatus {
  Pending = "pending",
  Enriched = "enriched",
  Failed = "failed",
  Unknown = "unknown",
  Processed = "processed",
}

export enum EventType {
  Track = "track",
  Identify = "identify",
  FlagCheck = "flag_check",
}
