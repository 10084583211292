import { BlankStateOnCreate, PageBlankState } from "@ui/PageBlankState";

export const FeatureFlagsBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <PageBlankState
      title="⛳️ Create your first flag"
      description={
        <>
          Create a feature flag and roll it out to user, attach it to <br />
          plans, and target based on usage.
        </>
      }
      buttons={
        onCreate && [
          {
            color: "blue",
            children: <>Create a flag</>,
            disabled,
            onClick: onCreate,
          },
        ]
      }
    />
  );
};
