import { RouteObject } from "react-router-dom";
import { ComponentView, ComponentsView } from "./components";

export const componentRoutes: RouteObject[] = [
  { path: "components", element: <ComponentsView /> },
  {
    path: "components/:id",
    element: <ComponentView />,
  },
];
