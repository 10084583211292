import { NotFound } from "@components/blanks/pages/NotFound";
import { FeatureFlag } from "@models/feature";

import { useOutletContext } from "react-router-dom";
import { FeatureFlagTargetingTab } from "./FeatureFlagTargetingTab/FeatureFlagTargetingTab";

export const FlagTargetingTab = () => {
  const { flag } = useOutletContext<{ flag: FeatureFlag }>();

  if (!flag) {
    return <NotFound />;
  }

  return <FeatureFlagTargetingTab flag={flag} showFeature={true} />;
};
