import { Tabs } from "@components/ui/Tabs";
import { useEditor } from "@craftjs/core";
import { type EmbedContextProps } from "@schematichq/schematic-react";
import { createElement, useState } from "react";
import FontPicker from "react-fontpicker-ts";
import { ComponentsList } from "./ComponentsList";
import * as Settings from "./Settings";
import { Checkbox, Color, Columns, Select, TextInput, Toggle } from "../../ui";

type TypographyKey = keyof EmbedContextProps["settings"]["theme"]["typography"];

const typographyOptions: { value: TypographyKey; label: string }[] = [
  {
    value: "heading1",
    label: "Heading 1",
  },
  {
    value: "heading2",
    label: "Heading 2",
  },
  {
    value: "heading3",
    label: "Heading 3",
  },
  {
    value: "heading4",
    label: "Heading 4",
  },
  {
    value: "heading5",
    label: "Heading 5",
  },
  {
    value: "heading6",
    label: "Heading 6",
  },
  { value: "text", label: "Text" },
  { value: "link", label: "Link" },
];

const Wrapper = ({ children }: { children?: React.ReactNode }) => (
  <div className="min-w-[21.5rem] max-w-[21.5rem] h-full bg-white border-l border-gray-300 drop-shadow-lg overflow-hidden overflow-y-auto pb-8">
    {children}
  </div>
);

export const Sidebar = () => {
  const [selectedTypography, setSelectedTypography] =
    useState<TypographyKey>("heading1");

  const { actions, selected, settings } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;

    let selected;
    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
      settings: state.nodes.ROOT.data.props
        .settings as EmbedContextProps["settings"],
    };
  });

  if (selected?.settings) {
    return <Wrapper>{createElement(selected.settings)}</Wrapper>;
  }

  return (
    <Wrapper>
      <Tabs
        style="compact"
        className="mt-6"
        data={[
          {
            key: "elements",
            name: "Elements",
            content: <ComponentsList />,
          },
          {
            key: "design",
            name: "Design",
            content: (
              <Settings.Root
                description="Define styling for all elements within this component."
                bordered={false}
              >
                <Settings.Section>
                  <Settings.Header>General</Settings.Header>
                  <Settings.Input label="Columns">
                    <Columns />
                  </Settings.Input>
                  <Settings.Input label="Sections">
                    <Toggle
                      value={settings.theme.sectionLayout}
                      onChange={(value) => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          if (value !== "merged" && value !== "separate")
                            return;
                          props.settings.theme.sectionLayout = value;
                        });
                      }}
                      options={[
                        {
                          label: "Merged",
                          value: "merged",
                        },
                        {
                          label: "Separate",
                          value: "separate",
                        },
                      ]}
                    />
                  </Settings.Input>
                  <Settings.Input label="Primary Color">
                    <Color
                      value={settings.theme.primary}
                      onChange={(value) => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          props.settings.theme.primary = value;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Secondary Color">
                    <Color
                      value={settings.theme.secondary}
                      onChange={(value) => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          props.settings.theme.secondary = value;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Text">
                    <Color
                      value={settings.theme.typography.text.color}
                      onChange={(value) => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          props.settings.theme.typography.text.color = value;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Link">
                    <Color
                      value={settings.theme.typography.link.color}
                      onChange={(value) => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          props.settings.theme.typography.link.color = value;
                        });
                      }}
                    />
                  </Settings.Input>
                </Settings.Section>

                <Settings.Section>
                  <Settings.Header>Card Styling</Settings.Header>

                  <Settings.Input label="Background Color">
                    <Color
                      value={settings.theme.card.background}
                      onChange={(value) => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          props.settings.theme.card.background = value;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Border Radius">
                    <TextInput
                      suffix="px"
                      defaultValue={settings.theme.card.borderRadius}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        const updated = parseInt(event.target.value);
                        if (isNaN(updated)) return;
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          props.settings.theme.card.borderRadius = updated;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Card Shadow">
                    <Checkbox
                      checked={settings.theme.card.hasShadow}
                      onChange={() => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          const updated = !props.settings.theme.card.hasShadow;
                          props.settings.theme.card.hasShadow = updated;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Card Padding">
                    <TextInput
                      suffix="px"
                      value={settings.theme.card.padding}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        const updated = parseInt(event.target.value);
                        if (isNaN(updated)) return;
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          props.settings.theme.card.padding = updated;
                        });
                      }}
                    />
                  </Settings.Input>
                </Settings.Section>
                <Settings.Section>
                  <Settings.Header>Tyopgraphy</Settings.Header>
                  <Select
                    className="h-10"
                    name="typography-menu"
                    placeholder="Select an option"
                    options={typographyOptions}
                    value={typographyOptions.find(
                      (opt) => opt.value === selectedTypography,
                    )}
                    onChange={(option) => {
                      option && setSelectedTypography(option.value);
                    }}
                    isMulti={false}
                  />
                  <Settings.Input label="Font">
                    <FontPicker
                      defaultValue={
                        settings.theme.typography[selectedTypography].fontFamily
                      }
                      value={(font: string) => {
                        actions.setProp("ROOT", (prop) => {
                          prop.settings.theme.typography[
                            selectedTypography
                          ].fontFamily = font;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Font Size">
                    <TextInput
                      suffix="px"
                      value={
                        settings.theme.typography[selectedTypography].fontSize
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          const updated = parseInt(event.target.value);
                          if (isNaN(updated)) return;
                          props.settings.theme.typography[
                            selectedTypography
                          ].fontSize = updated;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Font Color">
                    <Color
                      value={
                        settings.theme.typography[selectedTypography].color
                      }
                      onChange={(value) => {
                        actions.setProp("ROOT", (props: EmbedContextProps) => {
                          props.settings.theme.typography[
                            selectedTypography
                          ].color = value;
                        });
                      }}
                    />
                  </Settings.Input>
                  <Settings.Input label="Font Weight">
                    <Select
                      className="w-28 h-[1.875rem]"
                      options={new Array(7).fill(0).map((_, i) => {
                        const n = (i + 2) * 100;
                        return {
                          label: n.toString(),
                          value: n,
                        };
                      })}
                      defaultValue={{
                        value:
                          settings.theme.typography[selectedTypography]
                            .fontWeight,
                        label:
                          settings.theme.typography[
                            selectedTypography
                          ].fontWeight.toString(),
                      }}
                      rawValue={settings.theme.typography[selectedTypography]}
                      onChange={(option) => {
                        option &&
                          actions.setProp("ROOT", (prop) => {
                            prop.settings.theme.typography[
                              selectedTypography
                            ].fontWeight = option.value;
                          });
                      }}
                      isMulti={false}
                    />
                  </Settings.Input>
                </Settings.Section>
              </Settings.Root>
            ),
          },
        ]}
        selected="elements"
      />
    </Wrapper>
  );
};
