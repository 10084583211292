// Common Links:

export enum Common {
  DOCS = "https://docs.schematichq.com/",
  SUPPORT = "https://support.schematichq.com/",
}

// Wiki Article Links:
export enum Article {
  IDENTIFY_COMPANY_AND_USERS = "https://sunset-coelurus-e64.notion.site/Docs-MVP-e9824df9f89b4d52913581bd6db6a01c#0c737ad0495e4512a6b11d45b2f7abc5",
  CREATE_FEATURE = "https://sunset-coelurus-e64.notion.site/Docs-MVP-e9824df9f89b4d52913581bd6db6a01c#858eb77997964b7c89040b17f020ec66",
  EMIT_EVENTS_TO_SCHEMATIC = "https://sunset-coelurus-e64.notion.site/Docs-MVP-e9824df9f89b4d52913581bd6db6a01c#9f40cefd454c4cad940fd35805d985dc",
  CREATE_PLAN = "https://sunset-coelurus-e64.notion.site/Docs-MVP-e9824df9f89b4d52913581bd6db6a01c#7ae3aeb9bfb54d17a5f7b0680e260db7",
}
