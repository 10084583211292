export const coerceDate = (date: Date | string): Date => {
  if (typeof date === "string") {
    return new Date(date);
  }

  return date;
};

export const formatDate = (maybeDate?: Date | string): string => {
  if (!maybeDate) return "";

  const options: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const date = coerceDate(maybeDate);
  if (date.toString() === "Invalid Date" && typeof maybeDate === "string") {
    // If we can't format it as a date, just return the input string
    return maybeDate;
  } else if (date.toString() === "Invalid Date") {
    // This case should never happen, as if maybeDate is not a string, it is a date already, and
    // thus coerceDate should have had no effect
    return "";
  }

  return coerceDate(date).toLocaleDateString("en-US", options);
};

export const formatReadableDate = (date: Date): string => {
  if (!date) return "";

  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
};

export const isDateBeforeCurrent = (date: Date): boolean => {
  return date.getTime() < new Date().getTime();
};
