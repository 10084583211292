import { FeatureType } from "@models/feature";
import { FeatureCompany, FeatureUsage } from "@models/usage";
import { ProgressBar } from "@ui/ProgressBar";

export interface EntitlementUsageCellProps {
  featureUsage: FeatureUsage | FeatureCompany;
  parent?: "table" | "company-sidebar" | "feature-sidebar";
}

export const EntitlementUsageCell = ({
  featureUsage,
  parent = "table",
}: EntitlementUsageCellProps) => {
  const { feature, usage, allocation } = featureUsage;
  if (!feature) return <></>;

  // For boolean, show events count from last 60 days without progress bar
  if (
    feature.featureType === FeatureType.Boolean &&
    typeof usage === "undefined"
  ) {
    return <></>;
  } else if (feature.featureType === FeatureType.Boolean) {
    return (
      <>
        {parent == "table" && (
          <div>
            {usage} <span className="text-gray-400">events in last 60</span>
          </div>
        )}
        {parent == "feature-sidebar" && (
          <div className="text-xs text-gray-400/60 font-bold  leading-1">
            {usage} events
            <br />
            in last 60
          </div>
        )}
        {parent == "company-sidebar" && (
          <div className="text-3xl leading-none">{usage}</div>
        )}
      </>
    );
  }

  const pctUsed =
    allocation && typeof usage !== "undefined"
      ? 100.0 * (usage / allocation)
      : 0;
  let progressColor: "blue" | "red" | "orange" = "blue";
  if (pctUsed >= 100.0) {
    progressColor = "red";
  } else if (pctUsed >= 90.0) {
    progressColor = "orange";
  }

  const usageLimit =
    typeof allocation === "undefined" ? "∞" : allocation.toString();

  return (
    <div className="inline-block space-y-1 w-full">
      <ProgressBar
        color={progressColor}
        progress={pctUsed}
        total={usageLimit}
        value={usage ?? 0}
      />
    </div>
  );
};
